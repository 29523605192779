import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useAddDeliveryBoy = (form) => {
  // console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const addDeliveryBoy = async (param) => {
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_delivery_boy", param);
    const data = await response.data;
    console.log(data, "distributorList");
    if (data.status === 200) {
      toast.success(data.message);
    } else if (data.status === 400) {
      toast.error(data.message);
    } else {
      toast.error("Failed to Add");
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addDeliveryBoy;
};

export default useAddDeliveryBoy;
