import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useApprovalUpdateStatus = (form, status) => {
  // console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateUserDetails = async () => {
    console.log(form, "update_delivery_boy")
    let param = {
      
      db_id: `${form.db_id}`,
      first_name: `${form.first_name}`,
      last_name: `${form.last_name}`,
      user_status: `${form?.user_status}`,
    };
    
    const response = await Axios.post("update_delivery_boy", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  return updateUserDetails;
};

export default useApprovalUpdateStatus;
