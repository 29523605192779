import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateASM = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const distributorId = useSelector(
    (store) => store.distributor.distributorDetails
  );
  const updateASMDetails = async () => {
    let param = {
      name: form?.name,
      user_status: form?.user_status,
      asm_id: form?.asm_id,
    };
    console.log(param, "param");
    const response = await Axios.post("update_asm", param);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  return updateASMDetails;
};

export default useUpdateASM;
