// import { useEffect } from "react";
import { getDashboardData } from "../slice/appSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

// import { getCategoryList } from "../slice/categorySlice";
// import { getSkuList } from "../slice/skuSlice";

const useRetailerCount = () => {
  const Data = useSelector((state) => state.app.curretDropdownData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getRetailerCount = async () => {
    const param = {
      zone_id: Data?.zone_id,
    };
    const response = await Axios.post("/dashboard", param);
    const data = await response.data;

    dispatch(getDashboardData(data.body));    
  };

  return getRetailerCount;
};

export default useRetailerCount;
