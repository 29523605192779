import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getsalesmanPartners } from "../slice/adminMapSlice";


const useSalesmanListmap = () => {
  const zoneData = useSelector((state) => state.SFstatus);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getSalesman = async (param) => {
    const response = await Axios.post("get_salesman", param);
    const data = await response.data;
    
    dispatch(getsalesmanPartners(data));
   
  };
  return getSalesman;                                         
};

export default useSalesmanListmap;