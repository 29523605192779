import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
// import NavBar from "./NavBar";
import Footer from "./Footer";
import NavBar from "./NavBar";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./ContactUs.css";

const ContactUs = () => {
  const url = `https://3zvs1zjf1d.execute-api.ap-south-1.amazonaws.com/vitaran-live/live-contact`;
  const token = `G8r37$BloY61B@Du09LOw#cnxy45Q`;
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Access the form data stored in the `data` state
    console.log(data, "SubmitData");
    if (!validateForm()) {
      return;
    }
    setLoading(true); // Set loading state to true

    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        console.log(response.data, "SubmitDataform");
        setFormData(response.data);

        if (response.data?.code === 200) {
          toast.success(response.data?.message);

          setData({
            name: "",
            mobile: "",
            email: "",
            message: "",
          });
        } else {
          toast.warning(response.data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error, "error submitting");
        setLoading(false);
      });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
  
    if (!data.name.trim()) {
      newErrors.name = "Please enter a valid name";
      isValid = false;
    }
  
    if (!data.mobile || !isNumeric(data.mobile) || data.mobile.length !== 10) {
      newErrors.mobile = "Please enter a valid mobile number (10 digits only).";
      isValid = false;
    }
  
    if (!data.message || data.message.length > 2000) {
      newErrors.message = "Please enter a valid message (up to 2000 characters).";
      isValid = false;
    }
  
    setErrors(newErrors);
    return isValid;
  };
  
  // Helper function to check if the input is a numeric value
  const isNumeric = (input) => {
    return !isNaN(input);
  };
  
  return (
    <>
      {/* <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      <Stack sx={{ backgroundColor: "white", color: "black" }}>
        <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
          <NavBar />
        </Stack>
        <Stack>
          <Stack
            sx={{ display: { xs: "flex", lg: "none" } }}
            mt={{ xs: "2rem", lg: "0" }}
            ml={{ xs: "8px", lg: "0" }}
          >
            <RouterLink
              style={{ textDecoration: "none", color: "black" }}
              to={"/"}
            >
              <KeyboardArrowLeftIcon />
            </RouterLink>
          </Stack>
        </Stack>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          width={{ xs: "90%", lg: "60%" }}
          m={"auto"}
          justifyContent={{ xs: "none", lg: "space-between" }}
          alignItems={"center"}
          height={{ xs: "", lg: "100vh" }}
          gap={{ xs: 5, lg: 2 }}
        >
          <Stack direction={"column"}>
            <Stack mb={{ xs: "0", lg: "1rem" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "30px", color: "#424063" }}
              >
                Contact Us
              </Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Stack >
                <MailOutlineIcon sx={{ color: "#313131", fontSize: "1.5rem", visibility:"hidden" }} />
              </Stack>
              <Stack>
                <Typography sx={{ fontWeight: "bold",fontSize:"1.2rem", color: "#313131" }} type="email">
                Vitaran India LLP
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Stack>
                <LocationOnOutlinedIcon sx={{ color: "#313131", fontSize: "1.5rem" }} />
              </Stack>
              <Stack>
                <Typography sx={{ color: "#313131" }} type="email">
                89, Ajanta Commercial Centre, Income Tax Office, Ashram Road, Ahmedabad, Gujarat, 380014, India
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Stack>
                <CallOutlinedIcon sx={{ color: "#313131", fontSize: "1.5rem" }} />
              </Stack>
              <Stack>
                <Typography sx={{ color: "#313131" }} type="email">
                +91-9611911992
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Stack>
                <MailOutlineIcon sx={{ color: "#313131", fontSize: "1.5rem" }} />
              </Stack>
              <Stack>
                <Typography sx={{ color: "#313131" }} type="email">
                ashish.s@vitarangt.com
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Stack gap={1.5}>
              <Stack sx={{marginTop:"-3rem"}}>
                <Typography
                  sx={{ fontWeight: "bold", color: "#424063" }}
                  fontSize={{ xs: "20px", lg: "30px" }}
                >
                  Send us a message!
                </Typography>
              </Stack>
              <Stack>
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">Your Name*</InputLabel>
                  <FormControl error={!!errors.name} fullWidth>
                    <TextField
                      variant="outlined"
                      value={data.name}
                      name="name"
                      onChange={handleChange}
                      sx={{
                        backgroundColor: "#EFEFEF",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                    {errors.name && (
                      <FormHelperText error>{errors.name}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack>
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">Mobile No.*</InputLabel>
                  <FormControl error={!!errors.mobile} fullWidth>
                    <TextField
                      variant="outlined"
                      value={data.mobile}
                      name="mobile"
                      sx={{
                        backgroundColor: "#EFEFEF",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                      onChange={handleChange}
                    />
                    {errors.mobile && (
                      <FormHelperText error>{errors.mobile}</FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
              <Stack>
                <Stack width={"20rem"}>
                  <InputLabel className="label-text">Email ID</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      sx={{
                        backgroundColor: "#EFEFEF",
                        "& .MuiOutlinedInput-input": {
                          color: "black !important",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                      variant="outlined"
                      value={data.email}
                      name="email"
                      onChange={handleChange}
                      type="email"
                    />
                  </FormControl>
                </Stack>
              </Stack>
              <Stack>
                <Stack width={{ xs: "20rem", lg: "20rem" }}>
                  <InputLabel className="label-text">Message*</InputLabel>
                  {/* <FormControl error={!!errors.message} fullWidth> */}
                  <TextField
                    variant="outlined"
                    value={data.message}
                    multiline
                    rows={4}
                    name="message"
                    onChange={handleChange}
                    className="TextBlack"
                    sx={{
                      backgroundColor: "#EFEFEF",

                      "& .MuiOutlinedInput-input": {
                        color: "black !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                    }}
                    InputProps={{
                      sx: {
                        "& input": {
                          color: "black",
                        },
                      },
                    }}
                    style={{ color: "black" }} //
                  />
                  {errors.message && (
                    <FormHelperText error>{errors.message}</FormHelperText>
                  )}
                  {/* </FormControl> */}
                </Stack>
              </Stack>
              <Stack
                sx={{
                  // width: "5rem",
                  // backgroundColor: "#424063",
                  borderRadius: "5px",
                }}
                mt={{ xs: "2rem", lg: "1rem" }}
                mb={{ xs: "5rem", lg: "1rem" }}
              >
                <Button
                  type="submit"
                  sx={{
                    color: "white",
                    width: "5rem",
                    backgroundColor: "#424063",
                    "&:hover": {
                      backgroundColor: "#424063", // Keep the same color on hover
                      boxShadow: "none", // Remove the box shadow on hover
                    },
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size="1.5rem" sx={{ color: "white" }} />
                  ) : (
                    "Send"
                  )}
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
        <Stack>
          <Footer />
        </Stack>
      </Stack>
    </>
  );
};

export default ContactUs;