import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getReportedRetailers } from "../slice/retailerSlice";

const useReportedRetailersList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const currentzone = useSelector((state) => state.SFstatus.zone_id);

  const getReportedRetailersList = async () => {
    
    if (currentzone) {
      const param = {
        zone_id: currentzone
      };
      try {
        const response = await Axios.post("fake_retailer_list", param);
        const data = await response.data;
        console.log(data, "fake_retailer_list");
        dispatch(getReportedRetailers(data.body));
      } catch (error) {
        console.error('Error fetching retailer list:', error);
      }
    }
  };

  return getReportedRetailersList;
};

export default useReportedRetailersList;