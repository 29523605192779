import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateSalesman = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const distributorId=useSelector((store)=>store.distributor.distributorDetails)
  const updateSalesmanDetails = async () => {
    
    let param = {
      first_name:form?.first_name,
      last_name:form?.last_name,
      user_status:form?.user_status,
      retailer_id: form?.retailer_id

    };
    console.log(param,"param")
    const response = await Axios.post("update_salesman", param);
    const data = await response.data;
    
    
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  return updateSalesmanDetails;
};

export default useUpdateSalesman;
