import React, { Fragment, useState } from "react";

import Styles from "./styles/sales.module.css";
import styles from "../UserManagement.js/styles/index.module.css";
import Carousel from "react-multi-carousel";
// import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@mui/styles";
import SalesMidContainer from "./SalesMidContainer";
import SalesMidContainerOAO from "./SalesMidContainerOAO";
import { alpha } from "@material-ui/core/styles";
import { convertDate } from "../Functions/function";
import { useDispatch, useSelector } from "react-redux";
import SalesTopContainer from "./SalesTopContainer";
import Sidebar from "../Sidebar";
import useSaleDetails from "../../hooks/useSaleDetails";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import useSaleDetailsOAO from "../../hooks/useSaleDetailsOAO";

const useStyles = makeStyles({
  date: {
    width: 180,
  },
});

const SalesMgt = ({ zone, Statevalue }) => {
  // const stateList = useSelector(state => state.Zone.StateList);
  const Firsttoken = useSelector((state) => state.Token);
  const currentZoneId = useSelector(
    (state) => state.app.curretDropdownData?.zone_id
  );
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const [token, setToken] = useState("");
  const [selectedStartDate, handleStartDateChange] = useState(
    new Date() - 1000 * 60 * 60 * 24 * 1
  );
  const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const [param, setParam] = useState({});
  const [role, setRole] = useState("Laabh Sales");
  const [id, setId] = useState({
    route_id: "",
    product_id: "",
    seller_id: "",
  });
  const Item = [
    "Laabh Sales",
    "One and Only Sales",
  ];
  
  const filteredItem = (userData?.role === "partner" && userData?.is_asm === false)
  ? Item.filter((item) => item !== "One and Only Sales")
  : Item;

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);
  const getSalesTableData = useSaleDetails();
  const getSalesDetailsForOneAndOnly = useSaleDetailsOAO();
  React.useEffect(() => {
    // console.log(
    //   convertDate(selectedStartDate),
    //   convertDate(selectedEndDate),
    //   "dates"
    // );
    const data = {
      zone_id: currentZoneId,
      from: convertDate(selectedStartDate),
      to: convertDate(selectedEndDate),
    };
    const response = getSalesTableData(data);
    getSalesDetailsForOneAndOnly(data);
  }, [selectedStartDate, selectedEndDate, currentZoneId]);

  const IdChange = (RID, PID, SID) => {
    setId({
      ...id,
      route_id: `${RID}`,
      product_id: `${PID}`,
      seller_id: `${SID}`,
    });
  };

  const Submit = (RID, PID, SID, check) => {
    let param1 = {
      admin_id: "1",
      zone_name: `${zone}`,
      from_date: `${convertDate(selectedStartDate)}`,
      to_date: `${convertDate(selectedEndDate)}`,
      route_id: `${RID}`,
      product_id: `${PID}`,
      seller_id: `${SID}`,
    };

  };
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div className={Styles.SalesContainer}>
        <Stack sx={{flexDirection:"column"}}>
        <div className={Styles.TopContainer}>
          <Typography variant="h1" sx={{ fontSize: "24px" }}>
            Sales Management
          </Typography>
          <SalesTopContainer
            selectedStartDate={selectedStartDate}
            handleStartDateChange={handleStartDateChange}
            selectedEndDate={selectedEndDate}
            handleEndDateChange={handleEndDateChange}
          />
        </div>
        </Stack>
        <Stack >
        <Carousel responsive={responsive} arrows={false} swipeable={true}>
        {filteredItem.map((item, index) => (
          <div
            className={styles.UserManagementCarousel_sales}
            onClick={() => setRole(item)}
          >
            <div
              key={index}
              className={
                role === `${item}`
                  ? styles.UserManagementCarouselLabelWrapperOne
                  : styles.UserManagementCarouselLabelWrapperTwo
              }
            >
              <label
                className={
                  role === `${item}`
                    ? styles.UserManagementCarouselLabelOne
                    : styles.UserManagementCarouselLabelTwo
                }
              >
                {item}
              </label>
            </div>
          </div>
        ))}
      </Carousel>
        </Stack>
        {role === "Laabh Sales" ? (
        <SalesMidContainer
          IdChange={IdChange}
          Submit={Submit}
          Statevalue={Statevalue}
          zone={zone}
        />
        ): 
        <SalesMidContainerOAO
          IdChange={IdChange}
          Submit={Submit}
          Statevalue={Statevalue}
          zone={zone}
        />
        }
      </div>
    </div>
  );
};

export default SalesMgt;

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };