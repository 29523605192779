import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getSaleDetails } from "../slice/saleSlice";

const useSaleDetails = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSaleDetails = async (param) => {
    
    const response = await Axios.post("sale_details", param);
    const data = await response.data;
    console.log(data.body, "sale")
    dispatch(getSaleDetails(data.body));
  };
  
    return getAllSaleDetails;
  
};

export default useSaleDetails;
