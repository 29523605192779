import React, { useEffect } from "react";

import { Card, Stack, Typography } from "@mui/material";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashboardTable from "./DashboardTable";
import useASMDashboard from "../../hooks/useASMDashboard";
import { StackedBarChart } from "@mui/icons-material";
import BarGraph from "./BarGraph";
import { StackedECOGraph } from "./StackedECOGraph";
import useGraphData from "../../hooks/useGraphData";
import TimeBarGraph from "./TimeBarGraph";
import useBoundryData from "../../hooks/useBoundryData";
import useMapData from "../../hooks/useMapData";
const DashboardContainer = () => {
  const [retailerCount, setRetailerCount] = React.useState(0);
  const getASMDashboardData = useASMDashboard();
  const getDashboardData = useGraphData();
  const Data = useSelector((state) => state.app.curretDropdownData);
  const DashboardData = useSelector((state) => state.app.asmDashboardData);
  const adminMapData = useSelector((state) => state.adminMap.getBoundryDetails);
  useEffect(() => {
    const fetchData = async () => {
      const data = await getASMDashboardData();
      setRetailerCount(data);
    };
  
    // Call the function initially
    fetchData();
    getDashboardData();
    // Set the interval to call the function every 15 seconds
    const id = setInterval(fetchData, 15000);
  
    // Cleanup the interval on component unmount
    return () => clearInterval(id);
  }, [Data?.zone_id]);


  return (
    <Stack padding={1} sx={{ width: "100%",display:"flex",flexDirection:"column",gap:"0.5rem", overflow:"hidden" }}>
      <Stack sx={{ gap: "12px" }}>
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E47D03",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.activeSF}/{adminMapData?.sf_details?.length}
            </Typography>
            <Typography variant="h6" color="initial">
              Boundaries
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E40303",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.totalSM}
            </Typography>
            <Typography variant="h6" color="initial">
              SRs
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #3003E4",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.totalRetailers}
            </Typography>
            <Typography variant="h6" color="initial">
              Retailers
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #E403C0",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.total_calls}/{DashboardData?.totalSM * 40}
            </Typography>
            <Typography variant="h6" color="initial">
              Calls
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              borderBottom: "3px solid #3003E4",
              boxShadow: "0px 4px 8px 0px #0000004D",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.no_of_sales_calls}/{DashboardData?.total_calls}
            </Typography>
            <Typography variant="h6" color="initial">
              ECO
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
              boxShadow: "0px 4px 8px 0px #0000004D",
              borderBottom: "3px solid #15E403",
            }}
          >
            <Typography variant="h3" color="initial">
              {DashboardData?.today_sales || 0}
            </Typography>
            <Typography variant="h6" color="initial">
              Sales
            </Typography>
          </Card>
        </Stack>
      </Stack>
      <Stack sx={{ display: "flex", flexDirection: "row", width: "97%" ,gap:"0.5rem" }}>
        <StackedECOGraph />
        <BarGraph />
        <TimeBarGraph />
      </Stack>
      <Stack>
        <DashboardTable tableData={DashboardData?.shape_file_data} />
      </Stack>
    </Stack>
  );
};

export default DashboardContainer;