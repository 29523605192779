import React, { useEffect, useState } from 'react';
import { Modal, Box, TextField, Stack, Typography, Button } from '@mui/material';
import useAddInventoryQty from '../../../hooks/useAddInventoryQty';
import useInventoryList from '../../../hooks/useInventoryList';

const AddInventory = ({ open, handleClose, inputValues }) => {
    const [form, setForm] = useState({
        new_inventory: '',
        new_alert:''
    });

    useEffect(() => {
        setForm(prevForm => ({
          ...prevForm,
          ...inputValues
        }));
      }, [inputValues]);
      

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setForm({ ...form, [name]: value });
    };

    const getInventoruList = useInventoryList();
    const addInventoryQty = useAddInventoryQty();
    const handleSubmit = async() => {
      await addInventoryQty(form);
      handleClose();
      await getInventoruList(form);
      setForm({
        new_inventory: '',
        new_alert:''
    })  
    };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
      BackdropProps={{
        style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
      }}
    >
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 4,
          p: 4,
          width: "90%",
          maxWidth: "500px",
        }}
      >
        <Typography variant="h6" mb={2}>
          Add Stock
        </Typography>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
            <TextField
              value={`${inputValues?.office_name} | ${inputValues?.pincode}`}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
            <TextField
              value={inputValues?.category_name}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
            <TextField
              value={inputValues?.sku_name}
              variant="outlined"
              disabled
              size="small"
              sx={{
                flex: "1 1 45%",
                "& .MuiInputBase-root": {
                  height: "35px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography>Please select Pieces to be added:</Typography>
            <TextField
              variant="outlined"
              size="small"
              name = "new_inventory"
              type='number'
              placeholder={inputValues?.last_updated_qty} 
              onChange={handleInputChange}
              sx={{
                flex: "1 1 45%",
                maxWidth: "140px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  width: "140px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography>Stock Alert @:</Typography>
            <TextField
              variant="outlined"
              size="small"
              name="new_alert"
              type='number' 
              placeholder={inputValues?.alert_qty} 
              onChange={handleInputChange}
              sx={{
                flex: "1 1 45%",
                maxWidth: "140px",
                "& .MuiInputBase-root": {
                  height: "35px",
                  width: "140px",
                },
              }}
            />
          </Box>
        </Stack>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 3, gap: 2 }}
        >
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#fff !important",
              color: "#000 !important",
              fontWeight: "700 !important",
              padding: "6px 12px 6px 12px !important",
              borderRadius: "6px !important",
              border:"1px solid #000 !important"
            }}
          >
            Close
          </Button>
          <Button
            onClick={() =>handleSubmit()}
            sx={{
              backgroundColor: "#DC1D13 !important",
              color: "#fff !important",
              fontWeight: "700 !important",
              padding: "6px 12px 6px 12px !important",
              borderRadius: "6px !important",
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddInventory;
