import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    stateList: null,
    zoneList: null,
    zoneListUpdate: null,
    curretDropdownData: null,
    dashboardData: null,
    shapeIdList: null,
    asmDashboardData: null,
    shapeFileByZone: null,
    selectedTab:null
  },
  reducers: {
    getStateList: (state, action) => {
      state.stateList = action.payload;
    },
    getSelectedTab: (state, action) => {
      state.selectedTab= action.payload;
    },
    getZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    getZoneListUpdate: (state, action) => {
      state.zoneListUpdate = action.payload;
    },
    getCurrentDropdownData: (state, action) => {
      state.curretDropdownData = action.payload;
    },
    getDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    getASMDashboardData: (state, action) => {
      state.asmDashboardData = action.payload;
    },
    getShapeIdList: (state, action) => {
      state.shapeIdList = action.payload;
    },
    getShapeFileList: (state, action) => {
      state.shapeFileByZone = action.payload;
    },
  },
});

export default appSlice.reducer;
export const {
  getZoneList,
  getStateList,
  getCurrentDropdownData,
  getZoneListUpdate,
  getDashboardData,
  getShapeIdList,
  getASMDashboardData,
  getShapeFileList,
  getSelectedTab
} = appSlice.actions;
