import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboardStateList: null,
    dashboardZoneList: null,
    graphDetails: null,
  },
  reducers: {
    getDashboardStateList: (state, action) => {
      state.dashboardStateList = action.payload;
    },
    getGraphDetails: (state, action) => {
      state.graphDetails = action.payload;
    },
    getDashboardZoneList: (state, action) => {
      state.dashboardZoneList = action.payload;
    },
  },
});

export default dashboardSlice.reducer;
export const { getDashboardStateList, getDashboardZoneList, getGraphDetails } =
  dashboardSlice.actions;
