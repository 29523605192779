import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateInventorySkuStatus = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateInventorySKUstatus = async (param) => {
    
    const response = await Axios.post("v2/update_inventory_sku_status", param);
    const data = await response.data;

    console.log(data, "parameter");
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateInventorySKUstatus;
};

export default useUpdateInventorySkuStatus;
