import React from "react";
import Sidebar from "../Sidebar";
import UserManagement from "./index";
import { Stack } from "@mui/material";
import DashboardHeader from "../Dashboard/AdminHeader";
import { Navigate } from "react-router";

const UserManagementPage = () => {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    
    return <Navigate to="/" />;
  }
  return (
    <Stack>
      <Stack>
        {/* <DashboardHeader /> */}
      </Stack>
      <Stack direction="row">
        <Sidebar />
        <UserManagement />
      </Stack>
    </Stack>
  );
};

export default UserManagementPage;
