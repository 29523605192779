import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import skuData from "./data.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
// import useSkuList from "../../hooks/useSkuList";
import ConfirmationDialog from "../../../utility/ConfirmationDialog";
import { useState, useEffect } from "react";
import useDeleteSku from "../../../hooks/useDeleteSku";
import EditGiftSku from "./EditGiftSku";
import useGiftSkuList from "../../../hooks/useGiftSkuList";

export default function GiftSKUManagementTable() {
  const skuGiftList = useSelector((store) => store.sku.skuGiftList);
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [skuId, setSkuId] = useState(null);
  const [skuEditId, setSkuEditId] = useState(null);
  const [skuRowData, setSkuRowData] = useState(null);
  const [open, setOpen] = useState(false);
  const getAllSkuList = useGiftSkuList();
  const deleteSku = useDeleteSku(skuId);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setSkuId(data?.sku_id);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = async() => {
    await deleteSku();
    await getAllSkuList();
    setIsConfirmDialogOpen(false);
  };

  const handleEditClick = (data) => {
    setOpen(true);
    setSkuEditId(data?.sku_id);
    setSkuRowData(data);
  };
  useEffect(() => {
    getAllSkuList();
  }, [isConfirmDialogOpen]);
  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                SI no.
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                Gift Image
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                Gift Name
              </TableCell>

              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Category Name
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Price
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Coins
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skuGiftList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((data, index) => {
                const serialNumber = page * rowsPerPage + index + 1;
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start">{serialNumber}</TableCell>
                      <TableCell align="start" colSpan={3}>
                        <img style={{width:"3rem"}} src={data?.sku_image} />
                      </TableCell>
                      <TableCell align="start" colSpan={3}>
                        {data.sku_name}
                      </TableCell>
                      <TableCell align="start">
                        {data.category_name ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.sp}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data?.laabh_coins??"N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.quantity}
                      </TableCell>
                      <TableCell align="start">
                        {data.status === true ? (
                          <Typography
                            sx={{
                              background: "#6EB443",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              background: "#58585A",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      {userData?.role === "admin" ? (
                      <TableCell align="middle">
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "0.5rem",
                            gap: "0.5rem",
                          }}
                        >
                          <EditIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditClick(data)}
                          />
                          <DeleteIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(data)}
                          />
                        </Stack>
                      </TableCell>
                      ) : null}
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
        <EditGiftSku
          setOpen={setOpen}
          open={open}
          skuEditId={skuEditId}
          skuRowData={skuRowData}
        />
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmApproveClick}
          sku_id={skuId}
          type={2}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={skuGiftList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
