// import blueIcon from "./assets/markersImg/blue.png";
// import greenIcon from "./assets/markersImg/green.png";
// import orangeIcon from "./assets/markersImg/orange.png";
// import pinkIcon from "./assets/markersImg/pink.png";
// import purpleIcon from "./assets/markersImg/purple.png";
// import dotBlackIcon from "./assets/markersImg/dotBlack.png";
// import yellowIcon from "./assets/markersImg/yellow.png";

// //for list
// import greenMarker from "./assets/SEMarkers/green-marker.png"
// import orangeMarker from "./assets/SEMarkers/orange-marker.png"
// import pinkMarker from "./assets/SEMarkers/pink-marker.png"
// import yellowMarker from "./assets/SEMarkers/yellow-marker.png"

import black from "./assets/marker-img/black.png";
import Lgreen from "./assets/marker-img/Lgreen.png";
import LLgreen from "./assets/marker-img/LLgreen.png";
import blue from "./assets/marker-img/blue.png";
import cyan from "./assets/marker-img/cyan.png";
import Dgreen from "./assets/marker-img/Dgreen.png";
import Dskin from "./assets/marker-img/Dskin.png";
import gray from "./assets/marker-img/gray.png";
import maroon from "./assets/marker-img/maroon.png";
import orange from "./assets/marker-img/orange.png";
import pink from "./assets/marker-img/pink.png";
import purple from "./assets/marker-img/purple.png";
import red from "./assets/marker-img/red.png";
import skin from "./assets/marker-img/skin.png";
import white from "./assets/marker-img/white.png";
import yellow from "./assets/marker-img/yellow.png";

import blackPin from "./assets/pin-img/black.png";
import LgreenPin from "./assets/pin-img/Lgreen.png";
import LLgreenPin from "./assets/pin-img/LLgreen.png";
import bluePin from "./assets/pin-img/blue.png";
import cyanPin from "./assets/pin-img/cyan.png";
import DgreenPin from "./assets/pin-img/Dgreen.png";
import DskinPin from "./assets/pin-img/Dskin.png";
import grayPin from "./assets/pin-img/gray.png";
import maroonPin from "./assets/pin-img/maroon.png";
import orangePin from "./assets/pin-img/orange.png";
import pinkPin from "./assets/pin-img/pink.png";
import purplePin from "./assets/pin-img/purple.png";
import redPin from "./assets/pin-img/red.png";
import skinPin from "./assets/pin-img/skin.png";
import whitePin from "./assets/pin-img/white.png";
import yellowPin from "./assets/pin-img/yellow.png";

// export const markerIcon = {
//     0: pinkIcon,
//     1: greenIcon,
//     2: yellowIcon,
//     3: orangeIcon,
//     4: pinkIcon,
//     // 6: purpleIcon,
//     default: dotBlackIcon,
// };

// export const getMarkerIcon = (zoneId) => {
//     const iconMapping = {
//       0: pinkMarker,
//       1: greenMarker,
//       2: yellowMarker,
//       3: orangeMarker,
//       4: pinkMarker,

//       default: greenMarker,
//     };

//     return iconMapping[zoneId] || iconMapping.default;
//   };

export const markerIcon = {
  0: Lgreen,
  1: red,
  2: blue,
  3: pink,
  4: Dgreen,
  5: Dskin,
  6: gray,
  7: maroon,
  8: orange,
  9: cyan,
  10: purple,
  11: LLgreen,
  12: skin,
  13: yellow,
  14: white,
  default: black,
};

export const getMarkerIcon = (zoneId) => {
  const iconMapping = {
    0: LgreenPin,
    1: redPin,
    2: bluePin,
    3: pinkPin,
    4: DgreenPin,
    5: DskinPin,
    6: grayPin,
    7: maroonPin,
    8: orangePin,
    9: cyanPin ,
    10: purplePin,
    11: LLgreenPin,
    12: skinPin,
    13: yellowPin,
    14: whitePin,

    default: blackPin,
  };

  return iconMapping[zoneId] || iconMapping.default;
};
