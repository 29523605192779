import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";

import { getskuInventoryList } from "../slice/skuSlice";


const useInventorySkuList = () => {
  const zoneData = useSelector((state) => state.SFstatus);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getInventorySkuList = async (param) => {
    const response = await Axios.post("get_sku_list", param);
    const data = await response.data;    
    dispatch(getskuInventoryList(data.body));
   
  };
  return getInventorySkuList;
};

export default useInventorySkuList;