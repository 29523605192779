// mySlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {  
    zone_id: '',
    pincode : '',
    shape_id : '',
    status:''
};
//admin assign SE
const SFstatusSlice = createSlice({
  name: 'setSFstatus',
  initialState,
  reducers: {
    setZoneId: (state, action) => {
      state.zone_id = action.payload;
    },
    setSFPincode: (state, action) => {
      state.pincode = action.payload;
    },
    setSFShapeId:(state, action) =>{
      state.shape_id =action.payload;
    },
    setStatus: (state, action) => {
        state.status = action.payload;
      },
    
    resetData: (state) => {
      return initialState;
    },
  },
});

export const { setZoneId, setSFPincode, setSFShapeId, setStatus, resetData } = SFstatusSlice.actions;
export default SFstatusSlice.reducer;

