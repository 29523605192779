import React, { useState } from 'react';
import styles from '../styles/UserMgtWholesalerUpdateForm.module.css';
import CreateIcon from '@mui/icons-material/Create';
import { Paper, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { withStyles, makeStyles } from '@mui/styles';
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { UpdateUserMgt, WholesalerUpdateUserMgt } from '../../../../actions/UserMgt/UserMgt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BaseImgUrl,ShopBaseImgUrl } from '../../../utility/BaseURL';
import { TableconvertDate } from '../../Functions/function';
import useUpdateDistributor from '../../../hooks/useUpdateDistributor';
import useDistributorList from '../../../hooks/useDistributorList';
import useLaabhPartner from '../../../hooks/useLaabhPartners';

// const theme = createTheme({
//     overrides:{
//         MuiFormControl: {
//             root: {
//               height: '56px',
//             },
//           },
//           MuiInputBase: {
//             root: {
//               height: '36px',
//             },
//           },
//     }
// });

const useStyles = makeStyles((theme) => ({
    TextField: {
        color: "black"
    }
}));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));

const GrayButton = styled(Button)(({ theme }) => ({
    color: "#404040",
    backgroundColor: "#E5E5E5",
    '&:hover': {
        backgroundColor: "#E5E5E5",
    },
}));


const UserMgtWholesalerUpdateForm = ({detail, getAllDistributorList}) => {
  const distributorDetails = useSelector(
    (store) => store.distributor.distributorDetails
  );
  const dropDownData = useSelector((state) => state.app.curretDropdownData);
    const Firsttoken = useSelector(state => state.Token)
    const dispatch = useDispatch();
    const classes = useStyles();
    const [token, setToken] = useState('');
    const [form, setForm] = useState({});
    const [formError, setFormError] = useState({});
    const [edit, setEdit] = useState(false);
    // const list = useSelector(state => state.UserMgt.wholesalerList);
    // const detail = useSelector(state => state.UserMgt.wholesalerDetail);
    const[rootForm,setRootForm] = useState({})
    const [Zone, setZone] = React.useState('');

    const [RoleList, setRoleList] = useState(["wholesaler","rider","wd"]);
    const [Role, setRole] = React.useState('');

    const [StatusList, setStatusList] = useState(["active", "inactive"]);
    const [Status, setStatus] = React.useState('');

    const [Updatesuccess, setUpdatesuccess] = useState(false);
    const [obj, setObj] = useState({
        failed: false,
        message: ''
    })
    const [invalidToken, setInvalidToken] = useState(false)

    const handleEdit = () => {
        setEdit(true)
    }
    const handleCancelEdit = () => {
        setEdit(false);
        setForm(rootForm);
        setFormError({})
    }

    // React.useEffect(() => {
    //     if (list && list.length!==0 && list!=="undefined" && list!==null) {
    //         setRootForm(detail)
    //         setForm(detail);
    //         setZone(detail.zone_name);
    //         setRole(detail.role_name);
    //         // dispatch({type:USERMGTID,payload:detail.user_id});
    //         if (detail.status === "partial") {
    //             setStatus("active")
    //         } else {
    //             setStatus(detail.status)
    //         }

    //     }
    // }, [detail])

    React.useEffect(() => {
        if(Firsttoken && Firsttoken.token && Firsttoken.token!==null && Firsttoken.token.length!==0 && Firsttoken!=="undefined"){
          setToken(Firsttoken.token)  
        }
        
    }, [Firsttoken])


    // const IsValid = () => {
    //     let success = true;
    //     let error = {};

    //     if (!form.zone_name.trim()) {
    //         error.zone = "Zone cannot be empty"
    //         success = false;
            
    //     }
    //     if (!form.user_id.toString().trim()) {
    //         error.userid = "userid cannot be empty";
    //         success = false;
            
    //     }
    //     if (!form.role_name.trim()) {
    //         error.role = "role cannot be empty";
    //         success = false;
            
    //     }
    //     if (!form.name.trim()) {
    //         error.name = "name cannot be empty";
    //         success = false;
            
    //     }
    //     if (!form.surname.trim()) {
    //         error.surname = "surname cannot be empty";
    //         success = false;
            
    //     }
    //     if (!form.mobile_no.toString().trim()) {
    //         error.mobileno = "mobileno cannot be empty";
    //         success = false;
            
    //     }
    //     else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.mobile_no)) {
    //         error.mobileno = "Enter a Valid mobileno Number";
    //         success = false
            
    //     }
    //     if (!form.whatsapp_no.toString().trim()) {
    //         error.whatsappno = "whatsappno cannot be empty";
    //         success = false;
            
    //     }
    //     else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.whatsapp_no)) {
    //         error.whatsappno = "Enter a Valid whatsapp Number";
    //         success = false;
            
    //     }

    //     if (!form.address.trim()) {
    //         error.address = "address cannot be empty";
    //         success = false;
            
    //     }


    //     // if (!form.bank_name.trim()) {
    //     //     error.bankname = "Bank Name cannot be empty";
    //     //     success = false;
            
    //     // }
    //     // if (!form.account_no.trim()) {
    //     //     error.accountno = "accountno cannot be empty";
    //     //     success = false;
            
    //     // }
    //     // if (!form.ifsc_code.trim()) {
    //     //     error.ifsccode = "Ifsc Code cannot be empty";
    //     //     success = false;
            
    //     // }

    //     // if (!form.branch_name.trim()) {
    //     //     error.branchname = "Branch name Code cannot be empty";
    //     //     success = false;
            
    //     // }

    //     // if (!form.routeid.trim()) {
    //     //     error.routeid = "routeid cannot be empty";
    //     //     success = false;
    //     // }
    //     // if (!form.timestamp.trim()) {
    //     //     error.timestamp = "timestamp cannot be empty";
    //     //     success = false;
    //     // }


    //     setFormError(error)
    //     return success
    // }

  

  

    const handleRoleChange = (e) => {

        setRole(e.target.textContent);
        setForm({
            ...form,
            role_name: e.target.textContent
        })
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.textContent)
        setForm({
            ...form,
            user_status: e.target.textContent
        })
    }



    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    // console.log(form)

    let status = form.status;

    // if (status === "active") {
    //     if (form && form.profile_image && form.profile_image.length !== 0 && form.profile_image !== "undefined" && form.profile_image !== null) {
    //         status = "active"
    //     }
    //     else {
    //         status = "partial"
    //     }
    // }

    // if (status === "partial") {
    //     status = "active";
    //     if (status === "active") {
    //         if (form && form.profile_image && form.profile_image.length !== 0 && form.profile_image !== "undefined" && form.profile_image !== null) {
    //             status = "active"
    //         }
    //         else {
    //             status = "partial"
    //         }
    //     }
    // }


    let param = {
        "admin_id": 1,
        "user_id": `${form.user_id}`,
        "name": `${form.name}`,
        "surname": `${form.surname}`,
        "mobile_no": `${form.mobile_no}`,
        "whatsapp_no": `${form.whatsapp_no}`,
        "address": `${form.address}`,
        "zone_name": `${form.zone_name}`,
        "role_name": `${form.role_name}`,
        "status": `${status}`,
        "bank_name": `${form.bank_name}`,
        "account_no": `${form.account_no}`,
        "ifsc_code": `${form.ifsc_code}`,
        "branch_name": `${form.branch_name}`,

    }

    
    const updateDistributorDetails=useUpdateDistributor(form)
    // const getAllDistributorList=useDistributorList()
    const getLaabhPartners = useLaabhPartner();
    const handleUpdateAsm = async() => {
        let param = {
            zone_id: dropDownData?.zone_id,
          };
        await updateDistributorDetails();
        await getAllDistributorList();
        await getLaabhPartners(param);
    }



    React.useEffect(() => {
        if (Updatesuccess) {
            toast.success('Updated SuccessFully');
            setUpdatesuccess(false)
        }
    }, [Updatesuccess])

    React.useEffect(() => {
        if (invalidToken) {
            toast.warning('Invalid Token');
            setInvalidToken(false)
        }
    }, [invalidToken])


    React.useEffect(() => {
        if (obj.failed) {
            toast.error(obj.message);
            setObj({
                ...obj,
                failed: false,
                message: ""
            })
        }
    }, [obj.failed])

    React.useEffect(() => {
        if (
            distributorDetails &&
            distributorDetails.length !== 0 &&
            distributorDetails !== "undefined" &&
            distributorDetails !== null
        ) {
          setRootForm(distributorDetails);
          setForm(distributorDetails);
          setZone(distributorDetails.dist_name);
          setRole(distributorDetails.role_name);
          // dispatch({type:USERMGTID,payload:detail.user_id});
         
        }
      }, [distributorDetails]);
  

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div>

            <div className={styles.UserMgtWholesalerUpdateFormContainer} style={{gap:"1.5rem"}}>
                <div className={styles.UserMgtWholesalerUpdateFormContainerOne}>

                    <div className={styles.UserMgtWholesalerUpdateFormContainerSectionOne}>
                    <div className={styles.UserMgtUpdateFormContainerSectionOneM}>
                                    <div className={styles.UserMgtUpdateFormSectionOneTitleContainer}>
                                        <label className={styles.UserMgtUpdateFormSectionOneTitle}>{distributorDetails?.shop_name}</label>
                                    </div>
   
                                </div>


                        <div className={styles.UserMgtWholesalerUpdateFormSectionOneEditContainer} onClick={handleEdit}>
                            <CreateIcon className={styles.UserMgtWholesalerUpdateFormSectionOneEditIcon} />
                        </div>
                    </div>

                    <div className={styles.UserMgtWholesalerUpdateFormContainerSectionTwo}>
                        <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOne}>

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Zone</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={true} value={distributorDetails?.zone_name} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />


                                    {formError.zone && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.zone}</span>}
                                </div>
                            </div>

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>L P Code</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={true} value={distributorDetails?.partner_code??"N/A"} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.userid && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.userid}</span>}
                                </div>
                            </div>

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Role</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    {
                                        edit ? (<Box className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField}>
                                            <FormControl fullWidth size="small">

                                                <Select value={Role} onClick={handleRoleChange} sx={{height:"1.8rem"}}>

                                                    {RoleList.map((item, index) => (
                                                        <MenuItem value={item} key={index}>{item}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                        </Box>) : (
                                            <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={edit ? false : true} value={distributorDetails?.dist_code} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                        )
                                    }

                                    {formError.role && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.role}</span>}
                                </div>
                            </div> */}

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Name</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={edit ? false : true} value={form?.name} name="name" className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} onChange={handleChange} />
                                    {formError.name && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.name}</span>}
                                </div>
                            </div>

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Surname</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <TextField variant="outlined" size="small" value={form.surname} disabled={edit ? false : true} name="surname" className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} onChange={handleChange} />
                                    {formError.surname && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.surname}</span>}
                                </div>
                            </div> */}

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Mobile No</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" value={form?.mobile_no} disabled="false" name="mobile_no" onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.mobileno && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.mobileno}</span>}
                                </div>
                            </div>

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Whatsapp ID</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" value={form?.whatsapp_no} disabled={edit ? false : true} name="whatsapp_no" onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.whatsappno && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.whatsappno}</span>}
                                </div>
                            </div> */}

                        

                        </div>
                        <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionTwo}>

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Address</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" value={form?.address} disabled={edit ? false : true} multiline rows={3} name="address" onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.address && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.address}</span>}
                                </div>
                            </div>

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Latitude</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={true} name="lati" value={form?.lat} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} onChange={handleChange} />
                                    {formError.lati && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.lati}</span>}
                                </div>
                            </div> */}

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Longitude</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" name="longi" value={form?.lng} disabled={true} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} onChange={handleChange} />
                                    {formError.longi && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.longi}</span>}
                                </div>
                            </div> */}

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>IFSC Code</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <TextField variant="outlined" size="small" name="ifsc_code" value={form.ifsc_code} disabled={edit ? false : true} onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.ifsccode && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.ifsccode}</span>}
                                </div>
                            </div>

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Branch Name</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <TextField variant="outlined" size="small" name="branch_name" value={form.branch_name} disabled={edit ? false : true} onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.branchname && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.branchname}</span>}
                                </div>
                            </div> */}

                            {/* <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>User Code</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" name="user_code" value={form?.user_code??"N/A"} disabled={true} onChange={handleChange} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                           
                                </div>
                            </div> */}

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>status</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    {
                                        edit ? (
                                            <Box className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField}>
                                                <FormControl fullWidth size="small">

                                                    <Select value={form.user_status} onClick={handleStatusChange} sx={{height:"1.8rem"}}>

                                                        {StatusList.map((item, index) => (
                                                            <MenuItem value={item} key={index}>{item}</MenuItem>
                                                        ))}

                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        ) : (<input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" disabled={true} value={form?.user_status} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />)
                                    } </div>
                            </div>

                            <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneList}>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListOne}>
                                    <label className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListLabel}>Joining Date</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwo}>
                                    <input style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}} variant="outlined" size="small" value={TableconvertDate(form?.created_at)} disabled={edit ? false : true} className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListField} />
                                    {formError.timestamp && <span className={styles.UserMgtWholesalerUpdateFormTwoSubSectionOneListTwoErrorLabel}>{formError.timestamp}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        edit ? (<div className={styles.UserMgtWholesalerUpdateFormContainerSectionThree}>
                            {/* <>
                            {
                                form && form.status === "pending" ? (
                                    <RedButton variant="contained" onClick={handleUpdate}>Approve</RedButton>
                                ) : (
                                    <RedButton variant="contained" onClick={handleUpdate}>Update</RedButton>
                                )

                            }
                        </> */}
                            <RedButton sx={{height:"1.8rem"}} variant="contained" id="UserMgtWholesalerUpdateForm" onClick={handleUpdateAsm}>Update</RedButton>
                            <GrayButton sx={{height:"1.8rem"}} variant="contained" onClick={handleCancelEdit}>Cancel</GrayButton>
                        </div>) : null
                    }

                </div>
                <Paper sx={{ flex: "0.9", padding: "5px" }}>
                    <div className={styles.UserMgtWholesalerUpdateFormContainerTwo}>

                        <div className={styles.UserMgtWholesalerUpdateFormContainerTwoFirmContainer}>
                            <img src={form && form.shop_image && form.shop_image.length !== 0 && form.shop_image !== "undefined" ? (`${ShopBaseImgUrl}${form.shop_image}`) : ("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEpWWQrAJpIR6Xy7FhzhCT00vzSmT7xw9S2Q&usqp=CAU")} alt="firm" />
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "-60px" }}>
                            <div className={styles.UserMgtWholesalerUpdateFormContainerTwoProfileContainer}>
                                <img className={styles.UserMgtWholesalerUpdateFormContainerTwoProfile} src={form && form.profile_image && form.profile_image.length !== 0 && form.profile_image !== "undefined" ? (`${BaseImgUrl}${form.profile_image}`) : ("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEpWWQrAJpIR6Xy7FhzhCT00vzSmT7xw9S2Q&usqp=CAU")}  />
                            </div>
                        </div>

                        <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListContainer}>
                            <div className={styles.UserMgtWholesalerUpdateFormContainerTwoList}>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListOne}>
                                    <span className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer}><PersonOutlineIcon className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIcon} /></span>
                                    <label>Name</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListTwo}>
                                    {form.name}
                                </div>
                            </div>

                            <div className={styles.UserMgtWholesalerUpdateFormContainerTwoList}>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListOne}>
                                    <span className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer}><CallOutlinedIcon className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIcon} /></span>
                                    <label>Mobile</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListTwo}>
                                    {form.mobile_no}
                                </div>
                            </div>

                            <div className={styles.UserMgtWholesalerUpdateFormContainerTwoList}>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListOne}>

                                    <span className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer}><LandscapeOutlinedIcon className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIcon} /></span>
                                    <label>zone</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListTwo}>
                                    {form.zone_name}
                                </div>
                            </div>
                            
                            {/* <div className={styles.UserMgtWholesalerUpdateFormContainerTwoList}>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListOne}>

                                    <span className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer}><DirectionsWalkOutlinedIcon className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIcon} /></span>
                                    <label>Role</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListTwo}>
                                    {form.dist_code}
                                </div>
                            </div> */}

                            <div className={styles.UserMgtWholesalerUpdateFormContainerTwoList}>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListOne}>
                                    <span className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIconContainer}><RoomOutlinedIcon className={styles.UserMgtWholesalerUpdateFormContainerTwoListOneIcon} /></span>
                                    <label>Address</label>
                                </div>
                                <div className={styles.UserMgtWholesalerUpdateFormContainerTwoListTwo}>
                                    {form.address}
                                </div>
                            </div>

                            {/* <div className={styles.UserMgtUpdateFormContainerTwoList}>
                                            <div className={styles.UserMgtUpdateFormContainerTwoListOne}>

                                                <span className={styles.UserMgtUpdateFormContainerTwoListOneIconContainer}><EditLocationAltOutlinedIcon className={styles.UserMgtUpdateFormContainerTwoListOneIcon} /></span>
                                                <label>Landmark</label>
                                            </div>
                                            <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                                                {form.landmark}
                                            </div>
                                        </div> */}

                            
                        </div>
                    </div>
                </Paper>
            </div>
</div>
        </>
    )
}

export default UserMgtWholesalerUpdateForm