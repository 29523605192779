import { createSlice } from "@reduxjs/toolkit";

const adminMapSlice = createSlice({
  name: "admin",
  initialState: {
    adminMapDetails: null,
    laabhPartnersData: null,
    deliveryPartners: null,
    salesmanData: null,
    getPincodeDetails: null,
    getBoundryDetails: null,
    getClusterDetails: null,
    getAssignedBoundryDetails: null,
    deliveryMapData: null,
    getReqBoundryDetails: null,
    smIconData: null,
    retailerData: null,
    currentSM: null,
    retailerMapData: null,
  },
  reducers: {
    getAdminMapDetails: (state, action) => {
      state.adminMapDetails = action.payload;
    },
    getLaabhPartnersData: (state, action) => {
      state.laabhPartnersData = action.payload;
    },
    getDeliveryPartners: (state, action) => {
      state.deliveryPartners = action.payload;
    },
    getsalesmanPartners: (state, action) => {
      state.salesmanData = action.payload;
    },
    getRetailerList: (state, action) => {
      state.retailerData = action.payload;
    },
    getRetailerMap: (state, action) => {
      state.retailerMapData = action.payload;
    },
    getSelectedSM: (state, action) => {
      state.currentSM = action.payload;
    },
   
    assigngetPincodeDetails: (state, action) => {
      state.getPincodeDetails = action.payload;
    },
    getBoundryDetailsList: (state, action) => {
      state.getBoundryDetails = action.payload;
    },
    getRouteClusterList: (state, action) => {
      state.getClusterDetails = action.payload;
    },
    assignedBoundryDetails: (state, action) => {
      state.getAssignedBoundryDetails = action.payload;
    },
    getDeliveryMapData: (state, action) => {
      state.deliveryMapData = action.payload;
    },
    getSMIconData: (state, action) => {
      state.smIconData = action.payload;
    },
    reqBoundryDetails: (state, action) => {
      state.getReqBoundryDetails = action.payload;
    },
    resetReqBoundryDetails: (state) => {
      state.getReqBoundryDetails = null;
    },
    resetPincodeDetails: (state, action) => {
      state.getPincodeDetails = null;
    },
    resetRouteClusterList: (state) => {
      state.getClusterDetails = null;
    },
    resetAssignedBoundryDetails: (state, action) => {
      state.getAssignedBoundryDetails = null;
    },
  },
});

export default adminMapSlice.reducer;
export const {
  getAdminMapDetails,
  getLaabhPartnersData,
  getDeliveryPartners,
  getsalesmanPartners,
  assigngetPincodeDetails,
  getBoundryDetailsList,
  getRouteClusterList,
  resetRouteClusterList,
  assignedBoundryDetails,
  getDeliveryMapData,
  reqBoundryDetails,
  resetPincodeDetails,
  resetAssignedBoundryDetails,
  resetReqBoundryDetails,
  getSMIconData,
  getRetailerList,
  getSelectedSM,
  getRetailerMap,
} = adminMapSlice.actions;
