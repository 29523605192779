import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getSkuList } from "../slice/skuSlice";

const useSkuList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSkuList = async (shape_id) => {
    const param = {
      shape_id: shape_id,
    }
    const response = await Axios.post("/v2/sku_list", param);
    const data = await response.data;
    dispatch(getSkuList(data.body));
  };

  return getAllSkuList;
};

export default useSkuList;
