import React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import {Typography,Button,Stack} from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={styles.paper}>
        <Typography variant="h5" sx={styles.title}>
          Unauthorized Access
        </Typography>
        <Typography variant="body1" sx={styles.message}>
          You do not have permission to view this page. Please log in or contact the administrator.
        </Typography>
        <Stack sx={{marginTop:"1rem"}}>
        <Link to="/"><Button sx={{background:"#DC1D13",color:"white"}}>Return to Home Page</Button></Link>
        </Stack>
        
      </Paper>
    </Container>
  );
};

const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
  },
  title: {
    color: '#d9534f',
    marginBottom: '10px',
  },
  message: {
    color: '#333',
  },
};

export default UnauthorizedPage;
