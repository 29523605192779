import React from "react";
import Sidebar from "../Sidebar";
import { Stack } from "@mui/material";
import CoinMgtTable from "./CoinMgtTable";
const index = () => {
  return (
    <Stack>
        <Stack direction="row">
        <Sidebar />
        <CoinMgtTable />
      </Stack>      
      
    </Stack>
  );
};

export default index;