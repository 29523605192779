import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    coinTable: '',    
};

const coinMgtSlice = createSlice({
    name: 'coinMgt',
    initialState,
    reducers: {
      getCoinTable: (state, action) => {
        state.coinTable = action.payload;
      },
    },
  });

export const { getCoinTable } = coinMgtSlice.actions;
export default coinMgtSlice.reducer;
export const CoinMgtData = (state) => state.coinMgt;

