import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddGiftCategory = (form) => {
  // console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const categoryFormData = useSelector(
    (store) => store.category.giftCategoryFormData
  );
  console.log(categoryFormData)
  const viewImgPath = `${BaseImgUrl}/category`;
  const addGiftCategory = async () => {
    let param = {
      category_name: form?.name,
      category_image: categoryFormData?.full_path,
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_gift_category", param);
    const data = await response.data;
    // console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    }else if (data.code == 400) {
      toast.error("Category name already exist!");
    } else {
      toast.error("Failed to Add");
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addGiftCategory;
};

export default useAddGiftCategory;
