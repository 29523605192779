import React, { useState } from "react";
import Styles from "./styles/ApprovalCoinTransaction.module.css";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import ApprovalUpdateLocationTable from "./ApprovalUpdateLocationTable";

const ApprovalUpdateLocation = ({ token, zone }) => {
  const approval = useSelector((state) => state.ApprovalMgt);
  const Searchcolumn = [
    "ul_id",
    "retailer_name",
    "requester_name",
    "mobile_no",
    "retailer_id",
    "user_id",
    "zone_name",
  ];
  const [q, setQ] = useState("");

  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      Searchcolumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.TitleContainer}>
        <label className={Styles.Title}>Update location </label>
      </div>
      <div className={Styles.TextContainer}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search User.."
          sx={{ width: "30%" }}
          onChange={(e) => setQ(e.target.value)}
        />
      </div>
      <div className={Styles.TableContainer}>
        {/* <ApprovalUpdateLocationTable list={Searchfilter(approval.ApprovalListLocation)} token={token} zone={zone}/> */}
        <ApprovalUpdateLocationTable token={token} zone={zone} />
      </div>
    </div>
  );
};

export default ApprovalUpdateLocation;
