import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList } from "../slice/categorySlice";
import { getGiftSkuList, getSkuList } from "../slice/skuSlice";

const useGiftSkuList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllGiftSkuList = async () => {
    const response = await Axios.get("/gift_sku_list");
    const data = await response.data;
    dispatch(getGiftSkuList(data.giftSkuList));
    
  };

   return getAllGiftSkuList;

};

export default useGiftSkuList;
