import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import SKUManagementTable from "../SKU Management/SKUManagementTable";
import AddSku from "../SKU Management/AddSku";

import AddGiftCategory from "./GiftCategoryManagement/AddGiftCategory";
import GiftCategoryManagementTable from "./GiftCategoryManagement/GiftCategoryManagementTable";
import GiftSKUManagementTable from "./GiftSKUManagement/GiftSKUManagementTable";
import AddGiftSku from "./GiftSKUManagement/AddGiftSku";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicGiftTabs() {
  const [value, setValue] = React.useState(0);
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
          display: "flex",
          width: "98%",
          ".MuiTabs-indicator":{

            backgroundColor:"#DC1D13"
          }
        }}
      >
        <Stack>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="SuperCategory Management" {...a11yProps(2)} /> */}
            <Tab sx={{color:"#DC1D13 !important", fontWeight:"600"  }} label="Category Management" {...a11yProps(0)} />
            <Tab sx={{color:"#DC1D13 !important", fontWeight:"600" }} label="Gift Management" {...a11yProps(1)} />
          </Tabs>
        </Stack>

        {value === 2 ? (
          <Stack>
            {/* <AddSuperCategory /> */}
          </Stack>
        ) : value === 0 ? (
          <Stack>
          { userData?.role === "admin"?  <AddGiftCategory />: null}
          </Stack>
        ) : (
          <Stack>
            <AddGiftSku />
          </Stack>
        )}
      </Box>
      {/* <CustomTabPanel value={value} index={2}>
        <SuperCategoryManagementTable />
      </CustomTabPanel> */}
      <CustomTabPanel value={value} index={0}>
        <GiftCategoryManagementTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <GiftSKUManagementTable />
      </CustomTabPanel>
    </Box>
  );
}
