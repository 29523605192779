import React, { useState, useEffect } from "react";
import Logo from "./assets/logo.png";
import { Stack, Box, Typography, Button } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import useStateList from "../../hooks/useStateList";
import useZoneList from "../../hooks/useZoneList";
import { getCurrentDropdownData } from "../../slice/appSlice";
import { setZoneId } from "../../slice/SFstatusSlice";
import { setZoneIds } from "../../slice/assignSEslice";
import LogoutIcon from "@mui/icons-material/Logout";
import useBoundryData from "../../hooks/useBoundryData";

import { getSelectedTab } from "../../slice/appSlice";
// import { AdminMap } from "../../actions/AdminMap";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const login = useSelector((store) => store.login.refreshToken);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedState, setSelectedState] = useState("Gujarat");
  const [obj, setObj] = useState({});

  const stateList = useSelector((store) => store.app.stateList);
  const zoneList = useSelector((store) => store.app.zoneList);
  const selectedTab = useSelector((store) => store.app.selectedTab);
  const hiddenRoutes = [
    "/category",
    "/coinmgt",
    "/giftCategory",
    "/zonemgt",
    "/addApk",
  ];
  const isHiddenRoute = hiddenRoutes.includes(location.pathname);
  const getStateList = useStateList();
  const getzoneList = useZoneList();

  useEffect(()=>{
    dispatch(getSelectedTab(null));
    // console.log(location.pathname, "location.pathname")
  }, [location]);

  useEffect(() => {
    if (stateList && stateList.length > 0) {
      setSelectedState(stateList[0]?.state_name);
    }
  }, [stateList]);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    dispatch(getCurrentDropdownData(obj));
    dispatch(setZoneId(obj.zone_id));
    dispatch(setZoneIds(obj.zone_id));
    console.log(obj);
  }, [obj]);

  useEffect(() => {
    setObj((prev) => ({
      ...prev,
      state_id: stateList?.[0]?.state_id,
      state_name: stateList?.[0]?.state_name,
    }));

    handleStateChange({
      state_id: stateList?.[0]?.state_id,
    });
  }, [stateList]);

  useEffect(() => {
    setObj((prev) => ({
      ...prev,
      zone_id: zoneList?.[0]?.zone_id,
      zone_name: zoneList?.[0]?.zone_name,
    }));
    onZoneChange(zoneList?.[0]?.zone_name);
  }, [zoneList]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const getBoundryData = useBoundryData();
  const onZoneChange = async (zoneName) => {
    const selectedZoneObject = zoneList?.find(
      (zone) => zone.zone_name === zoneName
    );

    if (selectedZoneObject) {
      setSelectedZone(zoneName);
      const zoneId = selectedZoneObject.zone_id;
      let param = {
        zone_id: zoneId,
      };

      setObj((prev) => ({
        ...prev,
        zone_id: zoneId,
        zone_name: zoneName,
      }));

      dispatch(setZoneId(zoneId));
      dispatch(setZoneIds(zoneId));

      const apiCalls = [
        getBoundryData(param),
      ];

      try {
        const [mapData, boundryData, laabhPartnersData, laabhDelPartnersData] =
          await Promise.all(apiCalls);
      } catch (error) {
        console.log("Error in onZoneChange:", error);
        // Handle errors if needed
      }
    } else {
      console.log("No matching zone found for zone_name:", zoneName);
    }
  };

  const onStateChange = async (selectedState) => {
    // Find the selected state object based on the state_name
    const selectedStateObject = stateList.find(
      (state) => state.state_name === selectedState
    );
    setObj((prev) => ({
      ...prev,
      state_id: selectedStateObject.state_id,
      state_name: selectedState,
    }));
    // If a matching state is found, send state_id along with state_name
    if (selectedStateObject) {
      const { state_id, state_name } = selectedStateObject;
      setSelectedState(state_name);

      // Call the function with both state_id and state_name
      handleStateChange({ state_id, state_name });
    }
  };

  const handleStateChange = ({ state_id }) => {
    const param = {
      state_id: state_id,
    };

    dispatch(getCurrentDropdownData(obj));
    getzoneList(param);
  };

  const handleLogOut = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("data");
    navigate("/login");
    sessionStorage.clear();
    window.location.reload(); 
  };
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#DC1D13",
          padding: "6px",
          paddingLeft: "12px",
          paddingRight: "12px",
          maxWidth: "100vw",
          maxHeight: "120px",
        }}
      >
        <Stack>
          <img src={Logo} onClick={toggleSidebar} />
        </Stack>

        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack></Stack>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.5rem",
              color: "white",
            }}
          >
            <Box
              sx={{
                paddingLeft: "1.5rem",
                paddingRight: "1.5rem",
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingRight: "10px",
                paddingLeft: "0px",
              }}
            >
              {!isHiddenRoute && (
                <Stack
                  sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  {(selectedTab !== "Distribution Partner" &&
                    selectedTab !== "ASM") && (
                    <>
                      <FormControl>
                        <Select
                          value={selectedState}
                          input={
                            <InputBase
                              sx={{
                                width: { xs: "auto", sm: "8rem" },
                                height: "1.8rem",
                                border: "1px solid #BBBABA",
                                backgroundColor: "#fff",
                                padding: "5px",
                              }}
                            />
                          }
                          onChange={(event) =>
                            onStateChange(event.target.value)
                          }
                        >
                          {stateList &&
                            stateList.map((state) => (
                              <MenuItem
                                key={state.state_id}
                                value={state.state_name}
                              >
                                {state.state_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl>
                        <Select
                          value={selectedZone}
                          input={
                            <InputBase
                              sx={{
                                width: { xs: "auto", sm: "8rem" },
                                height: "1.8rem",
                                border: "1px solid #BBBABA",
                                backgroundColor: "#fff",
                                padding: "5px",
                              }}
                            />
                          }
                          onChange={(event) => onZoneChange(event.target.value)}
                        >
                          {zoneList &&
                            zoneList.map((zone) => (
                              <MenuItem
                                key={zone.zone_id}
                                value={zone.zone_name}
                              >
                                {zone.zone_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Stack>
              )}
            </Box>
            <Divider
              orientation="vertical"
              sx={{ backgroundColor: "#fff", width: "1px" }}
              flexItem
            />
            <Stack
              sx={{ flexDirection: "row", alignSelf: "center", gap: "10px" }}
            >
              <AccountCircleOutlinedIcon />
              <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                {login?.user_data?.first_name}
              </Typography>
              <LogoutIcon onClick={handleLogOut} sx={{ cursor: "pointer" }} />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Outlet />
    </>
  );
};

export default DashboardHeader;
