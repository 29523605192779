import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import styles from "./styles/DashboardTable.module.css";
import { useSelector, useDispatch } from "react-redux";
import { TableconvertDate } from "../Functions/function";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { BsDot } from "react-icons/bs";

import { SearchFilter } from "../../utility/function";
import useSaleDetails from "../../hooks/useSaleDetails";
import useDashboardTableDetails from "../../hooks/useDashboardTableDetails";

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const DashboardTable = ({}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.SalesMgt);
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [expandedRows, setExpandedRows] = useState([]);
  const columnsOne = [
    "Zone",
    "Shape ID",
    "Partner",
    "Retailers",
    "Pending",
    "On the Way",
    "Completed",
    "Cancelled",
    "Amount",
  ];
  const getAllDashboardTableDetails = useDashboardTableDetails();
  const dashboardDetails = useSelector((state) => state.app.dashboardTableData);
  console.log(dashboardDetails, "dashboardDetails");
  // useSaleDetails();
  const saleDetails = useSelector((store) => store.sale.saleDetails);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter((rowIndex) => rowIndex !== index)
        : [...prevExpandedRows, index]
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   React.useEffect(() => {
  //     setPage(0);
  //   }, [Product, route, retailerText]);

  // sx={Id===row.user_id?({backgroundColor:"#faebf0"}):({backgroundColor:"white"})}

  return (
    <div>
      <TableContainer sx={{ maxHeight: 800, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={styles.head}
            sx={{ backgroundColor: "#c4c4c4" }}
          >
            <TableRow
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              {columnsOne.map((column, index) => (
                <TableCell
                  sx={{ backgroundColor: "#c4c4c4" }}
                  key={index}
                  align="center"
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ background: "#F0F0F0" }}>
            {dashboardDetails !== "undefined" &&
            dashboardDetails !== null &&
            dashboardDetails?.length !== 0 ? (
              <>
                {dashboardDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell align="center">{item?.zone_name}</TableCell>
                        <TableCell align="center">{item?.shape_id}</TableCell>
                        <TableCell align="center">
                          {item?.partner_name?.join(", ") ?? "N/A"}
                        </TableCell>

                        <TableCell align="center">
                          {item?.retailer_count ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {item?.pending_count ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {item?.on_the_way_count ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {item?.completed_count ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {item?.cancelled_count ?? 0}
                        </TableCell>
                        <TableCell align="center">
                          {item?.total_amount ?? 0}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <TableRow>
                <TableCell align="center">No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={dashboardDetails && dashboardDetails?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className={styles.pagination} // Update with your styles
      />
    </div>
  );
};

export default DashboardTable;
