import React from "react";
import { Stack, Card, Typography } from "@mui/material";
import graphImage from "./assets/graphImage.png";
import profile1 from "./assets/Ellipse987.png";
import profile2 from "./assets/Ellipse988.png";
import profile3 from "./assets/Ellipse989.png";
import profile4 from "./assets/image42.png";

const GraphContainer = () => {
  return (
    <Stack sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Card
        sx={{
          background: "#FFF",
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            color: "#2A2A2A",
            fontFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "21px",
          }}
        >
          Sales Chart
        </Typography>
        <Stack>
          <img src={graphImage} />
        </Stack>
      </Card>
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Card sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Top SEs of the month</Typography>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "2rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <img src={profile1} style={{ width: "50px" }} />
              </Stack>
              <Card>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "rgba(215, 215, 215, 0.70)",
                    paddingRight: "1.5rem",
                    paddingLeft: "1.5rem",
                    paddingTop: "1rem",
                    height: "150px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      background: "#FFFFFF",
                      width: "max-content",
                      padding: "0.3rem",
                      borderRadius: "50%",
                    }}
                  >
                    2
                  </Typography>
                  <Typography>Ramya</Typography>
                  <Typography>1,60,000</Typography>
                </Stack>
              </Card>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <img src={profile1} style={{ width: "50px" }} />
              </Stack>
              <Card>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "rgba(215, 215, 215, 0.70)",
                    paddingRight: "1.5rem",
                    paddingLeft: "1.5rem",
                    paddingTop: "1rem",
                    height: "200px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      background: "#FFFFFF",
                      width: "max-content",
                      padding: "0.3rem",
                      borderRadius: "50%",
                    }}
                  >
                    2
                  </Typography>
                  <Typography>Ramya</Typography>
                  <Typography>1,60,000</Typography>
                </Stack>
              </Card>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <img src={profile1} style={{ width: "50px" }} />
              </Stack>
              <Card>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    background: "rgba(215, 215, 215, 0.70)",
                    paddingRight: "1.5rem",
                    paddingLeft: "1.5rem",
                    paddingTop: "1rem",
                    height: "100px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      background: "#FFFFFF",
                      width: "max-content",
                      padding: "0.3rem",
                      borderRadius: "50%",
                    }}
                  >
                    2
                  </Typography>
                  <Typography>Ramya</Typography>
                  <Typography>1,60,000</Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Card>
        <Card>
          <Typography>Total Order Value</Typography>
          <Typography>$5,60,000</Typography>
          <Typography>22% of the week</Typography>
          <Stack>
            <img src={profile4} />
          </Stack>
        </Card>
      </Stack>
    </Stack>
  );
};

export default GraphContainer;
