import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Effectively Covered Outlets (ECO)",
      align: 'start', 
      font: {
        size: 16,  
        weight: 'bold'  
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        autoSkip: false, 
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
};

export function StackedECOGraph() {
  const smGraphDetails = useSelector((state) => state.dashboard.graphDetails);
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const updateChartWidth = () => {
      const labelCount = smGraphDetails?.smList?.length || 0;
      const minWidth = 400;
      const barWidth = 30; 
      const newWidth = Math.max(minWidth, labelCount * barWidth);
      setChartWidth(newWidth);
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, [smGraphDetails]);

  const data = {
    labels: smGraphDetails?.smList || [],
    datasets: [
      {
        label: "Total",
        data: smGraphDetails?.totalOutlet?.map((item) => item?.total_outlet),
        backgroundColor: "#D9D9D9",
        barThickness: 20,
      },
      {
        label: "Covered",
        data: smGraphDetails?.totalOutlet?.map((item) => item?.outlet_covered),
        backgroundColor: "#A9ECF0",
        barThickness: 20,
      },
      {
        label: "ECO",
        data: smGraphDetails?.totalOutlet?.map(
          (item) => item?.effective_covered_outlets
        ),
        backgroundColor: "#90D670",
        barThickness: 20,
      },
    ],
  };
  return (
    <div style={{ height: "265px", width: "33%", background: "white", padding: "6px", borderRadius: "6px" }}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <div style={{ width: `${chartWidth}px`, height: "265px" }}>
          <Bar options={options} data={data} ref={chartRef} />
        </div>
      </div>
    </div>
  );
}
