import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import styles from './styles/ApprovalCoinTransaction.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { convertDate } from '../Functions/function';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { BsDot } from "react-icons/bs"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { GetMobileUUIDApprove, GetMobileUUIDReject } from '../../../actions/Approval/approval';


const RedButton = styled(Button)(({ theme }) => ({
    color: "#DC1D13",
    backgroundColor: "white",
    '&:hover': {
        backgroundColor: "#DC1D13",
        color: "white",
        border: "2px solid #DC1D13"
    },
    height: "30px",
    fontSize: "12px",
    border: "2px solid #DC1D13"
}));

const RedRejectButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
        color: "white",
        border: "2px solid #DC1D13"
    },
    height: "30px",
    fontSize: "12px",
    border: "2px solid #DC1D13"
}));


const useStyles = makeStyles({
    head: {
        borderBottom: "2px solid black"
    },
    pagination: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center"
    },
    rowcell: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        gap: "5px"
    }
})
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#E5E5E5",
        color: "black",
        fontSize: "14px",
        fontWeight: "600"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const subTableHead = ["ProductName", "Product Cost", "Product Quantity", "Product Toatal Cost"]


function Row(props) {
    const { item, token,zone } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState({
        failed: false,
        message: ""
    })

    const [Rejectsuccess, setRejectSuccess] = useState(false);
    const [Rejectfailed, setRejectFailed] = useState({
        failed: false,
        message: ""
    })

let AdminData = JSON.parse(localStorage.getItem('Login2'))
    const Accept = () => {
        let param = {
            "admin_id":AdminData?.id ?? 0,
            "user_id":`${item.user_id}`,
            "uuid":`${item.new_uuid}`,
             role : AdminData.role,
            id : AdminData.id,
            zone_name : zone

        }

        // dispatch(GetMobileUUIDApprove(token, param, setSuccess, failed, setFailed))
    }

    const Reject = () => {
        let param = {
            "admin_id":AdminData?.id ?? 0,
            "user_id": item.user_id,
            "id": item.id,
            role : AdminData.role,
            zone_name : zone
        }

        // dispatch(GetMobileUUIDReject(token, param, setRejectSuccess, Rejectfailed, setRejectFailed))
    }

    useEffect(() => {
        if (success) {
            toast.success('Approved SuccessFully');
            setSuccess(false)
        }

        if (failed.failed) {
            toast.error(failed.message);
            setFailed({
                ...failed,
                failed: false,
                message: ""
            })
        }
    }, [success, failed])


    useEffect(() => {
        if (Rejectsuccess) {
            toast.success('Rejected SuccessFully');
            setRejectSuccess(false)
        }

        if (Rejectfailed.failed) {
            toast.error(Rejectfailed.message);
            setRejectFailed({
                ...Rejectfailed,
                failed: false,
                message: ""
            })
        }
    }, [Rejectsuccess, Rejectfailed])

    return (
        <React.Fragment>
            <TableRow>
                {/* <TableCell align="center">{item.zone_name}</TableCell> */}
                <TableCell align='center'>{item.zone_name}</TableCell>
                <TableCell align='center'>{item.role_name}</TableCell>
                <TableCell align='center'>{item.name}{item.surname}</TableCell>
                <TableCell align='center'>{item.uuid}</TableCell>
                <TableCell align='center'>{item.new_uuid}</TableCell>
                <TableCell align='center'>{item.mobile_no}</TableCell>
                <TableCell align='center'>{convertDate(item.created_at)}</TableCell>
                <TableCell align='center' size='small'>
                    <div className={classes.rowcell}>
                        <RedButton onClick={Accept}>Approve</RedButton>
                        <RedRejectButton onClick={Reject}>Reject</RedRejectButton>
                    </div>

                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const ApprovalMobileReplacementTable = ({ list, token,zone }) => {
    const dispatch = useDispatch();
    const state = useSelector(state => state.SalesMgt);
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const columns = [
        { label: "Zone", colSpan: 3 },
        { label: "Role", colSpan: 3 },
        { label: "Name", colSpan: 3 },
        { label: "Existing UUID", colSpan: 3 },
        { label: "New UUID", colSpan: 3 },
        { label: "Mobile No.", colSpan: 3 },
        { label: "Date", colSpan: 3 },
        { label: "Actions", colSpan: 7 },
    ];

    const [open, setOpen] = React.useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const arr = [1, 2, 3, 4, 5, 6, 7]

    // sx={Id===row.user_id?({backgroundColor:"#faebf0"}):({backgroundColor:"white"})}

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <TableContainer sx={{ maxHeight: 440, minWidth: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className={styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                        <TableRow className={styles.head} sx={{ backgroundColor: "#c4c4c4" }}>
                            <>
                                {columns.map((column, index) => (
                                    <StyledTableCell
                                        key={index}
                                        align="center"
                                    // colSpan={column.colSpan}
                                    // style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </>
                        </TableRow>
                    </TableHead>
                    <TableBody>



                        {
                            list && list !== "undefined" && list !== null && list.length !== 0 ? (
                                <>{
                                    list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                        <>

                                            <Row item={item} token={token} zone={zone}/>

                                        </>
                                    ))
                                }
                                </>
                            ) :
                                (
                                    <TableRow>
                                        <TableCell align="center">No Data</TableCell>
                                    </TableRow>
                                )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={list && list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className={classes.pagination}
            />
        </div>
    )
}

export default ApprovalMobileReplacementTable
