import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getRetailerList, getsalesmanPartners } from "../slice/adminMapSlice";

const useRetailerListmap = (selectedSM) => {
  const zoneData = useSelector((state) => state.SFstatus);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getRetailer = async () => {
    const param = {
      zone_id: zoneData?.zone_id,
      sm_id: selectedSM,
    };
    const response = await Axios.post("get_retailer", param);
    const data = await response.data;

    dispatch(getRetailerList(data));
  };
  

  useEffect(()=>{
    getRetailer()
  },[zoneData?.zone_id,selectedSM])
  return getRetailer;
};

export default useRetailerListmap;
