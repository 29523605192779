import React, { useState, useEffect } from "react";
import { Stack, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Styles from "./styles/dashboard.module.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useDashboardStateZoneList from "../../hooks/useDashboardDropDownList";
import useDashboardTableDetails from "../../hooks/useDashboardTableDetails";

const useStyles = makeStyles({
  date: {
    width: 180,
    height: "10px",
  },
});
const DashboardDropDown = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(new Date());
  //   const [selectedStartDate, setSelectedStartDate] = useState(
  //     new Date() - 1000 * 60 * 60 * 24 * 1
  //   );
  const [currentZoneId, setCurrentZoneId] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [startDate, setStartDate] = React.useState(new Date());
  const stateList = useSelector((state) => state.dashboard.dashboardStateList);
  const zoneList = useSelector((state) => state.dashboard.dashboardZoneList);
  const getDashboardStateZoneList = useDashboardStateZoneList();
  const getAllDashboardTableDetails = useDashboardTableDetails(
    currentZoneId,
    selectedDate
  );

  const [currentZoneList, setCurrentZoneList] = useState(null);
  const [flag, setFlag] = useState(true);
  const [currentZone, setCurrentZone] = useState(null);

  const handleStartDateChange = (value) => {
    setSelectedDate((prev) => ({
      ...prev,
      startDate: value,
    }));
  };
  const handleEndDateChange = (value) => {
    setSelectedDate((prev) => ({
      ...prev,
      endDate: value,
    }));
  };

  useEffect(() => {
    let ZoneArray = [];
    for (let i = 0; i < zoneList?.length; i++) {
      if (zoneList[i]?.zone_name != "All") {
        ZoneArray.push({
          zone_id: zoneList[i]?.zone_id,
          zone_name: zoneList[i]?.zone_name,
        });
      }
    }
    setCurrentZoneList(ZoneArray);
  }, [zoneList]);

  const handleStateChange = (state) => {
    let stateId;
    for (let i = 0; i < stateList?.length; i++) {
      if (stateList?.[i]?.state_name === state) {
        stateId = stateList[i]?.state_id;
      }
    }
    getDashboardStateZoneList(stateId);
    if (state == "All") {
      setFlag(true);
      setCurrentZoneId(0);
    } else setFlag(false);
  };

  const handleZoneChange = (zone) => {
    setCurrentZone(zone);
    let zoneId;
    for (let i = 0; i < zoneList?.length; i++) {
      if (zoneList?.[i]?.zone_name === zone) {
        zoneId = zoneList[i]?.zone_id;
      }
    }
    setCurrentZoneId(zoneId);
    getAllDashboardTableDetails(zoneId);
  };

  //   useEffect(() => {
  //     if (currentZone !== currentZoneList?.[0]?.zone_name) {
  //         let zoneId;
  //         for (let i = 0; i < currentZoneList?.length; i++) {
  //           if (currentZoneList?.[i]?.zone_name === currentZone) {
  //             zoneId = currentZoneList[i]?.zone_id;
  //           }
  //         }
  //       setCurrentZoneId(zoneId);
  //     }
  //   },[currentZone]);

  //   console.log(currentZone,currentZoneList?.[0]?.zone_name,currentZoneId,"equalto")

  useEffect(() => {
    setCurrentZoneId(currentZoneList?.[0]?.zone_id);
  }, [currentZoneList]);

  console.log(currentZoneList, "currentZoneList");
  console.log(zoneList, "currentZoneList");
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        justifyContent: "space-between",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          color: "white",
        }}
      >
        <Box
          sx={{
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <select
              style={{
                width: "8rem",
                height: "1.8rem",
                border: "1px solid #BBBABA",
                padding: "5px",
              }}
              onClick={(e) => handleStateChange(e.target.value)}
            >
              {stateList &&
                stateList.map((state) => (
                  <option key={state.state_id}>{state.state_name}</option>
                ))}
            </select>
            <select
              style={{
                width: "8rem",
                height: "1.8rem",
                border: "1px solid #BBBABA",
                padding: "5px",
              }}
              onChange={(e) => handleZoneChange(e.target.value)}
            >
              {flag == true
                ? zoneList?.map((zone) => (
                    <option key={zone.zone_id} value={zone?.zone_name}>
                      {zone?.zone_name}
                    </option>
                  ))
                : currentZoneList?.map((zone) => (
                    <option key={zone?.zone_id} value={zone?.zone_name}>
                      {zone?.zone_name}
                    </option>
                  ))}
            </select>
          </Stack>
        </Box>
      </Stack>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={Styles.DateContainer}>
          <div className={Styles.DateList}>
            <label className={Styles.DateText}>From Date</label>
            <div style={{ zIndex: "999" }}>
              <DatePicker
                selected={selectedDate?.startDate}
                onChange={(date) => handleStartDateChange(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>

          <div className={Styles.DateList}>
            <label className={Styles.DateText}>To Date</label>

            <div style={{ zIndex: "999" }}>
              <DatePicker
                selected={selectedDate?.endDate}
                onChange={(date) => handleEndDateChange(date)}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </Stack>
  );
};

export default DashboardDropDown;
