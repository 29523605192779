import React, { useEffect, useState } from "react";
import styles from "./styles/index.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MidContainerUserMgt from "./UserMgtMidContainer";
import UserMgtBottomContainer from "./UserMgtBottomContainer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import UserMgtMidContainerTwo from "./UserMgtMidContainerTwo";
import { USERMGTERRORNULL } from "../constants/UserMgt/UserMgt";
import SalesmanMgtContainer from "./SalesmanMgtContainer";
import UserMgtWholesaler from "./wholesaler/UserMgtWholesaler";
import ASMContainer from "./ASMContainer";
import useShapeFileList from "../../hooks/useShapeFileList";
import { getSelectedTab } from "../../slice/appSlice";

// ... (existing imports)

const UserManagement = ({ zone, Statevalue }) => {
  const dispatch = useDispatch();
  
  useShapeFileList();
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const Item = [
    "Delivery Partner",
    "Laabh Retailer",
    "Distribution Partner",
    "Sales Partner",
    "ASM",
  ];
  const filteredItem =
    userData?.role === "partner"
      ? Item.filter(
          (item) =>
            item !== "Distribution Partner" &&
            item !== "ASM"
        )
      : userData?.is_asm === true
      ? Item.filter(
          (item) =>
            item !== "Distribution Partner" &&
            item !== "ASM" &&
            item !== "Delivery Partner"
        )
      : Item;
  const [role, setRole] = useState(filteredItem[0]);

  React.useEffect(() => {
    dispatch({ type: USERMGTERRORNULL });
    setRole(filteredItem[0]);
  }, []);

  const handleTab=(item)=>{
    dispatch(getSelectedTab(item));
    setRole(item)
  }
  
  const renderComponent = () => {
    switch (role) {
      case "Laabh Retailer":
        return (
          <>
            <UserMgtMidContainerTwo tsiZone={zone} Statevalue={Statevalue} />
            <UserMgtBottomContainer tsiZone={zone} />
          </>
        );
      case "Distribution Partner":
        return <UserMgtWholesaler zone={zone} />;
      case "Delivery Partner":
        return <MidContainerUserMgt asmZone={zone} />;
      case "ASM":
        return <ASMContainer asmZone={zone} />;
      default:
        return <SalesmanMgtContainer />;
    }
  };

  return (
    <div className={styles.UserManagementContainer}>
      <Carousel responsive={responsive} arrows={false} swipeable={true}>
        {filteredItem.map((item, index) => (
          <div
            className={styles.UserManagementCarousel}
            onClick={() => handleTab(item)}
          >
            <div
              key={index}
              className={
                role === `${item}`
                  ? styles.UserManagementCarouselLabelWrapperOne
                  : styles.UserManagementCarouselLabelWrapperTwo
              }
            >
              <label
                className={
                  role === `${item}`
                    ? styles.UserManagementCarouselLabelOne
                    : styles.UserManagementCarouselLabelTwo
                }
              >
                {item}
              </label>
            </div>
          </div>
        ))}
      </Carousel>

      {/* <>
        {role === "Laabh Retailer" ? (
          <>
            <UserMgtMidContainerTwo tsiZone={zone} Statevalue={Statevalue} />
            <UserMgtBottomContainer tsiZone={zone} />
          </>
        ) : (
          <>
            {role === "Distribution Partner" && userData?.role !== "partner" ? (
              <UserMgtWholesaler zone={zone} />
            ) : (
              <>
                {role === "Delivery Partner" ? (
                  <>
                    <MidContainerUserMgt asmZone={zone} />
                  </>
                ) : role === "ASM" ? (
                  <ASMContainer asmZone={zone} />
                ) : (
                  <>
                    <SalesmanMgtContainer />
                  </>
                )}
              </>
            )}
          </>
        )}
      </> */}
      {renderComponent()}
    </div>
  );
};

export default UserManagement;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
