import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const useAddSku = (form) => {
  console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const skuFormData = useSelector(
    (store) => store.sku.skuFormData
  );
  const addSku = async () => {
    let param = {
      sku_name: form?.sku_name,
      hindi_name:form?.hindi_name,
      gujarati_name: form?.gujarati_name,
      sku_image: skuFormData?.full_path,
      category_id:form?.category_id,
      inventory:form?.inventory,
      ptr:form?.ptr,
      ptw:form?.ptw,
      mrp:form?.mrp,
      scheme:form?.scheme,
      selling_price:Number(form?.selling_price)
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_sku", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else {
      toast.error(data.message);
    }
  };
  return addSku;
};

export default useAddSku;
