// mySlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  partner_id_list: [],
  db_id_list: [],
  sm_id_list:[],
  pincode: '',
  shape_id: '',
  zone_id: '' // Add zone_id to the initial state
};

// admin assign SE
const assignSEslice = createSlice({
  name: 'assignSE',
  initialState,
  reducers: {
    setDistId: (state, action) => {
      state.partner_id_list = action.payload;
    },
    setSeIdList: (state, action) => {
      state.db_id_list = action.payload;
    },
    setSMIdList: (state, action) => {
      state.sm_id_list = action.payload;
    },
    setPincode: (state, action) => {
      state.pincode = action.payload;
    },
    setShapeId: (state, action) => {
      state.shape_id = action.payload;
    },
    setZoneIds: (state, action) => {
      state.zone_id = action.payload; // Add the reducer for setting zone_id    
    },
    resetSMId: (state) => {
      state.sm_id_list = [];
    },
    resetSeId: (state) => {
      state.db_id_list = [];
    },
    resetData: (state) => {
      return initialState;
    },
  },
});

export const { setDistId, setSMIdList, setSeIdList, setPincode, setShapeId, setZoneIds, resetData, resetSeId, resetSMId } = assignSEslice.actions;
export default assignSEslice.reducer;
export const selectAssignSEData = (state) => state.assignSE;
