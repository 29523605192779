import { createSlice } from "@reduxjs/toolkit";

const skuSlice = createSlice({
  name: "sku",
  initialState: {
    skuList: null,
    skuFormData: null,
    skuGiftFormData: null,
    skuGiftList: null,
    inventorySkuList: null,
    selectedSF:null,
    selectedAreaName:null,
    selectedCategoryId:null,
    inventoryList:null,
  },
  reducers: {
    getSkuList: (state, action) => {
      state.skuList = action.payload;
    },
    getGiftSkuList: (state, action) => {
      state.skuGiftList = action.payload;
    },
    getSkuFormData: (state, action) => {
      state.skuFormData = action.payload;
    },
    getskuInventoryList: (state, action) => {
      state.inventorySkuList = action.payload;
    },
    getGiftSkuFormData: (state, action) => {
      state.skuGiftFormData = action.payload;
    },
    getSelectedSF: (state, action) => {
      state.selectedSF = action.payload;
    },
    getSelectedAreaName: (state, action) => {
      state.selectedAreaName = action.payload;
    },
    getSelectedCategory: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    getInvetoryListV2:(state, action) =>{
      state.inventoryList = action.payload;
    },
    resetSkuFormData: (state, action) => {
      state.skuFormData = null;
    },
    resetSkuGiftFormData: (state, action) => {
      state.skuGiftFormData = null;
    },
  },
});

export default skuSlice.reducer;
export const {
  getSkuList,
  getSkuFormData,
  resetSkuFormData,
  getGiftSkuFormData,
  getskuInventoryList,
  getSelectedCategory,
  getGiftSkuList,
  getSelectedSF,
  getSelectedAreaName,
  resetSkuGiftFormData,
  getInvetoryListV2
} = skuSlice.actions;
