import {
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import VitaranLogo from "../../assets/landing_page_images/one.png";
import lobo_img from "../../assets/landing_page_images/lobo.png";
import delivery_img from "../../assets/cards/delivery-img.jpg";
import gift_img from "../../assets/cards/gift-img.jpg";
import b2b_img from "../../assets/cards/b2b-img.jpg";
import voucher_redeme from "../../assets/landing_page_images/voucher_redemption.png";
import real_time from "../../assets/landing_page_images/real_time_t.png";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
// import delivery_img from "../../../assets/cards/delivery-img.jpg";
import AdbIcon from "@mui/icons-material/Adb";
import ten from "../../assets/landing_page_images/ten.png";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import FooterHero from "./FooterHero";
import Footer from "./Footer";
import deliveryImg from "../../assets/delivery.jpg";
import aboutUs from "../../assets/landing_page_images/about.png";
import giftImg from "../../assets/gift_vouchers.jpg";
import instant_Delivery from "../../assets/landing_page_images/instant_delivery.png";
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import { baseUrl } from "../../utility/BaseURL";

const LandingPage = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const handleDetails = () => {
    var elmntToView = document.getElementById("ScrollView");
    elmntToView.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  };
  const cardsData = [
    {
      id: 1,
      imgSrc: delivery_img,
      heading: "AI-Powered Delivery Optimization",
      description:
        "Implementat AI Algorithms to optimize delivery Routes, reducing delivery time and cost for instant delivery",
    },
    {
      id: 1,
      imgSrc: gift_img,
      heading: "Personalized Gift Recommendation",
      description:
        "Developed a recommendation engine to suggest gift and vouchers based on user perferences and purchase history.",
    },
    {
      id: 1,
      imgSrc: b2b_img,
      heading: "Efficent B2B order Processing ",
      description:
        "Streamlined B2B order processing system on the mobile app, improving efficency and reducing manual error",
    },
    {
      id: 1,
      imgSrc: voucher_redeme,
      heading: "Seamless Voucher Redemption",
      description:
        "Create a Seamless Voucher redemption process with the app, enchaining user experience and promoting loyality",
    },
    {
      id: 1,
      imgSrc: real_time,
      heading: "Real-Time Delivery Tracking",
      description:
        "Implemented real-time tracking features for orders, allowing users to track their deliveries and receive notifications",
    },
    {
      id: 1,
      imgSrc: lobo_img,
      heading: "Interactive AI Assistant",
      description:
        "Developed an AI-powered chatbot for interactive customer support, providing instant assistance and resolving queries efficiently.",
    },
    // Add similar objects for the remaining cards
  ];
  const refreshToken = localStorage.getItem("refreshToken");
  const apiUrl = `${baseUrl}/get_app_link`;
  const updateUrl = `${baseUrl}update_download_count`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to the specified URL
        const response = await axios.post(apiUrl);
        console.log(response.data);
        // Set the data in the state
        setData(response.data?.result[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, [apiUrl]);
  const releaseDate = new Date(data?.release_date);
  const UpdateCount = async () => {
    try {
      // Make a GET request to the specified URL
      const response = await axios.post(updateUrl);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formattedDate = releaseDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  if (refreshToken) {
    // Redirect to / if refreshToken exists
    return <Navigate to="/home/dashboard" />;
  }
  return (
    <>
      <Stack sx={{ backgroundColor: "white", overflowX: "hidden" }}>
        <Stack sx={{ backgroundColor: "#ed7f79" }} height={{ lg: "100vh" }}>
          <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
            <NavBar open={open} />
          </Stack>
          <Stack
            sx={{ display: { xs: "flex", lg: "none" } }}
            mt={"1rem"}
            ml={"1rem"}
            mr={"1rem"}
            direction={"row"}
            alignItems={"center"}
            gap={5}
          >
            <Stack onClick={() => setOpen(true)}>
              <MenuIcon sx={{ color: "white", fontSize: "2rem" }} />
            </Stack>
            <Stack sx={{ width: "100px" }}>
              <img src={ten} alt="logo" />
            </Stack>
            <Stack
              sx={{
                backgroundColor: "white",
                padding: "5px 2rem",
                display: { xs: "flex", lg: "none" },
                borderRadius: "6px",
              }}
            >
              <RouterLink style={{ textDecoration: "none" }} to={"/login"}>
                <Button sx={{color:"#000"}}>Login</Button>
              </RouterLink>
            </Stack>
          </Stack>
          <Stack sx={{ display: { xs: "none", lg: "flex" } }}>
            <NavDrawer open={open} setOpen={setOpen} />
          </Stack>
          <Stack
            direction={{ sm: "column", lg: "row" }}
            sx={{
              width: "90%",
              margin: "auto",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Stack sx={{ color: "#000" }}>
              <Typography
                sx={{ color: "#000", fontWeight: "bold", textAlign: "left" }}
                variant="h3"
                gutterBottom
              >
                Unlock Instant Delivery Rewards
              </Typography>
              <Stack>
                <Typography sx={{ fontSize: "24px", textAlign: "left" }}>
                  Experience AI-powered B2B e-commerce for fast delivery order
                  now to receive exclusive gift and vouchers
                </Typography>
              </Stack>
              <Stack sx={{ color: "#000", marginTop: "1rem" }}>
                {/* <Typography>
                    Sign up with us to have a hassle free and fruitful trading
                    experience!
                  </Typography> */}
                <Button
                  startIcon={<AdbIcon fontSize="large" />}
                  variant="contained"
                  sx={{
                    width: "165px",
                    backgroundColor: "#fff",
                    color: "#DC1D13",
                    ":hover": { backgroundColor: "#fff" },
                    textWrap: "nowrap",
                  }}
                  onClick={UpdateCount}
                >
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontWeight: "600",
                    }}
                    href={data?.apk_url}
                    target="_blank"
                  >
                    {" "}
                    Download App
                  </a>
                </Button>
                <Typography>
                  v{data?.version_code} @ {formattedDate}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "24rem",
              }}
            >
              <img src={VitaranLogo} alt="img" />
            </Stack>
          </Stack>

          <Stack
            sx={{ color: "white", margin: "auto" }}
            onClick={handleDetails}
            style={{ cursor: "pointer" }}
          >
            <Stack>
              <Typography>Explore</Typography>
            </Stack>
            <Stack
              mb={"2rem"}
              sx={{ paddingLeft: "15px" }}
              onClick={handleDetails}
            >
              <KeyboardDoubleArrowDownIcon />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "80px" }}>
          <Stack
            sx={{
              padding: "0% 10%",
              marginBottom: "58px",
              alignItems: "center",
            }}
          >
            <Typography maxWidth="md" sx={{ fontSize: "20px" }}>
              Introducing VITARAN LAABH, the AI-powered B2B e-commerce
              mobile-app revolutionizing instant deliveries and rewards in
              India. With our innovative platform, businesses can streamline
              their ordering process and receive instant delivery while also
              offering gifts and voucher to their loyal customers. Located in
              India, VITARAN LAABH is the solution for businesses looking to
              enhance their B2B e-commerce experience. Join us in redefining the
              future of instant deliveries and rewards in the digital
              marketplace.
            </Typography>
          </Stack>
          <Stack
            sx={{
              padding: "0% 10%",
              flexDirection: { xs: "column", lg: "row" },
              maxWidth: "100%",
            }}
          >
            <Stack
              sx={{
                width: "100%",
                marginBottom: "20px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={instant_Delivery}
                alt="deliveryImg"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  height: "auto",
                  maxHeight: "480px",
                  display: "block",
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Instant Delivery Service
              </Typography>
              <Typography
                sx={{ fontSize: "14px", textAlign: "center", color: "gray" }}
              >
                Efficient and quick delivery per order
              </Typography>
            </Stack>
            <Stack
              id="ScrollView"
              sx={{
                width: "100%",
                marginBottom: "20px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={giftImg}
                alt="giftImg"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  height: "auto",
                  display: "block",
                  maxHeight: "480px",
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Gift and Vouchers
              </Typography>
              <Typography
                sx={{ fontSize: "14px", textAlign: "center", color: "gray" }}
              >
                Reward your clients with gifts and vouchers
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          textAlign="center"
          sx={{
            marginTop: "40px",
            backgroundColor: "#c8c9cc",
            paddingTop: "40px",
          }}
        >
          <Typography variant="h3">Features</Typography>

          <Stack
            spacing={2}
            direction="row"
            flexWrap="wrap"
            padding="0% 5%"
            justifyContent="space-between"
            sx={{ marginTop: "80px" }}
          >
            {cardsData.map((card) => (
              <Card
                key={card.id}
                elevation={0}
                sx={{
                  backgroundColor: "#c8c9cc",
                  marginBottom: "40px !important",
                  maxWidth: "400px",
                  minWidth: "120px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="img"
                  height="400"
                  width="100"
                  image={card.imgSrc}
                  alt={card.heading}
                  sx={{
                    // width: '100%',
                    objectFit: "cover",
                    maxWidth: "320px",
                    borderRadius: "14px",
                  }}
                />
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600", fontSize: "18px" }}
                  >
                    {card.heading}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Stack>
        <Stack
          padding="0% 5%"
          sx={{
            flexDirection: "row",
            "@media (max-width: 600px)": { flexDirection: "column" },
            width: "90vw",
            marginTop: "80px",
            // gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Stack
            sx={{
              width: "60%",
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            <Typography variant="h3">About Us</Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                lineHeight: "45px",
                marginTop: "20px",
                textAlign: "left",
              }}
            >
              Welcome to VITARAN LAABH, a cutting-edge AI powered B2B e-commerce
              mobile app revolutionizing instant deliveries and offering a wide
              range of gifts and vouchers. Based in India, we are dedicated to
              providing seamless and efficient solutions for businesses looking
              to streamline their supply chain operations.
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", lineHeight: "45px", textAlign: "left" }}
            >
              With a focus on innovation and customer satisfaction, our platform
              is designed to enhance the shopping experience for both buyers and
              sellers. Join us on this journey towards a more connected and
              efficient B2B e-commerce ecosystem.
            </Typography>
          </Stack>
          <Stack
            sx={{
              width: "30%",
              "@media (max-width: 600px)": { width: "100%" },
            }}
          >
            <img
              src={aboutUs}
              alt="deliveryImg"
              style={{
                width: "100%",
                minHeight: "400px",
                maxWidth: "500px",
                height: "auto",
                maxHeight: "480px",
                display: "block",
                borderRadius: "16px",
              }}
            />
          </Stack>
        </Stack>

        {/* <Stack
            sx={{
              color: "black",
              backgroundColor: "white",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack sx={{ width: "15%", margin: "auto", marginTop: "5rem" }}>
              <img src={eleven} alt="logo" />
            </Stack>
            <Stack>
              <Typography
                fontFamily={"-moz-initial"}
                sx={{
                  fontWeight: "bold",
                  margin: "auto",
                  color: "#565656",
                  marginTop: "1rem",
                }}
                fontSize={"25px"}
              >
                Buy, sell, make trade, at LOWEST prices & HIGHEST Profits
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: "40%",
                margin: "auto",
                textAlign: "center",
                color: "#565656",
                marginTop: "1rem",
              }}
            >
              <Typography>
                This platform lets you trade commodities, seamlessly within your
                zone, for the best possible prices being offered by buyers and
                sellers in your zone.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              color: "black",
              backgroundColor: "white",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              sx={{
                width: "65%",
                margin: "auto",
                marginTop: "5rem",
                display: { xs: "flex", lg: "none" },
              }}
            >
              <img src={eleven} alt="logo" />
            </Stack>
            <Stack>
              <Typography
                fontFamily={"-moz-initial"}
                sx={{
                  fontWeight: "bold",
                  margin: "auto",
                  color: "#565656",
                  marginTop: "1rem",
                }}
                width={"90%"}
                fontSize={"22px"}
                textAlign={"center"}
              >
                Buy, sell, make trade, at LOWEST prices & HIGHEST Profits
              </Typography>
            </Stack>
            <Stack
              sx={{
                width: "80%",
                margin: "auto",
                textAlign: "center",
                color: "#565656",
                marginTop: "1rem",
              }}
            >
              <Typography>
                This platform lets you trade commodities, seamlessly within your
                zone, for the best possible prices being offered by buyers and
                sellers in your zone.
              </Typography>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
            id="ScrollView"
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Custom Price Quotes
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Make you own price quote open to the market to find an
                    appropriate trader for the prices that suit you.
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={two} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
            id="ScrollView"
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Custom Price Quotes
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={two} alt="img" />
              </Stack>
              <Stack mt={5} textAlign={"center"}>
                <Typography>
                  Make you own price quote open to the market to find an
                  appropriate trader for the prices that suit you.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={three} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Top Sellers and Buyers List
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    At any given time and zone, Vitaran LIVE provides you a
                    comprehensive list of buyers/sellers with the best trading
                    offers for a commodity.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "19px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Top Sellers and Buyers List
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={three} alt="img" />
              </Stack>
              <Stack>
                <Stack marginTop={5} textAlign={"center"}>
                  <Typography>
                    At any given time and zone, Vitaran LIVE provides you a
                    comprehensive list of buyers/sellers with the best trading
                    offers for a commodity.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Category Based Catalogue
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Get a meticulously categorized view of all the products that
                    you want to trade, for clarity and ease of operation.
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={Four} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Category Based Catalogue
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={Four} alt="img" />
              </Stack>
              <Stack marginTop={5} textAlign={"center"}>
                <Typography>
                  Get a meticulously categorized view of all the products that
                  you want to trade, for clarity and ease of operation.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={five} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "28px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Delivery & Pickup Management
                  </Typography>
                </Stack>
                <Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>MAP VIEW</Typography>{" "}
                      allows you to make note of all the addresses from where
                      you have traded to figure out the pickup and delivery
                      logistics.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        DELIVERY PARTNER APP
                      </Typography>{" "}
                      helps selected delivery partners to define the daily route
                      and tasks wrt deliveries and pick up. Partners are always
                      connected digitally.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Delivery & Pickup Management
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={five} alt="img" />
              </Stack>
              <Stack mt={5} textAlign={"center"}>
                <Stack>
                  <Stack>
                    <Typography>
                      <Typography fontWeight={"bold"}>MAP VIEW</Typography>{" "}
                      allows you to make note of all the addresses from where
                      you have traded to figure out the pickup and delivery
                      logistics.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        DELIVERY PARTNER APP
                      </Typography>{" "}
                      helps selected delivery partners to define the daily route
                      and tasks wrt deliveries and pick up. Partners are always
                      connected digitally.
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Historic data in graphs
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Charts and Graphs set the context to understand the market
                    trend could be related to products, comparision and Users
                    profiling. 
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={six} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "23px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Historic data in graphs
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} marginLeft={{ xs: 0, lg: "20rem" }}>
                <img src={six} alt="img" />
              </Stack>
              <Stack mt={1} textAlign={"center"}>
                <Typography>
                  Charts and Graphs set the context to understand the market
                  trend could be related to products, comparision and Users
                  profiling. 
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack width={{ xs: "50%", lg: "30%" }}>
                <img src={seven} alt="img" />
              </Stack>
              <Stack marginLeft={{ xs: 0, lg: "20rem" }}>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Schemes & Incentives
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    Get customized and personalized schemes and offers on the
                    trade you make and make the process even more rewarding.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "23px",
                    borderBottom: "5px solid black",
                  }}
                >
                  Schemes & Incentives
                </Typography>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={seven} alt="img" />
              </Stack>
              <Stack>
                <Stack mt={5} textAlign={"center"}>
                  <Typography>
                    Get customized and personalized schemes and offers on the
                    trade you make and make the process even more rewarding.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "none", lg: "flex" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Trade Management and Ratings
                  </Typography>
                </Stack>
                <Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>
                        Update Multiple Prices at ONCE
                      </Typography>{" "}
                      by having all trades made in a single view and be able to
                      update the prices for multiple products as per need.
                    </Typography>
                  </Stack>
                  <Stack marginTop={"1rem"}>
                    <Typography>
                      <Typography fontWeight={"bold"}>Ratings</Typography>
                      lets you know a trader’s background record and helps you
                      make an informed decision!
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                width={{ xs: "50%", lg: "30%" }}
                marginLeft={{ xs: 0, lg: "20rem" }}
              >
                <img src={eight} alt="img" />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "white",
              marginTop: "5rem",
              display: { xs: "flex", lg: "none" },
            }}
          >
            <Stack
              direction={{ sm: "column", lg: "row" }}
              sx={{
                width: "70%",
                color: "#424063",
                margin: "auto",
                alignItems: "center",
              }}
              justifyContent={"space-between"}
            >
              <Stack>
                <Stack>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "17px",
                      borderBottom: "5px solid black",
                    }}
                  >
                    Trade Management and Ratings
                  </Typography>
                </Stack>
              </Stack>
              <Stack width={"100%"} mt={5}>
                <img src={eight} alt="img" />
              </Stack>
              <Stack textAlign={"center"}>
                <Stack mt={5}>
                  <Typography>
                    <Typography fontWeight={"bold"}>
                      Update Multiple Prices at ONCE
                    </Typography>{" "}
                    by having all trades made in a single view and be able to
                    update the prices for multiple products as per need.
                  </Typography>
                </Stack>
                <Stack marginTop={"1rem"}>
                  <Typography>
                    <Typography fontWeight={"bold"}>Ratings</Typography>
                    lets you know a trader’s background record and helps you
                    make an informed decision!
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack> */}
        <FooterHero />
        <Footer />
      </Stack>
    </>
  );
};

export default LandingPage;
