import React from "react";
import Sidebar from "../Sidebar";
import ZoneMgt from "./ZoneMgt";
import { Stack } from "@mui/material";
import DashboardHeader from "../Dashboard/AdminHeader";
const index = () => {
  return (
    <Stack>
      <Stack>
        {/* <DashboardHeader /> */}
      </Stack>
      <Stack direction="row">
        <Sidebar />
        <ZoneMgt />
      </Stack>
    </Stack>
  );
};

export default index;