import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
const SearchableDropdown = ({
  setSelectedPolygon,
  handlePolygonClick,
  polygonData,
  options,
  label1,
  label2,
  id,
  selectedVal,
  handleChange,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const boundryData = useSelector((state) => state.adminMap.getBoundryDetails);
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    const optionIndex = options.findIndex(opt => opt.pincode === option.pincode);
    handlePolygonClick(
          boundryData?.sf_details[optionIndex]?.pin_code,
          boundryData?.sf_details[optionIndex]?.shape_id,
          polygonData[optionIndex],
          boundryData?.sf_details[optionIndex]
    )  
    setSelectedPolygon(option.pincode);
    setQuery(() => "");
    handleChange(`${option[label1]} (${option[label2]})`);
    setIsOpen(false);
  };

  function toggle(e) {
    if (e && e.target === inputRef.current) {
      setIsOpen(!!query);
    } else {
      setIsOpen(false);
    }
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;
    return "";
  };

  const filter = (options) => {
    return options?.filter(
      (option) =>
        option[label1]?.toLowerCase()?.indexOf(query.toLowerCase()) > -1 ||
        option[label2]?.toString()?.indexOf(query) > -1
    );
  };

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
              setIsOpen(!!e.target.value);
            }}
            onClick={(e) => toggle(e)}
            style={{width:"250px",color:"gray",borderRadius:"none"}}
            placeholder="Search Area..."
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      {isOpen && (
        <div style={{cursor:"pointer"}} className={`options ${isOpen ? "open" : ""}`}>
          {filter(options)?.map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option, index)}
                className={`option ${
                  `${option[label1]} (${option[label2]})` === selectedVal
                    ? "selected"
                    : ""
                }`}
                key={`${id}-${index}`}
              >
                {option[label1]} ({option[label2]})
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
