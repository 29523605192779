import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getSaleDetailsOAO } from "../slice/saleSlice";

const useSaleDetailsOAO = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSaleDetails = async (param) => {
    
    const response = await Axios.post("sale_details_oao", param);
    const data = await response.data;
    dispatch(getSaleDetailsOAO(data.body)); 
  };
  
    return getAllSaleDetails;
  
};

export default useSaleDetailsOAO;
