//THIS MARKERS ARE FOR RETAILERS
import black from "./assest/retailers_markers/black.png";
import Lgreen from "./assest/retailers_markers/Lgreen.png";
import LLgreen from "./assest/retailers_markers/LLgreen.png";
import blue from "./assest/retailers_markers/blue.png";
import cyan from "./assest/retailers_markers/cyan.png";
import Dgreen from "./assest/retailers_markers/Dgreen.png";
import Dskin from "./assest/retailers_markers/Dskin.png";
import gray from "./assest/retailers_markers/gray.png";
import maroon from "./assest/retailers_markers/maroon.png";
import orange from "./assest/retailers_markers/orange.png";
import pink from "./assest/retailers_markers/pink.png";
import purple from "./assest/retailers_markers/purple.png";
import red from "./assest/retailers_markers/red.png";
import skin from "./assest/retailers_markers/skin.png";
import white from "./assest/retailers_markers/white.png";
import yellow from "./assest/retailers_markers/yellow.png";
//THIS MARKER ARE FOR SE
import blackPin from "./assest/sr_markers/black.png";
import LgreenPin from "./assest/sr_markers/Lgreen.png";
import LLgreenPin from "./assest/sr_markers/LLgreen.png";
import bluePin from "./assest/sr_markers/blue.png";
import cyanPin from "./assest/sr_markers/cyan.png";
import DgreenPin from "./assest/sr_markers/Dgreen.png";
import DskinPin from "./assest/sr_markers/Dskin.png";
import grayPin from "./assest/sr_markers/gray.png";
import maroonPin from "./assest/sr_markers/maroon.png";
import orangePin from "./assest/sr_markers/orange.png";
import pinkPin from "./assest/sr_markers/pink.png";
import purplePin from "./assest/sr_markers/purple.png";
import redPin from "./assest/sr_markers/red.png";
import skinPin from "./assest/sr_markers/skin.png";
import whitePin from "./assest/sr_markers/white.png";
import yellowPin from "./assest/sr_markers/yellow.png";

// // ASM files:
// import ASM_10 from './10_ASM.png';
// import ASM_11 from './11_ASM.png';
// import ASM_12 from './12_ASM.png';
// import ASM_13 from './13_ASM.png';
// import ASM_14 from './14_ASM.png';
// import ASM_15 from './15_ASM.png';
// import ASM_16 from './16_ASM.png';
// import ASM_17 from './17_ASM.png';
// import ASM_18 from './18_ASM.png';
// import ASM_19 from './19_ASM.png';
// import ASM_1 from './1_ASM.png';
// import ASM_20 from './20_ASM.png';
// import ASM_21 from './21_ASM.png';
// import ASM_22 from './22_ASM.png';
// import ASM_23 from './23_ASM.png';
// import ASM_24 from './24_ASM.png';
// import ASM_25 from './25_ASM.png';
// import ASM_26 from './26_ASM.png';
// import ASM_27 from './27_ASM.png';
// import ASM_28 from './28_ASM.png';
// import ASM_29 from './29_ASM.png';
// import ASM_2 from './2_ASM.png';
// import ASM_30 from './30_ASM.png';
// import ASM_31 from './31_ASM.png';
// import ASM_32 from './32_ASM.png';
// import ASM_33 from './33_ASM.png';
// import ASM_34 from './34_ASM.png';
// import ASM_35 from './35_ASM.png';
// import ASM_36 from './36_ASM.png';
// import ASM_37 from './37_ASM.png';
// import ASM_38 from './38_ASM.png';
// import ASM_39 from './39_ASM.png';
// import ASM_3 from './3_ASM.png';
// import ASM_40 from './40_ASM.png';
// import ASM_41 from './41_ASM.png';
// import ASM_42 from './42_ASM.png';
// import ASM_43 from './43_ASM.png';
// import ASM_44 from './44_ASM.png';
// import ASM_45 from './45_ASM.png';
// import ASM_46 from './46_ASM.png';
// import ASM_47 from './47_ASM.png';
// import ASM_48 from './48_ASM.png';
// import ASM_49 from './49_ASM.png';
// import ASM_4 from './4_ASM.png';
// import ASM_50 from './50_ASM.png';
// import ASM_51 from './51_ASM.png';
// import ASM_5 from './5_ASM.png';
// import ASM_6 from './6_ASM.png';
// import ASM_7 from './7_ASM.png';
// import ASM_8 from './8_ASM.png';
// import ASM_9 from './9_ASM.png';
// // distributor files:
// import distributor_10 from './10_distributor.png';
// import distributor_11 from './11_distributor.png';
// import distributor_12 from './12_distributor.png';
// import distributor_13 from './13_distributor.png';
// import distributor_14 from './14_distributor.png';
// import distributor_15 from './15_distributor.png';
// import distributor_16 from './16_distributor.png';
// import distributor_17 from './17_distributor.png';
// import distributor_18 from './18_distributor.png';
// import distributor_19 from './19_distributor.png';
// import distributor_1 from './1_distributor.png';
// import distributor_20 from './20_distributor.png';
// import distributor_21 from './21_distributor.png';
// import distributor_22 from './22_distributor.png';
// import distributor_23 from './23_distributor.png';
// import distributor_24 from './24_distributor.png';
// import distributor_25 from './25_distributor.png';
// import distributor_26 from './26_distributor.png';
// import distributor_27 from './27_distributor.png';
// import distributor_28 from './28_distributor.png';
// import distributor_29 from './29_distributor.png';
// import distributor_2 from './2_distributor.png';
// import distributor_30 from './30_distributor.png';
// import distributor_31 from './31_distributor.png';
// import distributor_32 from './32_distributor.png';
// import distributor_33 from './33_distributor.png';
// import distributor_34 from './34_distributor.png';
// import distributor_35 from './35_distributor.png';
// import distributor_36 from './36_distributor.png';
// import distributor_37 from './37_distributor.png';
// import distributor_38 from './38_distributor.png';
// import distributor_39 from './39_distributor.png';
// import distributor_3 from './3_distributor.png';
// import distributor_40 from './40_distributor.png';
// import distributor_41 from './41_distributor.png';
// import distributor_42 from './42_distributor.png';
// import distributor_43 from './43_distributor.png';
// import distributor_44 from './44_distributor.png';
// import distributor_45 from './45_distributor.png';
// import distributor_46 from './46_distributor.png';
// import distributor_47 from './47_distributor.png';
// import distributor_48 from './48_distributor.png';
// import distributor_49 from './49_distributor.png';
// import distributor_4 from './4_distributor.png';
// import distributor_50 from './50_distributor.png';
// import distributor_51 from './51_distributor.png';
// import distributor_5 from './5_distributor.png';
// import distributor_6 from './6_distributor.png';
// import distributor_7 from './7_distributor.png';
// import distributor_8 from './8_distributor.png';
// import distributor_9 from './9_distributor.png';
// // retailer files:
import retailer_10 from "./assest/Selorce_Markers/10_retailer.png";
import retailer_11 from "./assest/Selorce_Markers/11_retailer.png";
import retailer_12 from "./assest/Selorce_Markers/12_retailer.png";
import retailer_13 from "./assest/Selorce_Markers/13_retailer.png";
import retailer_14 from "./assest/Selorce_Markers/14_retailer.png";
import retailer_15 from "./assest/Selorce_Markers/15_retailer.png";
import retailer_16 from "./assest/Selorce_Markers/16_retailer.png";
import retailer_17 from "./assest/Selorce_Markers/17_retailer.png";
import retailer_18 from "./assest/Selorce_Markers/18_retailer.png";
import retailer_19 from "./assest/Selorce_Markers/19_retailer.png";
import retailer_1 from "./assest/Selorce_Markers/1_retailer.png";
import retailer_20 from "./assest/Selorce_Markers/20_retailer.png";
import retailer_21 from "./assest/Selorce_Markers/21_retailer.png";
import retailer_22 from "./assest/Selorce_Markers/22_retailer.png";
import retailer_23 from "./assest/Selorce_Markers/23_retailer.png";
import retailer_24 from "./assest/Selorce_Markers/24_retailer.png";
import retailer_25 from "./assest/Selorce_Markers/25_retailer.png";
import retailer_26 from "./assest/Selorce_Markers/26_retailer.png";
import retailer_27 from "./assest/Selorce_Markers/27_retailer.png";
import retailer_28 from "./assest/Selorce_Markers/28_retailer.png";
import retailer_29 from "./assest/Selorce_Markers/29_retailer.png";
import retailer_2 from "./assest/Selorce_Markers/2_retailer.png";
import retailer_30 from "./assest/Selorce_Markers/30_retailer.png";
import retailer_31 from "./assest/Selorce_Markers/31_retailer.png";
import retailer_32 from "./assest/Selorce_Markers/32_retailer.png";
import retailer_33 from "./assest/Selorce_Markers/33_retailer.png";
import retailer_34 from "./assest/Selorce_Markers/34_retailer.png";
import retailer_35 from "./assest/Selorce_Markers/35_retailer.png";
import retailer_36 from "./assest/Selorce_Markers/36_retailer.png";
import retailer_37 from "./assest/Selorce_Markers/37_retailer.png";
import retailer_38 from "./assest/Selorce_Markers/38_retailer.png";
import retailer_39 from "./assest/Selorce_Markers/39_retailer.png";
import retailer_3 from "./assest/Selorce_Markers/3_retailer.png";
import retailer_40 from "./assest/Selorce_Markers/40_retailer.png";
import retailer_41 from "./assest/Selorce_Markers/41_retailer.png";
import retailer_42 from "./assest/Selorce_Markers/42_retailer.png";
import retailer_43 from "./assest/Selorce_Markers/43_retailer.png";
import retailer_44 from "./assest/Selorce_Markers/44_retailer.png";
import retailer_45 from "./assest/Selorce_Markers/45_retailer.png";
import retailer_46 from "./assest/Selorce_Markers/46_retailer.png";
import retailer_47 from "./assest/Selorce_Markers/47_retailer.png";
import retailer_48 from "./assest/Selorce_Markers/48_retailer.png";
import retailer_49 from "./assest/Selorce_Markers/49_retailer.png";
import retailer_4 from "./assest/Selorce_Markers/4_retailer.png";
import retailer_50 from "./assest/Selorce_Markers/50_retailer.png";
import retailer_51 from "./assest/Selorce_Markers/51_retailer.png";
import retailer_5 from "./assest/Selorce_Markers/5_retailer.png";
import retailer_6 from "./assest/Selorce_Markers/6_retailer.png";
import retailer_7 from "./assest/Selorce_Markers/7_retailer.png";
import retailer_8 from "./assest/Selorce_Markers/8_retailer.png";
import retailer_9 from "./assest/Selorce_Markers/9_retailer.png";
// // SO files:
// import SO_10 from './10_SO.png';
// import SO_11 from './11_SO.png';
// import SO_12 from './12_SO.png';
// import SO_13 from './13_SO.png';
// import SO_14 from './14_SO.png';
// import SO_15 from './15_SO.png';
// import SO_16 from './16_SO.png';
// import SO_17 from './17_SO.png';
// import SO_18 from './18_SO.png';
// import SO_19 from './19_SO.png';
// import SO_1 from './1_SO.png';
// import SO_20 from './20_SO.png';
// import SO_21 from './21_SO.png';
// import SO_22 from './22_SO.png';
// import SO_23 from './23_SO.png';
// import SO_24 from './24_SO.png';
// import SO_25 from './25_SO.png';
// import SO_26 from './26_SO.png';
// import SO_27 from './27_SO.png';
// import SO_28 from './28_SO.png';
// import SO_29 from './29_SO.png';
// import SO_2 from './2_SO.png';
// import SO_30 from './30_SO.png';
// import SO_31 from './31_SO.png';
// import SO_32 from './32_SO.png';
// import SO_33 from './33_SO.png';
// import SO_34 from './34_SO.png';
// import SO_35 from './35_SO.png';
// import SO_36 from './36_SO.png';
// import SO_37 from './37_SO.png';
// import SO_38 from './38_SO.png';
// import SO_39 from './39_SO.png';
// import SO_3 from './3_SO.png';
// import SO_40 from './40_SO.png';
// import SO_41 from './41_SO.png';
// import SO_42 from './42_SO.png';
// import SO_43 from './43_SO.png';
// import SO_44 from './44_SO.png';
// import SO_45 from './45_SO.png';
// import SO_46 from './46_SO.png';
// import SO_47 from './47_SO.png';
// import SO_48 from './48_SO.png';
// import SO_49 from './49_SO.png';
// import SO_4 from './4_SO.png';
// import SO_50 from './50_SO.png';
// import SO_51 from './51_SO.png';
// import SO_5 from './5_SO.png';
// import SO_6 from './6_SO.png';
// import SO_7 from './7_SO.png';
// import SO_8 from './8_SO.png';
// import SO_9 from './9_SO.png';
// // SR files:
import SR_10 from "./assest/Selorce_Markers/10_SR.png";
import SR_11 from "./assest/Selorce_Markers/11_SR.png";
import SR_12 from "./assest/Selorce_Markers/12_SR.png";
import SR_13 from "./assest/Selorce_Markers/13_SR.png";
import SR_14 from "./assest/Selorce_Markers/14_SR.png";
import SR_15 from "./assest/Selorce_Markers/15_SR.png";
import SR_16 from "./assest/Selorce_Markers/16_SR.png";
import SR_17 from "./assest/Selorce_Markers/17_SR.png";
import SR_18 from "./assest/Selorce_Markers/18_SR.png";
import SR_19 from "./assest/Selorce_Markers/19_SR.png";
import SR_1 from "./assest/Selorce_Markers/1_SR.png";
import SR_20 from "./assest/Selorce_Markers/20_SR.png";
import SR_21 from "./assest/Selorce_Markers/21_SR.png";
import SR_22 from "./assest/Selorce_Markers/22_SR.png";
import SR_23 from "./assest/Selorce_Markers/23_SR.png";
import SR_24 from "./assest/Selorce_Markers/24_SR.png";
import SR_25 from "./assest/Selorce_Markers/25_SR.png";
import SR_26 from "./assest/Selorce_Markers/26_SR.png";
import SR_27 from "./assest/Selorce_Markers/27_SR.png";
import SR_28 from "./assest/Selorce_Markers/28_SR.png";
import SR_29 from "./assest/Selorce_Markers/29_SR.png";
import SR_2 from "./assest/Selorce_Markers/2_SR.png";
import SR_30 from "./assest/Selorce_Markers/30_SR.png";
import SR_31 from "./assest/Selorce_Markers/31_SR.png";
import SR_32 from "./assest/Selorce_Markers/32_SR.png";
import SR_33 from "./assest/Selorce_Markers/33_SR.png";
import SR_34 from "./assest/Selorce_Markers/34_SR.png";
import SR_35 from "./assest/Selorce_Markers/35_SR.png";
import SR_36 from "./assest/Selorce_Markers/36_SR.png";
import SR_37 from "./assest/Selorce_Markers/37_SR.png";
import SR_38 from "./assest/Selorce_Markers/38_SR.png";
import SR_39 from "./assest/Selorce_Markers/39_SR.png";
import SR_3 from "./assest/Selorce_Markers/3_SR.png";
import SR_40 from "./assest/Selorce_Markers/40_SR.png";
import SR_41 from "./assest/Selorce_Markers/41_SR.png";
import SR_42 from "./assest/Selorce_Markers/42_SR.png";
import SR_43 from "./assest/Selorce_Markers/43_SR.png";
import SR_44 from "./assest/Selorce_Markers/44_SR.png";
import SR_45 from "./assest/Selorce_Markers/45_SR.png";
import SR_46 from "./assest/Selorce_Markers/46_SR.png";
import SR_47 from "./assest/Selorce_Markers/47_SR.png";
import SR_48 from "./assest/Selorce_Markers/48_SR.png";
import SR_49 from "./assest/Selorce_Markers/49_SR.png";
import SR_4 from "./assest/Selorce_Markers/4_SR.png";
import SR_50 from "./assest/Selorce_Markers/50_SR.png";
import SR_51 from "./assest/Selorce_Markers/51_SR.png";
import SR_5 from "./assest/Selorce_Markers/5_SR.png";
import SR_6 from "./assest/Selorce_Markers/6_SR.png";
import SR_7 from "./assest/Selorce_Markers/7_SR.png";
import SR_8 from "./assest/Selorce_Markers/8_SR.png";
import SR_9 from "./assest/Selorce_Markers/9_SR.png";

// const SOarray =[
//   SO_10,
// SO_11,
// SO_12,
// SO_13,
// SO_14,
// SO_15,
// SO_16,
// SO_17,
// SO_18,
// SO_19,
// SO_1,
// SO_20,
// SO_21,
// SO_22,
// SO_23,
// SO_24,
// SO_25,
// SO_26,
// SO_27,
// SO_28,
// SO_29,
// SO_2,
// SO_30,
// SO_31,
// SO_32,
// SO_33,
// SO_34,
// SO_35,
// SO_36,
// SO_37,
// SO_38,
// SO_39,
// SO_3,
// SO_40,
// SO_41,
// SO_42,
// SO_43,
// SO_44,
// SO_45,
// SO_46,
// SO_47,
// SO_48,
// SO_49,
// SO_4,
// SO_50,
// SO_51,
// SO_5,
// SO_6,
// SO_7,
// SO_8,
// SO_9
// ];
const SRarray = [
  SR_1,
  SR_2,
  SR_3,
  SR_4,
  SR_5,
  SR_6,
  SR_7,
  SR_8,
  SR_9,
  SR_10,
  SR_11,
  SR_12,
  SR_13,
  SR_14,
  SR_15,
  SR_16,
  SR_17,
  SR_18,
  SR_19,
  SR_20,
  SR_21,
  SR_22,
  SR_23,
  SR_24,
  SR_25,
  SR_26,
  SR_27,
  SR_28,
  SR_29,
  SR_30,
  SR_31,
  SR_32,
  SR_33,
  SR_34,
  SR_35,
  SR_36,
  SR_37,
  SR_38,
  SR_39,
  SR_40,
  SR_41,
  SR_42,
  SR_43,
  SR_44,
  SR_45,
  SR_46,
  SR_47,
  SR_48,
  SR_49,
  SR_50,
  SR_51,
];
// const ASMarray =[
//   ASM_10,
// ASM_11,
// ASM_12,
// ASM_13,
// ASM_14,
// ASM_15,
// ASM_16,
// ASM_17,
// ASM_18,
// ASM_19,
// ASM_1,
// ASM_20,
// ASM_21,
// ASM_22,
// ASM_23,
// ASM_24,
// ASM_25,
// ASM_26,
// ASM_27,
// ASM_28,
// ASM_29,
// ASM_2,
// ASM_30,
// ASM_31,
// ASM_32,
// ASM_33,
// ASM_34,
// ASM_35,
// ASM_36,
// ASM_37,
// ASM_38,
// ASM_39,
// ASM_3,
// ASM_40,
// ASM_41,
// ASM_42,
// ASM_43,
// ASM_44,
// ASM_45,
// ASM_46,
// ASM_47,
// ASM_48,
// ASM_49,
// ASM_4,
// ASM_50,
// ASM_51,
// ASM_5,
// ASM_6,
// ASM_7,
// ASM_8,
// ASM_9,
// ];
// const Distributorarray =[
//   distributor_10,
// distributor_11,
// distributor_12,
// distributor_13,
// distributor_14,
// distributor_15,
// distributor_16,
// distributor_17,
// distributor_18,
// distributor_19,
// distributor_1,
// distributor_20,
// distributor_21,
// distributor_22,
// distributor_23,
// distributor_24,
// distributor_25,
// distributor_26,
// distributor_27,
// distributor_28,
// distributor_29,
// distributor_2,
// distributor_30,
// distributor_31,
// distributor_32,
// distributor_33,
// distributor_34,
// distributor_35,
// distributor_36,
// distributor_37,
// distributor_38,
// distributor_39,
// distributor_3,
// distributor_40,
// distributor_41,
// distributor_42,
// distributor_43,
// distributor_44,
// distributor_45,
// distributor_46,
// distributor_47,
// distributor_48,
// distributor_49,
// distributor_4,
// distributor_50,
// distributor_51,
// distributor_5,
// distributor_6,
// distributor_7,
// distributor_8,
// distributor_9,
// ];
const Retailerarray = [
  retailer_1,
  retailer_2,
  retailer_3,
  retailer_4,
  retailer_5,
  retailer_6,
  retailer_7,
  retailer_8,
  retailer_9,
  retailer_10,
  retailer_11,
  retailer_12,
  retailer_13,
  retailer_14,
  retailer_15,
  retailer_16,
  retailer_17,
  retailer_18,
  retailer_19,
  retailer_20,
  retailer_21,
  retailer_22,
  retailer_23,
  retailer_24,
  retailer_25,
  retailer_26,
  retailer_27,
  retailer_28,
  retailer_29,
  retailer_30,
  retailer_31,
  retailer_32,
  retailer_33,
  retailer_34,
  retailer_35,
  retailer_36,
  retailer_37,
  retailer_38,
  retailer_39,
  retailer_40,
  retailer_41,
  retailer_42,
  retailer_43,
  retailer_44,
  retailer_45,
  retailer_46,
  retailer_47,
  retailer_48,
  retailer_49,
  retailer_50,
  retailer_51,
];

const availableMarkers = [
  // black,
  blue,
  cyan,
  Dgreen,
  Dskin,
  maroon,
  orange,
  pink,
  purple,
  red,
  skin,
  white,
  Lgreen,
  LLgreen,
  yellow,
];
const srMarkersList = [
  // blackPin,
  bluePin,
  cyanPin,
  DgreenPin,
  DskinPin,
  maroonPin,
  orangePin,
  pinkPin,
  purplePin,
  redPin,
  skinPin,
  whitePin,
  LgreenPin,
  LLgreenPin,
  yellowPin,
];

// export const allSalesmanMarkers = (salesmanList) => {
//   let homeIconArray = [];
//   const salesmanWithColor = salesmanList?.map((salesman, index) => ({
//     retailer_id: salesman?.retailer_id,
//     marker: Retailerarray[index % Retailerarray?.length],
//     srMarkers: SRarray[index % SRarray?.length],
//   }));

//   salesmanWithColor?.map((salesman) =>
//     homeIconArray?.push(salesman?.srMarkers)
//   );

//   return homeIconArray;
// };

export const allSalesmanMarkers = (salesmanList) => {
  if (!salesmanList) return []; // Handle case where salesmanList is undefined or null

  let homeIconArray = [];
  const salesmanWithColor = salesmanList?.map((salesman, index) => ({
    retailer_id: salesman?.retailer_id,
    marker: Retailerarray[index % Retailerarray.length],
    srMarkers: SRarray[index % SRarray.length],
  }));

  salesmanWithColor.forEach((salesman) =>
    homeIconArray.push(salesman.srMarkers)
  );

  return homeIconArray;
};

// Randomly assign marker images to user_ids
// export const assignUserMarkers = (userList, salesmanList) => {
//   const salesmanWithColor = salesmanList?.map((salesman, index) => ({
//     retailer_id: salesman?.retailer_id,
//     marker: Retailerarray[index % Retailerarray.length],
//   }));

//   const markers = salesmanWithColor?.filter((salesman) =>
//     userList?.includes(salesman?.retailer_id)
//   );

//   return markers?.map((user, index) => {
//     return {
//       index,
//       user_id: user?.retailer_id,
//       marker: user?.marker,
//     };
//   });
// };

export const assignUserMarkers = (userList, salesmanList) => {

  const salesmanWithColor = salesmanList?.map((salesman, index) => ({
    retailer_id: salesman?.retailer_id,
    marker: Retailerarray[index % Retailerarray.length],
  }));

  const markers = salesmanWithColor?.filter((salesman) =>
    userList?.some((user) => user?.sm_id === salesman?.retailer_id)
  );

  return markers?.map((user, index) => {
    // users?.retailer_details?.map((user, index) => {
    // const markerIndex = index % availableMarkers.length;
    return {
      index,
      user_id: user?.retailer_id,
      marker: user?.marker,
    };
  });
};
