import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { createTheme } from "@mui/system";
import "./styles/zonemgt.scss";
import InputLabel from "@mui/material/InputLabel";
import { LoadingButton } from "@mui/lab";
import UpdateZoneMgt from "./UpdateZoneMgt";
import { useDispatch, useSelector } from "react-redux";
// import { PerformanceStampDailyReportByZone } from '../../../actions/DailyReports/dailyReport';
// import { AddZoneByState, GetZoneByStateId } from '../../../actions/ZoneMgt/zoneMgtAction';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAddZone from "../../hooks/useAddZone";
import useZoneListUpdate from "../../hooks/useZoneListUpdate";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ipm: 768,

      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const AddLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: "25px 25px",
  fontWeight: "bold",
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  width: "100px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
  },
}));

const useStyles = makeStyles({
  content: {
    // marginLeft:window.innerWidth < '960'? 0 : 250,
    marginLeft: 0,
    flexGrow: 1,
    padding: "5px 5px",
    paddingRight: "15px",
    backgroundColor: "#E5E5E5",
    height: "100%",
    minHeight: "100vh",
  },

  appBar: {
    position: "fixed",
    height: 60,
    backgroundColor: "#DC1D13",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "-10px",
    gap: "5px",
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  autocomplete: {
    color: "#000",
    [theme.breakpoints.between("xs", "md")]: {
      width: 100,
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      width: 165,
    },
  },
  autocompleteContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  loaderdiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  zoneEmpty: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // width: "100%"
  },
  zoneEmptyTitle: {
    fontSize: "50px",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
  },
});

export default function ZoneMgt() {
  const Firsttoken = useSelector((state) => state.Token);
  const stateList = useSelector((state) => state.app.stateList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [Stateoptions, setStateoptions] = React.useState([]);
  const [Statevalue, setStateValue] = React.useState("");
  const [StateName, setStateName] = React.useState();
  const [zoneName, setZoneName] = React.useState("");
  const [zoneCode, setZoneCode] = React.useState("");
  const [lat, setLat] = React.useState('');
  const [lng, setLng] = React.useState('');
  // const stateList = useSelector(state => state.Zone.StateList);
  const [error, setError] = React.useState({});
  const [token, setToken] = React.useState("");

  const handleStateChange = (event) => {
    const selectedStateId = event.target.value;
    setStateValue(selectedStateId);
  
    // Find the state object in Stateoptions based on state_id
    const selectedState = Stateoptions.find((item) => item.state_id === selectedStateId);
  
    if (selectedState) {
      const selectedStateName = selectedState.state_name;
      console.log(selectedStateName);
      setStateName(selectedStateName);
    }
  };

  React.useEffect(() => {
    setStateoptions(stateList);
  }, [stateList]);

  React.useEffect(() => {
    if (Stateoptions && Stateoptions.length > 0) {
      setStateValue(Stateoptions[0]?.state_id);
      setStateName(Stateoptions[0]?.state_name);
    }
  }, [Stateoptions]);

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  const IsValid = () => {
    let success = true;
    let errors = {};

    if (!zoneName?.trim()) {
      errors.zoneName = '**Please type a zone name**';
      success = false;
    }

    if (!zoneCode?.trim()) {
      errors.zoneCode = '**Please type a zone code**';
      success = false;
    }

    if (!lat?.trim()) {
      errors.lat = '**Please enter latitude**';
      success = false;
    }

    if (!lng?.trim()) {
      errors.lng = '**Please enter longitude**';
      success = false;
    }

    setError(errors);
    return success;
  };

  const AddZone = useAddZone();
  const refreshZoneList = useZoneListUpdate();
  const handleClick = async (e) => {
    e.preventDefault();
    
    if (IsValid()) {
        const formData = {
          zone_name: zoneName,
          zone_code: zoneCode,
          lat: lat,
          lng: lng,
          state_id:Statevalue
        };
    
        await AddZone(formData);
        await refreshZoneList(Statevalue);
        setZoneName('');
      setZoneCode('');
      setLat('');
      setLng('');
  };
}

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="ZoneMgtContainer">
        <Stack direction={{ xs: "column" }}>
          <Stack
            direction={{ xs: "column" }}
            component={Paper}
            padding={1}
            spacing={2}
          >
            <Stack direction="row" spacing={1} padding={2}>
              <Typography sx={{ fontWeight: "bold" }}>Select State</Typography>
              <FormControl fullWidth sx={{ minWidth: 220, maxWidth: 220 }}>
                <Select
                  value={Statevalue}
                  onChange={handleStateChange}
                  size="small"
                  // label="Age"
                >
                  {Stateoptions &&
                    Stateoptions.map((item, index) => (
                      <MenuItem value={item.state_id} key={index}>
                        {item?.state_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Stack>
            <Typography
              variant="h6"
              component="h6"
              paddingLeft={2}
              sx={{ fontWeight: "bold" }}
            >
              Add Zone
            </Typography>

            <form onSubmit={handleClick}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        padding={1}
        alignItems="flex-start"
        spacing={2}
      >
        {/* Zone Name */}
        <Stack direction="row" spacing={1} paddingLeft={2}>
          <Typography sx={{ fontWeight: 'bold' }}>Zone Name</Typography>
          <TextField
            size="small"
            error={error?.zoneName ? true : false}
            helperText={error?.zoneName ? error?.zoneName : ''}
            name="zone_name"
            value={zoneName}
            onChange={(e) => setZoneName(e.target.value)}
          />
        </Stack>

        {/* Zone Code */}
        <Stack direction="row" spacing={1} paddingLeft={2}>
          <Typography sx={{ fontWeight: 'bold' }}>Zone Code</Typography>
          <TextField
            size="small"
            error={error?.zoneCode ? true : false}
            helperText={error?.zoneCode ? error?.zoneCode : ''}
            name="zone_code"
            value={zoneCode}
            onChange={(e) => setZoneCode(e.target.value)}
          />
        </Stack>

        {/* Latitude */}
        <Stack direction="row" spacing={1} paddingLeft={2}>
          <Typography sx={{ fontWeight: 'bold' }}>Latitude</Typography>
          <TextField
            size="small"
            error={error?.lat ? true : false}
            helperText={error?.lat ? error?.lat : ''}
            name="lat"
            value={lat}
            onChange={(e) => setLat(e.target.value)}
          />
        </Stack>

        {/* Longitude */}
        <Stack direction="row" spacing={1} paddingLeft={2}>
          <Typography sx={{ fontWeight: 'bold' }}>Longitude</Typography>
          <TextField
            size="small"
            error={error?.lng ? true : false}
            helperText={error?.lng ? error?.lng : ''}
            name="lng"
            value={lng}
            onChange={(e) => setLng(e.target.value)}
          />
        </Stack>

        {/* Submit Button */}
        <AddLoadingButton
          type="submit"
          loading={loading}
          variant="contained"
          size="small"
        >
          Add
        </AddLoadingButton>
      </Stack>
    </form>
          </Stack>
        </Stack>

        <UpdateZoneMgt token={token} state={Statevalue} />
      </div>
    </>
  );
}
