import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const AddLaabhPartner = ({ open, onClose, onSubmit }) => {
  const getDropDownData = useSelector((state)=> state.app.curretDropdownData);

  const [formData, setFormData] = useState({
    name: '', 
    zone_id: getDropDownData.zone_id,
    mobile_no: '',
    email:""
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      zone_id: getDropDownData.zone_id,
    }));
  }, [getDropDownData, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateMobileNumber = () => {
    const mobileNumber = formData.mobile_no;
  
    // Check if the mobile number is a string of 10 digits
    if (mobileNumber && mobileNumber.length === 10 && !isNaN(mobileNumber)) {
      return true;
    }
  
    return false;
  };
  
  const validateEmail = () => {
    const email = formData.email;
  
    if (email && email.includes('@') && email.split('@').length === 2 && email.split('@')[1].includes('.') && email.trim() !== '') {
      return true;
    }
  
    return false;
  };
  
  

  const handleFormSubmit = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name?.trim()) {
      newErrors.name = "Name is required";
    }
    
    if (!formData.email?.trim()) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail()) {
      newErrors.email = 'Invalid Email Address';
    }

    // Validate mobile_no
    if (!formData?.mobile_no?.trim()) {
      newErrors.mobile_no = "Mobile Number is required";
    } else if (!validateMobileNumber()) {
      newErrors.mobile_no = "Invalid Mobile Number";
    }

    // If there are errors, setErrors and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If no errors, submit the form
    onSubmit(formData);
    onClose();
    setFormData({});
    setErrors({});
  };
  
  const handleClose = () => {
    onClose();
    setFormData({
      name: '',
      zone_id: getDropDownData.zone_id,
      mobile_no: '',
    });
    setErrors({});
  };
  return (
    <Dialog maxWidth={"800px"} open={open} onClose={onClose} sx={{ width: '50vw', margin: 'auto' }}>
      <DialogTitle>Add Distribution Partner</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width:"40vw", maxWidth:"100%" }}>
        <label htmlFor="name">Name:</label>
        <TextField
          size="small"
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.name)}
          helperText={errors.name}
        />
        <label htmlFor="name">Email:</label>
        <TextField
          size="small"
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <label htmlFor="zone_id">Zone Name:</label>
        <TextField
          size="small"
          type="text"
          id="zone_id"
          name="zone_id"
          value={getDropDownData?.zone_name}
          onChange={handleInputChange}
          fullWidth
          disabled
        />

        <label htmlFor="mobile_no">Mobile Number:</label>
        <TextField
          size="small"
          type="text"
          id="mobile_no"
          name="mobile_no"
          value={formData.mobile_no}
          onChange={handleInputChange}
          fullWidth
          required
          error={Boolean(errors.mobile_no)}
          helperText={errors.mobile_no}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { onClose(); handleClose(); }} sx={{ color: "gray" }}>
          Cancel
        </Button>
        <Button
          onClick={handleFormSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#DC1D13",
            ":hover": {
              backgroundColor: "#960c08",
            },
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLaabhPartner;
