import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import usePincodeData from "../../hooks/useGetPincodeAssign";
import "./assets/mapStyle.css";
import {
  resetSeId,
  setPincode,
  setSeIdList,
  setShapeId,
} from "../../slice/assignSEslice";
import { useSelector, useDispatch } from "react-redux";
import { markerIcon } from "./MarkerIcon";
import polyMaker from "./assets/markersImg/dotBlack.png";
import { setSFPincode, setSFShapeId } from "../../slice/SFstatusSlice";

import {
  resetRouteClusterList,
  resetAssignedBoundryDetails,
  resetReqBoundryDetails,
  resetPincodeDetails,
  assignedBoundryDetails,
} from "../../slice/adminMapSlice";
import checkIncon from "./assets/check_circle.png";
import crossIcon from "./assets/delete.png";
// Makrer for Router Cluster
import RED from "./assets/routeMarker/red.png";
import BLUE from "./assets/routeMarker/blue.png";
import CYAN from "./assets/routeMarker/cyan.png";
import GREEN from "./assets/routeMarker/green.png";
import ORANGE from "./assets/routeMarker/orange.png";
import PINK from "./assets/routeMarker/pink.png";
import infoWindowMarker from "./assets/info_window_marker.png";
import useAssignedRoute from "../../hooks/useAssignedRoute";
import SearchableDropdown from "./SearchableDropdown";
import { Stack, Box, Typography, Card, ButtonBase } from "@mui/material";

const MapComponent = ({
  pincodeCord,
  retailerCord,
  saveDB_idIndex,
  showDailyRoute,
  assignedBoundryRoutes,
}) => {
  const markerIconsByDay = {
    monday: RED,
    tuesday: GREEN,
    wednesday: BLUE,
    thursday: ORANGE,
    friday: CYAN,
    saturday: PINK,
  };

  const daysOfWeekColors = {
    monday: "#FF0000",
    tuesday: "#00FF00",
    wednesday: "#0000FF",
    thursday: "#ff8c00",
    friday: "#17EAE3",
    saturday: "#C139C3",
  };

  const days = Object.keys(daysOfWeekColors);
  const [center, setCenter] = useState();
  const [zoomLevel, setZoomLevel] = useState(12);
  const [markerData, setMarkerData] = useState(null);
  const zoneId = useSelector((state) => state.SFstatus.zone_id);
  const zoneData = useSelector((state) => state.app.zoneList);
  const pincodeDetails = useSelector(
    (state) => state.adminMap.getPincodeDetails
  );

  const clusterDetails = useSelector(
    (state) => state.adminMap.getClusterDetails
  );
  const boundryData = useSelector((state) => state.adminMap.getBoundryDetails);
  useEffect(() => {
    const data = clusterDetails?.cluster_list || pincodeDetails?.cluster_list;
    setMarkerData(data);
  }, [clusterDetails, pincodeDetails]);

  const dispatch = useDispatch();
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedPinMarker, setSelectedPincodeMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    // Check the condition and update the 'visible' state accordingly
    if (pincodeDetails?.marker_list?.length > 0) {
      setVisible(true);
    } else {
      // If the condition is not true, set 'visible' to false
      setVisible(false);
    }
  }, [pincodeDetails]);

  useEffect(() => {
    // Find the data object with the matching zoneId
    const selectedZone = zoneData?.find((zone) => zone.zone_id === zoneId);
    setValue("");

    // If a matching zone is found, update the centroid state
    if (selectedZone) {
      const [lat, lng] = selectedZone.centroid.split(",").map(Number);
      setCenter({ lat, lng });
    } else {
      // Handle the case where no matching zone is found, if needed
      setCenter(null);
    }
  }, [zoneId, zoneData]);
  const polygonData = pincodeCord?.map((location) =>
    location.coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );

  useEffect(() => {
    dispatch(resetRouteClusterList());
    dispatch(resetReqBoundryDetails());
    dispatch(resetPincodeDetails());
    dispatch(resetSeId());
  }, [selectedPolygon]);

  const getAssignPincodeData = usePincodeData();
  const getAssignedRoute = useAssignedRoute();

  const handlePolygonClick = async (
    pincode,
    shapeId,
    coordinates,
    pincodeData
  ) => {
    setSelectedPolygon(pincode);
    dispatch(setPincode(pincode));
    dispatch(setSFPincode(pincode));
    dispatch(setShapeId(shapeId));
    dispatch(setSFShapeId(shapeId));
    dispatch(assignedBoundryDetails(pincodeData));
    setValue("");
    const param = {
      pincode: pincode,
      shape_id: shapeId,
    };
    const param2 = {
      zone_id: zoneId,
      pincode: pincode,
      shape_id: shapeId,
    };
    setSelectedPincodeMarker(coordinates);
    // const markersInsidePolygon = retailerCord?.filter((data) => {
    //   const markerPosition = {
    //     lat: parseFloat(data.lat),
    //     lng: parseFloat(data.lng),
    //   };

    //   return isMarkerInsidePolygon(markerPosition, coordinates);
    // });

    // Check if markersInsidePolygon is empty
    // if (markersInsidePolygon.length === 0) {
    //   // If empty, use coordinates for setSelectedPincodeMarker
    //   setSelectedPincodeMarker(coordinates);
    // } else {
    //   // If not empty, use the markersInsidePolygon array
    //   setSelectedPincodeMarker(markersInsidePolygon);
    // }

    try {
      const data = getAssignPincodeData(param);

      // Continue with the rest of your code
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const salesmanRetailerIcon = {
    1: RED,
    2: BLUE,
  };

  // const getMarkerIcon = (zoneId) => {
  //   return {
  //     url: markerIcon[zoneId] || markerIcon.default,
  //     // scaledSize: new window.google.maps.Size(30 + zoomLevel, 30 + zoomLevel),
  //   };
  // };

  const getMarkerIcon = (user_status) => {
    return {
      url:
        user_status === 'active'
          ? salesmanRetailerIcon[2]
          : salesmanRetailerIcon[1],
      // scaledSize: new window.google.maps.Size(30 + zoomLevel, 30 + zoomLevel),
    };
  };

  useEffect(() => {
    if (map && selectedPinMarker) {
      const bounds = new window.google.maps.LatLngBounds();

      selectedPinMarker?.forEach((retailer) => {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(retailer.lat),
            parseFloat(retailer.lng)
          )
        );
      });

      setBounds(bounds);

      // Fit the map to the bounds
      map.fitBounds(bounds);
    }
  }, [map, selectedPinMarker]);

  const handleMapLoad = (map) => {
    setMap(map);
  };
  const [selectedMarker, setSelectedMarker] = useState(null);
  // useEffect(() => {
  //   setSelectedPincodeMarker(retailerCord);
  // }, [retailerCord]);

  const isMarkerInsidePolygon = (markerPosition, polygonCoordinates) => {
    const markerLatLng = new window.google.maps.LatLng(
      markerPosition.lat,
      markerPosition.lng
    );

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
    });

    return window.google.maps.geometry.poly.containsLocation(
      markerLatLng,
      polygon
    );
  };

  const handleZoomChanged = (map) => {
    // Get the current zoom level
    const currentZoom = map?.getZoom();

    // Update the state with the new zoom level
    setZoomLevel(currentZoom);
  };

  const findIndexByDbId = (db_id) => {
    const match = saveDB_idIndex.find((item) => item.db_id === db_id);
    return match ? match.index : null;
  };

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          onZoomChanged={() => handleZoomChanged(map)}
          onLoad={handleMapLoad}
          mapContainerStyle={{
            width: "100%",
            height: "calc(100vh - 62px)",
            borderRadius: "16px",
            border: "1px solid black",
          }}
          zoom={12}
          center={center}
          options={{
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              // {
              //   featureType: "road",
              //   elementType: "labels",
              //   stylers: [{ visibility: "off" }],
              // },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              top: "10px",
              right: "60px",
              flexDirection: "row",
              gap: "4px",
              backgroundColor: "#fff",
              padding: "4px",
              // borderRadius: "6px",
            }}
          >
            <SearchableDropdown
              setSelectedPolygon={setSelectedPolygon}
              handlePolygonClick={handlePolygonClick}
              polygonData={polygonData}
              options={boundryData?.sm_details}
              label1="office_name"
              label2="pincode"
              id="id"
              selectedVal={value}
              handleChange={(val) => setValue(val)}
            />
          </Stack>
          {polygonData?.map((coordinates, index) => (
            <Polygon
              key={index}
              paths={coordinates}
              options={{
                fillColor: "#ccdbcf",
                fillOpacity: 0.4,
                strokeColor:
                  selectedPolygon === pincodeCord[index]?.pin_code
                    ? "black"
                    : "#3483eb",
                strokeOpacity: 1,
                strokeWeight:
                  selectedPolygon === pincodeCord[index]?.pin_code ? 2 : 1,
                clickable: true,
              }}
              onClick={() =>
                handlePolygonClick(
                  pincodeCord[index]?.pin_code,
                  pincodeCord[index]?.shape_id,
                  coordinates,
                  boundryData.sf_details[index]
                )
              }
            />
          ))}
          {zoomLevel > 12 ? (
            <>
              {boundryData?.sf_details?.map((data, index) => (
                <Marker
                  icon={{
                    url: infoWindowMarker,
                  }}
                  key={index}
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  // onClick={() => setSelectedMarker(data)}
                />
              ))}

              {boundryData?.sf_details?.map((data, index) => (
                <InfoWindow
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                  options={{
                    disableAutoPan: true,
                  }}
                >
                  <div className="info-container">
                    <div className="marker-label">
                      {/* <div>Retailers: {data.retailers_count?.toString()}</div> */}
                      {data.shape_file_status ? (
                        <img src={checkIncon} alt="Marker Image" />
                      ) : (
                        <img src={crossIcon} alt="cross img" />
                      )}
                    </div>
                    <div
                      className="lp-name"
                      // style={{ color: "black", fontWeight: "600" }}
                    >
                      <div>{data?.office_name} </div>
                      <div> ({data.pincode?.toString()})</div>
                    </div>
                    {data.lp_list.some((db) => db !== null) && (
                      <div
                        className="lp-name"
                        // style={{ color: "black", fontWeight: "600" }}
                      >
                        {data.lp_list.map((lp, index) => (
                          <div key={index}>{lp?.name}</div>
                        ))}
                      </div>
                    )}
                    {data?.db_list?.some((db) => db !== null) && (
                      <div className="db-name-wrapper">
                        {data.db_list.map(
                          (db, index) =>
                            db !== null && (
                              <div
                                className="db-name"
                                key={index}
                                // style={{ color: "black", fontWeight: "600" }}
                              >
                                {db?.full_name}
                              </div>
                            )
                        )}
                      </div>
                    )}
                    {data?.sm_list?.some((sm) => sm !== null) && (
                      <div className="db-name-wrapper">
                        {data.sm_list.map(
                          (sm, index) =>
                            sm !== null && (
                              <div
                                className="lp-name"
                                key={index}
                                // style={{ color: "black", fontWeight: "600" }}
                              >
                                {sm?.full_name}
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </InfoWindow>
              ))}
            </>
          ) : null}

          {/* {!clusterDetails && !pincodeDetails?.cluster_list.length > 0 ? ( */}
          {retailerCord?.map((retailer, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(retailer.lat),
                  lng: parseFloat(retailer.lng),
                }}
                // icon={getMarkerIcon(retailer.zone_id)}
                icon={getMarkerIcon(retailer?.user_status)}
                onClick={() => {
                  setSelectedRetailer(retailer);
                }}
              />
            ))
              }
          {/* ) : (
            <></>
          )} */}

          {selectedRetailer && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedRetailer.lat),
                lng: parseFloat(selectedRetailer.lng),
              }}
              onCloseClick={() => {
                setSelectedRetailer(null);
              }}
            >
              <div>
                <p>Shop Name: {selectedRetailer.shop_name}</p>
              </div>
            </InfoWindow>
          )}

          <React.Fragment>
            {markerData && markerData.length > 0 ? (
              markerData.map((cluster, clusterindex) => (
                <React.Fragment key={cluster.cluster_id}>
                  {cluster.coord_list.map((retailer, index) => {
                    const clusterIndex = findIndexByDbId(cluster.db_id);
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: parseFloat(retailer.lat),
                          lng: parseFloat(retailer.lng),
                        }}
                        icon={getMarkerIcon(clusterIndex)}
                        onClick={() => {
                          setSelectedRetailer(retailer);
                        }}
                      />
                    );
                  })}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}

            {selectedRetailer && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedRetailer.lat),
                  lng: parseFloat(selectedRetailer.lng),
                }}
                onCloseClick={() => {
                  setSelectedRetailer(null);
                }}
              >
                <div>
                  {/* Render retailer details here */}
                  <p>Shop Name: {selectedRetailer.shop_name}</p>
                  {/* You can fetch and display more retailer details here */}
                </div>
              </InfoWindow>
            )}
          </React.Fragment>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
