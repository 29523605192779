import React, { useState, useEffect } from "react";
import Styles from "./styles/login.module.css";
import { Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import useSendOtp from "../../hooks/useSendOtp";
import useVerifyRestPassword from "../../hooks/useVerifyRestPassword";
import { ToastContainer} from "react-toastify";
import { useSelector } from "react-redux";
import {TailSpin} from "react-loader-spinner"
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F2843F",
  "&:hover": {
    backgroundColor: "#F2843F",
  },
}));

const ResetPassword = ({ setIsResetPassword }) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [passwordScreen, setPasswordScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleBackToLogin = () => {
    setPasswordScreen(false);
    setIsResetPassword(false);    
  };
  const userData = useSelector((state)=> state.login.resetData);
  const EmailValid = () => {
    let success = true;
    let err = {};

    if (!form?.email?.trim()) {
      err.email = "Email cannot be empty";
      success = false;
    }

    setError(err);
    return success;
  };
  const isValid = () => {
    let success = true;
    let err = {};
  
    if (!form?.otp?.trim()) {
      err.otp = "OTP is mandatory to reset password";
      success = false;
    }
  
    if (!form?.new_password?.trim()) {
      err.new_password = "New Password cannot be empty";
      success = false;
    }
  
    if (!form?.confirm_password?.trim()) {
      err.confirm_password = "Confirm Password cannot be empty";
      success = false;
    } else if (form?.new_password?.trim() !== form?.confirm_password?.trim()) {
      err.confirm_password = "New Password and Confirm Password do not match";
      success = false;
    }
  
    setError(err);
    return success;
  };
  // useEffect(() => {
  //   if (userData && userData.code === 200) {
  //    setPasswordScreen(true);
  //    }
  //    setLoading(false);
  // }, [userData]);
  const resetPassword = useVerifyRestPassword();
  const handleSubmit = async() => {  
    const param={        
      email:form.email,
      otp:form.otp,
      new_password:form.confirm_password,
      role:userData?.role[0]?.role   
  }  
    if (isValid()) {     
     const data = await resetPassword(param);
     if (data && data.code === 200) {
     await setIsResetPassword(false);
     }
    }
  };
  const sendOtpEmail = useSendOtp();
  const sendOpt = async () => {
    if (EmailValid()) {
      setLoading(true);
      const data = await sendOtpEmail(form.email);
      if(data){
        setLoading(false);
      }
      if (data && data.code === 200) {
        setLoading(false);
        setPasswordScreen(true);       
      }
    }
  };
  

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <div>
      {!passwordScreen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "center",
            justifyContent: "center",
            padding: "1.6rem",
          }}
        >
          <label className={Styles.NumberTitle}>
            Enter your registered Email to get OTP
          </label>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.2rem",
            }}
          >
            <div className={Styles.TextContainer}>
              <input
                placeholder="Enter Email Id"
                name="email"
                type="text"
                className={Styles.TextField}
                style={{ width: "14rem" }}
                onChange={handleChange}
              />
            </div>
            {error.email && (
              <label className={Styles.errorMessage}>
                {"**" + error.email + "**"}
              </label>
            )}
          </Stack>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div style={{ marginTop: "0.6rem" }}>
                
                <RedButton
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={sendOpt}
                  disabled={loading}
                >
                  {loading ? (
                <TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass=""
                />):(
                  "Send OTP"
                  )}
                </RedButton>
                
              </div>
              <Stack
                sx={{
                  color: "#F2843F",
                  textDecoration: "underline",
                  fontSize: "15px",
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                }}
                variant="body2"
                onClick={handleBackToLogin}
              >
                Back to login
              </Stack>
            </Stack>
          </Stack>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "center",
            justifyContent: "center",
            padding: "1.6rem",
          }}
        >
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <label className={Styles.NumberTitle}>
              Enter the OTP sent to your email id
            </label>
            <div className={Styles.TextContainer}>
              <input
                placeholder="Enter OTP"
                name="otp"
                type="number"
                className={Styles.TextField}
                style={{ width: "14rem" }}
                onChange={handleChange}
              />
            </div>
            {error.otp && (
              <label className={Styles.errorMessage}>
                {"**" + error.otp + "**"}
              </label>
            )}
          </Stack>
          <Stack
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <label className={Styles.NumberTitle}>Enter new password</label>
              <div className={Styles.TextContainer}>
                <input
                  placeholder="New Password"
                  name="new_password"
                  type="text"
                  className={Styles.TextField}
                  style={{ width: "14rem" }}
                  onChange={handleChange}
                />
              </div>
              {error.new_password && (
                <label className={Styles.errorMessage}>
                  {"**" + error.new_password + "**"}
                </label>
              )}
            </Stack>
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <label className={Styles.NumberTitle}>
                Enter confirm password
              </label>

              <div className={Styles.TextContainer}>
                <input
                  placeholder="Confirm Password"
                  name="confirm_password"
                  type="text"
                  className={Styles.TextField}
                  style={{ width: "14rem" }}
                  onChange={handleChange}
                />
              </div>
              {error.confirm_password && (
                <label className={Styles.errorMessage}>
                  {"**" + error.confirm_password + "**"}
                </label>
              )}
            </Stack>

            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div style={{ marginTop: "0.6rem" }}>
                <RedButton variant="contained" onClick={handleSubmit}>
                  Submit
                </RedButton>
              </div>
              <Stack
                sx={{
                  color: "#F2843F",
                  textDecoration: "underline",
                  fontSize: "15px",
                  cursor: "pointer",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                }}
                variant="body2"
                onClick={handleBackToLogin}
              >
                Back to login
              </Stack>
            </Stack>
          </Stack>
        </div>
      )}
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ResetPassword;
