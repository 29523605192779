import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getSalesmanList } from "../slice/salesmanSlice";
import { getASMList } from "../slice/asmSlice";
import { getShapeFileList } from "../slice/appSlice";

const useShapeFileList = () => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllShapeFileList = async (dataparam) => {
    if (currentzone) {
      const param = {
        zone_id: currentzone,
        type: dataparam?.type ?? null
      };
      const response = await Axios.post("shape_file_list", param);
      const data = await response.data;
      dispatch(getShapeFileList(data.shapeFileList));
    }
  };
  // useEffect(() => {
  //   getAllShapeFileList();
  // }, [currentzone]);
  return getAllShapeFileList;
};

export default useShapeFileList;
