import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  Polyline,
  InfoWindow,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import polyMaker from "../Dashboard/assets/markersImg/dotBlack.png";
import pendingMarker from "./assests/marker_icons/pending_marker.png";
import cancelledIcon from "./assests/legends_icons/cancelled_icon.png";
import completedIcon from "./assests/legends_icons/completed_icon.png";
import ontheWayIcon from "./assests/legends_icons/on_the_way_icon.png";
import pendingIcon from "./assests/legends_icons/pending_icon.png";
import InfoWindowMarker from "./assests/info_window_marker.png";
import useMapData from "../../hooks/useMapData";
import { Stack } from "@mui/material";
const DeliveryMgtMainMapView = () => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState();
  const [zoomLevel, setZoomLevel] = useState(12);

  const zoneData = useSelector((state) => state.app.zoneList);
  const zoneId = useSelector((state) => state.SFstatus.zone_id);
  const markerData = useSelector((state) => state.adminMap.deliveryMapData);
  const adminMap = useSelector((state) => state.adminMap.adminMapDetails);
  const boundryData = useSelector((state) => state.adminMap.getBoundryDetails);
  
  useEffect(() => {
    const selectedZone = zoneData?.find((zone) => zone.zone_id === zoneId);
    if (selectedZone) {
      const [lat, lng] = selectedZone.centroid.split(",").map(Number);
      setCenter({ lat, lng });
    } else {
      // Handle the case where no matching zone is found, if needed
      setCenter(null);
    }
  }, [zoneId, zoneData]);

  const getMapData = useMapData();
  useEffect(() => {
    const fetchData = async () => {
      if (zoneId) {
        const param = {
          zone_id: zoneId,
        };
        try {
          const adminMapData = await getMapData(param);
        } catch (error) {
          console.log("Error fetching admin map data:", error);
        }

      } else {
        console.log("No matching zone found for zone_name:");
      }
    };

    fetchData();
  }, [zoneId]);

  const polygonData = adminMap?.pincodes?.map((location) =>
    location.coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );

  const handleZoomChanged = (map) => {
    if (map) {
      const currentZoom = map.getZoom();
      setZoomLevel(currentZoom);
    }
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const getMarkerIcon = (saleStatus) => {
    switch (saleStatus) {
      case "pending":
        return pendingIcon;
      case "on_the_way":
        return ontheWayIcon;
      case "completed":
        return completedIcon;
      case "cancelled":
        return cancelledIcon;
      default:
        return pendingMarker;
    }
  };

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          center={center}
          zoom={zoomLevel}
          onZoomChanged={() => handleZoomChanged(map)}
          onLoad={handleMapLoad}
          mapContainerStyle={{
            width: "100%",
            height: "calc(100vh - 62px)",
            borderRadius: "16px",
            border: "1px solid black",
          }}
          options={{
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          <Stack
            sx={{
              position: "absolute",
              backgroundColor: "#FFF",
              top: "10px",
              left: "200px",
              flexDirection: "row",
              padding: "8px 8px",
              gap: "8px",
              borderRadius: "10px",
            }}
          >
            <Stack direction="row" alignItems="center" gap="4px">
              <img
                style={{ width: "10px", height: "10px" }}
                src={pendingIcon}
                alt="Pending"
              ></img>
              Pending
            </Stack>
            <Stack direction="row" alignItems="center" gap="4px">
              <img
                style={{ width: "10px", height: "10px" }}
                src={ontheWayIcon}
                alt="On the Way"
              ></img>
              On the Way
            </Stack>
            <Stack direction="row" alignItems="center" gap="4px">
              <img
                style={{ width: "10px", height: "10px" }}
                src={completedIcon}
                alt="Completed"
              ></img>
              Completed
            </Stack>
            <Stack direction="row" alignItems="center" gap="4px">
              <img
                style={{ width: "10px", height: "10px" }}
                src={cancelledIcon}
                alt="Cancelled"
              ></img>
              Cancelled
            </Stack>
          </Stack>

          {polygonData?.map((coordinates, index) => (
            <Polygon
              key={index}
              paths={coordinates}
              options={{
                fillColor: "#ccdbcf",
                fillOpacity: 0.4,
                strokeColor: "#3483eb",
                strokeOpacity: 1,
                clickable: true,
              }}
            />
          ))}
          {zoomLevel > 12 ? (
            <>
              {boundryData?.sf_details?.map((data, index) => (
                <Marker
                  icon={{
                    url: InfoWindowMarker,
                  }}
                  key={index}
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  // onClick={() => setSelectedMarker(data)}
                />
              ))}

              {boundryData?.sf_details?.map((data, index) => (
                <InfoWindow
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  // onCloseClick={() => setSelectedMarker(null)}
                  options={{
                    disableAutoPan: true,
                  }}
                >
                  <div className="info-container">
                    <div className="retailer-detail">
                      <div className="lp-name" key={index}>
                        {data?.retailers_count} RC | {data?.pending_order_count}{" "}
                        PO
                      </div>
                      {/* <div className="lp-name" key={index}></div> */}
                    </div>

                    {data.db_list.some((db) => db !== null) && (
                      <div className="db-name-wrapper">
                        {data.db_list.map(
                          (db, index) =>
                            db !== null && (
                              <div className="db-name-delivery-mgt" key={index}>
                                {db?.full_name}
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </InfoWindow>
              ))}
            </>
          ) : null}
          {markerData?.map((data, index) => (
            <Marker
              icon={{
                url: getMarkerIcon(data.sale_status),
              }}
              key={index}
              position={{
                lat: parseFloat(data.lat),
                lng: parseFloat(data.lng),
              }}
            />
          ))}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default DeliveryMgtMainMapView;
