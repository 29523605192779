
import { getCoinTable } from "../slice/coinSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const useCopySkuInventory = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const copySkuInventory = async (param) => {

    try {
      const response = await Axios.post("copy_inventory", param);
      const data = response.data;      
      if (data.code === 200) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    //   return data;  
    } catch (error) {
      console.error("Error fetching Coin Details", error);
      throw error;  
    }
  };
  return copySkuInventory;
};

export default useCopySkuInventory;

