import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { FormControl, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { BaseImgUrl } from "../../../utility/BaseURL";
import { ToastContainer } from "react-toastify";
import useGiftCategoryImageUpload from "../../../hooks/useGiftCategoryImageUpload";
import useAddGiftCategory from "../../../hooks/useAddGiftCategory";
import useGiftCategoryList from "../../../hooks/useGiftCategoryList";
import { resetGiftCategoryFormData } from "../../../slice/categorySlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddGiftCategory() {
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    dispatch(resetGiftCategoryFormData());
  };
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);
  //Api calls
  const getGiftCategoryImageUpload = useGiftCategoryImageUpload();
  const addGiftCategory = useAddGiftCategory(form);
  const getAllGiftCategoryList = useGiftCategoryList();
  const currentImageSelected = useSelector((state) => state.category.giftCategoryFormData);
  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  
  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);

  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.name?.trim() === "" ||
      form.name?.trim() === undefined ||
      form.name?.trim() === null
    ) {
      err.name = "**Category Name Cannot be Empty**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    // } else if (
    //   imageDimensions.width !== 200 ||
    //   imageDimensions.height !== 200
    // ) {
    //   err.selectedImage = "**Image should be 200x200 pixels**";
    //   success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }
    setError(err);
    return success;
  };

  const handleImageChange = async(e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));

    const formData = new FormData();
    formData.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };
    setEnable(true);
    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }
    await getGiftCategoryImageUpload(formData);
    setError({});
    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid()) {
      await addGiftCategory();
      await getAllGiftCategoryList();
      setOpen(false);
      setForm({});
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            height: "0.6rem",
            color: "white",
            background: "#DC1D13",
            padding: "1rem",
            "&:hover": {
              background: "#DC1D13",
            },
            marginTop:"6px"
          }}
        >
          <AddIcon sx={{ fontSize: "14px", fontWeight: "700" }} />
          Add Category
        </Button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <input
                  variant="outlined"
                  name="name"
                  placeholder="Enter category name"
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label htmlFor="category_image" style={{ cursor: "pointer" }}>
                  <input
                    id="category_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="category_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />

                  <div
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    {form.selectedImage ? form.selectedImage : "Choose Image"}
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#DC1D13",
                    border: "2px solid #DC1D13",
                    height: "1.8rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#DC1D13",
                    color: "white",
                    height: "1.8rem",
                    "&:hover": {
                      background: "#DC1D13",
                    },
                  }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
