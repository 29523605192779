import React, { useEffect } from "react";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import Swal from "sweetalert2";
import { getCategoryFormData } from "../slice/categorySlice";
import { useDispatch } from "react-redux";
import { getSkuFormData } from "../slice/skuSlice";

const useSkuImageUpload = () => {
  const ImageAxiosPrivate = useImageAxiosPrivate();
  const dispatch = useDispatch();
  const getSkuImageUpload = async (formData) => {
    console.log(formData);
    const response = await ImageAxiosPrivate.post(
      "/sku_image_upload",
      formData
    );
    const data = await response.data;
    dispatch(getSkuFormData(data));
    console.log(data, "image uploaded successfully");
    // if (data.code === 200) {
    //   Swal.fire({
    //     title: "Success",
    //     text: "Image Uploaded Successfully",
    //     icon: "success",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // } else {
    //   Swal.fire({
    //     title: "Failure",
    //     text: "Image Upload Failed",
    //     icon: "failure",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

  //   useEffect(() => {
  //     getCategoryImageUpload();
  //   }, []);
  return getSkuImageUpload;
};

export default useSkuImageUpload;
