import { Stack, Typography } from "@mui/material";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Stack
      sx={{
        backgroundColor: "#313131",
        color: "white",
        height: "50vh",
      }}
    >
      <Stack
        width={{ xs: "90%", lg: "80%" }}
        direction={{ xs: "column", lg: "row" }}
        justifyContent={"space-between"}
        m={"auto"}
        gap={5}
      >
        <Stack>
          <Stack mb={"1rem"}>
            <Typography fontWeight={"bold"}>Contact Us :)</Typography>
          </Stack>
          <Stack>
            <Typography>Ph.no. : +91-9611911992</Typography>
          </Stack>
          <Stack>
            <Typography>Email ID : ashish.s@vitarangt.com</Typography>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Typography fontWeight={"bold"} mb={"1rem"}>
              Quick Links
            </Typography>
          </Stack>
          <Stack>
            <RouterLink
              to={"/vitaranpartner"}
              style={{ color: "white" }}
              onClick={handleClick}
            >
              <Typography>Become a Partner</Typography>
            </RouterLink>
          </Stack>
          <Stack>
            <RouterLink
              to={"/contact"}
              style={{ color: "white" }}
              onClick={handleClick}
            >
              <Typography>Contact Us</Typography>
            </RouterLink>
          </Stack>
          <Stack>
            <RouterLink
              to={"/termsandconditions"}
              style={{ color: "white" }}
              onClick={handleClick}
            >
              <Typography>Terms and Conditions</Typography>
            </RouterLink>
          </Stack>
          <Stack>
            <RouterLink
              to={"/privacypolicy"}
              style={{ color: "white" }}
              onClick={handleClick}
            >
              <Typography>Privacy Policy</Typography>
            </RouterLink>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Typography fontWeight={"bold"} mb={"1rem"}>
              Social
            </Typography>
          </Stack>
          <Stack m={"auto"} direction={"row"} gap={2}>
            <Stack>
              <LinkedInIcon />
            </Stack>
            <Stack>
              <TwitterIcon />
            </Stack>
            <Stack>
              <InstagramIcon />
            </Stack>
            <Stack>
              <FacebookIcon />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;