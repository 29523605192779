import React, { useState } from 'react';
import styles from './styles/UserMgtAddForm.module.css';
import CreateIcon from '@mui/icons-material/Create';
import { Paper, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DirectionsWalkOutlinedIcon from '@mui/icons-material/DirectionsWalkOutlined';
import { useDispatch,useSelector } from 'react-redux';
// import { UserMgtRegistration } from '../../../actions/UserMgt/UserMgt';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';


// const theme = createTheme({
//     overrides:{
//         MuiFormControl: {
//             root: {
//               height: '56px',
//             },
//           },
//           MuiInputBase: {
//             root: {
//               height: '36px',
//             },
//           },
//     }
// });

// const useStyles = makeStyles((theme) => ({
//     TextField:{

//     }
//   }));

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));

const GrayButton = styled(Button)(({ theme }) => ({
    color: "#404040",
    backgroundColor: "#E5E5E5",
    '&:hover': {
        backgroundColor: "#E5E5E5",
    },
}));


const UserMgtAddForm = () => {
    const Firsttoken = useSelector(state => state.Token)
    const [form, setForm] = useState({
        name: "",
        surname: "",
        mobile_no: "",
        whatsapp_no: "",
        address: "",
        lati: "",
        longi: "",
        profile_image: "",
        bank_name: "",
        account_no: "",
        ifsc_code: "",
        branch_name: "",
        zone_name: "",
        role_name: "",
        status:"",

    });
    const initialState ={
        name: "",
        surname: "",
        mobile_no: "",
        whatsapp_no: "",
        address: "",
        lati: "",
        longi: "",
        profile_image: "",
        bank_name: "",
        account_no: "",
        ifsc_code: "",
        branch_name: "",
        zone_name: "",
        role_name: "",
        status:"",

    }
    const [formError, setFormError] = useState({});
    const [ZoneList, setZoneList] = useState(["Mumbai", "Bangalore"]);
    const [Zone, setZone] = React.useState('');
    const[token,setToken]=useState('');
    const [RoleList, setRoleList] = useState(["asm", "se", "tsi"]);
    const [Role, setRole] = React.useState('');
    const dispatch = useDispatch()
    const [StatusList, setStatusList] = useState(["active", "pending", "inactive"]);
    const [Status, setStatus] = React.useState('');
    const [addsuccess,setAddsuccess] = useState(false);
    const [obj,setObj] = useState({
        failed:false,
        message:''
    })
    // const IsValid = () => {
    //     let success = true;
    //     let error = {};

    //     if (!form.zone_name.trim()) {
    //         error.zone = "Zone cannot be empty"
    //         success = false;
    //     }
     
    //     if (!form.role_name.trim()) {
    //         error.role = "role cannot be empty";
    //         success = false;
    //     }
    //     if (!form.name.trim()) {
    //         error.name = "name cannot be empty";
    //         success = false;
    //     }
    //     if (!form.surname.trim()) {
    //         error.surname = "surname cannot be empty";
    //         success = false;
    //     }
    //     if (!form.mobile_no.trim()) {
    //         error.mobileno = "mobileno cannot be empty";
    //         success = false;
    //     }
    //     else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.mobile_no)) {
    //         error.mobileno = "Enter a Valid mobileno Number";
    //         success = false
    //     }
    //     if (!form.whatsapp_no.trim()) {
    //         error.whatsappno = "whatsappno cannot be empty";
    //         success = false;
    //     }
    //     else if (!(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g).test(form.whatsapp_no)) {
    //         error.whatsappno = "Enter a Valid whatsapp Number";
    //         success = false
    //     }

    //     if (!form.address.trim()) {
    //         error.address = "address cannot be empty";
    //         success = false;
    //     }
     
     


    //     setFormError(error)
    //     return success
    // }

    

    const handleZoneChange = (e) => {

        setZone(e.target.textContent);
        setForm({
            ...form,
            zone_name: e.target.textContent
        })
    }

    const handleRoleChange = (e) => {

        setRole(e.target.textContent)
        setForm({
            ...form,
            role_name: e.target.textContent
        })
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.textContent);
        setForm({
            ...form,
            status: e.target.textContent
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    React.useEffect(() => {
        if(Firsttoken && Firsttoken.token && Firsttoken.token!==null && Firsttoken.token.length!==0 && Firsttoken!=="undefined"){
          setToken(Firsttoken.token)  
        }
        
    }, [Firsttoken])
   



   let param ={
    "name": `${form.name}`,
    "surname": `${form.surname}`,
    "mobile_no": `${form.mobile_no}`,
    "whatsapp_no": `${form.whatsapp_no}`,
    "address": `${form.address}`,
    "lati": `${form.lati}`,
    "longi": `${form.longi}`,
    "profile_image": `${form.profile_image}`,
    "bank_name": `${form.bank_name}`,
    "account_no": `${form.account_no}`,
    "ifsc_code": `${form.ifsc_code}`,
    "branch_name": `${form.branch_name}`,
    "zone_name": `${form.zone_name}`,
    "role_name": `${form.role_name}`,
    status:`${form.status}`
   }

const handleAdd = () => {
        // if (IsValid()) {
        //     // dispatch(UserMgtRegistration(token,param,initialState,setForm,setAddsuccess,setZone,setRole,setStatus,obj,setObj))
        // }
    }
   
  
    const handleClear=()=>{
        setForm(initialState);
        setZone('')
        setRole('');
        setStatus('')
    }

    React.useEffect(()=>{
        if(addsuccess){
            toast.success('Added SuccessFully');
            setAddsuccess(false)
        }
    },[addsuccess])

     React.useEffect(()=>{
         if(obj.failed){
             toast.error(obj.message);
             setObj({
                 ...obj,
                 failed:false,
                 message:""
             })
         }
     },[obj.failed])

 

    return (
        <>
        <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div className={styles.UserMgtAddFormContainer}>
            <div className={styles.UserMgtAddFormContainerOne}>

                <div className={styles.UserMgtAddFormContainerSectionOne}>
                    <div className={styles.UserMgtAddFormSectionOneTitleContainer}>
                        <label className={styles.UserMgtAddFormSectionOneTitle}>User Mangement</label>
                    </div>

                    {/* <div className={styles.UserMgtAddFormSectionOneEditContainer}>
                        <CreateIcon className={styles.UserMgtAddFormSectionOneEditIcon} />
                    </div> */}
                </div>

                <div className={styles.UserMgtAddFormContainerSectionTwo}>
                    <div className={styles.UserMgtAddFormTwoSubSectionOne}>

                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Zone</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <Box className={styles.UserMgtAddFormTwoSubSectionOneListField}>
                                    <FormControl fullWidth size="small">

                                        <Select value={Zone} onClick={handleZoneChange} sx={{height:"1.8rem"}}>

                                            {ZoneList.map((item, index) => (
                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>
                                {formError.zone && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.zone}</span>}
                            </div>
                        </div>



                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Role</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <Box className={styles.UserMgtUpdateFormTwoSubSectionOneListField}>
                                    <FormControl fullWidth >

                                        <Select value={Role} onClick={handleRoleChange} sx={{height:"1.8rem"}}>

                                            {RoleList.map((item, index) => (
                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>
                                {formError.role && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.role}</span>}
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Name</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <TextField variant="outlined" size="small" value={form.name} name="name" onChange={handleChange} className={styles.UserMgtAddFormTwoSubSectionOneListField} />
                                {formError.name && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.name}</span>}
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Surname</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <TextField variant="outlined" size="small" value={form.surname} name="surname" onChange={handleChange} className={styles.UserMgtAddFormTwoSubSectionOneListField} />
                                {formError.surname && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.surname}</span>}
                            </div>
                        </div>





                    </div>
                    <div className={styles.UserMgtAddFormTwoSubSectionTwo}>

                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Address</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <TextField variant="outlined" size="small" value={form.address} name="address" onChange={handleChange} className={styles.UserMgtAddFormTwoSubSectionOneListField} />
                                {formError.address && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.address}</span>}
                            </div>
                        </div>



                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>status</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <Box className={styles.UserMgtUpdateFormTwoSubSectionOneListField}>
                                    <FormControl fullWidth size="small">

                                        <Select value={Status} onClick={handleStatusChange}>

                                            {StatusList.map((item, index) => (
                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Box>
                            </div>


                        </div>
                        <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Mobile No</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <TextField variant="outlined" size="small" name="mobile_no" onChange={handleChange} value={form.mobile_no} className={styles.UserMgtAddFormTwoSubSectionOneListField} />
                                {formError.mobileno && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.mobileno}</span>}
                            </div>
                        </div>

                        {/* <div className={styles.UserMgtAddFormTwoSubSectionOneList}>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListOne}>
                                <label className={styles.UserMgtAddFormTwoSubSectionOneListLabel}>Whatsapp ID</label>
                            </div>
                            <div className={styles.UserMgtAddFormTwoSubSectionOneListTwo}>
                                <TextField variant="outlined" size="small" name="whatsapp_no" onChange={handleChange} value={form.whatsappno} className={styles.UserMgtAddFormTwoSubSectionOneListField} />
                                {formError.whatsappno && <span className={styles.UserMgtAddFormTwoSubSectionOneListTwoErrorLabel}>{formError.whatsappno}</span>}
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={styles.UserMgtAddFormContainerSectionThree}>
                    <RedButton variant="contained" onClick={handleAdd}>Add</RedButton>
                    <GrayButton variant="contained" onClick={handleClear}>Clear</GrayButton>
                </div>
            </div>

            <div className={styles.UserMgtAddFormContainerTwo}>

                {/* <div className={styles.UserMgtAddFormContainerTwoFirmContainer}>
                        <img src={"https://i.guim.co.uk/img/media/24b25c23b97e37af3892230202b98b8ac20a10e0/0_31_5550_3330/master/5550.jpg?width=445&quality=45&auto=format&fit=max&dpr=2&s=389ec7167a0686c3ed084d7487c5be2c"} alt="firm" />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center", marginTop: "-60px" }}>
                        <div className={styles.UserMgtAddFormContainerTwoProfileContainer}>
                            <img className={styles.UserMgtAddFormContainerTwoProfile} src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEpWWQrAJpIR6Xy7FhzhCT00vzSmT7xw9S2Q&usqp=CAU"} alt="prof" />
                        </div>
                    </div>

                    <div className={styles.UserMgtAddFormContainerTwoListContainer}>
                        <div className={styles.UserMgtAddFormContainerTwoList}>
                            <div className={styles.UserMgtAddFormContainerTwoListOne}>
                                <span className={styles.UserMgtAddFormContainerTwoListOneIconContainer}><PersonOutlineIcon className={styles.UserMgtAddFormContainerTwoListOneIcon} /></span>
                                <label>Name</label>
                            </div>
                            <div className={styles.UserMgtAddFormContainerTwoListTwo}>
                                vijay r
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormContainerTwoList}>
                            <div className={styles.UserMgtAddFormContainerTwoListOne}>
                                <span className={styles.UserMgtAddFormContainerTwoListOneIconContainer}><CallOutlinedIcon className={styles.UserMgtAddFormContainerTwoListOneIcon} /></span>
                                <label>Mobile</label>
                            </div>
                            <div className={styles.UserMgtAddFormContainerTwoListTwo}>
                                8217847430
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormContainerTwoList}>
                            <div className={styles.UserMgtAddFormContainerTwoListOne}>

                                <span className={styles.UserMgtAddFormContainerTwoListOneIconContainer}><LandscapeOutlinedIcon className={styles.UserMgtAddFormContainerTwoListOneIcon} /></span>
                                <label>zone</label>
                            </div>
                            <div className={styles.UserMgtAddFormContainerTwoListTwo}>
                                Ahmedabad
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormContainerTwoList}>
                            <div className={styles.UserMgtAddFormContainerTwoListOne}>
                                <span className={styles.UserMgtAddFormContainerTwoListOneIconContainer}><RoomOutlinedIcon className={styles.UserMgtAddFormContainerTwoListOneIcon} /></span>
                                <label>Address</label>
                            </div>
                            <div className={styles.UserMgtAddFormContainerTwoListTwo}>
                                106, 1st A Main Rd, East of NGEF Layout, Kasturi Nagar, Bengaluru, Karnataka 560043
                            </div>
                        </div>

                        <div className={styles.UserMgtAddFormContainerTwoList}>
                            <div className={styles.UserMgtAddFormContainerTwoListOne}>

                                <span className={styles.UserMgtAddFormContainerTwoListOneIconContainer}><DirectionsWalkOutlinedIcon className={styles.UserMgtAddFormContainerTwoListOneIcon} /></span>
                                <label>Role</label>
                            </div>
                            <div className={styles.UserMgtAddFormContainerTwoListTwo}>
                                Retailer
                            </div>
                        </div>
                    </div> */}
            </div>

        </div>
        </>
    )
}

export default UserMgtAddForm
