import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateGiftCategory = (form) => {
  const currentImageSelected = useSelector((state) => state.category.giftCategoryFormData);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateGiftCategoryDetails = async () => {
   console.log(form, "1111");
    let param = {
      category_name:form?.name??form?.categoryRowData?.category_name,
      status:form?.status==="Inactive"?false:true??form?.categoryRowData?.status,
      category_id:form?.categoryRowData.category_id,
      category_image: currentImageSelected?.full_path ?? form?.categoryRowData?.category_image
    };
    console.log(param, "0000");
    const response = await Axios.post("/update_gift_category", param);
    const data = await response.data;
    
 
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

   
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateGiftCategoryDetails;
};

export default useUpdateGiftCategory;
