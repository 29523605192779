import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import categoryData from "./data.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import useCategoryList from "../../hooks/useCategoryList";
import useDeleteCategory from "../../hooks/useDeleteCategory";
import ConfirmationDialog from "../../utility/ConfirmationDialog";
import EditCategory from "./EditCategory";

export default function CategoryManagementTable() {
  const categoryList = useSelector((store) => store.category.categoryList);
  // useCategoryList();

  const [page, setPage] = React.useState(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [categoryId, setCategoryId] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const deleteCategory = useDeleteCategory(categoryId);
  const getAllCategoryList = useCategoryList();
  const handleChangePage = (event, newPage) => {
    // console.log('Page changed to:', newPage);
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setCategoryId(data?.category_id);


  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = async() => {
    await deleteCategory();
    await getAllCategoryList();
    setIsConfirmDialogOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [categoryEditId, setCategoryEditId] = React.useState(null);
  const [categoryRowData,setCategoryRowData]=React.useState(null)
  const handleClick = (data) => {
    setOpen(true);
    setCategoryEditId(data?.category_id);
    setCategoryRowData(data)
  };
  React.useEffect(() => {
    getAllCategoryList();
  }, [isConfirmDialogOpen]);
  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                SI no.
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                Category Image
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                Category name
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Total SKU
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                const serialNumber = page * rowsPerPage + index + 1;
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start" colSpan={1}>
                      {serialNumber}
                      </TableCell>
                      <TableCell align="start" colSpan={3}>
                        <img style={{width:"3rem"}} src={data?.category_image} />
                      </TableCell>
                      <TableCell align="start" colSpan={3}>
                        {data.category_name}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.total_sku ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.status === true ? (
                          <Typography
                            sx={{
                              background: "#6EB443",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              background: "#58585A",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="middle" colSpan={1}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "0.5rem",
                            gap: "0.5rem",
                          }}
                        >
                          <EditIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleClick(data)}
                          />
                          <DeleteIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(data)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
        <EditCategory
          setOpen={setOpen}
          open={open}
          categoryEditId={categoryEditId}
          categoryRowData={categoryRowData}
        />
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmApproveClick}
          category_id={categoryId}
          type={1}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={categoryList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
