import React from "react";
import { Stack, Typography, Card } from "@mui/material";

const MidContainer = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        background: "#FFF",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      <Stack>
        <Typography>HighLights</Typography>
        <Typography sx={{
            color: "#2A2A2A",
            fonFamily: "Roboto",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "21px",
          }}>
          Lorem ipsum is placeholder text commonly used in the graphic, print,
          and publishing industries for previewing layouts and visual
        </Typography>
      </Stack>

      <Stack>
        <Typography>HighLights</Typography>
        <Typography sx={{ background: "#FFEFEF", padding: "0.5rem" }}>
          Lorem ipsum is placeholder text commonly used in the graphic, print,
          and publishing industries for previewing layouts and visual
        </Typography>
      </Stack>
    </Card>
  );
};

export default MidContainer;
