import { createSlice } from "@reduxjs/toolkit";

const asmSlice = createSlice({
  name: "asm",
  initialState: {
    asmList: null,
    asmDetails: null,
    salesmanUpdatedDetails: null,
    stateList: null,
    zoneList: null,
    userStatusList: null,
  },
  reducers: {
    getASMList: (state, action) => {
      state.asmList = action.payload;
    },
    getStateList: (state, action) => {
      state.stateList = action.payload;
    },
    getZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    getUserStatusList: (state, action) => {
      state.userStatusList = action.payload;
    },
    getASMDetails: (state, action) => {
      state.asmDetails = action.payload;
    },
    updateSalesmanDetails: (state, action) => {
      state.salesmanUpdatedDetails = action.payload;
    },
  },
});

export default asmSlice.reducer;
export const {
  getASMList,
  getASMDetails,
  getStateList,
  getUserStatusList,
  getZoneList,
} = asmSlice.actions;
