import { createSlice } from "@reduxjs/toolkit";

const distributorSlice = createSlice({
  name: "distributor",
  initialState: {
    distributorList: null,
    distributorDetails: null,
    stateList: null,
    zoneList: null,
    areaNameList: null,
    userStatusList: null,
  },
  reducers: {
    getDistributorList: (state, action) => {
      state.distributorList = action.payload;
    },
    getStateList: (state, action) => {
      state.stateList = action.payload;
    },
    getZoneList: (state, action) => {
      state.zoneList = action.payload;
    },
    getAreaNameList: (state, action) => {
      state.areaNameList = action.payload;
    },
    getUserStatusList: (state, action) => {
      state.userStatusList = action.payload;
    },
    getDistributorDetails: (state, action) => {
      state.distributorDetails = action.payload;
    },
  },
});

export default distributorSlice.reducer;
export const {
  getDistributorList,
  getDistributorDetails,
  getStateList,
  getAreaNameList,
  getZoneList,
  getUserStatusList,
} = distributorSlice.actions;
