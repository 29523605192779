
import React from "react";
import Sidebar from "../Sidebar";
import { Stack } from "@mui/material";
import AddApkForm from "./AddApkForm";
import { ToastContainer, toast } from "react-toastify";
const index = () => {
  return (
    <Stack>
      <Stack>
        {/* <DashboardHeader /> */}
      </Stack>
      <Stack direction="row">
        <Sidebar />
        <AddApkForm />
      </Stack>
      <ToastContainer position="bottom"/>
    </Stack>
  );
};

export default index;