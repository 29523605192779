import { createSlice } from "@reduxjs/toolkit";

const routeSlice = createSlice({
  name: "router",
  initialState: {
    routeStateRetailerList: null,
    routeZoneRetailerList: null,
    routeRetailerDetails: null,
    map:""
  },
  reducers: {
    getStateRouteRetailerList: (state, action) => {
      state.routeStateRetailerList = action.payload;
      state.map="cluster"
    },
    getZoneRouteRetailerList: (state, action) => {
      state.routeZoneRetailerList = action.payload;
    },
    getRouteRetailerDetails: (state, action) => {
      state.routeRetailerDetails = action.payload;
    },
  },
});

export default routeSlice.reducer;
export const {
  getStateRouteRetailerList,
  getZoneRouteRetailerList,
  getRouteRetailerDetails,
} = routeSlice.actions;
