// useSendOtp.js
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getResetData } from "../slice/loginSlice";

const useSendOtp = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const sendOtpEmail = async (email) => {
    let param = {
      email: email
    };
    console.log(param, "0000");
    const response = await Axios.post("send_otp_email", param);
    const data = await response.data;
    dispatch(getResetData(data));
    if (data.code === 200) {
      toast.success("OTP sent to your Email successfully");
    } else {
      toast.error(data.message);
    }
    return data; // Return the API response data
  };

  return sendOtpEmail;
};

export default useSendOtp;
