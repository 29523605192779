import { createSlice } from "@reduxjs/toolkit";

const saleSlice = createSlice({
  name: "sale",
  initialState: {
    saleDetails: null,
    saleDetailsOAO: null,
  },
  reducers: {
    getSaleDetails: (state, action) => {
      state.saleDetails = action.payload;
    },
    getSaleDetailsOAO: (state, action) => {
      state.saleDetailsOAO = action.payload;
    },
  },
});

export default saleSlice.reducer;
export const { getSaleDetails, getSaleDetailsOAO } = saleSlice.actions;
