import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const useAddZone = () => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const addZone = async (form) => {
    
    const response = await Axios.post("add_zone", form);
    const data = await response.data;
    
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    }else if(data.code===400){
      toast.error(data?.message)
    }
     else {
      toast.error("Failed to Add");
    }
    
  };
  return addZone;
};

export default useAddZone;
