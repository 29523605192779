
import { useSelector } from "react-redux";
import useAxiosPrivate from "./useAxiosPrivate";


const useUpdateSFDetails = () => {
  const Axios = useAxiosPrivate();

  
  
  const setUpdateSFDetails = async (param) => {
    try {
      const response = await Axios.post("update_shape_file_status", param);
      const data = response.data; 

      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return setUpdateSFDetails;
};

export default  useUpdateSFDetails;