import React from 'react';
import styles from './styles/usermgtbottomcontainer.module.css'
import UserMgtAddForm from './UserMgtAddForm';
import UserMgtUpdateForm from './UserMgtUpdateForm';

const UserMgtBottomContainer = ({tsiZone}) => {
    return (
      <>
        <div className={styles.UserMgtBottomContainer}>
          <UserMgtUpdateForm zone={tsiZone}/>
        </div>

     
        </>
    )
}

export default UserMgtBottomContainer
