import React from "react";
import TopContainer from "./TopContainer";
import MidContainer from "./MidContainer";
import GraphContainer from "./GraphContainer";
import TableContainer from "./TableContainerPage";
import { Stack, Box } from "@mui/material";
import Sidebar from "../Sidebar";
import Header from "./Header";

const MainDashboard = () => {
  return (
    // <Stack sx={{ height: "100vh" }}>
    //   <Header />
    //   <Stack sx={{ display: "flex", flexDirection: "row" }}>
    //     <Stack>
    //       <Sidebar />
    //     </Stack>
    //     <Stack
    //       sx={{
    //         display: "flex",
    //         flexDirection: "column",
    //         width: "100vw",
    //         padding: "2rem",
    //         gap: "1rem",
    //       }}
    //     >
    //       <TopContainer />
    //       <MidContainer />
    //       <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
    //         <GraphContainer sx={{ flex: 3 }} />
    //         <TableContainer sx={{ flex: 4 }} />
    //       </Stack>
    //     </Stack>
    //   </Stack>
    // </Stack>
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />
      <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
        <Sidebar sx={{ flex: "0 0 auto" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "2rem",
            gap: "1rem",
          }}
        >
          <TopContainer />
          <MidContainer />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexGrow: 1,
            }}
          >
            <GraphContainer sx={{ flex: 3 }} />
            <TableContainer sx={{ flex: 4 }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainDashboard;
