import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getRetailerList,
  getRetailerMap,
  getsalesmanPartners,
} from "../slice/adminMapSlice";

const useRetailerMapData = (selectedSM) => {
  const zoneData = useSelector((state) => state.SFstatus);
  const currentSM = useSelector((state) => state.adminMap.currentSM);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getRetailerMapData = async () => {
    const param = {
      zone_id: zoneData?.zone_id,
      sm_id: selectedSM,
    };
    const response = await Axios.post("get_selected_salesman_route", param);
    const data = await response.data;

    dispatch(getRetailerMap(data));
  };

  useEffect(() => {
    getRetailerMapData();
  }, [selectedSM]);
  return getRetailerMap;
};

export default useRetailerMapData;
