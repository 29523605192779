import React, { useState, useEffect } from "react";
import selorceLogo from "../Dashboard/assets/logo.png"
import { Stack, Box, Typography, Button } from "@mui/material";
import ApprovalIcon from "@mui/icons-material/Approval";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { AccountCircleOutlined } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import useStateList from "../../hooks/useStateList";
import useZoneList from "../../hooks/useZoneList";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentDropdownData } from "../../slice/appSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateList = useSelector((store) => store.app.stateList);

  const zoneList = useSelector((store) => store.app.zoneList);
  useStateList();
  useZoneList();

  const handleApprove = () => {
    navigate("/usermgt");
  };

  const [obj, setObj] = useState({});

  useEffect(() => {
    dispatch(getCurrentDropdownData(obj));
  }, [obj]);

  useEffect(() => {
    setObj((prev) => ({
      ...prev,
      state_id: stateList?.[0]?.state_id,
      state_name: stateList?.[0]?.state_name,
    }));
  }, [stateList]);

  useEffect(() => {
    setObj((prev) => ({
      ...prev,
      zone_id: zoneList?.[0]?.zone_id,
      zone_name: zoneList?.[0]?.zone_name,
    }));
  }, [zoneList]);

  const handleStateChange = (e) => {
    let stateId = 0;
    for (let i = 0; i < stateList?.length; i++) {
      if (e.target.value === stateList[i].state_name) {
        stateId = stateList[i].state_id;
      }
    }

    setObj((prev) => ({
      ...prev,
      state_id: stateId,
      state_name: e.target.value,
    }));
  };
  const handleZoneChange = (e) => {
    let zoneId = 0;
    for (let i = 0; i < zoneList?.length; i++) {
      if (e.target.value === zoneList[i].zone_name) {
        zoneId = zoneList[i].zone_id;
      }
    }

    setObj((prev) => ({
      ...prev,
      zone_id: zoneId,
      zone_name: e.target.value,
    }));
  };

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // maxWidth: "100vw",
        backgroundColor: "#DC1D13",
        padding: "6px",
        paddingLeft: "12px",
        paddingRight: "12px",
      }}
    >
      <Stack>
        <img src={selorceLogo} style={{ width: "35px" }} />
      </Stack>

      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Stack
          sx={{
            width: "8rem",
          }}
        ></Stack> */}
        {/* <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            color: "white",
          }}
        > */}
        {/* <Box
            sx={{
              paddingLeft: "1.5rem",
              paddingRight: "1.5rem",
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
              <select
                style={{
                  width: "8rem",
                  height: "1.8rem",
                  border: "1px solid #BBBABA",
                  padding: "5px",
                }}
                onChange={handleStateChange}
              >
                {stateList &&
                  stateList.map((state) => (
                    <option key={state.state_id} value={state.state_name}>
                      {state.state_name}
                    </option>
                  ))}
              </select>
              <select
                style={{
                  width: "8rem",
                  height: "1.8rem",
                  border: "1px solid #BBBABA",
                  padding: "5px",
                }}
                onChange={handleZoneChange}
              >
                {zoneList &&
                  zoneList.map((zone) => (
                    <option key={zone.zone_id} value={zone.zone_name}>
                      {zone.zone_name}
                    </option>
                  ))}
              </select>
            </Stack>
          </Box> */}
        <Stack sx={{ flexDirection: "row", alignSelf: "center", gap: "10px" }}>
          <AccountCircleOutlinedIcon />
          <Typography>Ramesh</Typography>
        </Stack>
        {/* </Stack> */}
      </Stack>
    </Stack>
  );
};

export default Header;
