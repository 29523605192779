import React, { useEffect } from "react";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import Swal from "sweetalert2";
import { getCategoryFormData, getGiftCategoryFormData } from "../slice/categorySlice";
import { useDispatch } from "react-redux";

const useGiftCategoryImageUpload = () => {
  const ImageAxiosPrivate = useImageAxiosPrivate();
  const dispatch = useDispatch();
  const getGiftCategoryImageUpload = async (formData) => {
    // console.log(formData);
    const response = await ImageAxiosPrivate.post(
      "/gift_category_image_upload",
      formData
    );
    const data = await response.data;
    console.log(data, "9999");
    dispatch(getGiftCategoryFormData(data));
    // console.log(data);
    // if (data.code === 200) {
    //   Swal.fire({
    //     title: "Success",
    //     text: "Image Uploaded Successfully",
    //     icon: "success",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // } else {
    //   Swal.fire({
    //     title: "Failure",
    //     text: "Image Upload Failed",
    //     icon: "failure",
    //     showConfirmButton: false,
    //     timer: 2000,
    //   });
    // }
  };

    // useEffect(() => {
    //   getCategoryImageUpload();
    // }, []);
  return getGiftCategoryImageUpload;
};

export default useGiftCategoryImageUpload;
