import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddGiftSku = (form) => {
  console.log(form);
  const Axios = useAxiosPrivate();
  const skuGiftFormData = useSelector((store) => store.sku.skuGiftFormData);
  const addGiftSku = async () => {
    let param = {
      sku_name: form?.gift_name,
      sku_image: skuGiftFormData?.full_path,
      category_id: form?.category_id,
      sp: form?.price,
      laabh_coins: form?.coins,
      quantity: form?.quantity,
      status: form?.status === "active" ? true : false,
    };
    const response = await Axios.post("/add_gift_sku", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else if (data.code == 400) {
      toast.error("Sku name already exist!");
    } else {
      toast.error("Failed to Add");
    }
  };
  return addGiftSku;
};

export default useAddGiftSku;
