
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getBoundryDetailsList } from "../slice/adminMapSlice";
const useBoundryData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  
  const getBoundryData = async (param) => {
    
    try {
      const response = await Axios.post("get_all_boundary_data", param);
      const data = response.data;
      console.log(data, "getBoundryData");
      dispatch(getBoundryDetailsList(data));
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getBoundryData;
};

export default  useBoundryData;