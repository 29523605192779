import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categoryList: null,
    categoryFormData: null,
    giftCategoryList: null,
    giftCategoryFormData: null,
  },
  reducers: {
    getCategoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    getGiftCategoryList: (state, action) => {
      state.giftCategoryList = action.payload;
    },
    getCategoryFormData: (state, action) => {
      state.categoryFormData = action.payload;
    },
    getGiftCategoryFormData: (state, action) => {
      state.giftCategoryFormData = action.payload;
    },
    resetCategoryDromData: (state, action) => {
      state.categoryFormData = null;
    },
    resetGiftCategoryFormData: (state, action) => {
      state.giftCategoryFormData = null;
    },
  },
});

export default categorySlice.reducer;
export const {
  getCategoryList,
  getCategoryFormData,
  resetCategoryDromData,
  getGiftCategoryList,
  getGiftCategoryFormData,
  resetGiftCategoryFormData
} = categorySlice.actions;
