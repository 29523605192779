import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddNewApk = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const addApk = async (form) => {
    let param = {
        apk_url: form?.apkUrl,
        version: form?.apkVersion,
        compulsory_update: form?.isCompulsoryUpdate,
        release_date: form?.releaseDate
    };

    const response = await Axios.post("add_app_version", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  return addApk;
};

export default useAddNewApk;
