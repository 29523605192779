import React from "react";
import Sidebar from "../Sidebar";
import UserManagement from "./index";
import { Stack } from "@mui/material";
import DashboardHeader from "../Dashboard/AdminHeader";
import ApprovalsMgt from "./index";
const ApprovalManagementPage = () => {
  return (
    <Stack>
      <Stack>
        {/* <DashboardHeader /> */}
      </Stack>
      <Stack direction="row">
        <Sidebar />
        <ApprovalsMgt/>
      </Stack>
    </Stack>
  );
};

export default ApprovalManagementPage;