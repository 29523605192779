import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getLaabhPartnersData } from "../slice/adminMapSlice";


const useLaabhPartner = () => {
  const zoneData = useSelector((state) => state.SFstatus);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
 //
  const getLaabhPartner = async (param) => {   
    const response = await Axios.post("get_partners", param);
    const data = await response.data;
    
    dispatch(getLaabhPartnersData(data));
   
  };
  return getLaabhPartner;                                         
};

export default useLaabhPartner;