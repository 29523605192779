import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getSalesmanList } from "../slice/salesmanSlice";
import {
  getASMList,
  getStateList,
  getUserStatusList,
  getZoneList,
} from "../slice/asmSlice";

const useASMList = (selectedZoneId, selectedStatus, selectedStateId) => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllASMList = async () => {
    if (currentzone) {
      const param = {
        zone_id: selectedZoneId === 0 ? "ALL" : selectedZoneId,
        state_id: selectedStateId === 0 ? "ALL" : selectedStateId,
        user_status: selectedStatus,
      };
      const response = await Axios.post("asm_list", param);
      const data = await response.data;
      dispatch(getASMList(data.body));
      dispatch(getStateList(data.stateNameList));
      dispatch(getZoneList(data.zoneNameList));
      dispatch(getUserStatusList(data.userStatusList));
    }
  };
  useEffect(() => {
    getAllASMList();
  }, [selectedZoneId, selectedStatus, selectedStateId]);
  return getAllASMList;
};

export default useASMList;
