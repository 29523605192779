import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ten from "../../assets/landing_page_images/ten.png";
const NavBar = () => {
  const [selectedLink, setSelectedLink] = useState("home");
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.pathname, "location");

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  useEffect(() => {
    if (location?.pathname === "/home") {
      setSelectedLink("home");
    }
    if (location?.pathname === "/contact") {
      setSelectedLink("contact");
    }
    if (location?.pathname === "/vitaranpartner") {
      setSelectedLink("partner");
    }
  }, [location?.pathname]);

  return (
    <Stack>
      <Stack
        direction={{ sm: "column-reverse", lg: "row" }}
        sx={{
          width: "90%",
          justifyContent: "space-between",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            backgroundColor: "#cc6f6a",
            justifyContent: "space-between",
            padding: "1rem 2rem 0.1rem 1rem",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            color: "white",
          }}
          gap={6}
        >
          <Stack
            sx={{ textDecoration: "none", color: "white" }}
            onClick={() => handleLinkClick("home")}
          >
            <RouterLink style={{ textDecoration: "none" }} to={"/home"}>
            <Stack sx={{ width: "50px", height:"50px" }}>
                  <img src={ten} alt="Vitaran Live" style={{width: "50px", height:"50px", objectFit:"contain"}} />
                </Stack>
            </RouterLink>
          </Stack>
          <Stack
            sx={{ textDecoration: "none", color: "white" }}
            onClick={() => handleLinkClick("home")}
          >
            <RouterLink style={{ textDecoration: "none" }} to={"/home"}>
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "white",
                  fontWeight: selectedLink === "home" ? "bold" : "normal",
                }}
              >
                Home
              </Typography>
            </RouterLink>
          </Stack>
          <Stack
            sx={{ textDecoration: "none", color: "white" }}
            onClick={() => handleLinkClick("partner")}
          >
            <RouterLink
              style={{ textDecoration: "none" }}
              to={"/vitaranpartner"}
            >
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "white",
                  fontWeight: selectedLink === "partner" ? "bold" : "normal",
                }}
              >
                Laabh Partner
              </Typography>
            </RouterLink>
          </Stack>
          <Stack
            sx={{ textDecoration: "none", color: "white" }}
            onClick={() => handleLinkClick("contact")}
          >
            <RouterLink style={{ textDecoration: "none" }} to={"/contact"}>
              <Typography
                sx={{
                  textDecoration: "none",
                  color: "white",
                  fontWeight: selectedLink === "contact" ? "bold" : "normal",
                }}
              >
                Contact Us
              </Typography>
            </RouterLink>
          </Stack>
        </Stack>
        <Stack
          sx={{
            backgroundColor:
              selectedLink === "partner" || selectedLink === "contact"
                ? "#424063"
                : "white",
            padding: "5px 2rem",
            borderRadius: "6px",
          }}
        >
          <RouterLink style={{ textDecoration: "none" }} to={"/login"}>
            <Button
              sx={{
                color:
                  selectedLink === "partner" || selectedLink === "contact"
                    ? "white"
                    : "black",
              }}
            >
              Login
            </Button>
          </RouterLink>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NavBar;
