import React, { useState } from "react";
import Styles from "./styles/productmgt.module.css";
import ProductMgtTable from "./ProductMgtTable";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
// import { ProductMgtList } from '../../../actions/ProductMgt/ProductMgt';
// import { GetToken, GetZoneList } from '../../../actions/login/login';
import UpdateProductMgt from "./UpdateProductMgt";
import { PRODUCTMGTERRORNULL } from "../constants/ProductMgt/product";
import SaathiError from "../Error/Error";
import SaathiLogoLoader from "../Loader/saathiLoader";

const ProductMgt = ({ zone, Statevalue }) => {
  const Firsttoken = useSelector((state) => state.Token);
  const [token, setToken] = useState("");
  // const state = useSelector(state => state.Zone.StateList);
  const loading = useSelector((state) => state.ProductMgt);

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //     dispatch({type:PRODUCTMGTERRORNULL})

  //     let id = 0;

  //     if(state && state.length!==0 && state!=="undefined"){
  //          for (let i=0;i<state.length;i++){
  //              if(Statevalue===state[i].state_name){
  //                  id = state[i].state_id
  //              }
  //          }
  //     }
  //     let param = {
  //         "admin_id": "1",
  //         "state_id":id
  //     }

  //     if (zone && zone.length !== 0 && zone !== "undefined" && zone !== null) {
  //         dispatch(ProductMgtList(token, param))
  //     }

  // }, [zone,token])

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  const handleZoneChange = (e) => {
    // setZone(e.target.value);
  };

  return (
    <>
      {/* <>{
            loading.PageError ? (
                <SaathiError />
            ) :
                (
                    <>{
                        loading.PageLoading ? (
                            <SaathiLogoLoader />
                        ) : */}

      <div className={Styles.Container}>
        <div className={Styles.FirstContainer}>
          <div className={Styles.TableContainer}>
            <ProductMgtTable />
          </div>
        </div>

        <div className={Styles.DetailContainer}>
          <div className={Styles.DetailSubContainer}>
            <UpdateProductMgt />
          </div>
        </div>
      </div>

      {/* }
                    </>
                )
        }
        </> */}
    </>
  );
};

export default ProductMgt;
