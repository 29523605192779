import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const useUpdateCoinRule = () => {
  const Axios = useAxiosPrivate();
  
  const updateCoinRule = async (param) => {
    const response = await Axios.post("update_coin_rule", param);
    const data = await response.data;
    
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }   
  };
  return updateCoinRule;
};

export default useUpdateCoinRule;
