import React, { useState } from "react";
import styles from "./styles/UserMgtUpdateForm.module.css";
import CreateIcon from "@mui/icons-material/Create";
import { Paper, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { withStyles, makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import DirectionsWalkOutlinedIcon from "@mui/icons-material/DirectionsWalkOutlined";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { UpdateUserMgt } from "../../../actions/UserMgt/UserMgt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseImgUrl } from "../../utility/BaseURL";
import { USERMGTID } from "../constants/UserMgt/UserMgt";
import { TableconvertDate } from "../Functions/function";
import useApprovalUpdateStatus from "../../hooks/useApprovalUpdateStatus";
import { EggTwoTone } from "@mui/icons-material";
import useRefreshUserList from "../../hooks/useRefreshUserList";
import useLaabhDELPartner from "../../hooks/useLaabhDeliveryPart";

// const theme = createTheme({
//     overrides:{
//         MuiFormControl: {
//             root: {
//               height: '56px',
//             },
//           },
//           MuiInputBase: {
//             root: {
//               height: '36px',
//             },
//           },
//     }
// });

const useStyles = makeStyles((theme) => ({
  TextField: {
    color: "black",
  },
}));

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const GrayButton = styled(Button)(({ theme }) => ({
  color: "#404040",
  backgroundColor: "#E5E5E5",
  "&:hover": {
    backgroundColor: "#E5E5E5",
  },
}));

const UserMgtAsmUpdate = ({ asmZone, detail, list, getAllUserList }) => {
  const Firsttoken = useSelector((state) => state.Token);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [token, setToken] = useState("");
  const [form, setForm] = useState({});
  const [formError, setFormError] = useState({});
  const [edit, setEdit] = useState(false);
  // const detail = useSelector(state => state.UserMgt.userdetail);
  const [rootForm, setRootForm] = useState({});
  const [Zone, setZone] = React.useState("");

  const [RoleList, setRoleList] = useState(["asm", "se", "tsi"]);
  const [Role, setRole] = React.useState("");

  const userDetails = useSelector((store) => store.user?.userDetails);
  const dropDownData = useSelector((state) => state.app.curretDropdownData);
  const [StatusList, setStatusList] = useState(["active", "inactive"]);
  const [Status, setStatus] = React.useState("");

  const [Updatesuccess, setUpdatesuccess] = useState(false);
  const [obj, setObj] = useState({
    failed: false,
    message: "",
  });
  const [invalidToken, setInvalidToken] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };
  const handleCancelEdit = () => {
    setEdit(false);
    setForm(rootForm);
    setFormError({});
  };

  React.useEffect(() => {
    if (
      userDetails &&
      userDetails.length !== 0 &&
      userDetails !== "undefined" &&
      userDetails !== null
    ) {
      setRootForm(userDetails);
      setForm(userDetails);
      setZone(userDetails.zone_name);
      setRole(userDetails.role_name);
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  //   let success = true;
  //   let error = {};

  //   if (!form.zone_name.trim()) {
  //     error.zone = "Zone cannot be empty";
  //     success = false;
  //   }
  //   if (!form.user_id.toString().trim()) {
  //     error.userid = "userid cannot be empty";
  //     success = false;
  //   }
  //   if (!form.role_name.trim()) {
  //     error.role = "role cannot be empty";
  //     success = false;
  //   }
  //   if (!form.name.trim()) {
  //     error.name = "name cannot be empty";
  //     success = false;
  //   }
  //   if (!form.surname.trim()) {
  //     error.surname = "surname cannot be empty";
  //     success = false;
  //   }
  //   if (!form.mobile_no.toString().trim()) {
  //     error.mobileno = "mobileno cannot be empty";
  //     success = false;
  //   } else if (
  //     !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g.test(
  //       form.mobile_no
  //     )
  //   ) {
  //     error.mobileno = "Enter a Valid mobileno Number";
  //     success = false;
  //   }
  //   if (!form.whatsapp_no.toString().trim()) {
  //     error.whatsappno = "whatsappno cannot be empty";
  //     success = false;
  //   } else if (
  //     !/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/g.test(
  //       form.whatsapp_no
  //     )
  //   ) {
  //     error.whatsappno = "Enter a Valid whatsapp Number";
  //     success = false;
  //   }

  //   if (!form.address.trim()) {
  //     error.address = "address cannot be empty";
  //     success = false;
  //   }

  //   if (!form.bank_name.trim()) {
  //     error.bankname = "Bank Name cannot be empty";
  //     success = false;
  //   }
  //   if (!form.account_no.trim()) {
  //     error.accountno = "accountno cannot be empty";
  //     success = false;
  //   }
  //   if (!form.ifsc_code.trim()) {
  //     error.ifsccode = "Ifsc Code cannot be empty";
  //     success = false;
  //   }

  //   if (!form.branch_name.trim()) {
  //     error.branchname = "Branch name Code cannot be empty";
  //     success = false;
  //   }

  //   // if (!form.routeid.trim()) {
  //   //     error.routeid = "routeid cannot be empty";
  //   //     success = false;
  //   // }
  //   // if (!form.timestamp.trim()) {
  //   //     error.timestamp = "timestamp cannot be empty";
  //   //     success = false;
  //   // }

  //   setFormError(error);
  //   return success;
  // };

  React.useEffect(() => {
    if (asmZone && asmZone.length !== 0) {
      setZone(asmZone);
    }
  }, [asmZone]);

  const handleRoleChange = (e) => {
    setRole(e.target.textContent);

    if (e.target.textContent === "se") {
      setForm({
        ...form,
        role_name: e.target.textContent,
        role_id: 1,
      });
    } else if (e.target.textContent === "asm") {
      setForm({
        ...form,
        role_name: e.target.textContent,
        role_id: 2,
      });
    } else {
      setForm({
        ...form,
        role_name: e.target.textContent,
        role_id: 3,
      });
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.textContent);

    if (e.target.textContent === "pending") {
      setForm({
        ...form,
        user_status: "pending_approval",
      });
    } else if (e.target.textContent === "active") {
      setForm({
        ...form,
        user_status: "active",
      });
    } else if (e.target.textContent === "inactive") {
      setForm({
        ...form,
        user_status: "inactive",
      });
    } else {
      setForm({
        ...form,
        user_status: "approval_denied",
      });
    }
    // setForm({
    //   ...form,
    //   status: e.target.textContent,
    // });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  // console.log(form);

  let status = form.user_status;

  if (status === "active") {
    if (
      form &&
      form.profile_image &&
      form.profile_image.length !== 0 &&
      form.profile_image !== "undefined" &&
      form.profile_image !== null
    ) {
      status = "active";
    } else {
      status = "partial";
    }
  }

  if (status === "partial") {
    status = "active";
    if (status === "active") {
      if (
        form &&
        form.profile_image &&
        form.profile_image.length !== 0 &&
        form.profile_image !== "undefined" &&
        form.profile_image !== null
      ) {
        status = "active";
      } else {
        status = "partial";
      }
    }
  }

  const userUpdateDetails = useApprovalUpdateStatus(form, status);

  let param = {
    admin_id: 1,
    user_id: `${form.user_id}`,
    first_name: `${form.first_name}`,
    last_name: `${form.last_name}`,
    mobile_no: `${form.mobile_no}`,
    whatsapp_no: `${form.whatsapp_no}`,
    address: `${form.address}`,
    zone_id: `${form.zone_id}`,
    role_id: `${form.role_id}`,
    user_status: `${status}`,
    bank_name: `${form.bank_name}`,
    account_no: `${form.account_no}`,
    ifsc_code: `${form.ifsc_code}`,
    branch_name: `${form.branch_name}`,
  };
  // const getRefreshUserList = useRefreshUserList();
  const getLaabhDelPartners = useLaabhDELPartner();
  const handleUpdateAsm = async () => {
    let param = {
      zone_id: dropDownData?.zone_id,
    };
    await userUpdateDetails();
    // await getRefreshUserList();
    await getAllUserList();
    await getLaabhDelPartners(param);
  };

  React.useEffect(() => {
    if (Updatesuccess) {
      toast.success("Updated SuccessFully");
      setUpdatesuccess(false);
    }
  }, [Updatesuccess]);

  React.useEffect(() => {
    if (invalidToken) {
      toast.warning("Invalid Token");
      setInvalidToken(false);
    }
  }, [invalidToken]);

  React.useEffect(() => {
    if (obj.failed) {
      toast.error(obj.message);
      setObj({
        ...obj,
        failed: false,
        message: "",
      });
    }
  }, [obj.failed]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div
          className={styles.UserMgtUpdateFormContainer}
          style={{ gap: "1.5rem" }}
        >
          <div className={styles.UserMgtUpdateFormContainerOne}>
            <div className={styles.UserMgtUpdateFormContainerSectionOne}>
              <div className={styles.UserMgtUpdateFormSectionOneTitleContainer}>
                <label className={styles.UserMgtUpdateFormSectionOneTitle}>
                  User Management Update Form
                </label>
              </div>

              <div
                className={styles.UserMgtUpdateFormSectionOneEditContainer}
                onClick={handleEdit}
              >
                <CreateIcon
                  className={styles.UserMgtUpdateFormSectionOneEditIcon}
                />
              </div>
            </div>

            <div className={styles.UserMgtUpdateFormContainerSectionTwo}>
              <div className={styles.UserMgtUpdateFormTwoSubSectionOne}>
                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Zone
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      // size="small"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      disabled={true}
                      value={form?.zone_name}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />

                    {formError.zone && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.zone}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      DB ID
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      // size="small"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      disabled={true}
                      value={form?.db_code}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.userid && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.userid}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Role
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    {edit ? (
                      <Box
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListField
                        }
                      >
                        <FormControl fullWidth size="small">
                          <Select value={Role} onClick={handleRoleChange}  sx={{height: "1.8rem",background:"white"}}>
                            {RoleList.map((item, index) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : (
                      <input
                        variant="outlined"
                        // size="small"
                        style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                        disabled={edit ? false : true}
                        value={userDetails?.user_role_key}
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListField
                        }
                      />
                    )}

                    {formError.role && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.role}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Name
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      // size="small"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      disabled={edit ? false : true}
                      value={form?.first_name}
                      name="first_name"
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                      onChange={handleChange}
                    />
                    {formError.name && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.name}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Surname
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      value={form?.last_name}
                      disabled={edit ? false : true}
                      name="last_name"
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                      onChange={handleChange}
                    />
                    {formError.surname && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.surname}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Mobile No
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      value={form?.mobile_no}
                      disabled={true}
                      name="mobile_no"
                      onChange={handleChange}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.mobileno && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.mobileno}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Whatsapp ID
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      value={form?.whatsapp_no}
                      disabled={edit ? false : true}
                      name="whatsapp_no"
                      onChange={handleChange}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.whatsappno && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.whatsappno}
                      </span>
                    )}
                  </div>
                </div> */}
              </div>
              <div className={styles.UserMgtUpdateFormTwoSubSectionTwo}>
                {/* <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Address
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      value={form?.address}
                      disabled={edit ? false : true}
                      multiline
                      rows={3}
                      name="address"
                      onChange={handleChange}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.address && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.address}
                      </span>
                    )}
                  </div>
                </div> */}

                {/* <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Bank Name
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      disabled={edit ? false : true}
                      name="bank_name"
                      value={form?.bank_name}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                      onChange={handleChange}
                    />
                    {formError.bankname && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.bankname}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Account No
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      name="account_no"
                      value={form?.account_no}
                      disabled={edit ? false : true}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                      onChange={handleChange}
                    />
                    {formError.accountno && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.accountno}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      IFSC Code
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      name="ifsc_code"
                      value={form?.ifsc_code}
                      disabled={edit ? false : true}
                      onChange={handleChange}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.ifsccode && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.ifsccode}
                      </span>
                    )}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Branch Name
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{height: "1.8rem",background:"white",border:"1px solid rgba(0, 0, 0, 0.2)"}}
                      name="branch_name"
                      value={form?.branch_name}
                      disabled={edit ? false : true}
                      onChange={handleChange}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.branchname && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.branchname}
                      </span>
                    )}
                  </div>
                </div> */}

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      status
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    {edit ? (
                      <Box
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListField
                        }
                      >
                        <FormControl fullWidth size="small">
                          <Select
                            value={form?.user_status}
                            onClick={handleStatusChange}
                            sx={{ height: "1.8rem", background: "white" }}
                          >
                            {StatusList.map((item, index) => (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    ) : (
                      <input
                        variant="outlined"
                        style={{
                          height: "1.8rem",
                          background: "white",
                          border: "1px solid rgba(0, 0, 0, 0.2)",
                        }}
                        disabled={true}
                        value={form?.user_status}
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListField
                        }
                      />
                    )}{" "}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormTwoSubSectionOneList}>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListOne}
                  >
                    <label
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListLabel
                      }
                    >
                      Joining Date
                    </label>
                  </div>
                  <div
                    className={styles.UserMgtUpdateFormTwoSubSectionOneListTwo}
                  >
                    <input
                      variant="outlined"
                      style={{
                        height: "1.8rem",
                        background: "white",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      value={userDetails?.created_at}
                      disabled={true}
                      className={
                        styles.UserMgtUpdateFormTwoSubSectionOneListField
                      }
                    />
                    {formError.timestamp && (
                      <span
                        className={
                          styles.UserMgtUpdateFormTwoSubSectionOneListTwoErrorLabel
                        }
                      >
                        {formError.timestamp}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {edit ? (
              <div className={styles.UserMgtUpdateFormContainerSectionThree}>
                {/* <>
                            {
                                form && form.status === "pending" ? (
                                    <RedButton variant="contained" onClick={handleUpdate}>Approve</RedButton>
                                ) : (
                                    <RedButton variant="contained" onClick={handleUpdate}>Update</RedButton>
                                )

                            }
                        </> */}
                <RedButton
                  variant="contained"
                  id="usermgtasmupdate"
                  onClick={handleUpdateAsm}
                  sx={{ height: "1.8rem" }}
                >
                  Update
                </RedButton>
                <GrayButton
                  variant="contained"
                  onClick={handleCancelEdit}
                  sx={{ height: "1.8rem" }}
                >
                  Cancel
                </GrayButton>
              </div>
            ) : null}
          </div>
          <Paper sx={{ flex: "0.9", padding: "5px" }}>
            <div className={styles.UserMgtUpdateFormContainerTwo}>
              <div
                className={styles.UserMgtUpdateFormContainerTwoFirmContainer}
              >
                <img
                  src={
                    "https://i.guim.co.uk/img/media/24b25c23b97e37af3892230202b98b8ac20a10e0/0_31_5550_3330/master/5550.jpg?width=445&quality=45&auto=format&fit=max&dpr=2&s=389ec7167a0686c3ed084d7487c5be2c"
                  }
                  alt="firm"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: "-60px",
                }}
              >
                <div
                  className={
                    styles.UserMgtUpdateFormContainerTwoProfileContainer
                  }
                >
                  <img
                    className={styles.UserMgtUpdateFormContainerTwoProfile}
                    src={
                      form &&
                      form.profile_image &&
                      form.profile_image.length !== 0 &&
                      form.profile_image !== "undefined"
                        ? `${BaseImgUrl}${form.profile_image}`
                        : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEpWWQrAJpIR6Xy7FhzhCT00vzSmT7xw9S2Q&usqp=CAU"
                    }
                    alt="prof"
                  />
                </div>
              </div>

              <div
                className={styles.UserMgtUpdateFormContainerTwoListContainer}
              >
                <div className={styles.UserMgtUpdateFormContainerTwoList}>
                  <div className={styles.UserMgtUpdateFormContainerTwoListOne}>
                    <span
                      className={
                        styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                      }
                    >
                      <PersonOutlineIcon
                        className={
                          styles.UserMgtUpdateFormContainerTwoListOneIcon
                        }
                      />
                    </span>
                    <label>Name</label>
                  </div>
                  <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                    {form?.first_name + " "}
                    {form?.last_name}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormContainerTwoList}>
                  <div className={styles.UserMgtUpdateFormContainerTwoListOne}>
                    <span
                      className={
                        styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                      }
                    >
                      <CallOutlinedIcon
                        className={
                          styles.UserMgtUpdateFormContainerTwoListOneIcon
                        }
                      />
                    </span>
                    <label>Mobile</label>
                  </div>
                  <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                    {form?.mobile_no}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormContainerTwoList}>
                  <div className={styles.UserMgtUpdateFormContainerTwoListOne}>
                    <span
                      className={
                        styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                      }
                    >
                      <LandscapeOutlinedIcon
                        className={
                          styles.UserMgtUpdateFormContainerTwoListOneIcon
                        }
                      />
                    </span>
                    <label>zone</label>
                  </div>
                  <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                    {form.zone_name}
                  </div>
                </div>

                <div className={styles.UserMgtUpdateFormContainerTwoList}>
                  <div className={styles.UserMgtUpdateFormContainerTwoListOne}>
                    <span
                      className={
                        styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                      }
                    >
                      <RoomOutlinedIcon
                        className={
                          styles.UserMgtUpdateFormContainerTwoListOneIcon
                        }
                      />
                    </span>
                    <label>Address</label>
                  </div>
                  <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                    {form.address}
                  </div>
                </div>

                {/* <div className={styles.UserMgtUpdateFormContainerTwoList}>
                  <div className={styles.UserMgtUpdateFormContainerTwoListOne}>
                    <span
                      className={
                        styles.UserMgtUpdateFormContainerTwoListOneIconContainer
                      }
                    >
                      <DirectionsWalkOutlinedIcon
                        className={
                          styles.UserMgtUpdateFormContainerTwoListOneIcon
                        }
                      />
                    </span>
                    <label>Role</label>
                  </div>
                  <div className={styles.UserMgtUpdateFormContainerTwoListTwo}>
                    {form.user_role_name}
                  </div>
                </div> */}
              </div>
            </div>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default UserMgtAsmUpdate;
