import { StylesContext } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import Styles from "./styles/salesmidcontainer.module.css";

import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import SalesTable from "./SalesTableOAO";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const useStyles = makeStyles({
  text: {
    width: "290px",
  },
});

const SalesMidContainer = ({ IdChange, Submit, Statevalue, zone }) => {
  const saleDetails = useSelector((store) => store.sale.saleDetailsOAO);
  const classes = useStyles();
  const [route, setRoute] = useState("All");
  const [routeList, setRouteList] = useState([]);
  const state = useSelector((state) => state.SalesMgt);
  const [change, setChange] = useState(false);
  const [Product, setProduct] = useState("All");
  const [ProductList, setProductList] = useState([]);
  const [selectedShapeId, setSelectedShapeId] = useState("All");
  const [retailerText, setRetailerText] = useState("");

  return (
    <div className={Styles.SalesMidContainer}>
      <div className={Styles.SalesRouteContainer}>
        <div>
          <div className={Styles.SalesRouteList}>
            <label>Search</label>
            <TextField
              sx={{
                "& .MuiInputBase-root": {
                  height: "30px",
                  "& input": {
                    height: "30px",
                    padding: "0 14px", // Adjust the padding as needed
                  },
                },
                width: "180px",
              }}
              placeholder="Name..."
              size="small"
              value={retailerText}
              onChange={(e) => setRetailerText(e.target.value)}
            />

            <FormControl sx={{ height: "1.8rem", width: "150px" }}>
              <Select
                labelId="shape-id-label"
                id="shape-id-select"
                value={selectedShapeId}
                size="small"
                sx={{ height: "1.8rem" }}
                onChange={(e) => setSelectedShapeId(e.target.value)}
              >
                <MenuItem value="All">All</MenuItem>
                {Array.from(
                  new Set(saleDetails?.map((item) => item.area_name))
                ).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div>
        <SalesTable
          selectedShapeId={selectedShapeId}
          change={change}
          retailerText={retailerText}
          Statevalue={Statevalue}
          zone={zone}
          Product={Product}
          route={route}
        />
      </div>
    </div>
  );
};

export default SalesMidContainer;
