import axios from "axios";
import { BaseImgUrl, baseUrl } from "./BaseURL";

export const Authaxios = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
});

export const AxiosPrivate = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "application/json",
  },
  // withCredentials: true,
});

export const ImageAxiosPrivate = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    Authorization: "Bearer ${config}",
    "Content-Type": "multipart/form-data",
  },
  // withCredentials: true,
});
