import useAxiosPrivate from "./useAxiosPrivate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const useUpdateGiftSku = (form) => {
  const Axios = useAxiosPrivate();
  //Redux
  const currentImageSelected = useSelector(
    (state) => state.sku.skuGiftFormData
  );

  const updateGiftSkuDetails = async () => {
    let param = {
      sku_name: form?.gift_name ?? form?.skuRowData?.sku_name,
      status:
        form?.status === "Inactive" ? false : true ?? form?.skuRowData?.status,
      sku_id: form?.sku_id,
      sp: form?.price ?? form?.skuRowData?.sp,
      sku_image: currentImageSelected?.full_path??form?.skuRowData?.sku_image,
      coins: form?.coins ?? form?.skuRowData?.coins,
      quantity: form?.quantity ?? form?.skuRowData?.quantity,
      laabh_coins: form?.coins ?? form?.skuRowData?.laabh_coins,
      category_id:form?.category_id ?? form?.skuRowData?.category_id,
    };
    const response = await Axios.post("/update_gift_sku", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  return updateGiftSkuDetails;
};

export default useUpdateGiftSku;
