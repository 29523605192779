import {
  Card,
  Fab,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { IOSSwitch } from "../../utility/IoSwitch";
// import { GetZoneByStateId, UpdateZoneByZoneId } from '../../../actions/ZoneMgt/zoneMgtAction';
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HiPencilAlt } from "react-icons/hi";
import { GrClose } from "react-icons/gr";
import useZoneListUpdate from "../../hooks/useZoneListUpdate";
import useUpdateZone from "../../hooks/useUpdateZone";

export const AddLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: "15px 15px",
  fontWeight: "bold",
  color: "white",
  backgroundColor: "#DC1D13",
  border: "2px solid #DC1D13",
  width: "100px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    color: "white",
    backgroundColor: "#DC1D13",
    border: "2px solid #DC1D13",
  },
  "&:disabled": {
    color: "white",
  },
}));

const UpdateZoneMgt = ({ token, state }) => {
  const [edit, setEdit] = useState(false);
  const NewzoneList = useSelector((state) => state.app.zoneListUpdate);
  const [error, setError] = useState({});
  const [Stateoptions, setStateoptions] = React.useState(["Ahm"]);
  const [Statevalue, setStateValue] = React.useState("");
  const [StateinputValue, setStateInputValue] = React.useState("");
  const [zone, setZone] = React.useState("");
  const [zoneList, setZoneList] = useState([]);
  // const stateList = useSelector(state => state.Zone.StateList);
  const [loading, setLoading] = useState(false);
  const [zoneDetail, setZoneDetail] = React.useState({
    zone_id: '',
    zone_name: "",
    status: true,
    state_id: 1,
    lat: "",
    lng: "",
  });

  const getZoneList = useZoneListUpdate();
  React.useEffect(() => {
    const data = getZoneList(state);
    setZoneDetail({
      zone_id: '',
      zone_name: "",
      status: true,
      state_id: 1,
      lat: "",
      lng: "",
    });
    setZoneList(data);
  }, [state]);

  React.useEffect(() => {
    // console.log(NewzoneList, "NewzoneList");
    setZoneList(NewzoneList);
  }, [NewzoneList]);

  const handleDetails = (row) => {
    const [lat, lng] = row.centroid.split(",");

    // Set the ZoneDetail with the split lat and lng values
    setZoneDetail({
      ...row,
      lat,
      lng,
    });

    document
      .getElementById("ZoneDetail")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    // setUserDetail(row);
  };

  const handleChange = (e) => {
    if (e.target.name === "status" || e.target.name === "is_category") {
      setZoneDetail({
        ...zoneDetail,
        [e.target.name]: e.target.checked,
      });
    } else {
      setZoneDetail({
        ...zoneDetail,
        [e.target.name]: e.target.value,
      });
    }
  };

  // const IsValid = () => {
  //   let success = true;
  //   let error = {};

  //   if (
  //     zoneDetail?.lat?.length !== 0 &&
  //     !/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g.test(zoneDetail?.lat)
  //   ) {
  //     error.lat = "Please Enter valid latitude";
  //     success = false;
  //   }
  //   if (
  //     zoneDetail?.lng?.length !== 0 &&
  //     !/^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/g.test(zoneDetail?.lng)
  //   ) {
  //     error.lng = "Please Enter valid langitude";
  //     success = false;
  //   }

  //   setError(error);
  //   return success;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (IsValid()) {
      setLoading(true);
      let state_id;
      // for (let i = 0; i < stateList.length; i++) {
      //     if (stateList[i].state_name === Statevalue) {
      //         state_id = stateList[i].state_id

      //     }
      // }
      let param = {
        zone_name: zoneDetail?.zone_name,
        zone_id: zoneDetail?.zone_id,
        status: zoneDetail?.status,
        frequency: zoneDetail?.frequency,
        lat: zoneDetail?.lat,
        lng: zoneDetail?.lng,
        is_category: zoneDetail?.is_category,
        route_id: 0,
        state_id: state_id,
      };
      // const data = await dispatch(UpdateZoneByZoneId(token, param));

      // if (data.code === 200) {
      //     setLoading(false)
      //     toast.success('Zone Updated SuccessFully');
      //     setEdit(false)
      // }
      // else {
      //     toast.error(data?.message)
      // }
    // }
  };

  const hanldeToggle = () => {
    setEdit(!edit);
  };
  const updateZoneData = useUpdateZone();
  const refreshZoneList = useZoneListUpdate();
  const submitForm = async() =>{
    // console.log(zoneDetail, "1111");
     await updateZoneData(zoneDetail);
     await refreshZoneList(state);
  }
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <form onSubmit={handleSubmit}>
        <div>
          <Stack
            direction={{ xs: "column" }}
            component={Paper}
            padding={1}
            spacing={2}
            marginTop={1}
          >
            <Typography
              variant="body1"
              paddingLeft={1}
              sx={{ fontWeight: "bold" }}
            >
              Update Zones
            </Typography>
            <Divider />

            <Grid container spacing={2} sx={{ flex: 2 }} padding={2.4}>
              {zoneList && Array.isArray(zoneList) && zoneList.length > 0 ? (
                zoneList.map((item, index) => (
                  <Grid
                    item
                    xs={2}
                    sm={4}
                    md={4}
                    key={index}
                    onClick={() => handleDetails(item)}
                  >
                    <Card
                      component={Paper}
                      sx={{
                        padding: "8px",
                        cursor: "pointer",
                        backgroundColor: `${
                          zoneDetail?.zone_id === item?.zone_id
                            ? "#f0c2d1"
                            : "white"
                        }`,
                      }}
                    >
                      <Typography align="center">{item?.zone_name}</Typography>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={2} sm={4} md={4}>
                  <Card
                    component={Paper}
                    sx={{
                      padding: "8px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography align="center">**No Zones**</Typography>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Stack>

          <Stack marginTop={1} component={Paper} id="ZoneDetail" padding={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Zone Details
              </Typography>
              <Fab
                sx={{ backgroundColor: "white" }}
                onClick={hanldeToggle}
                size="small"
              >
                {edit ? (
                  <GrClose style={{ width: "10px", height: "10px" }} />
                ) : (
                  <HiPencilAlt style={{ width: "20px", height: "20px" }} />
                )}
              </Fab>
            </Stack>

            <Grid
              container
              spacing={2}
              sx={{ flex: 2, marginTop: "15px" }}
              padding={2.4}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", minWidth: "80px" }}
                  >
                    Zone Name
                  </Typography>
                  <TextField
                    size="small"
                    value={zoneDetail?.zone_name}
                    disabled={!edit}
                    name="zone_name"
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              
              <Grid item xs={2} sm={4} md={4}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", minWidth: "80px" }}
                  >
                    latitude
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!edit}
                    value={zoneDetail?.lat}
                    name="lat"
                    onChange={handleChange}
                    error={error?.lat}
                    helperText={error?.lat}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", minWidth: "80px" }}
                  >
                    longitude
                  </Typography>
                  <TextField
                    size="small"
                    disabled={!edit}
                    value={zoneDetail?.lng}
                    name="lng"
                    onChange={handleChange}
                    error={error?.lng}
                    helperText={error?.lng}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={4} sx={{marginTop:"15px"}}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", minWidth: "80px" }}
                  >
                    Status
                  </Typography>
                  <IOSSwitch
                    size="small"
                    disabled={!edit}
                    checked={zoneDetail?.status ? true : false}
                    name="status"
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Frequency</Typography><TextField size="small" value={zoneDetail?.frequency} type='number' name='frequency' error={error?.frequency} helperText={error?.frequency} disabled={!edit} onChange={handleChange} /></Stack>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                                <Stack direction="row" spacing={2}><Typography variant='body2' sx={{ fontWeight: "bold", minWidth: "80px" }}>Is Category</Typography><IOSSwitch disabled={!edit} size="small" checked={zoneDetail?.is_category ? true : false} name='is_category' onChange={handleChange} /></Stack>
                            </Grid> */}
            </Grid>

            <Stack alignItems="center" direction="column" marginTop={1} >
              {edit && (
                <AddLoadingButton
                  disabled={!edit}
                  size="small"
                  type="submit"
                  // loading={loading}
                  onClick={submitForm}
                >
                  Update
                </AddLoadingButton>
              )}
            </Stack>
          </Stack>
        </div>
      </form>
    </>
  );
};

export default UpdateZoneMgt;
