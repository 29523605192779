import React,{useState} from 'react';
import Styles from './styles/Auth.module.css';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));


const AuthorizationGmap = ({otp,setOtp,setAuthorize}) => {
    const correctOtp = ['2','1','2','2','2','8'];
    

  
    const handleOtpChange = (element, index) => {


        if (isNaN(element.target.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.target.value : d)]);
    }

    const inputFocus = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
        else {
            if (e.target.nextSibling) {
                e.target.nextSibling.focus();
            }
        }
    }

    const handleSubmit=()=>{
        let success = true;

        // setAuthorize(true);

        if(otp && correctOtp){
            let i=0;
            for(i=0;i<correctOtp.length;i++){
                if(correctOtp[i]===otp[i]){
                    success = true
                }
                else{
                     success = false;
                     
                }
                
            }
        }

        if(success){
            setAuthorize(true)
        }
        else{
            toast.error("Please Enter Valid Pin");
            setAuthorize(false)
        }
    }

    
    return (
        <div className={Styles.AuthContainer}>
         <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        <div>
            <h6>Please Enter the Code to have access for Map View</h6>
        </div>
            <div className={Styles.NumberTextContainerOtp}>
                {
                    otp.map((item, index) => (

                        <input
                            key={index}
                            type="text"
                            className={Styles.TextFieldOtp}
                            maxLength={1}
                            value={item}
                            onChange={e => handleOtpChange(e, index)}
                            onFocus={e => e.target.select()}
                            onKeyUp={e => inputFocus(e)}

                        // ref={ref => index===0? (ref && ref.focus()) : null}

                        />

                    ))
                }

            </div>
            <div className={Styles.ButtonContainer}>
                    <RedButton variant="contained" onClick={handleSubmit}>Submit</RedButton>
                
            </div>
        </div>
    )
}

export default AuthorizationGmap
