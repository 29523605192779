import useAxiosPrivate from "./useAxiosPrivate";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const useUpdateSku = (form) => {
  const Axios = useAxiosPrivate();
  //Redux
  const currentImageSelected = useSelector((state) => state.sku.skuFormData);
  console.log(form, "updateSku");
  const updateSkuDetails = async () => {
    let param = {
      sku_name: form?.sku_name ?? form?.skuRowData?.sku_name,
      hindi_name:form?.hindi_name??form?.skuRowData?.hindi_name,
      gujarati_name:form?.gujarati_name??form?.skuRowData?.gujarati_name,
      ptr: form?.ptr ?? form?.skuRowData?.ptr,
      ptw: form?.ptw ?? form?.skuRowData?.ptw,
      mrp: form?.mrp ?? form?.skuRowData?.mrp,
      status:
        form?.status === "Inactive" ? false : true ?? form?.skuRowData?.status,
      sku_id: form?.sku_id ?? form?.skuRowData?.sku_id,
      sp: form?.sp ?? form?.skuRowData?.sp,
      sku_image: currentImageSelected?.full_path ?? form?.skuRowData?.sku_image,
      scheme: form?.scheme ?? form?.skuRowData?.scheme,
      category_id:form?.category_id ?? form?.skuRowData?.category_id,
    };
    const response = await Axios.post("/update_sku", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  return updateSkuDetails;
};

export default useUpdateSku;
