import React, { useState, useRef } from "react";
import Styles from "./styles/login.module.css";
import LoginLogo from "./images/saath.png";
import SaathiLogoPurple from "./images/LoginLogo.png";
import ErrorIcon from "./images/coolicon.png";
import { Button, TextField, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import SuccessIcon from "./images/success.png";
// import { GetOtp, GetToken, VerifyOtp } from '../../actions/login/login';
import { useDispatch, useSelector } from "react-redux";
import SaathiLogoLoader from "../Loader/saathiLoader";
import useLogin from "../../hooks/useLogin";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import ResetPassword from "./ResetPassword";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ContactsTwoTone } from "@mui/icons-material";
import { ToastContainer} from "react-toastify";
const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#F2843F",
  "&:hover": {
    backgroundColor: "#F2843F",
  },
}));

const Login = ({ setLogin, setUser, setAdmin }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState({});

  const [otp, setOtp] = useState(new Array(6).fill(""));

  const [loginError, setLoginError] = useState(false);
  
  const isValid = () => {
    let success = true;
    let err = {};

    if (!form?.email?.trim()) {
      err.email = "Email cannot be empty";
      success = false;
    }

    if (!form?.password?.trim()) {
      err.password = "Password cannot be empty";
      success = false;
    }

    setError(err);
    return success;
  };

  const [form, setForm] = useState({});
  const [isResetPassword, setIsResetPassword] = useState(false);
  const getLogin = useLogin();

  const handleSubmit = () => {
    if (isValid()) {
      getLogin(form);
    }
  };

  const handleAgain = () => {
    setLoginError(false);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  // const [error, setError] = useState({ password: '' });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    if (e.target.name === "password") setPassword(e.target.value);

    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetPassword = () => {
    setIsResetPassword(true);
  };

  return (
    <div className={Styles.Container}>
      {/* Image and Logo part */}
      <div className={Styles.OneContainer}>
        {/* Logo and Name part */}
        <div className={Styles.OneWrapper}>
          <div className={Styles.SaathiLogoContainer}>
            {/* <img
              src={SaathiLogoPurple}
              alt="logo"
              className={Styles.SaathiLogo}
            /> */}
          </div>
        </div>

        {/* Image part */}
        <div className={Styles.UpstoxContainer}>
          <div className={Styles.Upstox}>
            <img
              src={LoginLogo}
              alt="loginlogo"
              className={Styles.UpstoxImage}
            />
          </div>
        </div>
      </div>

      <div className={Styles.TwoContainer}>
        {/* Login Form Part */}
        <div className={Styles.section}>
          {isResetPassword ? (
            <ResetPassword setIsResetPassword={setIsResetPassword} />
          ) : (
            <Stack
              sx={{ display: "flex", flexDirection: "column", gap: "4rem" }}
            >
              <label className={Styles.LoginTitle}>
                Sign in to
                <label className={Styles.LoginTitleDark}>Get Started</label>
              </label>
              {/* Input and Button Part */}
              <div className={Styles.list}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.2rem",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.2rem",
                    }}
                  >
                    <div className={Styles.TextContainer}>
                      <input
                        placeholder="Enter Email Id"
                        name="email"
                        type="text"
                        className={Styles.TextField}
                        style={{ width: "14rem", marginLeft: "-0.8rem" }}
                        onChange={handleChange}
                      />
                    </div>
                    {error.email && (
                      <label className={Styles.errorMessage}>
                        {"**" + error.email + "**"}
                      </label>
                    )}
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.2rem",
                    }}
                  >
                    <div className={Styles.TextContainer}>
                      <input
                        placeholder="Enter Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        className={Styles.TextField}
                        style={{ width: "14rem" }}
                        onChange={handleChange}
                      />
                      { showPassword ? (
                        <VisibilityIcon
                          sx={{
                            cursor: "pointer",
                            fontWeight: "100",
                            fontSize: "15px",
                          }}
                          onClick={togglePasswordVisibility}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            cursor: "pointer",
                            fontWeight: "100",
                            fontSize: "15px",
                          }}
                          onClick={togglePasswordVisibility}
                        />
                      )}
                    </div>
                    {error.password && (
                      <label className={Styles.errorMessage}>
                        {"**" + error.password + "**"}
                      </label>
                    )}
                  </Stack>
                </div>

                <Stack
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Stack
                    sx={{
                      color: "#F2843F",
                      textDecoration: "underline",
                      fontSize: "13px",
                      cursor: "pointer",
                    }}
                    variant="body2"
                    onClick={handleResetPassword}
                  >
                    Reset password
                  </Stack>
                </Stack>

                <div>
                  <RedButton variant="contained" onClick={handleSubmit}>
                    Login
                  </RedButton>
                </div>
              </div>
            </Stack>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-right"/>
    </div>
  );
};

export default Login;
