export const USERMGTDETAIL="USERMGTDETAIL";
export const GETUSERMGTLIST="GETUSERMGTLIST";

export const USERMGTLISTUPDATE="USERMGTLISTUPDATE";

export const UPDATEUSERDETAIL = "UPDATEUSERDETAIL";


export const RETAILERLIST = "RETAILERLIST";
export const USERMGTRETAILERDETAIL ="USERMGTRETAILERDETAIL";
export const USERMGTUPDATERETAILERDETAIL = 'USERMGTUPDATERETAILERDETAIL';


export const USERMGTPAGEERROR="USERMGTPAGEERROR";
export const USERMGTERRORNULL="USERMGTERRORNULL";


export const USERMGTID = "USERMGTID";

export const USERMGTROUTELIST="USERMGTROUTELIS";
export const USERRETAILERCATEGORYLIST = "USERRETAILERCATEGORYLIST";

export const USERMGTGETWHOLESALERLIST = "USERMGTGETWHOLESALERLIST";
export const USERMGTGETWHOLESALERDETAILS = "USERMGTGETWHOLESALERDETAILS";
export const WHOLESALERUPDATEUSERDETAIL = "WHOLESALERUPDATEUSERDETAIL";
export const USERMGTPRODUCTLIST = "USERMGTPRODUCTLIST"