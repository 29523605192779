import React, { useState, useEffect } from "react";
import { Stack, TextField, Button } from "@mui/material";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
import useCoinRules from "../../hooks/useCoinRules";
import { useSelector } from "react-redux";
import useUpdateCoinRule from "../../hooks/useUpdateCoinRule";
import { ToastContainer, toast } from "react-toastify";
const CoinMgtTable = () => {
  const [editedData, setEditedData] = useState([]);
  const [editedDataTwo, setEditedDataTwo] = useState([]);
  const CoinTableData = useCoinRules();
  const TableData = useSelector((state) => state.coin.coinTable);
  const headerData = [
    "Value From",
    "Value To",
    "Cashback % From",
    "Cashback % To",
  ];

  useEffect(() => {
    CoinTableData();
  }, []);

  // useEffect(() => {
  // console.log(editedData, "editedData");
  //  }, [editedData]);
  useEffect(() => {
    if (TableData) {
      setEditedData(TableData?.resultToday?.map((row) => ({ ...row })));
    }
  }, [TableData]);
  useEffect(() => {
    if (TableData) {
      setEditedDataTwo(TableData?.resultTomorrow?.map((row) => ({ ...row })));
    }
  }, [TableData]);
  const handleInputChange = (index, field, value) => {
    const updatedData = [...editedData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setEditedData(updatedData);
  };
  const handleInputChangeTwo = (index, field, value) => {
    const updatedData = [...editedDataTwo];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setEditedDataTwo(updatedData);
  };

  const updateCoinRule = useUpdateCoinRule();
  const handleFormSubmit = () => {
    // Check if any input field is empty
    const isEmpty = editedData.some(
      (data) =>
        data.value_from === "" ||
        data.value_to === "" ||
        data.per_from === "" ||
        data.per_to === ""
    );

    if (isEmpty) {
      toast.error("All fields are required");
    } else {
      // Call the update function only if all fields are filled
      const data = updateCoinRule(editedData);
      // if (data.code === 200) {
      //     CoinTableData();
      //   }
    }
  };

  const handleFormSubmitTwo = () => {
    // Check if any input field is empty
    const isEmpty = editedDataTwo.some(
      (data) =>
        data.value_from === "" ||
        data.value_to === "" ||
        data.per_from === "" ||
        data.per_to === ""
    );

    if (isEmpty) {
      toast.error("All fields are required");
    } else {
      // Call the update function only if all fields are filled
      const data = updateCoinRule(editedDataTwo);
      // if (data.code === 200) {
      //     CoinTableData();
      //   }
    }
  };

  if (!Array.isArray(TableData?.resultToday)) {
    return <div>Loading...</div>;
  }

  return (
    <Stack sx={{ width: "100%", height: "100%", padding: "10px 10px" }}>
      <Typography sx={{ textAlign: "left", fontSize: "32px" }}>
        Coin Management
      </Typography>
      <Stack
      gap={2}
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
        <form onSubmit={handleFormSubmit}>
          <Stack sx={{ alignItems: "center" }}>
            <Typography variant="h6" color="initial" sx={{margin:"5px"}}>
              Today Delivery Rule
            </Typography>
            <TableContainer sx={{ minWidth: "100%", borderRadius:"10px", border:"1px solid gray" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={{ backgroundColor: "#c4c4c4" }}>
                  <TableRow sx={{ backgroundColor: "#c4c4c4" }}>
                    {headerData.map((header, index) => (
                      <TableCell
                        sx={{ backgroundColor: "#c4c4c4", textAlign: "center" }}
                        key={index}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* row.cr_id */}
                  {TableData?.resultToday?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedData[index]?.value_from}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "value_from",
                              e.target.value
                            )
                          }
                          error={editedData[index]?.value_from === ""}
                          helperText={
                            editedData[index]?.value_from === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedData[index]?.value_to}
                          onChange={(e) =>
                            handleInputChange(index, "value_to", e.target.value)
                          }
                          error={editedData[index]?.value_to === ""}
                          helperText={
                            editedData[index]?.value_to === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedData[index]?.per_from}
                          onChange={(e) =>
                            handleInputChange(index, "per_from", e.target.value)
                          }
                          error={editedData[index]?.per_from === ""}
                          helperText={
                            editedData[index]?.per_from === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedData[index]?.per_to}
                          onChange={(e) =>
                            handleInputChange(index, "per_to", e.target.value)
                          }
                          error={editedData[index]?.per_to === ""}
                          helperText={
                            editedData[index]?.per_to === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={handleFormSubmit}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#DC1D13",
                marginTop: "14px",
                textTransform: "none",
                ":hover": { backgroundColor: "#e82217" },
              }}
            >
              Update
            </Button>
          </Stack>
        </form>

        <form onSubmit={handleFormSubmit}>
          <Stack sx={{ alignItems: "center" }}>
            <Typography variant="h6" color="initial" sx={{margin:"5px"}}>
              Tomorrow Delivery Rule
            </Typography>
            <TableContainer sx={{ minWidth: "100%", borderRadius:"10px", border:"1px solid gray" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={{ backgroundColor: "#c4c4c4" }}>
                  <TableRow sx={{ backgroundColor: "#c4c4c4" }}>
                    {headerData.map((header, index) => (
                      <TableCell
                        sx={{ backgroundColor: "#c4c4c4", textAlign: "center" }}
                        key={index}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* row.cr_id */}
                  {TableData?.resultTomorrow?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedDataTwo[index]?.value_from}
                          onChange={(e) =>
                            handleInputChangeTwo(
                              index,
                              "value_from",
                              e.target.value
                            )
                          }
                          error={editedDataTwo[index]?.value_from === ""}
                          helperText={
                            editedDataTwo[index]?.value_from === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedDataTwo[index]?.value_to}
                          onChange={(e) =>
                            handleInputChangeTwo(
                              index,
                              "value_to",
                              e.target.value
                            )
                          }
                          error={editedDataTwo[index]?.value_to === ""}
                          helperText={
                            editedDataTwo[index]?.value_to === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedDataTwo[index]?.per_from}
                          onChange={(e) =>
                            handleInputChangeTwo(
                              index,
                              "per_from",
                              e.target.value
                            )
                          }
                          error={editedDataTwo[index]?.per_from === ""}
                          helperText={
                            editedDataTwo[index]?.per_from === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={editedDataTwo[index]?.per_to}
                          onChange={(e) =>
                            handleInputChangeTwo(
                              index,
                              "per_to",
                              e.target.value
                            )
                          }
                          error={editedDataTwo[index]?.per_to === ""}
                          helperText={
                            editedDataTwo[index]?.per_to === ""
                              ? "This Field is required"
                              : ""
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              onClick={handleFormSubmitTwo}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#DC1D13",
                marginTop: "14px",
                textTransform: "none",
                ":hover": { backgroundColor: "#e82217" },
              }}
            >
              Update
            </Button>
          </Stack>
        </form>
        <ToastContainer />
      </Stack>
    </Stack>
  );
};

export default CoinMgtTable;
