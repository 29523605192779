import React, { useState, useEffect } from "react";
import styles from "./styles/midContainerUser.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { styled } from "@mui/material/styles";

import { useSelector } from "react-redux";
import SalesmanMgtTable from "./SalesmanMgtTable";
import AddSalesmanForm from "./AddSalesmanForm";
import useAddSalesman from "../../hooks/useAddSalesman";
import useSalesmanList from "../../hooks/useSalesmanList";
import SalesmanUpdateForm from "./SalesmanUpdateForm";
import useSalesmanListmap from "../../hooks/useSalesmanListmap";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const SalesmanMgtContainer = ({ asmZone }) => {
  const [query, setQuery] = useState("");
  const [Userdetail, setUserDetail] = useState({});
  const [filterlist, setFilterlist] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const addSalesman = useAddSalesman();
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [selectedAreaName, setSelectedAreaName] = useState("ALL");
  const getAllSalesmanList = useSalesmanList(selectedStatus, selectedAreaName);
  const userAllList = useSelector((store) => store.salesman.salesmanList);
  const getSalesmanListMap = useSalesmanListmap();
  // console.log(userAllList, "userAllList");
  // const getRefreshUserList = useSalesmanList();

  const handleAddDeliveryBoy = async (formData) => {
    await addSalesman(formData);
    await getAllSalesmanList();
    const param = {
      zone_id: formData.zone_id
    }
    await getSalesmanListMap(param);
  };

  const handleSearch = (e) => {
    setQuery(e.target.value);
    if (query?.length > 0) {
      setSelectedShapeId("ALL");
      setSelectedStatus("ALL");
    }
  };
  const [filterUserList, setFilterUserList] = useState(null);

  useEffect(() => {
    if (userAllList) {
      setFilterUserList(userAllList);
    }
  }, [userAllList]);

  useEffect(() => {
    const filteredData = userAllList?.filter((user) => {
      const full_name = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      const areaNamesTitle = user?.shape_details
        ?.map((shape) => shape?.area_name)
        .join(", ");
      return (
        full_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.user_role_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.bank_name?.toLowerCase().includes(query?.toLowerCase()) ||
        areaNamesTitle?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterUserList(filteredData);
  }, [query]);

  const [selectedShapeId, setSelectedShapeId] = useState("");
  const [uniqueShapeId, setUniqueShapeId] = useState([]);

  // const getUniqueShapeIds = (data) => {
  //   const allShapeIds = data?.flatMap((item) =>
  //     item?.shape_id
  //       ? item?.shape_id?.includes(",")
  //         ? item?.shape_id?.split(", ")
  //         : [item?.shape_id]
  //       : []
  //   );
  //   const flatAllShapeIds = allShapeIds?.flatMap((item) => item);
  //   return Array?.from(new Set(flatAllShapeIds));
  // };

  // useEffect(() => {
  //   const uniqueShapeId = getUniqueShapeIds(userAllList);
  //   setUniqueShapeId(uniqueShapeId);
  // }, [userAllList]);

  const areaNameList = useSelector((store) => store.salesman.areaNameList);
  const userStatusList = useSelector((store) => store.salesman.userStatusList);

  const handleStatusChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedAreaName("ALL");
    } else if (selectedStatus === "ALL") {
      setSelectedAreaName("");
    }
    setSelectedStatus(e.target.value);
  };

  const handleAreaNameChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedStatus("ALL");
    } else if (selectedAreaName === "ALL") {
      setSelectedStatus("");
    }
    setSelectedAreaName(e.target.value);
  };

  // useEffect(() => {
  //   var filteredData;
  //   if (selectedStatus && selectedShapeId) {
  //     filteredData = userAllList?.filter(
  //       (item) =>
  //         item?.user_status === selectedStatus &&
  //         item?.shape_id?.includes(selectedShapeId)
  //     );
  //   } else if (selectedStatus) {
  //     filteredData = userAllList?.filter(
  //       (item) => item.user_status === selectedStatus
  //     );
  //   } else if (selectedShapeId) {
  //     filteredData = userAllList?.filter((item) =>
  //       item?.shape_id?.includes(selectedShapeId)
  //     );
  //   } else {
  //     filteredData = userAllList;
  //   }
  //   setFilterUserList(filteredData);
  // }, [selectedStatus, selectedShapeId, userAllList]);

  return (
    <>
      <div
        className={styles.MidContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={styles.MidContainerOne}>
          <div className={styles.MidSubContainerOne}>
            <div
              className={styles.MidSubContainerOneList}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                // sx={{ minWidth: 200}}
                // size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
                style={{ height: "1.8rem" }}
              />
            </div>
            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <div className={styles.MidSubContainerOneList}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="shape-id-status-label"
                      id="shape-id-status-select"
                      value={selectedStatus}
                      size="small"
                      sx={{ height: "1.8rem" }}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      {userStatusList?.map((status) => (
                        <MenuItem
                          key={status.user_status}
                          value={status.user_status}
                        >
                          {status.user_status?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <label className={styles.MidSubContainerOneLabel}>
                Area Name
              </label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedAreaName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleAreaNameChange(e)}
                  >
                    {areaNameList?.map((areaName) => (
                      <MenuItem
                        key={areaName?.area_name}
                        value={areaName?.area_name}
                      >
                        {areaName?.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className={styles.MidSubContainerOneList}>
              {/* <Button style={{ backgroundColor: "#00b300", color: "white",marginTop:"-4px" }} onClick={handleDownload}>Export</Button> */}
            </div>
          </div>

          <div className={styles.MidSubContainerTwo}>
            <RedButton
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              sx={{ textTransform: "none" }}
            >
              Add
            </RedButton>

            <AddSalesmanForm
              open={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              onSubmit={handleAddDeliveryBoy}
            />
          </div>
        </div>
        <div className={styles.MidContainerTwo}>
          <SalesmanMgtTable
            filterlist={filterUserList}
            detail={
              filterlist &&
              filterlist.length !== 0 &&
              filterlist !== "undefined"
                ? Userdetail
                : {}
            }
            query={query}
            setUserDetail={setUserDetail}
          />
        </div>
      </div>

      <div id="AsmUserUpdate">
        <div>
          <div>
            <div>
              <div className={styles.UserMgtBottomContainer}>
                {/* {filterlist &&
                filterlist.length !== 0 &&
                filterlist !== "undefined" ? ( */}
                <SalesmanUpdateForm
                  asmZone={asmZone}
                  detail={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? Userdetail
                      : {}
                  }
                  list={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? filterlist
                      : []
                  }
                  getAllSalesmanList={getAllSalesmanList}
                />
                {/* ) : (
                  <h5>No Data</h5>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesmanMgtContainer;
