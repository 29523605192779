import { Button, Stack, Typography } from "@mui/material";
import nine from "../../assets/landing_page_images/nine.png";
import React from "react";

import { Navigate, Link as RouterLink } from "react-router-dom";

const FooterHero = () => {
  return (
    <Stack sx={{ backgroundColor: "#424063", marginTop: "10rem" }}>
      <Stack
        sx={{
          margin: "auto",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        direction={{ xs: "column", lg: "row" }}
        gap={10}
        width={{ xs: "90%", lg: "80%" }}
        marginBottom={{ xs: "2rem" }}
        height={{ xs: "50vh" }}
      >
        <Stack>
          <Stack
            marginTop={{ xs: "2rem", lg: "0%" }}
            marginBottom={{ xs: "1rem", lg: "0%" }}
          >
            <Typography sx={{ color: "white" }}>JOIN US!</Typography>
          </Stack>
          <Stack>
            <Typography
              fontWeight={"bold"}
              sx={{ color: "white" }}
              fontSize={{ xs: "20px", lg: "40px" }}
            >
              Become a Certified Vitaran Partner
            </Typography>
          </Stack>
          <Stack
            width={"80%"}
            marginTop={{ xs: "2rem", lg: "0%" }}
            marginBottom={{ xs: "1rem", lg: "0%" }}
          >
            <Typography sx={{ color: "white" }}>
              Enjoy the features of Vitaran Live to land the perfect offers for
              various products by becoming a Vitaran Partner today!
            </Typography>
          </Stack>
          <Stack
            justifyContent={"flex-start"}
            sx={{
              backgroundColor: "White",
              width: "10rem",
              borderRadius: "20px",
            }}
            marginTop={{ xs: "2rem", lg: "2rem" }}
            marginBottom={{ xs: "1rem", lg: "0%" }}
          >
            <Button sx={{ color: "black" }}>
              <RouterLink
                to={"/vitaranpartner"}
                style={{ textDecoration: "none", color: "black" }}
              >
                APPLY NOW{" "}
              </RouterLink>
            </Button>
          </Stack>
        </Stack>
        <Stack
          width={{ xs: "50%", lg: "30%" }}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <img src={nine} alt="img" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FooterHero;
