import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Polyline,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import usePincodeData from "../../hooks/useGetPincodeAssign";
import "./assets/mapStyle.css";
import { resetSeId, setPincode, setShapeId } from "../../slice/assignSEslice";
import { useSelector, useDispatch } from "react-redux";
import { markerIcon } from "./MarkerIcon";
import { setSFPincode, setSFShapeId } from "../../slice/SFstatusSlice";

// import clusterData from "../../clusterone.json";

import {
  resetRouteClusterList,
  resetReqBoundryDetails,
  resetPincodeDetails,
  assignedBoundryDetails,
  getSMIconData,
} from "../../slice/adminMapSlice";
import checkIncon from "./assets/check_circle.png";
import crossIcon from "./assets/delete.png";
// Makrer for Router Cluster
import RED from "./assets/routeMarker/red.png";
import BLUE from "./assets/routeMarker/blue.png";
import CYAN from "./assets/routeMarker/cyan.png";
import GREEN from "./assets/routeMarker/green.png";
import ORANGE from "./assets/routeMarker/orange.png";
import yellow from "./assets/pin-img/yellow.png";
import PINK from "./assets/routeMarker/pink.png";
import infoWindowMarker from "./assets/info_window_marker.png";
import useAssignedRoute from "../../hooks/useAssignedRoute";
import { assignUserMarkers } from "./markerLogic";
import gray from "./assest/gray_retailer_marker.png";
import blackMarkerIcon from "./assest/retailers_markers/black.png";

const MapComponent = ({
  pincodeCord,
  retailerCord,
  saveDB_idIndex,
  showDailyRoute,
  assignedBoundryRoutes,
  selectedSMCode,
  unassigned,
  active,
}) => {
  const markerIconsByDay = {
    monday: RED,
    tuesday: GREEN,
    wednesday: BLUE,
    thursday: ORANGE,
    friday: CYAN,
    saturday: PINK,
  };

  const daysOfWeekColors = {
    monday: "#FF0000",
    tuesday: "#00FF00",
    wednesday: "#0000FF",
    thursday: "#ff8c00",
    friday: "#17EAE3",
    saturday: "#C139C3",
  };

  const color = [
    "#efd1ad",
    "#b1e9ee",
    "#efc9ea",
    "#d7c6dc",
    "#f2c1c2",
    "#d7e3b3",
  ];

  const days = Object.keys(daysOfWeekColors);
  const [center, setCenter] = useState();
  const [zoomLevel, setZoomLevel] = useState(12);
  const [markerData, setMarkerData] = useState(null);
  const zoneId = useSelector((state) => state.SFstatus.zone_id);
  const zoneData = useSelector((state) => state.app.zoneList);
  // console.log(zoneData?.filter((zone)=>zone.zone_id===zoneId),"zoneData")
  // const [selectedZone, setSelectedZone] = useState(null);
  // useEffect(() => {
  //   const selectedZone = zoneData?.filter((zone) => zone.zone_id === zoneId);
  //   setSelectedZone(selectedZone);
  // }, [zoneId]);
  const pincodeDetails = useSelector(
    (state) => state.adminMap.getPincodeDetails
  );

  const clusterDetails = useSelector(
    (state) => state.adminMap.getClusterDetails
  );
  const clusterData = useSelector((state) => state.adminMap.retailerMapData);
  const boundryData = useSelector((state) => state.adminMap.getBoundryDetails);
  useEffect(() => {
    const data = clusterDetails?.cluster_list || pincodeDetails?.cluster_list;
    setMarkerData(data);
  }, [clusterDetails, pincodeDetails]);

  const dispatch = useDispatch();
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [selectedPinMarker, setSelectedPincodeMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Check the condition and update the 'visible' state accordingly
    if (pincodeDetails?.marker_list?.length > 0) {
      setVisible(true);
    } else {
      // If the condition is not true, set 'visible' to false
      setVisible(false);
    }
  }, [pincodeDetails]);

  useEffect(() => {
    // Find the data object with the matching zoneId
    const selectedZone = zoneData?.find((zone) => zone.zone_id === zoneId);

    // If a matching zone is found, update the centroid state
    if (selectedZone) {
      const [lat, lng] = selectedZone.centroid.split(",").map(Number);
      setCenter({ lat, lng });
    } else {
      // Handle the case where no matching zone is found, if needed
      setCenter(null);
    }
  }, [zoneId, zoneData]);
  const polygonData = pincodeCord?.map((location) =>
    location.coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }))
  );

  useEffect(() => {
    dispatch(resetRouteClusterList());
    dispatch(resetReqBoundryDetails());
    dispatch(resetPincodeDetails());
    dispatch(resetSeId());
  }, [selectedPolygon]);

  const getAssignPincodeData = usePincodeData();
  const getAssignedRoute = useAssignedRoute();

  const handlePolygonClick = async (
    pincode,
    shapeId,
    coordinates,
    pincodeData
  ) => {
    setSelectedPolygon(pincode);
    dispatch(setPincode(pincode));
    dispatch(setSFPincode(pincode));
    dispatch(setShapeId(shapeId));
    dispatch(setSFShapeId(shapeId));
    dispatch(assignedBoundryDetails(pincodeData));

    const param = {
      pincode: pincode,
      shape_id: shapeId,
    };
    const param2 = {
      zone_id: zoneId,
      pincode: pincode,
      shape_id: shapeId,
    };
    setSelectedPincodeMarker(coordinates);

    try {
      const data = getAssignPincodeData(param);

      // Continue with the rest of your code
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };

  const getMarkerIcon = (zoneId) => {
    return {
      url: markerIcon[zoneId] || markerIcon.default,
      // scaledSize: new window.google.maps.Size(30 + zoomLevel, 30 + zoomLevel),
    };
  };

  useEffect(() => {
    if (map && selectedPinMarker) {
      const bounds = new window.google.maps.LatLngBounds();

      selectedPinMarker?.forEach((retailer) => {
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(retailer.lat),
            parseFloat(retailer.lng)
          )
        );
      });

      setBounds(bounds);

      // Fit the map to the bounds
      map.fitBounds(bounds);
    }
  }, [map, selectedPinMarker]);

  const handleMapLoad = (map) => {
    console.log(map, "123456");
    setMap(map);
  };
  const [selectedMarker, setSelectedMarker] = useState(null);
  // useEffect(() => {
  //   setSelectedPincodeMarker(retailerCord);
  // }, [retailerCord]);

  const isMarkerInsidePolygon = (markerPosition, polygonCoordinates) => {
    const markerLatLng = new window.google.maps.LatLng(
      markerPosition.lat,
      markerPosition.lng
    );

    const polygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
    });

    return window.google.maps.geometry.poly.containsLocation(
      markerLatLng,
      polygon
    );
  };

  const handleZoomChanged = (map) => {
    // Get the current zoom level
    const currentZoom = map?.getZoom();

    // Update the state with the new zoom level
    setZoomLevel(currentZoom);
  };

  const findIndexByDbId = (db_id) => {
    const match = saveDB_idIndex.find((item) => item.db_id === db_id);
    return match ? match.index : null;
  };

  const salesmanPartners = useSelector((state) => state.adminMap.salesmanData);
  const [userMarkers, setUserMarkers] = useState(null);

  const retailerData = useSelector((state) => state.adminMap.retailerData);
  useEffect(() => {
    const markerData = assignUserMarkers(
      retailerData?.retailer_list,
      salesmanPartners?.partner_list
    );
    setUserMarkers(markerData);
    dispatch(getSMIconData(markerData));
  }, [retailerData?.retailer_list, salesmanPartners?.partner_list]);

  console.log(userMarkers, "74587458");

  const generateRGBColor = (index) => {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  };

  function cross(o, a, b) {
    return (
      (a.lat - o.lat) * (b.lng - o.lng) - (a.lng - o.lng) * (b.lat - o.lat)
    );
  }

  function calculateConvexHull(points) {
    console.log(points, "pointsfdjf");
    // Create a copy of points to avoid mutating the original array
    const sortedPoints = points?.slice(); // Make a shallow copy of the array

    // Sort points lexicographically
    sortedPoints?.sort((a, b) =>
      a.lat === b.lat ? a.lng - b.lng : a.lat - b.lat
    );

    // Build the lower hull
    const lower = [];
    for (let point of sortedPoints) {
      while (
        lower.length >= 2 &&
        cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0
      ) {
        lower.pop();
      }
      lower.push(point);
    }

    // Build the upper hull
    const upper = [];
    for (let i = sortedPoints.length - 1; i >= 0; i--) {
      const point = sortedPoints[i];
      while (
        upper.length >= 2 &&
        cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0
      ) {
        upper.pop();
      }
      upper.push(point);
    }

    // Concatenate lower and upper hull to make the full hull
    lower.pop();
    upper.pop();
    const hull = lower.concat(upper);

    // Map to include only lat and lng properties
    const filteredHull = hull
      .map(({ lat, lng }) => ({ lat: parseFloat(lat), lng: parseFloat(lng) }))
      .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng));
    return filteredHull;

    // return hull;
  }

  const [hull, setHull] = useState(null);
  useEffect(() => {
    const hulls = clusterData?.route_list?.map((points) =>
      calculateConvexHull(points?.retailer_list)
    );
    setHull(hulls);
  }, []);

  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && clusterData?.route_list) {
      const bounds = new window.google.maps.LatLngBounds();
      let hasValidLatLng = false;

      clusterData?.route_list?.forEach((polygon) => {
        polygon?.polygon_list?.forEach(({ lat, lng }) => {
          if (lat && lng) {
            bounds.extend(
              new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng))
            );
            hasValidLatLng = true;
          }
        });
      });
      if (hasValidLatLng) {
        mapRef.current.fitBounds(bounds);
      } else {
        const newCenter = new window.google.maps.LatLng(center); 
        mapRef.current.setCenter(newCenter);
        mapRef.current.setZoom(12);
      }
    }
  }, [clusterData?.route_list]);

  return (
    <div>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
        <GoogleMap
          onZoomChanged={() => handleZoomChanged(map)}
          // onLoad={handleMapLoad}
          onLoad={(map) => (mapRef.current = map)}
          mapContainerStyle={{
            width: "100%",
            height: "calc(100vh - 62px)",
            borderRadius: "16px",
            border: "1px solid black",
          }}
          zoom={12}
          center={center}
          options={{
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },

              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
        >
          {polygonData?.map((coordinates, index) => (
            <Polygon
              key={index}
              paths={coordinates}
              options={{
                fillColor: "#ccdbcf",
                fillOpacity: 0.4,
                strokeColor:
                  selectedPolygon === pincodeCord[index]?.pin_code
                    ? "black"
                    : "#3483eb",
                strokeOpacity: 1,
                strokeWeight:
                  selectedPolygon === pincodeCord[index]?.pin_code ? 5 : 1,
                clickable: true,
              }}
              // onClick={() =>
              //   handlePolygonClick(
              //     pincodeCord[index]?.pin_code,
              //     pincodeCord[index]?.shape_id,
              //     coordinates,
              //     boundryData.sf_details[index]
              //   )
              // }
            />
          ))}
          {zoomLevel > 12 ? (
            <>
              {boundryData?.sf_details?.map((data, index) => (
                <Marker
                  icon={{
                    url: infoWindowMarker,
                  }}
                  key={index}
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  // onClick={() => setSelectedMarker(data)}
                />
              ))}

              {boundryData?.sf_details?.map((data, index) => (
                <InfoWindow
                  position={{
                    lat: parseFloat(data.lat),
                    lng: parseFloat(data.lng),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                  options={{
                    disableAutoPan: true,
                  }}
                >
                  <div className="info-container">
                    <div className="marker-label">
                      {/* <div>Retailers: {data.retailers_count?.toString()}</div> */}
                      {data.shape_file_status ? (
                        <img src={checkIncon} alt="Marker Image" />
                      ) : (
                        <img src={crossIcon} alt="cross img" />
                      )}
                    </div>
                    <div className="lp-name">
                      <div>{data?.office_name} </div>
                      <div> ({data.pincode?.toString()})</div>
                    </div>
                    {/* {data.lp_list.some((db) => db !== null) && (
                      <div className="lp-name">
                        {data.lp_list.map((lp, index) => (
                          <div key={index}>{lp?.name}</div>
                        ))}
                      </div>
                    )}
                    {data.db_list.some((db) => db !== null) && (
                      <div className="db-name-wrapper">
                        {data.db_list.map(
                          (db, index) =>
                            db !== null && (
                              <div className="db-name" key={index}>
                                {db?.full_name}
                              </div>
                            )
                        )}
                      </div>
                    )} */}
                    {data.sm_list.some((sm) => sm !== null) && (
                      <div className="db-name-wrapper">
                        {data.sm_list.map(
                          (sm, index) =>
                            sm !== null && (
                              <div
                                className="lp-name"
                                key={index}
                                // style={{ color: "black", fontWeight: "600" }}
                              >
                                {sm?.full_name}
                              </div>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </InfoWindow>
              ))}
            </>
          ) : null}

          {/* <Marker position={centerBike} icon={bikeIcon} /> */}

          {/* {boundryData?.sf_details?.map((sfDetail, sfIndex) => (
            <div key={`sf-${sfIndex}`}>
              {sfDetail.sm_list?.map((sm, smIndex) => (
                <Marker
                  key={smIndex}
                  position={{
                    lat: parseFloat(sm.lat),
                    lng: parseFloat(sm.lng),
                  }}
                  icon={getMarkerIcon(sm.zone_id)}
                  // onClick={() => {
                  //   setSelectedRetailer(retailer);
                  // }}
                />
              ))}
            </div>
          ))} */}

          {unassigned === true &&
            retailerData?.unassignedRetailer_list?.map((retailer, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(retailer.lat),
                  lng: parseFloat(retailer.lng),
                }}
                onClick={() => {
                  setSelectedRetailer(retailer);
                }}
                icon={{
                  url: blackMarkerIcon,
                  scaledSize: { width: 5, height: 5 },
                }}
              />
            ))}

          {active === true &&
            retailerData?.activeRetailer_list?.map((retailer, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(retailer.lat),
                  lng: parseFloat(retailer.lng),
                }}
                onClick={() => {
                  setSelectedRetailer(retailer);
                }}
                icon={{
                  url: gray,
                  scaledSize: { width: 5, height: 5 },
                }}
              />
            ))}

          {clusterData?.route_list?.map((polygon, index) => {
            const paths = polygon?.polygon_list?.map(({ lat, lng }) => ({
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            }));
            return (
              <Polygon
                key={index}
                paths={paths}
                options={{
                  fillColor: color[index % 6],
                  fillOpacity: 0.8,
                  strokeColor: generateRGBColor(index),
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  zIndex: 1,
                }}
              />
            );
          })}

          {/* {retailerData?.polycord?.map((polygon, index) => {
            return polygon?.map((poly, polyIndex) => {
              console.log(poly, "111111222233");
              return (
                <Polyline
                  key={polyIndex}
                  path={polygon}
                  options={{
                    strokeColor:
                      poly?.completed === false ? "#32a852" : "#8B0000",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    zIndex: 2,
                  }}
                />
              );
            });
          })} */}

          {/* {clusterData?.cluster_list?.map((cluster) => {
            // Collecting all retailer coordinates in a single array for the cluster
            const path = cluster.sar_list
              .map((retailer) => {
                // Convert lat and lng to numbers
                const lat = parseFloat(retailer.lat);
                const lng = parseFloat(retailer.lng);

                // Ensure lat and lng are valid
                if (isNaN(lat) || isNaN(lng)) {
                  console.error(
                    `Invalid coordinates for retailer ID: ${retailer?.retailer_id}`
                  );
                  return null;
                }

                return { lat, lng };
              })
              .filter((coord) => coord !== null); // Filter out any invalid coordinates

           

            return (
              <Polyline
                key={cluster.cluster_id}
                path={path} // Pass the array of coordinates for the `path`
                options={{
                  strokeColor: path.map((coord) =>
                    coord.called ? "green" : "black"
                  ),

                  strokeOpacity: 1,
                  strokeWeight: 1,
                  zIndex: 2,
                }}
              />
            );
          })} */}

          {clusterData?.route_list?.map((cluster) => {
            // Create polylines for each retailer segment in the cluster
            return cluster?.retailer_list?.map((retailer, index) => {
              const lat = parseFloat(retailer?.lat);
              const lng = parseFloat(retailer?.lng);

              // Ensure lat and lng are valid
              if (isNaN(lat) || isNaN(lng)) {
                console.error(
                  `Invalid coordinates for retailer ID: ${retailer?.retailer_id}`
                );
                return null;
              }

              // Determine the color for the segment
              const strokeColor = retailer?.called ? "green" : "black";

              // Define the path for the polyline segment
              const nextRetailer = cluster?.retailer_list?.[index + 1];
              if (nextRetailer) {
                const nextLat = parseFloat(nextRetailer.lat);
                const nextLng = parseFloat(nextRetailer.lng);

                // Ensure nextLat and nextLng are valid
                if (isNaN(nextLat) || isNaN(nextLng)) {
                  console.error(
                    `Invalid coordinates for next retailer ID: ${nextRetailer?.retailer_id}`
                  );
                  return null;
                }

                return (
                  <Polyline
                    key={`${retailer.retailer_id}-${nextRetailer.retailer_id}`}
                    path={[
                      { lat, lng },
                      { lat: nextLat, lng: nextLng },
                    ]} // Path for the segment
                    options={{
                      strokeColor: strokeColor,
                      strokeOpacity: 1,
                      strokeWeight: 1,
                      zIndex: 2,
                    }}
                  />
                );
              }

              return null;
            });
          })}

          {/* {retailerData?.retailer_list?.map((retailerList, index) =>
            retailerList?.retailer_details?.map((retailer) => (
              <Marker
                key={index}
                position={
                  userMarkers?.some(
                    (user) => user.user_id === retailerList.sm_id
                  )
                    ? {
                        lat: parseFloat(retailer.lat),
                        lng: parseFloat(retailer.lng),
                      }
                    : null
                }
                onClick={() => {
                  setSelectedRetailer(retailer);
                }}
                icon={
                  userMarkers?.some(
                    (user) => user.user_id === retailerList.sm_id
                  )
                    ? {
                        url: userMarkers?.find(
                          (user) => user.user_id === retailerList.sm_id
                        )?.marker,
                        // scaledSize: { width: 14, height: 14 },
                      }
                    : null
                }
              />
            ))
          )} */}

          {/* Original One */}
          {/* {retailerData?.retailer_list?.map((retailerList) =>
            retailerList?.retailer_details?.map((retailer) => {
              const userMarker = userMarkers?.find(
                (user) => user?.user_id === retailerList?.sm_id
              );

              return (
                <Marker
                  key={retailer?.retailer_id}
                  position={{
                    lat: parseFloat(retailer?.lat),
                    lng: parseFloat(retailer?.lng),
                  }}
                  onClick={() => {
                    setSelectedRetailer(retailer);
                  }}
                  icon={
                    userMarker
                      ? {
                          url: userMarker?.marker,
                          scaledSize: { width: 5, height: 5 },
                          // Adjust size if needed
                        }
                      : gray
                  }
                />
              );
            })
          )} */}

          {clusterData?.route_list?.map((retailerList) =>
            retailerList?.retailer_list?.map((retailer) => {
              // Parse lat and lng as floats
              const lat = parseFloat(retailer?.lat);
              const lng = parseFloat(retailer?.lng);

              return (
                <Marker
                  key={retailer?.retailer_id}
                  position={{ lat, lng }}
                  onClick={() => {
                    setSelectedRetailer(retailer);
                  }}
                  icon={{
                    url:
                      retailer?.called === true &&
                      retailer?.one_and_only_ordered === true
                        ? GREEN
                        : retailer?.called === true &&
                          retailer?.one_and_only_ordered === false
                        ? yellow
                        : RED,
                    scaledSize: { width: 10, height: 10 },
                  }}
                />
              );
            })
          )}

          {/* {!clusterDetails && !pincodeDetails?.cluster_list.length > 0 ? (
            retailerCord?.map((retailer, index) => (
              <Marker
                key={index}
                position={{
                  lat: parseFloat(retailer.lat),
                  lng: parseFloat(retailer.lng),
                }}
                icon={getMarkerIcon(retailer.zone_id)}
                onClick={() => {
                  setSelectedRetailer(retailer);
                }}
              />
            ))
          ) : (
            <></>
          )} */}

          {/* {selectedRetailer && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedRetailer.lat),
                lng: parseFloat(selectedRetailer.lng),
              }}
              onCloseClick={() => {
                setSelectedRetailer(null);
              }}
            >
              <div>
                <p> {selectedRetailer.full_name}</p>
              </div>
            </InfoWindow>
          )} */}

          <React.Fragment>
            {markerData && markerData.length > 0 ? (
              markerData.map((cluster, clusterindex) => (
                <React.Fragment key={cluster.cluster_id}>
                  {cluster.coord_list.map((retailer, index) => {
                    const clusterIndex = findIndexByDbId(cluster.db_id);
                    return (
                      <Marker
                        key={index}
                        position={{
                          lat: parseFloat(retailer.lat),
                          lng: parseFloat(retailer.lng),
                        }}
                        icon={getMarkerIcon(clusterIndex)}
                        onClick={() => {
                          setSelectedRetailer(retailer);
                        }}
                      />
                    );
                  })}
                </React.Fragment>
              ))
            ) : (
              <></>
            )}

            {selectedRetailer && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedRetailer.lat),
                  lng: parseFloat(selectedRetailer.lng),
                }}
                onCloseClick={() => {
                  setSelectedRetailer(null);
                }}
              >
                <div>
                  {/* Render retailer details here */}
                  <p>Shop Name: {selectedRetailer.full_name}</p>
                  {/* You can fetch and display more retailer details here */}
                </div>
              </InfoWindow>
            )}
          </React.Fragment>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapComponent;
