import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getInvetoryListV2 } from "../slice/skuSlice";

const useInventoryList = () => {

  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getInventoryList = async (param) => {

    const response = await Axios.post("/get_inventory_list", param);
    const data = await response.data;
    dispatch(getInvetoryListV2(data.body));

  };

  return getInventoryList;
};

export default useInventoryList;
