import React, { useEffect } from "react";
import Sidebar from "../Sidebar";
import { Card, Stack, Typography } from "@mui/material";
import useRetailerCount from "../../hooks/useretailerCount";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DashboardDropDown from "./DashboardDropDown";
import DashboardTable from "./DashboardTable";

const DashboardContainer = () => {
  const [retailerCount, setRetailerCount] = React.useState(0);
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  const getRetailerCount = useRetailerCount();
  const Data = useSelector((state) => state.app.curretDropdownData);
  const DashboardData = useSelector((state) => state.app.dashboardData);
  useEffect(() => {
    const data = getRetailerCount();

    setRetailerCount(data);
    const id = setInterval(() => {
      getRetailerCount();
    }, 15000);

    return () => clearInterval(id);
  }, [Data]);

  return (
    <Stack padding={1} sx={{ width: "100%" }}>
      <Stack sx={{ gap: "12px" }}>
        {/* <Typography variant="h6" color="initial">Dashboard</Typography> */}
        <Typography variant="h6" color="initial">
          Dashboard
        </Typography>
        <Stack
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.totalRetailers}
            </Typography>
            <Typography variant="h6" color="initial">
              Retailers
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.order_details?.pending_orders}
            </Typography>
            <Typography variant="h6" color="initial">
              Pending Orders
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.order_details?.on_the_way}
            </Typography>
            <Typography variant="h6" color="initial">
              On the Way
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.order_details?.completed_orders}
            </Typography>
            <Typography variant="h6" color="initial">
              Completed Orders
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.order_details?.cancelled_orders}
            </Typography>
            <Typography variant="h6" color="initial">
              Cancelled Orders
            </Typography>
          </Card>
          <Card
            sx={{
              flex: "1 1 160px",
              alignItems: "center",
              textAlign: "center",
              padding: "6px",
              borderRadius: "6px",
            }}
          >
            <Typography variant="h2" color="initial">
              {DashboardData?.totalDownloads}
            </Typography>
            <Typography variant="h6" color="initial">
              Total Downloads
            </Typography>
          </Card>
        </Stack>

        {userData?.role === "admin" || userData?.is_asm === true ? (
          <Stack>
            <Link style={{ textDecoration: "none" }} to="/home/asmdashboard">
              <Typography
                sx={{
                  padding: "10px",
                  border: "2px solid #DC1D13",
                  color: "#DC1D13",
                  width: "fit-content",
                  cursor: "pointer",
                }}
              >
                ASM Dashboard
              </Typography>
            </Link>
          </Stack>
        ) : null}
      </Stack>
      {/* <DashboardDropDown />
      <DashboardTable /> */}
    </Stack>
  );
};

export default DashboardContainer;
