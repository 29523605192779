import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";

import CategoryManagementTable from "./CategoryManagement/CategoryManagementTable";
import SKUManagementTable from "./SKU Management/SKUManagementTable";
import AddCategory from "./CategoryManagement/AddCategory";
import AddSku from "./SKU Management/AddSku";
import SuperCategoryManagementTable from "./InventoryManagement/InventoryManagemrntTable";
import AddSuperCategory from "./InventoryManagement/AddSuperCategory";
import { useSelector } from "react-redux";
import InventoryMgt from "./InventoryManagement/InventoryQtyManagement";
function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const BoundryDetails = useSelector(
    (state) => state.adminMap.getBoundryDetails
  );
  const SFId = useSelector((store) => store.sku.selectedSF);
  const [value, setValue] = React.useState(0);
  const [shapeId, setshapeId] = React.useState('');
  const [switchStatus, setSwitchStatus] = React.useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue); 
  };
  const handleChildValueChange = (newValue) => {
    setshapeId(newValue);
    
  };
  React.useEffect(() => {
    const shapeDetail = BoundryDetails?.sf_details?.find(
      (detail) => detail?.shape_id === SFId
    );
    if (shapeDetail) {
      setSwitchStatus(shapeDetail?.shape_file_status);
      
    } else {
      // console.log("Shape ID not found");
    }
  }, [SFId, BoundryDetails]);
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );

  React.useEffect(()=>{
  console.log(value, "value1121");
  }, [value])
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "space-between",
          display: "flex",
          width: "98%",
          ".MuiTabs-indicator":{

            backgroundColor:"#DC1D13"
          }
        }}
      >
        <Stack>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="SuperCategory Management" {...a11yProps(2)} /> */}
            <Tab sx={{color:"#DC1D13 !important", fontWeight:"600", padding:"12px 16px !important"  }} label="Category Management" {...a11yProps(0)} />
            
            <Tab sx={{color:"#DC1D13 !important", fontWeight:"600", padding:"12px 16px !important" }} label="SKU (SPF) Management" {...a11yProps(1)} />
            
            
            <Tab sx={{color:"#DC1D13 !important", fontWeight:"600", padding:"12px 16px !important" }} label="Inventory Management" {...a11yProps(2)} />

            {userData?.role !== "partner" && (
              <Tab sx={{color:"#DC1D13 !important", fontWeight:"600", padding:"12px 16px !important" }} label="Sku Management" {...a11yProps(3)} />
            )}
          </Tabs>
        </Stack>

        {value === 1 ? (
          <Stack sx={{alignSelf:"center"}}>
          {switchStatus ? <AddSuperCategory /> : null}  
          </Stack>
        ) : value === 0 ? (
          <Stack sx={{alignSelf:"center"}}>
            <AddCategory />
          </Stack>
        ) : value === 3 ? (
          <Stack sx={{alignSelf:"center"}}>
            <AddSku shapeId={shapeId} />
          </Stack>
        ) : null
      }
      </Box>
      <CustomTabPanel value={value} index={1}>
        <SuperCategoryManagementTable  />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={0}>
        <CategoryManagementTable />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <SKUManagementTable onValueChange={handleChildValueChange} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <InventoryMgt/>
      </CustomTabPanel>
    </Box>
  );
}
