import { createSlice } from "@reduxjs/toolkit";

const superCategorySlice = createSlice({
  name: "superCategory",
  initialState: {
    superCategoryList: null,
    superCategoryFormData: null,
  },
  reducers: {
    getSuperCategoryList: (state, action) => {
      state.superCategoryList = action.payload;
    },
    getSuperCategoryFormData: (state, action) => {
      state.superCategoryFormData = action.payload;
    },
  },
});

export default superCategorySlice.reducer;
export const { getSuperCategoryList, getSuperCategoryFormData } = superCategorySlice.actions;
