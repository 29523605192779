import React,{useState,useEffect} from 'react';
import Styles from './styles/ApprovalCoinTransaction.module.css';
import TextField from '@mui/material/TextField';
import ApprovalMobileReplacementTable from './ApprovalMobileReplacementTable';
import { useSelector } from 'react-redux';

const ApproveMobileReplacement = ({token,zone}) => {
    const approval = useSelector(state => state.ApprovalMgt)
    const Searchcolumn = ["user_id", "name", "surname","role_name",'mobile_no'];
    const [q, setQ] = useState('');

    const Searchfilter = (datas) => {
        return datas.filter((row) =>
            Searchcolumn.some(column => row[column] !== null && row[column] !== "undefined" && row[column].length !== 0 ? (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) : (""))

        )
    }

  return (
     <div className={Styles.container}>
        <div className={Styles.TitleContainer}>
             <label className={Styles.Title}>Mobile Number Replacement</label>
         </div>
         <div className={Styles.TextContainer}>
             <TextField variant="outlined" size="small" placeholder='Search User..' sx={{width:"30%"}} onChange={e=>setQ(e.target.value)}/>
         </div>
         <div className={Styles.TableContainer}>
             {/* <ApprovalMobileReplacementTable list={Searchfilter(approval.ApprovalListUUID)} token={token} zone={zone}/> */}
             <ApprovalMobileReplacementTable token={token} zone={zone}/>
         </div>
     </div>
    )
};

export default ApproveMobileReplacement;
