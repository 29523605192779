
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const useAddInventoryQty = () => {

  const Axios = useAxiosPrivate();
  const addInventoryQty = async (param) => {

    const response = await Axios.post("/add_inventory_qty", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success(data.message);
    } else if (data.code === 400) {
      toast.error(data.message);
    } else {
      toast.error("Failed to Add Inventory");
    }
  };

  return addInventoryQty;
};

export default useAddInventoryQty;
