import React from "react";
import BasicTabs from "./Tab";
import DashboardHeader from "./Dashboard/AdminHeader";
import DashboardContent from "./Dashboard/DashboardContent";
import {Stack} from "@mui/material"
import Sidebar from "./Sidebar";
import useCategoryImageUpload from "../hooks/useCategoryImageUpload";

const SkuCategoryPage = () => {
    // useCategoryImageUpload()
  return (
    <Stack sx={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <Stack>
      {/* <DashboardHeader /> */}
      </Stack>
      <Stack sx={{display:"flex",flexDirection:"row",gap:"1rem", maxWidth:"100vw"}}>
        <Sidebar/>
        <BasicTabs />
      </Stack>
    </Stack>
  );
};

export default SkuCategoryPage;
