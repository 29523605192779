
import { getDeliveryMapData } from "../slice/adminMapSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";


const useDeliveryMapData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  
  const getDeliveryMgtMapData = async (param) => {
    try {
      // console.log("useDeliveryMapData", param);
      const response = await Axios.post("delivery_map_data", param);
      const data = response.data;      
      dispatch(getDeliveryMapData(data.map_data));
      return data;  
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;  
    }
  };
  return getDeliveryMgtMapData;
};

export default useDeliveryMapData;

