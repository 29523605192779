import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const useUpdateInventoryV2 = () => {
  const Axios = useAxiosPrivate();
  const SFId = useSelector((store) => store.sku.selectedSF);
  const updateInventory = async (param) => {
    // Create a new object with updated shape_id
    const updatedParam = { ...param, shape_id: SFId };

    const response = await Axios.post("v2/update_sku", updatedParam);
    const data = await response.data;

    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  return updateInventory;
};

export default useUpdateInventoryV2;
