import React from "react";
import DashboardHeader from "./AdminHeader";
import DashboardContent from "./DashboardContent";

// import DashboardDropdown from "./DashboardDropdown";
const DashboardPreview = () => {
  return (
    <div style={{ width: "100vw", height: "fit-content", position: "relative", backgroundColor:"#E5E5E5", fontFamily:"'Roboto', sans-serif"}}>
      {/* <DashboardHeader /> */}
      {/* <DashboardDropdown /> */}
      <DashboardContent />
    </div>
  );
};

export default DashboardPreview;
