import React from "react";
import Sidebar from "../Sidebar";
import { Stack } from "@mui/material";
import DeliveryMgtMainContainer from "./DeliveryMgtMainContainer";

const index = () => {
  return (
    <Stack>
        <Stack direction="row" sx={{width:"100%", backgroundColor: "#E5E5E5", height:"100%"}}>
        <Sidebar />
        <DeliveryMgtMainContainer />
      </Stack>      
      
    </Stack>
  );
};

export default index;