import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getAreaNameList, getSalesmanList } from "../slice/salesmanSlice";
import { getUserStatusList } from "../slice/salesmanSlice";

const useSalesmanList = (selectedStatus, selectedAreaName) => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllSalesmanList = async () => {
    const param = {
      zone_id: currentzone,
      user_status: selectedStatus,
      area_name: selectedAreaName,
    };
    const response = await Axios.post("salesman_list", param);
    const data = await response.data;
    // console.log(data, "salesman_list");
    dispatch(getSalesmanList(data.body));
    dispatch(getUserStatusList(data.userStatusList));
    dispatch(getAreaNameList(data.areaNameList));
  };
  useEffect(() => {
    getAllSalesmanList();
  }, [currentzone, selectedAreaName, selectedStatus]);
  return getAllSalesmanList;
};

export default useSalesmanList;
