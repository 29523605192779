import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryPartners } from "../slice/adminMapSlice";


const useLaabhDELPartner = () => {
  const zoneData = useSelector((state) => state.SFstatus);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
 //
  const getLaabhDELPartner = async (param) => { 
    const response = await Axios.post("get_delivery_boys", param);
    const data = await response.data;
    dispatch(getDeliveryPartners(data));
   
  };
  return getLaabhDELPartner;
};

export default useLaabhDELPartner;