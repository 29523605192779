import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateInventorySuggest = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateInventorySuggest = async (param) => {
    
    const response = await Axios.post("v2/update_auto_suggest", param);
    const data = await response.data;

    console.log(data, "parameter");
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateInventorySuggest;
};

export default useUpdateInventorySuggest;
