import { createSlice } from "@reduxjs/toolkit";

const retailerSlice = createSlice({
  name: "retailer",
  initialState: {
    retailerList: null,
    retailerUserDetails: null,
    reportedRetailers: null,
    selectedReportedRetailer: null,
    userStatusList: null,
    areaNameList: null,
    retailerListCount: null,
  },
  reducers: {
    getRetailerList: (state, action) => {
      state.retailerList = action.payload;
    },
    getUserStatusList: (state, action) => {
      state.userStatusList = action.payload;
    },
    getAreaNameList: (state, action) => {
      state.areaNameList = action.payload;
    },
    getRetailerListCount: (state, action) => {
      state.retailerListCount = action.payload;
    },
    getRetailerUserDetails: (state, action) => {
      state.retailerUserDetails = action.payload;
    },
    getReportedRetailers: (state, action) => {
      state.reportedRetailers = action.payload;
    },
    getSelectedReportedRetailer: (state, action) => {
      state.selectedReportedRetailer = action.payload;
    },
  },
});

export default retailerSlice.reducer;
export const {
  getRetailerList,
  getReportedRetailers,
  getRetailerUserDetails,
  getSelectedReportedRetailer,
  getUserStatusList,
  getAreaNameList,
  getRetailerListCount
} = retailerSlice.actions;
