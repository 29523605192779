import * as React from "react";

import Paper from "@mui/material/Paper";
import { Stack, Typography, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import skuData from "./data.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import useSkuList from "../../hooks/useSkuList";
import ConfirmationDialog from "../../utility/ConfirmationDialog";
import { useState, useEffect } from "react";
import useDeleteSku from "../../hooks/useDeleteSku";
import EditSku from "./EditSku";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import useGetShapeIdList from "../../hooks/useGetShapeIdList";
import useUpdateSkuStatus from "../../hooks/useUpdateSkuStatus";
import useUpdateInventorySuggest from "../../hooks/useUpdateInventorySuggest";


export default function SKUManagementTable({onValueChange}) {
  const skuList = useSelector((store) => store.sku.skuList);
  const shapeFileList = useSelector((state) => state.app.shapeIdList);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
  const [skuId, setSkuId] = useState(null);
  const [skuEditId, setSkuEditId] = useState(null);
  const [skuRowData, setSkuRowData] = useState(null);
  const [switchStates, setSwitchStates] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filterRetailerList, setFilterRetailerList] = useState(null);
  const [shapeID, setSelectedshapeID] = React.useState("");
  const [autoSuggestStates, setAutoSuggestStates] = React.useState([]);
  const getAllSkuList = useSkuList();
  const zonedata = useSelector((store) => store.app.curretDropdownData);
  const deleteSku = useDeleteSku(skuId);
  const getAllshapeIdList = useGetShapeIdList();
  const updateSkuStatus = useUpdateSkuStatus();
  const updateAutoSuggest = useUpdateInventorySuggest();
  useEffect(() => {
    if (skuList) {
      if (selectedCategory === "All") {
            setFilterRetailerList(skuList);
          } else {
            // Filter data based on the selected category
            const filteredData = selectedCategory
              ? skuList.filter((item) => item.category_name === selectedCategory)
              : skuList;
            setFilterRetailerList(filteredData);
          }
          setPage(0);
    }
  }, [skuList]);

  React.useEffect(() => {
    if (skuList) {
      setSwitchStates(skuList?.map((data) => data.status));
    }
  }, [skuList]);

  // React.useEffect(() => {
  //   if (skuList) {
  //     setAutoSuggestStates(skuList.map((data) => data.auto_suggest));
  //   }
  // }, [skuList]);

  const handleSwitchChange = async(data, index) => {
    // console.log(index, "1111111111")
    const newSwitchStates = [...switchStates];
    newSwitchStates[index] = !newSwitchStates[index];
    setSwitchStates(newSwitchStates);
    // Make API call here with the updated status
    const updatedStatus = newSwitchStates[index];
    // const skuId = skuList[index].sku_id;
    const param = {
    sku_id: data?.sku_id, 
    status:updatedStatus
    }
    updateSkuStatus(param);
  };
  
  const handleAutoSuggestChange = (index) => {
    const newAutoSuggestStates = [...autoSuggestStates];
    newAutoSuggestStates[index] = !newAutoSuggestStates[index];
    setAutoSuggestStates(newAutoSuggestStates);
    // Make API call here with the updated status
    const updatedStatus = newAutoSuggestStates[index]; // The updated status of the row
    const si_Id = skuList[index].si_id; // Assuming there's a unique identifier for each row, like sku_id
    const param = {
      si_id: si_Id, 
      auto_suggest:updatedStatus
      }
    updateAutoSuggest(param);
  };

  // React.useEffect(() => {
  //   // Set shapeID to the shape_id of the first item in shapeFileList.sf_details
  //   if (shapeFileList?.sf_details?.length > 0) {
  //     setSelectedshapeID(shapeFileList.sf_details[0].shape_id);
  //     getAllSkuList(shapeFileList.sf_details[0].shape_id);
  //     onValueChange(shapeFileList.sf_details[0].shape_id);
  //   }
    
  // }, [shapeFileList]);

  React.useEffect(() => {
    const param ={
     zone_id: zonedata?.zone_id
    }
    getAllSkuList();
    // getAllshapeIdList(param);
   }, [zonedata]);

  // useEffect(() => {
  //   // Handle the case when "All" is selected
  //   if (selectedCategory === "All") {
  //     setFilterRetailerList(skuList);
  //   } else {
  //     // Filter data based on the selected category
  //     const filteredData = selectedCategory
  //       ? skuList.filter((item) => item.category_name === selectedCategory)
  //       : skuList;
  //     setFilterRetailerList(filteredData);
  //   }
  //   setPage(0);
  // }, [selectedCategory]);
  const handleaCateogryChange = (category) => {
    setSelectedCategory(category)
    if (category === "All") {
      setFilterRetailerList(skuList);
    } else {
      // Filter data based on the selected category
      const filteredData = selectedCategory
        ? skuList.filter((item) => item.category_name === category)
        : skuList;
      setFilterRetailerList(filteredData);
    }
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (data) => {
    setIsConfirmDialogOpen(true);
    setSkuId(data?.sku_id);
    // getAllSkuList(shapeID);
  };

  const handleConfirmDialogClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleConfirmApproveClick = async() => {
    setIsConfirmDialogOpen(false);
    await deleteSku();
    await getAllSkuList(shapeID);    
  };

  const handleEditClick = (data) => {
    setOpen(true);
    setSkuEditId(data?.sku_id);
    setSkuRowData(data);
  };
  // useEffect(() => {
  //   getAllSkuList(shapeID);
  // }, []);

  const handleSFChange = (data) => {
    setSelectedshapeID(data);
    getAllSkuList(data);
    onValueChange(data);
    // setFormDataList(prevFormDataList =>
    //   prevFormDataList?.map(() => ({
    //     inventory: '',
    //     ptr: '',
    //     mrp: ''
    //   }))
    // );
  };

  return (
    <Stack>
      <Stack flexDirection="row">
      <Stack sx={{ minWidth: 140, maxWidth: "200px", flexDirection:"row", justifyContent:"space-between", padding:"8px 6px" }}>
        <label>Category:</label>
        <FormControl sx={{ height: "1.8rem", width: "120px" }}>
          <Select
            labelId="shape-id-label"
            id="shape-id-select"
            value={selectedCategory}
            size="small"
            sx={{ height: "1.8rem"}}
            onChange={(e) => handleaCateogryChange(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {Array.from(
              new Set(skuList?.map((item) => item.category_name))
            ).map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {/* <Stack
          sx={{
            gap:"4px",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "8px 6px",
          }}
        >
          <label>Shape ID:</label>
          <FormControl
            sx={{ height: "1.8rem", width: "200px" }}
            
          >
            <Select
              labelId="shape-id-label"
              id="shape-id-select"
              value={shapeID}
              size="small"
              sx={{ height: "1.8rem" }}
              onChange={(e) => handleSFChange(e.target.value)}
            >
              {shapeFileList?.sf_details?.map((sf, index) => (
                <MenuItem key={index} value={sf?.shape_id}>
                  {sf?.shape_id}
                </MenuItem>
              ))}
            </Select>
            
          </FormControl>
        </Stack> */}
        </Stack>
    <Paper sx={{ width: "100%" }}>
      
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                SI no.
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                SKU Image
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={3}
              >
                SKU Name
              </TableCell>

              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Category
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                PTR
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                MRP
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Scheme
              </TableCell>
              {/* <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Inventory
              </TableCell> */}
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Scope
              </TableCell>
              {/* <TableCell
                  sx={{
                    background: "#F8F8F8",
                    fontSize: "16px",
                    color: "#5F6868",
                    fontWeight: "500",
                  }}
                  align="start"
                  colSpan={1}
                >
                  Suggested SKU
                </TableCell> */}
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterRetailerList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((data, index) => {
                const serialNumber = page * rowsPerPage + index + 1;
                const dataIndex = page * rowsPerPage + index;
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start">{serialNumber}</TableCell>
                      <TableCell align="start" colSpan={3}>
                        <img style={{ width: "3rem" }} src={data?.sku_image} />
                      </TableCell>
                      <TableCell align="start" colSpan={3}>
                        {data.sku_name}
                      </TableCell>
                      <TableCell align="start">
                        {data.category_name ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.sp ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.mrp ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.scheme ?? ""}
                      </TableCell>
                      {/* <TableCell align="start" colSpan={1}>
                        {data.inventory ?? ""}
                      </TableCell> */}
                      <TableCell align="start" colSpan={1}>
                        {data.sku_scope ?? ""}
                      </TableCell>
                      <TableCell align="start">
                        <Switch
                          checked={switchStates[dataIndex]}
                          onChange={() => handleSwitchChange(data, dataIndex)}
                          sx={{
                            "& .MuiSwitch-thumb": {
                              backgroundColor: switchStates[dataIndex]
                                ? "#4CAF50"
                                : undefined,
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#4CAF50",
                              },
                          }}
                        />
                      </TableCell>
                      {/* <TableCell
                        align="center"
                        onClick={() => handleAutoSuggestChange(index)}
                      >
                        {autoSuggestStates[index] ? (
                          <StarIcon color="#13D2DC" sx={{ color: "#DC1D13" }} />
                        ) : (
                          <StarBorderIcon />
                        )}
                      </TableCell> */}
                      <TableCell align="middle">
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "0.5rem",
                            gap: "0.5rem",
                          }}
                        >
                          <EditIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleEditClick(data)}
                          />
                          <DeleteIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(data)}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
        <EditSku
          setOpen={setOpen}
          open={open}
          skuEditId={skuEditId}
          skuRowData={skuRowData}
          shapeID = {shapeID}
        />
        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmApproveClick}
          sku_id={skuId}
          type={2}
        />
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterRetailerList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </Stack>
  );
}
