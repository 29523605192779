import { createSlice } from "@reduxjs/toolkit";

const salesmanSlice = createSlice({
  name: "salesman",
  initialState: {
    salesmanList: null,
    salesmanDetails: null,
    salesmanUpdatedDetails: null,
    userStatusList: null,
    areaNameList: null,
  },
  reducers: {
    getSalesmanList: (state, action) => {
      state.salesmanList = action.payload;
    },
    getUserStatusList: (state, action) => {
      state.userStatusList = action.payload;
    },
    getAreaNameList: (state, action) => {
      state.areaNameList = action.payload;
    },
    getSalesmanDetails: (state, action) => {
      state.salesmanDetails = action.payload;
    },
    updateSalesmanDetails: (state, action) => {
      state.salesmanUpdatedDetails = action.payload;
    },
  },
});

export default salesmanSlice.reducer;
export const {
  getSalesmanList,
  getSalesmanDetails,
  updateSalesmanDetails,
  getUserStatusList,
  getAreaNameList,
} = salesmanSlice.actions;
