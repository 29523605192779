import React from "react";
import { Stack, Box, Typography, Card } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect, useState } from "react";
import DeliveryMgtMainMapView from "./DeliveryMgtMapView";
import useDeliveryMapData from "../../hooks/useDeliveryMapData";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { FormGroup } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import Radio from "@mui/material/Radio";
const DeliveryMgtMainContainer = () => {
  const [value, setValue] = useState("1");

  const dropDownData = useSelector((state) => state.app.curretDropdownData);
  const laabhPartners = useSelector((state) => state.adminMap.deliveryPartners);
  const LPDetails = useSelector((state) => state.login.refreshToken?.user_data);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const deliveryMapData = useDeliveryMapData();

  useEffect(() => {
    const param = {
      zone_id: dropDownData?.zone_id,
      partner_id: LPDetails?.partner_id
    };

    deliveryMapData(param);

    // Set up interval to call deliveryMapData every 15 seconds
    const id = setInterval(() => {
      deliveryMapData(param);
    }, 15000);

    // Clear interval on component unmount
    return () => clearInterval(id);
  }, [dropDownData]);

  return (
    <div>
      <Stack
        sx={{
          flexDirection: "row",
          backgroundColor: "#E5E5E5",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "6px",
            justifyContent: "space-between",
            paddingRight: "5px",
            paddingTop: "5px",
            backgroundColor: "#E5E5E5",
            height: "fit-content",
            width: "calc(100vw - 95px)",
          }}
        >
          <Stack sx={{ width: "100%", height: "100%" }}>
            <DeliveryMgtMainMapView/>
          </Stack>
          {/* <Card
            sx={{
              minWidth: "280px",
              width: { xs: "92%", lg: "300px" },
              minHeight: "75vh",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "16px 10px",
              borderRadius: "12px",
              height: { xs: "fit-content", lg: "calc(100vh - 95px)" },
              position: "relative",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  // border: "1px solid",
                  // borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  indicatorColor="transparent"
                  sx={{
                    display: "none",
                    "& .Mui-selected": {
                      backgroundColor: "grey",
                      color: "#ffffff !important",
                      border: "none",
                      borderRadius: "4px",
                      margin: "4px",
                      minHeight: "15px", // Height for the selected tab
                    },
                    "& .MuiTabs-root": {
                      minHeight: "30px", // Height for the entire TabList
                    },
                  }}
                ></TabList>
              </Box>
              <TabPanel sx={{ padding: "4px", height: "100%" }} value="1">
                <FormControl
                  sx={{
                    padding: "0px 10px",
                    minHeight: "26vh",
                    height: "38%",
                    maxHeight: "30vh",
                    overflowY: "scroll",
                  }}
                >
                  {laabhPartners === null ? (
                    <Stack
                      sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#DC1D13"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </Stack>
                  ) : (
                    <FormGroup>
                      {laabhPartners?.partner_list?.map((data, index) => (
                        <FormControlLabel
                          key={data.dist_id}
                          control={
                            <Radio
                            // checked={selectedDistCode?.includes(
                            //   data.partner_id
                            // )}
                            // onChange={() =>
                            //   handleRadioChange(data.partner_id)
                            // }
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "start",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {data.name}{" "}
                              <span
                                style={{
                                  fontWeight: "300",
                                  fontSize: "12px",
                                }}
                              >
                                {data.dist_code}
                              </span>
                            </div>
                          }
                        />
                      ))}
                    </FormGroup>
                  )}
                </FormControl>
              </TabPanel>
            </TabContext>
          </Card> */}
        </Box>
      </Stack>
    </div>
  );
};

export default DeliveryMgtMainContainer;
