import React from "react";
import { Container, Typography, Paper, Stack } from "@mui/material";
import NavBar from "./NavBar";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <div>
      <Stack sx={{ display: "flex" }}>
        <NavBar />
      </Stack>

      <Container maxWidth="lg" style={{ marginTop: "20px" }}>
        <Paper elevation={0} style={{ padding: "20px" }}>
          <Stack>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
            >
              ** Terms and Conditions for Distribution Chain Mobile App “Vitaran
              Laabh”**
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              These Terms and Conditions govern your use of the Distribution
              Chain Mobile App ("Vitaran Laabh") provided by Vitaran India LLP
              ("we," "us," or "our"). By accessing or using the App, you agree
              to comply with these Terms and Conditions. If you do not agree
              with any part of these Terms and Conditions, you may not use the
              App.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              1. Use of the App:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b> 1.1 License:</b> We grant you a limited, non-exclusive,
              non-transferable, revocable license to use the App solely for your
              internal business purposes as a retailer in the distribution
              chain.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b> 1.2 User Accounts: </b>You may be required to create an
              account to access certain features of the App. You are responsible
              for maintaining the confidentiality of your account credentials
              and for all activities that occur under your account.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>1.3 Prohibited Activities: </b>You agree not to engage in any
              of the following activities:
              <br />
              - Violating any applicable laws, regulations, or third-party
              rights.
              <br />
              - Interfering with the security or integrity of the App.
              <br />
              - Uploading, transmitting, or distributing any harmful or
              malicious code.
              <br />
              - Reverse engineering, decompiling, or disassembling the App.
              <br />
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              2. Content and Intellectual Property:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>2.1 Ownership:</b>You agree not to engage in any All content,
              materials, and intellectual property rights in the App are owned
              by us or our licensors. You may not use, reproduce, distribute, or
              modify any content from the App without our prior written consent.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>2.2 User Content: </b>You retain ownership of any content you
              upload, submit, or transmit through the App ("User Content"). By
              posting User Content, you grant us a worldwide, non-exclusive,
              royalty-free license to use, reproduce, modify, adapt, publish,
              and distribute the User Content for the purpose of providing and
              improving the App.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              3. Transactions:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>3.1 Product Orders:</b> The App allows you to place orders for
              products from suppliers within the distribution chain. All product
              orders are subject to availability and acceptance by the supplier.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b> 3.2 Payment:</b> You agree to pay all fees and charges
              associated with your orders in accordance with the payment terms
              specified by the supplier. We may facilitate payment processing on
              behalf of the supplier, but we are not responsible for any payment
              disputes or issues.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              4. Privacy:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b> 4.1 Privacy Policy:</b> Your use of the App is subject to our
              Privacy Policy, which governs the collection, use, and disclosure
              of your personal information. By using the App, you consent to the
              terms of our Privacy Policy.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              5. Limitation of Liability:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>5.1 Disclaimer:</b> THE APP IS PROVIDED ON AN "AS-IS" AND
              "AS-AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER
              EXPRESS OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING BUT NOT
              LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              <b>5.2 Limitation of Liability:</b> TO THE FULLEST EXTENT
              PERMITTED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE FOR ANY
              INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING
              OUT OF OR IN CONNECTION WITH YOUR USE OF THE APP.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              6. Indemnification:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              You agree to indemnify, defend, and hold harmless Vitaran India
              LLP and its affiliates, officers, directors, employees, and agents
              from and against any and all claims, liabilities, damages, losses,
              costs, and expenses arising out of or in connection with your use
              of the App or any violation of these Terms and Conditions.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              7. Governing Law and Dispute Resolution:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of [Your Jurisdiction]. Any dispute
              arising out of or relating to these Terms and Conditions shall be
              resolved exclusively in the courts located in Ahmedabad, India.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              8. Amendments:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              We reserve the right to modify, amend, or update these Terms and
              Conditions at any time without prior notice. Any changes will be
              effective immediately upon posting on the App. Your continued use
              of the App after any modifications indicate your acceptance of the
              changes.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              9. Contact Us:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              If you have any questions or concerns about these Terms and
              Conditions, please contact us at snehal.p@vitarangt.com.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
              marginBottom="20px"
            >
              Effective Date:
            </Typography>
            <Typography
              variant="body1"
              paragraph
              maxWidth="lg"
              textAlign="left"
              marginBottom="20px"
            >
              These Terms and Conditions are effective as of <span style={{color:"red"}}>[Effective Date]</span> and supersedes all previous versions
            </Typography>
          </Stack>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
