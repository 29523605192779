import { createSlice } from "@reduxjs/toolkit";
const seSlice = createSlice({
  name: "SE",
  initialState: {
    SEList: null,
  },
  reducers: {
    getSEList: (state, action) => {
      state.SEList = action.payload;
    },
  },
});

export default seSlice.reducer;
export const { getSEList } = seSlice.actions;
