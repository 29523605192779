import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getAdminMapDetails } from "../slice/adminMapSlice";
import { getGraphDetails } from "../slice/dashboardSlice";

const useGraphData = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const currentDropdownData = useSelector(
    (state) => state.app.curretDropdownData
  );

  const getGraphData = async () => {
    try {
      let param = {
        zone_id: currentDropdownData?.zone_id,
      };
      const response = await Axios.post("/dashboard_graph_details", param);
      const data = response.data;
      dispatch(getGraphDetails(data));
      return data;
    } catch (error) {
      console.error("Error fetching admin map data:", error);
      throw error;
    }
  };

  // useEffect(() => {
  //   getGraphData();
  // }, [currentDropdownData]);

  return getGraphData;
};

export default useGraphData;
