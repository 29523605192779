import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import styled from "styled-components";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: "bottom",
      labels: {
        padding: 10,
        font: {
          size: 12,
        },
      },
    },
    title: {
      display: true,
      text: "Sales Stamp",
      align: 'start', 
      font: {
        size: 16,  
        weight: 'bold'  
      },
      padding: {
        top: 10,
        bottom: 30,
        left: 10
      }
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        autoSkip: false,  
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

export default function BarGraph() {
  const smGraphDetails = useSelector((state) => state.dashboard.graphDetails);
  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  const data = {
    labels: smGraphDetails?.smList || [],
    datasets: [
      {
        label: "Total Sale Amount",
        data: smGraphDetails?.totalSaleCount?.map((item) => item?.total_cost),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "#135ABD",
        barThickness: 20,
      },
    ],
  };

  useEffect(() => {
    const updateChartWidth = () => {
      const labelCount = smGraphDetails?.totalSaleCount?.length || 0;
      const minWidth = 400;
      const barWidth = 30; 
      const newWidth = Math.max(minWidth, labelCount * barWidth);
      setChartWidth(newWidth);
    };

    updateChartWidth();
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, [smGraphDetails]);

  return (
    <div style={{ height: "265px", width: "33%", background: "white", padding: "6px", borderRadius: "6px" }}>
    <div style={{ width: "100%", overflowX: "auto" }}>
      <div style={{ width: `${chartWidth}px`, height: "265px" }}>
        <Bar options={options} data={data} ref={chartRef} />
      </div>
    </div>
  </div>
  );
}