import React from "react";
import { Stack, Typography, Card } from "@mui/material";

const TopContainer = () => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Stack sx={{ display: "flex", flexDirection: "column" }}>
        <Typography sx={{
            color: "#000",
            fonFamily: "Inter",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "36px",
          }}>Good Morning Ramesh</Typography>
        <Typography
          sx={{
            color: "#000",
            fonFamily: "Inter",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "36px",
          }}
        >
          What would you like to do today?
        </Typography>
      </Stack>
      <Stack sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Stack sx={{ background: "#58585A", color: "white", padding: "1rem" }}>
          <Typography>User Management</Typography>
        </Stack>
        <Stack sx={{ background: "#58585A", color: "white", padding: "1rem" }}>
          <Typography>Product Management</Typography>
        </Stack>
        <Stack sx={{ background: "#58585A", color: "white", padding: "1rem" }}>
          <Typography>Route Management</Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default TopContainer;
