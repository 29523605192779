import React, { useEffect, useState } from "react";
import { Stack, Box, Typography, Card, ButtonBase } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useSelector, useDispatch } from "react-redux";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import MapPolygon from "./MapPolygon";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import useAssignPincode from "../../hooks/useAssignPincode";
import { setDistId, setSeIdList, resetSeId, setSMIdList } from "../../slice/assignSEslice";

import { selectAssignSEData } from "../../slice/assignSEslice";
import { selectAssignRoute, setStatus } from "../../slice/SFstatusSlice";
import {
  resetRouteClusterList,
  getRouteClusterList,
} from "../../slice/adminMapSlice";
import Swal from "sweetalert2";
import { getMarkerIcon } from "./MarkerIcon";
import { FormGroup } from "@mui/material";
import Sidebar from "../Sidebar";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./assets/mapStyle.css";
import Switch from "@mui/material/Switch";
import { TailSpin } from "react-loader-spinner";
import useMapData from "../../hooks/useMapData";
import useUpdateSFDetails from "../../hooks/useUpdateSFDetails";
import useBoundryData from "../../hooks/useBoundryData";
import useLaabhPartners from "../../hooks/useLaabhPartners";
import useLaabhDELPartner from "../../hooks/useLaabhDeliveryPart";
import useSalesmanList from "../../hooks/useSalesmanListmap";
import { Link } from "react-router-dom";

const DashboardContent = () => {
  const dispatch = useDispatch();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentRole = localStorage.getItem("data");
  const is_asm = localStorage.getItem("is_asm");

  const [switchState, setSwitchState] = useState(false);
  const [selectedSeUserIds, setSelectedSeUserIds] = useState([]);
  const [selectedDistIds, setSelectedDistIds] = useState([]);
  const [distributersListCount, setDistributersListCount] = useState(0);
  const [sellerListCount, setSellerListCount] = useState(0);
  const [routeSECount, setRouteSECount] = useState(0);
  const [selectedDistCode, setSelectedDistCode] = useState([]);
  const [selectedSMIds, setSelectedSMIds] = useState([]);
  const [selectedSMCode, setSelectedSMCode] = useState([]);
  const [selectedSEs, setSelectedSEs] = useState([]);
  const [value, setValue] = useState("1");
  const [bcIdList, setBcIdList] = useState([]);

  const [showDailyRoute, setShowDailyRoute] = useState(true);
  const [showRouteDate, setShowRouteDate] = useState(false);

  const [saveDB_idIndex, setSaveDB_idIndex] = useState([]);
  const adminMap = useSelector((state) => state.adminMap.adminMapDetails);
  //Laabh
  const polyDetails = useSelector((state) => state.adminMap.getPincodeDetails);
  const assignRouteDetails = useSelector((state) => state.adminMap.getClusterDetails);
  
  const dropDownData = useSelector((state) => state.app.curretDropdownData);
  const assignSEData = useSelector(selectAssignSEData);
  const SFdata = useSelector((state) => state.SFstatus);
  const laabhPartners = useSelector(
    (state) => state.adminMap.laabhPartnersData
  );
  const laabhDelPartners = useSelector(
    (state) => state.adminMap.deliveryPartners
  );
  const salesmanPartners = useSelector((state) => state.adminMap.salesmanData);

  const routeSEList = useSelector((state) => state.adminMap.getSEinShapeFile);
  const assignedBoundryDetails = useSelector(
    (state) => state.adminMap.getAssignedBoundryDetails
  );
  
  //commit
  useEffect(() => {
    dispatch(setSeIdList(selectedSeUserIds));
    dispatch(setDistId(selectedDistIds));
    dispatch(setSMIdList(selectedSMIds));
  }, [selectedSeUserIds, selectedDistIds, assignSEData, selectedSMIds]);

  useEffect(() => {
    if (polyDetails) {
      setBcIdList(polyDetails);
    }
  }, [polyDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Check if laabhDelPartners is available
    if (laabhDelPartners && laabhDelPartners.partner_list) {
      // Extract db_id and index from each partner and update state
      const selectedSEsData = laabhDelPartners.partner_list.map(
        (se, index) => ({
          db_id: se.db_id,
          index: index,
        })
      );

      setSaveDB_idIndex(selectedSEsData);
    }
  }, [laabhDelPartners]);

  useEffect(() => {
    if (polyDetails && polyDetails.assigned_partners) {
      // Extract dist_id from dist_data and update selectedDistCodes
      const distIds = polyDetails?.assigned_partners;
      setSelectedDistCode(distIds);
      setSelectedDistIds(distIds);
    } else {
      setSelectedDistCode([]);
      setSelectedDistIds([]);
    }
    if (polyDetails && polyDetails.assigned_sm) {
      // Extract dist_id from dist_data and update selectedDistCodes
      const SMIds = polyDetails?.assigned_sm;
      setSelectedSMIds(SMIds);
      setSelectedSMCode(SMIds);
    } else {
      setSelectedSMIds([]);
      setSelectedSMCode([]);
    }

    if (polyDetails && polyDetails.assigned_db) {
      // Extract se_id from se_data and merge with existing selectedSEs
      const seIds = polyDetails.assigned_db;

      setSelectedSEs(seIds);
      setSelectedSeUserIds(seIds);
    } else {
      setSelectedSeUserIds([]);
      // If se_data is falsy or empty, set selectedSEs to an empty array
      setSelectedSEs([]);
      dispatch(resetSeId());
    }
  }, [polyDetails]);

  useEffect(() => {
    setShowDailyRoute(true);
    setShowRouteDate(false);
  }, [assignSEData.pincode]);

  useEffect(() => {
    dispatch(setStatus(switchState));
  }, [switchState]);

  useEffect(() => {
    setSwitchState(assignedBoundryDetails?.shape_file_status);
  }, [assignedBoundryDetails]);

  useEffect(() => {
    // Update the state with the count of distributers_list
    setDistributersListCount(laabhPartners?.partner_list.length);
    setSellerListCount(laabhDelPartners?.partner_list.length);
    setRouteSECount(routeSEList?.se_list.length);
  }, [adminMap, routeSEList, laabhPartners, laabhDelPartners]);


  const handleRadioChange = (distId) => {
    if (selectedDistIds.includes(distId)) {
      setSelectedDistIds(prevSelectedDistIds =>
        prevSelectedDistIds.filter(id => id !== distId)
      );
      setSelectedDistCode(prevSelectedDistCode =>
        prevSelectedDistCode.filter(id => id !== distId)
      );
    } else {
      setSelectedDistIds(prevSelectedDistIds => [...prevSelectedDistIds, distId]);
      setSelectedDistCode(prevSelectedDistCode => [...prevSelectedDistCode, distId]);
    }
  };
  
  const handleSalemanChange = (smId) => {
    // if (selectedSMIds.includes(smId)) {
    //   setSelectedSMIds(prevSelectedSMIds =>
    //     prevSelectedSMIds.filter(id => id !== smId)
    //   );
    //   setSelectedSMCode(prevSelectedSMCode =>
    //     prevSelectedSMCode.filter(id => id !== smId)
    //   );
    // } else {
    //   setSelectedSMIds(prevSelectedSMIds => [...prevSelectedSMIds, smId]);
    //   setSelectedSMCode(prevSelectedSMCode => [...prevSelectedSMCode, smId]);
    // }
    setSelectedSMIds([smId]);
    setSelectedSMCode([smId]);
  };
  

  const getLengthByDbId = (db_id) => {
    if (assignRouteDetails && assignRouteDetails.cluster_list) {
      const assignRouteCluster = assignRouteDetails.cluster_list.find(
        (cluster) => cluster.db_id === db_id
      );
      if (assignRouteCluster) {
        return assignRouteCluster.coord_list.length;
      }
    }

    if (bcIdList && bcIdList.cluster_list) {
      const polyCluster = bcIdList.cluster_list.find(
        (cluster) => cluster.db_id === db_id
      );
      if (polyCluster) {
        return polyCluster.coord_list.length;
      }
    }

    return 0;
  };

  const handleCategoryClick = (se) => {
    // If se is already selected, remove it; otherwise, add it
    if (selectedSEs.includes(se)) {
      setSelectedSeUserIds((prevSelectedSeUserIds) =>
        prevSelectedSeUserIds.filter((id) => id !== se)
      );
      setSelectedSEs((prevSelectedSEs) =>
        prevSelectedSEs.filter((id) => id !== se)
      );
    } else {
      // If se is not selected, add it
      setSelectedSeUserIds((prevSelectedSeUserIds) => [
        ...prevSelectedSeUserIds,
        se,
      ]);
      setSelectedSEs((prevSelectedSEs) => [...prevSelectedSEs, se]);
    }
  };


  // const validateAssignSEData = (data) => {
  //   const requiredKeys = ["partner_id_list", "db_id_list"];

  //   for (const key of requiredKeys) {
  //     if (!(key in data)) {
  //       console.error(`Missing required key: ${key}`);
  //       Swal.fire({
  //         title: "Validation Error",
  //         text: `Please provide ${
  //           key === "partner_id_list" ? "Laabh partner" : "Delivery partner"
  //         } `,
  //         icon: "error",
  //         showConfirmButton: true,
  //       });
  //       return false;
  //     }

  //     if (Array.isArray(data[key]) && data[key].length === 0) {
  //       console.error(`${key} is an empty array.`);
  //       Swal.fire({
  //         title: "Validation Error",
  //         text: `Please provide at least one ${
  //           key === "partner_id_list" ? "Laabh partner" : "Delivery partner"
  //         }`,
  //         icon: "error",
  //         showConfirmButton: true,
  //       });
  //       return false;
  //     }

  //     if (data[key] === "" || data[key] === null) {
  //       console.error(`${key} is empty or null.`);
  //       Swal.fire({
  //         title: "Validation Error",
  //         text: `Please provide at least one ${
  //           key === "partner_id_list" ? "Laabh partner" : "Delivery partner"
  //         }`,
  //         icon: "error",
  //         showConfirmButton: true,
  //       });
  //       return false;
  //     }
  //   }

  //   return true; // All properties are filled
  // };
  const getMapData = useMapData();
  const getBoundryData = useBoundryData();
  const getLaabhPartners = useLaabhPartners();
  const getLaabhDelPartners = useLaabhDELPartner();
  const getSalesman = useSalesmanList();
  useEffect(() => {
    const fetchData = async () => {
      if (dropDownData?.zone_id) {
        const param = {
          zone_id: dropDownData.zone_id,
        };
        try {
          const adminMapData = await getMapData(param);
        } catch (error) {
          console.log("Error fetching admin map data:", error);
        }

        // try {
        //   const routeListData = await getBoundryData(param);
        // } catch (error) {
        //   console.log("Error fetching route list data:", error);
        // }

        const apiCalls = [
          getLaabhPartners(param),
          getLaabhDelPartners(param),
          getSalesman(param),
        ];
  
        try {
          const [mapData, boundryData, laabhPartnersData, laabhDelPartnersData] =
            await Promise.all(apiCalls);
        } catch (error) {
          console.error("Error in onZoneChange:", error);
          // Handle errors if needed
        }
      } else {
        console.log("No matching zone found for zone_name:");
      }
    };

    fetchData();
  }, [dropDownData]);

  const setAssignPincode = useAssignPincode();
  const getallBoundryData = useBoundryData();

  const assignSE = async () => {
    try {
      // Validate assignSEData before making the API call
      // if (!validateAssignSEData(assignSEData)) {
      //   return;
      // }

      const data = await setAssignPincode(assignSEData);
      if (data.code === 200) {
        dispatch(resetRouteClusterList());
        dispatch(getRouteClusterList(data));
        setBcIdList([]);
        Swal.fire({
          title: "Success",
          text: `${data.message}`,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        const param = {
          zone_id: dropDownData.zone_id,
        };
        getallBoundryData(param);
        // setSelectedSeUserIds([]);
      } else {
        Swal.fire({
          title: "Failed",
          text: `${data.message}`,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error("Error in onZoneChange:", error);
    }
  };

  const updateBoundry = useUpdateSFDetails();
  
  const handleSwitchChange = async (e) => {
    const isChecked = e.target.checked;

    
    const param = {
      zone_id: SFdata.zone_id,
      pincode: SFdata.pincode,
      shape_id: SFdata.shape_id,
      status: isChecked,
    };
    const data = await updateBoundry(param);
    if (data.code === 200) {
      setSwitchState(isChecked);
      Swal.fire({
        title: "Success",
        text: "Shape File updated successfully",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      const param = {
        zone_id: dropDownData.zone_id,
      };
      const data = getBoundryData(param);
    } else {
      Swal.fire({
        title: "Failed",
        text: data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <div>
      {/* {adminMap === null ? (
        <div>
          <Box sx={{ width: "100%", position:"absolute", top:"38px"}}>
            <LinearProgress
              // sx={{ color: "#DC1D13", backgroundColor: "#DC1D13" }}
            />
          </Box>
        </div>
      ) : (
        <></>
      )} */}
      <Stack
        sx={{
          flexDirection: "row",
          backgroundColor: "#E5E5E5",
          width: "100vw",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        <Sidebar />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "6px",
            justifyContent: "space-between",
            // paddingLeft: "10px",
            paddingRight: "5px",
            paddingTop: "5px",
            backgroundColor: "#E5E5E5",
            height: "fit-content",
            width: "100%",
          }}
        >
          <Stack sx={{ width: "100%", height: "100%" }}>
            <MapPolygon
              retailerCord={adminMap?.retailers_list}
              pincodeCord={adminMap?.pincodes}
              saveDB_idIndex={saveDB_idIndex}
              showDailyRoute={showDailyRoute}
            />
          </Stack>
          <Card
            sx={{
              minWidth: "280px",
              width: { xs: "92%", lg: "300px" },
              minHeight: "75vh",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              padding: "16px 10px",
              borderRadius: "12px",
              height: { xs: "fit-content", lg: "calc(100vh - 95px)" },
              position: "relative",
            }}
          >
            <TabContext value={value}>
              <Box
                sx={{
                  // border: "1px solid",
                  // borderColor: "divider",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "5px",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  indicatorColor="transparent"
                  sx={{
                    display: "none",
                    "& .Mui-selected": {
                      backgroundColor: "grey",
                      color: "#ffffff !important",
                      border: "none",
                      borderRadius: "4px",
                      margin: "4px",
                      minHeight: "15px", // Height for the selected tab
                    },
                    "& .MuiTabs-root": {
                      minHeight: "30px", // Height for the entire TabList
                    },
                  }}
                >
                  {/* <Tab
                    variant="soft"
                    label="Assign"
                    value="1"
                    sx={{
                      height: "20px",
                      minHeight: "30px !important",
                      margin: "4px",
                    }}
                  /> */}
                  {/* <Tab
                    label="Routes"
                    value="2"
                    sx={{
                      minHeight: "30px !important",
                      height: "20px !important",
                      margin: "4px",
                    }}
                  /> */}
                </TabList>
              </Box>
              <TabPanel sx={{ padding: "4px", height: "100%" }} value="1">
                <Stack
                  sx={{
                    height: "90%",
                    overflowY: "scroll",
                    /* Hide scrollbar for Firefox */
                    scrollbarWidth: "none",
                    /* Hide scrollbar for other browsers */
                    "::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Stack>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "0.8rem",
                      }}
                    >
                      {assignedBoundryDetails ? (
                        <Typography variant="P" color="initial">
                          {assignedBoundryDetails?.office_name} (
                          {assignedBoundryDetails?.pincode})
                        </Typography>
                      ) : (
                        <Typography variant="P" color="initial"></Typography>
                      )}
                      <Switch
                        checked={switchState}
                        onChange={handleSwitchChange}
                        sx={{
                          "& .MuiSwitch-thumb": {
                            backgroundColor: switchState
                              ? "#4CAF50"
                              : undefined,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: " #4CAF50",
                            },
                        }}
                      />
                    </Stack>
                    {currentRole === "admin" ? (
                      <div>
                        <Stack
                          sx={{
                            display: "flex",
                            gap: "18px",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            marginBottom: "6px",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "18px", fontWeight: "600" }}
                          >
                            Laabh Partner
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%", // Use 50% for a perfect circle
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {distributersListCount}
                            </span>
                          </div>
                        </Stack>

                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "26vh",
                            height: "38%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                            width: "-webkit-fill-available",
                          }}
                        >
                          {laabhPartners === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#DC1D13"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {laabhPartners?.partner_list?.map(
                                (data, index) => (
                                  <FormControlLabel
                                    key={data.dist_id}
                                    control={
                                      <Checkbox
                                        checked={selectedDistCode?.includes(
                                          data.partner_id
                                        )}
                                        onChange={() =>
                                          handleRadioChange(data.partner_id)
                                        }
                                      />
                                    }
                                    label={
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          textAlign: "start",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {data.name}{" "}
                                        <span
                                          style={{
                                            fontWeight: "300",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {data.dist_code}
                                        </span>
                                      </div>
                                    }
                                  />
                                )
                              )}
                            </FormGroup>
                          )}
                        </FormControl>
                      </div>
                    ) : null}
                  </Stack>
                  {(currentRole === "admin" || currentRole === "partner") && (
                    <>
                      <Divider
                        variant="middle"
                        sx={{
                          paddingTop: "16px",
                          borderBottom: "2px Solid black",
                        }}
                      />

                      <Stack
                        sx={{
                          height: currentRole === "partner" ? "100%" : "45%",
                        }}
                      >
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            gap: "18px",
                            paddingTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "600", fontSize: "18px" }}
                          >
                            Laabh Delivery partner
                          </Typography>
                          <div
                            style={{
                              backgroundColor: "rgba(0, 0, 0, 0.60)",
                              borderRadius: "50%", // Use 50% for a perfect circle
                              width: "24px",
                              height: "24px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color: "#fff",
                              }}
                            >
                              {sellerListCount}
                            </span>
                          </div>
                        </Stack>

                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "27vh",
                            height: currentRole === "partner" ? "100%" : "35%",
                            maxHeight:
                              currentRole === "partner"
                                ? "fit-content"
                                : "30vh",
                            overflowY: "scroll",
                            marginBottom: "50px",
                          }}
                        >
                          {laabhDelPartners === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#DC1D13"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {laabhDelPartners?.partner_list?.map(
                                (se, index) => (
                                  <FormControlLabel
                                    key={se.user_id}
                                    control={
                                      <Checkbox
                                        onChange={() =>
                                          handleCategoryClick(se.db_id)
                                        }
                                        checked={selectedSEs?.includes(
                                          se.db_id
                                        )}
                                      />
                                    }
                                    label={
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "5px",
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "24px",
                                            height: "24px",
                                            alignSelf: "center",
                                          }}
                                          src={getMarkerIcon(index)}
                                        />
                                        <Stack
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {se.name} (
                                            {getLengthByDbId(se.db_id)}){" "}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                )
                              )}
                            </FormGroup>
                          )}
                        </FormControl>
                      </Stack>
                    </>
                  )}

                  <Divider
                    variant="middle"
                    sx={{ paddingTop: "16px", borderBottom: "2px Solid black" }}
                  />
                  {(is_asm || currentRole === "admin") &&
                    currentRole !== "partner" && (
                      <Stack sx={{ height: is_asm === "true" ? "100%" : "45%" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            gap: "18px",
                            paddingTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "600", fontSize: "18px" }}
                          >
                            Salesman partner
                          </Typography>
                          <Link to="/home/routepreview">
                            <Typography
                              sx={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              Preview
                            </Typography>
                          </Link>
                        </Stack>

                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "27vh",
                            // height: "35%",
                            // maxHeight: "30vh",
                            height: is_asm === "true" ? "100%" : "35%",
                            maxHeight: is_asm === "true" ? "fit-content" : "30vh",
                            overflowY: "scroll",
                            marginBottom: "50px",
                          }}
                        >
                          {salesmanPartners === null ? (
                            <Stack
                              sx={{
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#DC1D13"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                              />
                            </Stack>
                          ) : (
                            <FormGroup>
                              {salesmanPartners?.partner_list?.map(
                                (se, index) => (
                                  <FormControlLabel
                                    key={se.user_id}
                                    control={
                                      <Radio
                                        checked={selectedSMCode?.includes(
                                          se.retailer_id
                                        )}
                                        onChange={() =>
                                          handleSalemanChange(se.retailer_id)
                                        }
                                      />
                                    }
                                    label={
                                      <Stack
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "5px",
                                        }}
                                      >
                                        <Stack
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Typography sx={{ fontSize: "14px" }}>
                                            {se.name}{" "}
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    }
                                  />
                                )
                              )}
                            </FormGroup>
                          )}
                        </FormControl>
                      </Stack>
                    )}
                </Stack>
                <Button
                  className="submit button"
                  variant="contained"
                  onClick={assignSE}
                  sx={{
                    backgroundColor: "#DC1D13",
                    ":hover": {
                      backgroundColor: "#DC1D13", // Change the color for the hover effect
                    },
                    position: "absolute",
                    bottom: "16px",
                    width: { xs: "90%", sm: "95%", lg: "90%" },
                    left: "16px",
                  }}
                >
                  Assign
                </Button>
              </TabPanel>

              {/* <TabPanel value="2" sx={{ padding: "4px", height: "100%" }}>
                {routeSEList === null ? (
                  <Typography
                    sx={{
                      height: "100%",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Please Select a Pincode to Create Routes
                  </Typography>
                ) : (
                  <div>
                    <Stack sx={{ gap: "12px" }}>
                      <Stack sx={{ height: "45%" }}>
                        <Stack
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            paddingLeft: "0.8rem",
                            flexDirection: "row",
                            gap: "18px",
                            paddingTop: "16px",
                            marginBottom: "8px",
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "600", fontSize: "18px" }}
                          >
                            Sales Executive
                          </Typography>
                          
                        </Stack>

                        <FormControl
                          sx={{
                            padding: "0px 10px",
                            minHeight: "12vh",
                            height: "35%",
                            maxHeight: "30vh",
                            overflowY: "scroll",
                          }}
                        >
                          <FormGroup>
                            {routeSEList?.se_list?.map((se, index) => (
                              <FormControlLabel
                                key={se.user_id}
                                control={
                                  <Checkbox
                                    onChange={() =>
                                      handleRouteSEClick(se.user_id)
                                    }
                                    checked={SelectedRouteSE?.includes(
                                      se.user_id
                                    )}
                                  />
                                }
                                label={
                                  <Stack
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "5px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        alignSelf: "center",
                                      }}
                                      src={getMarkerIcon(se?.zone_id)}
                                    />
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontSize: "15px" }}
                                      >{`${se?.full_name} `}</Typography>
                                    </Stack>
                                  </Stack>
                                }
                              />
                            ))}
                          </FormGroup>
                        </FormControl>
                      </Stack>

                      <Stack
                        sx={{
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="p" color="initial">
                          Weekly Frequency
                        </Typography>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Frequency
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={SelectedFreq}
                            label="Age"
                            onChange={handleChnageFreq}
                          >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                      <Button
                        className="submit button"
                        variant="outlined"
                        onClick={generateRoute}
                        sx={{
                          borderColor: "#000000",
                          color: "#000000",
                          textTransform: "none",
                          ":hover": { borderColor: "#000000" },
                        }}
                      >
                        {assignedBoundryRoutes?.route_cluster_list?.length > 0
                          ? "Regenerate Route"
                          : "Generate Route"}
                      </Button>
                      {showReqData ? (
                        <Stack>
                          <p style={{margin:"4px"}}>
                            Your already have a Request on{" "}
                            <span>{assignedBoundryRoutes?.previous_request_date}</span>
                          </p>
                          <Button
                            sx={{textTransform:"none"}}
                            onClick={getReqRoute}
                          >
                            View
                          </Button>
                        </Stack>
                      ) : (
                        <></>
                      )}
                      {showRouteDate ? (
                        <Typography
                          sx={{ marginBottom: "50px" }}
                          variant="p"
                          color="initial"
                        >
                          Your New Route will be deployed on{" "}
                          {reDeplyInfo?.formattedDate} ({reDeplyInfo?.nextDay})
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Stack>
                    {showDeployButton ? (
                      assignedBoundryRoutes?.route_cluster_list?.length > 0 ? (
                        <Button
                          className="submit button"
                          variant="contained"
                          onClick={RedeployRoute}
                          sx={{
                            backgroundColor: "#DC1D13",
                            textTransform: "none",
                            ":hover": {
                              backgroundColor: "#fa731e",
                            },
                            position: "absolute",
                            bottom: "16px",
                            width: { xs: "90%", sm: "95%", lg: "90%" },
                            left: "16px",
                          }}
                        >
                          ReDeploy
                        </Button>
                      ) : (
                        <Button
                          className="submit button"
                          variant="contained"
                          onClick={deployRoute}
                          sx={{
                            backgroundColor: "#DC1D13",
                            textTransform: "none",
                            ":hover": {
                              backgroundColor: "#fa731e",
                            },
                            position: "absolute",
                            bottom: "16px",
                            width: { xs: "90%", sm: "95%", lg: "90%" },
                            left: "16px",
                          }}
                        >
                          Deploy
                        </Button>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </TabPanel> */}
            </TabContext>
          </Card>
        </Box>
      </Stack>
    </div>
  );
};

export default DashboardContent;
