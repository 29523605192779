
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useVerifyRestPassword = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const verifyRestPassword = async (param) => {
    const response = await Axios.post("verify_reset_password", param);
    const data = await response.data;     
    if (data.code === 200) {
      toast.success("Password reset successfully");
    } else {
      toast.error(data.message);
    }
    return data;   
  };
  return verifyRestPassword;
};

export default useVerifyRestPassword;
