import { StylesContext } from '@material-ui/styles'
import React, { useState, useEffect } from 'react';
import Styles from './styles/salesmidcontainer.module.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SalesTable from './SalesTable';
import { useSelector } from 'react-redux'

const RedButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#DC1D13",
    '&:hover': {
        backgroundColor: "#DC1D13",
    },
}));


const useStyles = makeStyles({
    text: {
        width: "290px"
    }
});

const SalesMidContainer = ({ IdChange, Submit,Statevalue,zone }) => {
    const classes = useStyles()
    const [route, setRoute] = useState("All");
    const [routeList, setRouteList] = useState([])
    const state = useSelector(state => state.SalesMgt);
    const [change, setChange] = useState(false)
    const [Product, setProduct] = useState('All');
    const [ProductList, setProductList] = useState([]);

    const [retailerText,setRetailerText] = useState('')



    React.useEffect(() => {
        if (state && state !== null && state !== "undefined") {
            if (state.routeList && state.routeList.length !== 0 && state.routeList !== "undefined" && state.routeList !== null) {
                let r = [];
                r.push({ route_code: "All", route_id: "" });
                for (let i = 0; i < state.routeList.length; i++) {
                    r.push(state.routeList[i])
                }
                setRouteList(r);

                // setRoute(state.routeList[0].route_code);
                // setRouteId(state.routeList[0].route_id)

            }
            if (state.productList && state.productList.length !== 0 && state.productList !== "undefined" && state.productList !== null) {

                let r = [];
                r.push({ product_name: "All", product_id: "" });
                for (let i = 0; i < state.productList.length; i++) {
                    r.push(state.productList[i])
                }

                setProductList(r);
                // setProduct(state.productList[0].product_name);
                // setProductId(state.productList[0].product_id)
            }
        }
    }, [state])










    return (
        <div className={Styles.SalesMidContainer}>

            <div className={Styles.SalesRouteContainer}>
            <div>
            <div className={Styles.SalesRouteList}>
                    <label>Search</label>
                    <TextField placeholder="Name..." size="small" value={retailerText} onChange={(e)=>setRetailerText(e.target.value)}/>
                </div>
            </div>
            </div>
            

            <div>
                <SalesTable change={change} retailerText={retailerText} Statevalue={Statevalue} zone={zone} Product={Product} route={route}/>
            </div>

        </div>
    )
}

export default SalesMidContainer


