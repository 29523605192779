import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useDeleteCategory = (categoryId) => {
  const Axios = useAxiosPrivate();
  const deleteCategory = async () => {
    let param = { category_id: categoryId };
    const response = await Axios.post("/delete_category", param);
    const data = await response.data;
    if (data.code === 200) {
      toast.success("Deleted SuccessFully");
    } else {
      toast.error("Failed to Delete");
    }

    // console.log(data);
  };
  return deleteCategory;
};

export default useDeleteCategory;
