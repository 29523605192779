import  React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getZoneList } from "../slice/appSlice";
import useZoneList from "../hooks/useZoneList";
import useStateList from "../hooks/useStateList";
import useDistributorList from "../hooks/useDistributorList";

import AddLaabhPartner from "../component/UserManagement.js/AddLaabhPartner";
import useAddLaabhPartner from "../hooks/useAddLaabhPartner";
import useRefreshLaabhPartner from "../hooks/useRefreshLaabhPartner";
import useLaabhPartner from "../hooks/useLaabhPartners";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal( {getAllDistributorList}) {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  
  const postAddLaabhPartner = useAddLaabhPartner();
  const getmapLaabhPartnerList = useLaabhPartner(); 
  const handleAddDeliveryBoy = async(formData) => {
    
    await postAddLaabhPartner(formData);
    await getAllDistributorList();
    const param = {
      zone_id: formData.zone_id
    }
    await getmapLaabhPartnerList(param);
  };


  return (
    <div>
      <Button onClick={() => setIsDialogOpen(true)} sx={{ color: "white", textTransform:"none" }}>
        Add
      </Button>
      <AddLaabhPartner open={isDialogOpen} onClose={() => setIsDialogOpen(false)} onSubmit={handleAddDeliveryBoy} />
    </div>
  );
}
