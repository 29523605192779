import React, { useState } from "react";
import styles from "./styles/UserMgtMidContainerTwo.module.css";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserMgtTable from "./UserMgtTable";
import { HashLink as Link } from "react-router-hash-link";
// import { GetProductListRetailer, GetUserMgtRetailerList } from '../../../actions/UserMgt/UserMgt';
import { useSelector, useDispatch } from "react-redux";
import UserRetailerMgtTable from "./UserMgtRetailerTable";
import TextField from "@mui/material/TextField";
import exportFromJSON from "export-from-json";
import { useEffect } from "react";
import { USERMGTPRODUCTLIST } from "../constants/UserMgt/UserMgt";
import { getCategoryList } from "../../slice/categorySlice";
import useCategoryList from "../../hooks/useCategoryList";
import useRetailerList from "../../hooks/useRetailerList";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const UserMgtMidContainerTwo = ({
  setTsiZone,
  setRouteId,
  setCatId,
  setProductId,
  Statevalue,
}) => {
  // const stateList = useSelector(state => state.Zone.StateList);
  // const StateID = stateList.filter(state => state.state_name === Statevalue)
  const dispatch = useDispatch();
  const UserMgt = useSelector((state) => state.UserMgt);
  // const [zonelist, setZonelist] = React.useState([]);
  const Firsttoken = useSelector((state) => state.Token);
  const [Role, setRole] = React.useState("");
  const [Status, setStatus] = React.useState("");
  const [token, setToken] = useState("");
  // const list = useSelector(state => state.Zone.list);
  const [q, setQ] = useState("");

  const [route, setRoute] = useState("All");
  const [category, setCategory] = useState("All");
  const [RouteList, setRouteList] = useState([]);
  const [searchColumn, setSearchColumn] = useState([
    "retailer_id",
    "name",
    "surname",
    "shop_name",
    "mobile_no",
  ]);
  // const rows = useSelector(state => state.UserMgt.retailerlist);
  const [categoryList, setCategoryList] = useState([]);
  const [salesCategory, setSalesCategory] = useState("All");
  const [productList, setProductList] = useState(["All"]);
  const [product, setProduct] = useState("All");
  const [selectedShapeId, setSelectedShapeId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [selectedAreaName, setSelectedAreaName] = useState("ALL");
  
  useCategoryList();
  // useRetailerList(selectedStatus, selectedAreaName);

  const retailerAllList = useSelector((store) => store.retailer.retailerList);
  const areaNameList = useSelector((store) => store.retailer.areaNameList);
  const userStatusList = useSelector((store) => store.retailer.userStatusList);

  React.useEffect(() => {
    if (UserMgt?.routeList) {
      let r = [];
      r.push({ route_code: "All", route_id: "" });
      for (let i = 0; i < UserMgt.routeList.length; i++) {
        r.push(UserMgt.routeList[i]);
      }
      setRouteList(r);
    }
    if (UserMgt?.categoryList) {
      let r = [];
      r.push({ rc_name: "All", rc_id: "" });
      for (let i = 0; i < UserMgt.categoryList.length; i++) {
        r.push(UserMgt.categoryList[i]);
      }
      setCategoryList(r);
    }
  }, [UserMgt]);

  React.useEffect(() => {
    if (
      Firsttoken &&
      Firsttoken.token &&
      Firsttoken.token !== null &&
      Firsttoken.token.length !== 0 &&
      Firsttoken !== "undefined"
    ) {
      setToken(Firsttoken.token);
    }
  }, [Firsttoken]);

  // const handleStatusChange = (event) => {
  //   setStatus(event.target.value);
  // };

  // const handleZoneChange = (event) => {
  //     setTsiZone(event.target.value);
  // };

  const Searchfilter = (datas) => {
    return datas?.filter((row) =>
      searchColumn?.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column]?.length !== 0
          ? row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const fileName = "Retailers";
  const exportType = "csv";
  // const handleDownload = () => {
  //     let data = rows

  //     exportFromJSON({ data, fileName, exportType })

  // }

  const SalesArr = ["All", "No Visit", "No Sale", "1 Sale", "1+ Sale"];

  const handleSalesChange = (e) => {
    setSalesCategory(e.target.value);
  };
  const handleProductChange = (e) => {
    // console.log(e.target.value, "handleProductChange");
    const ProductId = productList?.filter(
      (product) => product.product_name === e.target.value
    );
    setProduct(e.target.value);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);

  };
  const [filterRetailerList, setFilterRetailerList] = useState(retailerAllList);

  // useEffect(() => {
  //   setSelectedShapeId(retailerAllList?.[0]?.shape_id);
  // }, [retailerAllList]);

  useEffect(() => {
    const filteredData = retailerAllList?.filter((retailer) => {
      const fullName =
        `${retailer?.first_name} ${retailer?.last_name}`.toLowerCase();
      return (
        fullName.includes(query?.toLowerCase()) ||
        retailer?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.shop_name?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.address?.toLowerCase().includes(query?.toLowerCase()) ||
        retailer?.area_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterRetailerList(filteredData);
  }, [query, retailerAllList]);

  const handleStatusChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedAreaName("ALL");
    } else if (selectedStatus === "ALL") {
      setSelectedAreaName("");
    }
    setSelectedStatus(e.target.value);
  };

  const handleAreaNameChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedStatus("ALL");
    } else if (selectedAreaName === "ALL") {
      setSelectedStatus("");
    }
    setSelectedAreaName(e.target.value);
  };

  

  return (
    <div className={styles.MidContainer}>
      <div className={styles.MidContainerOne}>
        <div className={styles.MidSubContainerOne}>
          <div className={styles.MidSubContainerOneList}>
            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                style={{ height: "1.8rem", marginTop: "-0.2rem" }}
                size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
              />
            </div>

            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <div className={styles.MidSubContainerOneList}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="shape-id-status-label"
                      id="shape-id-status-select"
                      value={selectedStatus}
                      size="small"
                      sx={{ height: "1.8rem" }}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      {userStatusList?.map((status) => (
                        <MenuItem
                          key={status.user_status}
                          value={status.user_status}
                        >
                          {status.user_status?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <label className={styles.MidSubContainerOneLabel}>Area Name</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedAreaName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleAreaNameChange(e)}
                  >
                    {areaNameList?.map((areaName) => (
                      <MenuItem
                        key={areaName?.area_name}
                        value={areaName?.area_name}
                      >
                        {areaName?.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className={styles.MidSubContainerOneList}>
              {/* <Button style={{ backgroundColor: "#00b300", color: "white",marginTop:"-4px" }} onClick={handleDownload}>Export</Button> */}
            </div>
          </div>

          {/* <div className={styles.MidSubContainerOneList}>
                        <label className={styles.Title}>{rows.length}</label> <label className={styles.label}>Retailers</label>
                    </div> */}
        </div>
      </div>
      <div className={styles.MidContainerTwo}>
        <UserRetailerMgtTable
          filterList={retailerAllList}
          Searchfilter={Searchfilter}
          salesCategory={salesCategory}
          selectedStatus = {selectedStatus}
          selectedAreaName ={selectedAreaName}
          query={query}
        />
      </div>
    </div>
  );
};

export default UserMgtMidContainerTwo;
