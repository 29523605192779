import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import styles from "./styles/UserMgtRetailerTableTwo.module.css";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import useUserList from "../../hooks/useUserList";
import { getUserDetails } from "../../slice/userSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#DC1D13",
    color: "white",
    border: "2px solid #DC1D13",
  },
  border: "2px solid #DC1D13",
  height: "30px",
  fontSize: "12px",
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});

const UserMgtTable = ({ filterlist, detail, setUserDetail, query }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    setRowsPerPage(10);
    setPage(0);
  }, [query]);

  // useUserList();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "Shape Id", label: "Area Name | Pincode", minWidth: 130 },
    { id: "dbcode", label: "Db Code", minWidth: 100 },
    { id: "name", label: "First Name", minWidth: 100 },
    { id: "surname", label: "Last Name", minWidth: 100 },
    { id: "mobile", label: "Mobile No", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleDetails = (row) => {
    dispatch(getUserDetails(row));
    document
      .getElementById("AsmUserUpdate")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setUserDetail(row);
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <TableContainer sx={{ maxHeight: 700, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            className={styles.head}
            sx={{ backgroundColor: "#c4c4c4" }}
          >
            <TableRow
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="center"
                  // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterlist && filterlist?.length !== 0 ? (
              <>
                {filterlist
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const areaNames = row?.shape_details
                        ?.map((shape) => shape?.area_name)
                        .slice(0, 2) // Take only the first 2 elements
                        .join(", ");
                        const areaNamesTitle = row?.shape_details
                        ?.map((shape) => shape?.area_name)
                        .join(", ");
                    const displayAreaNames = areaNames?.trim()?.length === 0 || !/[^\s,]/.test(areaNames) ? "N/A" : areaNames;
                    return (
                      <TableRow hover role="checkbox" key={index}>
                        <>
                          <TableCell align="center" title={areaNamesTitle}>
                            {displayAreaNames ?? "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {row?.db_code?.toUpperCase() ?? "N/A"}
                          </TableCell>
                          <TableCell align="center">{row.first_name}</TableCell>
                          <TableCell align="center">{row.last_name}</TableCell>
                          <TableCell align="center">{row.mobile_no}</TableCell>
                          <TableCell align="center">
                            {row?.user_status}
                          </TableCell>
                          <TableCell align="center">
                            <RedButton
                              variant="outlined"
                              onClick={() => handleDetails(row)}
                            >
                              Details
                            </RedButton>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell align="center">No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterlist && filterlist.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserMgtTable;
