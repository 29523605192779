import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { BaseImgUrl } from "../utility/BaseURL";
const useAddCategory = (form) => {
  // console.log(form);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const categoryFormData = useSelector(
    (store) => store.category.categoryFormData
  );
  // console.log(categoryFormData?.name)
  const viewImgPath = `${BaseImgUrl}/category`;
  const addCategory = async () => {
    let param = {
      category_name: form?.name,
      hindi_name:form?.hindi_name,
      gujarati_name: form?.gujarati_name,
      category_image: categoryFormData?.full_path,
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("/add_category", param);
    const data = await response.data;
    console.log(data, "1111");
    // console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success(data.body);
    } else {
      toast.error(data.body);
    }

    // console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return addCategory;
};

export default useAddCategory;
