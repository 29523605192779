import React from "react";
import DashboardHeader from "../Dashboard/AdminHeader";
import DashboardContent from "../Dashboard/DashboardContent";
import {Stack} from "@mui/material"
import Sidebar from "../Sidebar";
import BasicGiftTabs from "./GiftTab";

const GiftSkuCategoryPage = () => {
    // useCategoryImageUpload()
  return (
    <Stack sx={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
      <Stack>
      {/* <DashboardHeader /> */}
      </Stack>
      <Stack sx={{display:"flex",flexDirection:"row",gap:"1rem"}}>
        <Sidebar/>
        <BasicGiftTabs />
      </Stack>
    </Stack>
  );
};

export default GiftSkuCategoryPage;
