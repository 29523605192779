
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import {LocalizationProvider} from '@mui/x-date-pickers';
// import DatePicker from '@mui/lab/DatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import Styles from './styles/sales.module.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const useStyles = makeStyles({
    date: {
        width: 180,
        height: "10px"
    }
});


const SalesTopContainer = ({ selectedStartDate, handleStartDateChange, selectedEndDate, handleEndDateChange }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(new Date());

    const [startDate, setStartDate] = React.useState(new Date());

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={Styles.DateContainer}>
                
                <div className={Styles.DateList}>
                    <label className={Styles.DateText}>From Date</label>
                    <div style={{zIndex:"999"}}>
                    <DatePicker selected={selectedStartDate} onChange={(date) => handleStartDateChange(date) } dateFormat="dd/MM/yyyy" />
                    </div>
                </div>

                <div className={Styles.DateList}>
                    <label className={Styles.DateText}>To Date</label>
                
                        
                         <div style={{zIndex:"999"}}>
                    <DatePicker selected={selectedEndDate} onChange={(date) => handleEndDateChange(date) } dateFormat="dd/MM/yyyy" />
                    
                    </div>
                </div>

            </div>
         </LocalizationProvider>
    )
}

export default SalesTopContainer
