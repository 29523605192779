import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch,useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateDistributor = (form) => {
  
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const distributorId=useSelector((store)=>store.distributor.distributorDetails)
  const updateDistributorDetails = async () => {
    
    let param = {
      name:form?.name,
      user_status:form?.user_status,
      partner_id: form?.partner_id

    };
    
    const response = await Axios.post("update_laabh_partner", param);
    const data = await response.data;
    
    console.log(data,"parameter")
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateDistributorDetails;
};

export default useUpdateDistributor;
