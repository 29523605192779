import "./App.css";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import "./index.css";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import PersistLogin from "./utility/PersistLogin";
import ProductMgt from "./component/ProductManagement/index.js";
import ZoneMgt from "./component/ZoneManagement/index";
import { useSelector } from "react-redux";
import Dashboard from "./component/Dashboard/Dashboard.js";
import GoogleMapSaathi from "./component/MapView/GoogleMapSaathiGrid.js";
import SkuCategoryPage from "./component/SkuCategoryPage.js";
import UserManagementPage from "./component/UserManagement.js/UserManagementPage.js";
import ApprovalManagementPage from "./component/ApprovalManagement/ApprovalManagementPage.js";
import MainDashboard from "./component/MainDashboard/MainDashboard.js";
import UnauthorizedPage from "./UnauthorizedPage.js";
import MissingRoute from "./utility/MissingRoute";
import Login from "./component/Login/index.js";
import CoinManagement from "./component/CoinManagement/index";
import GiftSkuCategoryPage from "./component/Gift/GiftSkuCategoryPage.js";
import DashboardHeader from "./component/Dashboard/AdminHeader";
import SalesMgt from "./component/SalesManagement/index.js";
import DeliveryMgt from "./component/DeliveryManagement/index";
import LandingPage from "./component/HomePage/LandingPage";
import ContactUs from "./component/HomePage/ContactUs";
import VitranPartnerForm from "./component/HomePage/VitranPartnerForm";
import TermsAndConditions from "./component/HomePage/TermsandConditon";
import PrivacyPolicy from "./component/HomePage/PrivacyPolicy";
import AddApkForm from "./component/ApkVersionManagement/index";
import DashboardMgt from "./component/DashboardMgt/index";
import ASMDashboard from "./component/ASMDashboard/index";
import DashboardPreview from "./component/DashboardPreview/DashboardPreview.js";
function App() {
  const THEME = createTheme({
    typography: {
      fontFamily: `'Roboto', sans-serif"`,
    },
  });

  const loginData = useSelector((store) => store.login.loginData);

  const currentRole = localStorage.getItem("data");
  const is_asm = localStorage.getItem("is_asm");
  return (
    <ThemeProvider theme={THEME}>
      <div className="App">
        <Router basename="/">
          <Routes>
            <Route path="*" element={<MissingRoute />} />
            <Route path="/" element={<LandingPage />} />
            <Route
              exact
              path="/vitaranpartner"
              element={<VitranPartnerForm />}
            />
            <Route exact path="/contact" element={<ContactUs />} />
            <Route
              exact
              path="/termsandconditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route exact path="/login" element={<Login />} />

            <Route path="/home" element={<DashboardHeader />}>
              <Route index element={<UserManagementPage />} />
              <Route path="/home/Productmgt" element={<ProductMgt />} />
              <Route path="/home/dashboard" element={<DashboardMgt />} />
              <Route path="/home/salesmgt" element={<SalesMgt />} />
              <Route
                path="/home/zonemgt"
                element={
                  currentRole === "admin" ? <ZoneMgt /> : <UnauthorizedPage />
                }
              />
              <Route
                path="/home/approval"
                element={<ApprovalManagementPage />}
              />
              <Route
                path="/home/routemgt"
                element={
                  
                    <Dashboard />
                  
                }
              />

              <Route
                path="/home/routepreview"
                element={
                  currentRole === "admin" || is_asm ? (
                    <DashboardPreview />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />

              <Route path="/home/category" element={<SkuCategoryPage />} />
              <Route
                path="/home/giftCategory"
                element={<GiftSkuCategoryPage />}
              />
              <Route
                path="/home/coinmgt"
                element={
                  currentRole === "admin" ? (
                    <CoinManagement />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
              <Route path="/home/salesmgt" element={<SalesMgt />} />
              <Route path="/home/deliverymgt" element={<DeliveryMgt />} />
              <Route path="/home/addApk" element={<AddApkForm />} />
              <Route path="/home/asmdashboard" element={<ASMDashboard />} />
            </Route>

            <Route exact path="/dashboard" element={<MainDashboard />} />
            <Route exact path="/unauthorized" element={<UnauthorizedPage />} />
            <Route element={<PersistLogin />}></Route>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
