import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreaNameList,
  getDistributorList,
  getStateList,
  getUserStatusList,
  getZoneList,
} from "../slice/distributorSlice";

const useDistributorList = (
  selectedStatus,
  selectedStateId,
  selectedZoneId,
  selectedAreaName
) => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllDistributorList = async () => {
    const param = {
      zone_id: selectedZoneId === 0 ? "ALL" : selectedZoneId,
      state_id: selectedStateId === 0 ? "ALL" : selectedStateId,
      user_status: selectedStatus,
      area_name: selectedAreaName,
    };
    const response = await Axios.post("/laabh_partner_list", param);
    const data = await response.data;
    dispatch(getDistributorList(data.body));
    dispatch(getStateList(data.stateNameList));
    dispatch(getZoneList(data.zoneNameList));
    dispatch(getAreaNameList(data.areaNameList));
    dispatch(getUserStatusList(data.userStatusList));
  };
  useEffect(() => {
    getAllDistributorList();
  }, [selectedZoneId, selectedStatus, selectedAreaName, selectedStateId]);

  return getAllDistributorList;
};

export default useDistributorList;
