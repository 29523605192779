import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getCategoryList, getGiftCategoryList } from "../slice/categorySlice";

const useGiftCategoryList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllGiftCategoryList = async () => {
    const response = await Axios.get("/gift_category_list");
    const data = await response.data;
    dispatch(getGiftCategoryList(data.giftCategoryList));
  };
  useEffect(() => {
    getAllGiftCategoryList();
  }, []);

  return getAllGiftCategoryList;
};

export default useGiftCategoryList;
