import React from "react";
import Sidebar from "../Sidebar";
import { Stack } from "@mui/material";
import DashboardContainer from "./DashboardConatainer";

const index = () => {
  
  return (
    <Stack>
      <Stack
        direction="row"
        sx={{ width: "100%", backgroundColor: "#E5E5E5", height: "100%" }}
      >
        <Sidebar />
        <DashboardContainer />
      </Stack>
    </Stack>
  );
};

export default index;
