import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  TextField,
  FormHelperText,
  Stack,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import useSkuImageUpload from "../../hooks/useSkuImageUpload";
import { ToastContainer, toast } from "react-toastify";
import useSkuList from "../../hooks/useSkuList";
import { resetSkuFormData } from "../../slice/skuSlice";
import useAddInventoryV2 from "../../hooks/useAddInventoryV2";
import useInventorySkuList from "../../hooks/useInventorySkuList";
import useCopySkuInventory from "../../hooks/useCopySkuInventory";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningIcon from '@mui/icons-material/Warning';
import IconButton from '@mui/material/IconButton';
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddSku(shapeId) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const [enable, setEnable] = React.useState(false);
  const [primaryShapeID, setPrimarySelectedshapeID] = React.useState(null);
  const categoryList = useSelector((store) => store.category.categoryList);
  const SFId = useSelector((store) => store.sku.selectedSF);
  const AreaName = useSelector((store) => store.sku.selectedAreaName);
  const getSkuImageUpload = useSkuImageUpload();
  const addSku = useAddInventoryV2(form);

  const getSkuList = useInventorySkuList();
  const shapeFileList = useSelector((state) => state.adminMap.getBoundryDetails);
  const [error, setError] = React.useState({});
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);
  const [areaName, setAreaName] = React.useState(null);
  const currentImageSelected = useSelector((state) => state.sku.skuFormData);
  const selectedSF = useSelector((state) => state.sku.selectedSF);
  const selectedCategory = useSelector((state) => state.sku.selectedCategoryId);
  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    dispatch(resetSkuFormData());
  };

  const isValid = () => {
    let success = true;
    let err = {};
  
    if (
      form.sku_name?.trim() === "" ||
      form.sku_name?.trim() === undefined ||
      form.sku_name?.trim() === null
    ) {
      err.sku_name = "**SKU Name Cannot be Empty**";
      success = false;
    }
    if (
      form.hindi_name?.trim() === "" ||
      form.hindi_name?.trim() === undefined ||
      form.hindi_name?.trim() === null
    ) {
      err.hindi_name = "**Hindi Name Cannot be Empty**";
      success = false;
    }
    if (
      form.gujarati_name?.trim() === "" ||
      form.gujarati_name?.trim() === undefined ||
      form.gujarati_name?.trim() === null
    ) {
      err.gujarati_name = "**Gujarati Name Cannot be Empty**";
      success = false;
    }
    // if (
    //   form.scheme?.trim() === "" ||
    //   form.scheme?.trim() === undefined ||
    //   form.scheme?.trim() === null
    // ) {
    //   err.scheme = "**Scheme cannot be Empty**";
    //   success = false;
    // }
  
    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
      // } else if (
      //   imageDimensions.width !== 200 ||
      //   imageDimensions.height !== 200
      // ) {
      //   err.selectedImage = "**Image should be 200x200 pixels**";
      //   success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }
  
    if (
      form.category_id === 0 ||
      form.category_id === undefined ||
      form.category_id === null
    ) {
      err.category = "**Category Cannot be Empty**";
      success = false;
    }
  
    // Add validation for slabs
    if (parseInt(form.toOne) <= parseInt(form.fromOne)) {
      err.toOne = "To value must be > From value";
      success = false;
    }
  
    if (parseInt(form.toTwo) <= parseInt(form.fromTwo)) {
      err.toTwo = "To value must be > From value";
      success = false;
    }
  
    if (parseInt(form.toThree) <= parseInt(form.fromThree)) {
      err.toThree = "To value must be > From value";
      success = false;
    }
  
    if (parseInt(form.fromTwo) <= parseInt(form.toOne)) {
      err.fromTwo = "From value must be > to value in Slab 1";
      success = false;
    }
  
    if (parseInt(form.fromThree) <= parseInt(form.toTwo)) {
      err.fromThree = "From value must be > to value in Slab 2";
      success = false;
    }
  
    setError(err);
    return success;
  };
  

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));
    setEnable(true);
    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }
    const form = new FormData();
    form.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };

    getSkuImageUpload(form);
    setError({});
    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = { ...error };
    if (name === "priceType") {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        enable_slab: value === "slab" ? true : false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    
  };


  

  // useEffect(() => {
  // console.log(form , "form");
  // }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(form, "111111");
    if (isValid()) {
    await addSku();
    const param = {
      category_id: form.category_id,
      shape_id: SFId,
    };
    await getSkuList(param);
    setOpen(false);
    setForm({});
    }
  };

  const handleCategory = (e) => {
    let categoryId = 0;
    for (let i = 0; i < categoryList?.length; i++) {
      if (e.target.value === categoryList[i]?.category_name) {
        categoryId = categoryList[i]?.category_id;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      category_id: categoryId,
    }));
  };

  const copySkuInventory = useCopySkuInventory();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    setDialogOpen();
    await handleCopyData();
  };
  

  const handleSFChange = (data) => {
    setPrimarySelectedshapeID(data);
    let area = null;
    let pincode = null;
    for (let i = 0; i < shapeFileList?.sf_details.length; i++) {
      if (data === shapeFileList?.sf_details[i].shape_id) {
        area = shapeFileList?.sf_details[i].office_name;
        pincode = shapeFileList?.sf_details[i].pincode;
        break;
      }
    }
    setAreaName(area);
  };

  // const handleCopyData = async () => {
  //   const param = {
  //     shape_id: selectedSF,
  //     category_id: selectedCategory,
  //   };
  //   await copySkuInventory(param);
  //   await getSkuList(param);
  // };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleCopyData = async () => {
  const param = {
    shape_id: selectedSF,
    category_id: selectedCategory,
    primary_shape_id : primaryShapeID
  };
  if (primaryShapeID !== null) {
  await copySkuInventory(param);
  await delay(500);  // Add a 500ms delay
  await getSkuList(param);
  }
};

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Stack sx={{ gap: "12px", flexDirection: "row", alignItems: "center" }}>
          {selectedSF === primaryShapeID ? null : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Stack
                sx={{
                  gap: "4px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "8px 6px",
                  alignItems: "center",
                }}
              >
                <label> Copy Data from Shape ID:</label>
                <FormControl
                  sx={{ height: "1.8rem", width: "200px" }}
                  error={Boolean(error.sku_name)}
                >
                  <Select
                    labelId="shape-id-label"
                    id="shape-id-select"
                    placeholder="Select Area"
                    // value={primaryShapeID}
                    size="small"
                    displayEmpty
                    // renderValue={(value) => (value ? value : "Select")}
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleSFChange(e.target.value)}
                  >
                    {shapeFileList?.sf_details
                      ?.filter((sf) => sf.shape_file_status)
                      .map((sf, index) => (
                        <MenuItem key={index} value={sf?.shape_id}>
                          {`${sf?.office_name} | ${sf?.pincode}`}
                        </MenuItem>
                      ))}
                  </Select>
                  {error.sku_name && (
                    <FormHelperText>{error.sku_name}</FormHelperText>
                  )}
                </FormControl>
              </Stack>
              <Button
                onClick={handleClickOpen}
                sx={{
                  height: "0.6rem",
                  color: "white",
                  background: "#DC1D13",
                  padding: "1rem",
                  "&:hover": {
                    background: "#DC1D13",
                  },
                  textTransform: "none",
                }}
              >
                Apply
              </Button>
            </div>
          )}
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <IconButton color="inherit" aria-label="warning">
                <WarningIcon />
              </IconButton>
              {"Warning"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This will delete all the present data of this Shape File and
                copy the data from <b>{areaName}</b>. Are you sure you
                want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                sx={{
                  height: "0.6rem",
                  color: "white",
                  background: "#DC1D13",
                  padding: "1rem",
                  "&:hover": {
                    background: "#DC1D13",
                  },
                  textTransform: "none",
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  height: "0.6rem",
                  color: "white",
                  background: "#DC1D13",
                  padding: "1rem",
                  "&:hover": {
                    background: "#DC1D13",
                  },
                  textTransform: "none",
                }}
                onClick={handleConfirm}
                color="primary"
                autoFocus
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
          <Button
            onClick={handleOpen}
            sx={{
              height: "0.6rem",
              color: "white",
              background: "#DC1D13",
              padding: "1rem",
              "&:hover": {
                background: "#DC1D13",
              },
              fontSize: { xs: "12px", sm: "12px", md: "12px", lg: "12px", xl: "14px"},
            }}
          >
            <AddIcon
              sx={{
                fontSize: "14px",
                fontWeight: "700",
              }}
            />
            Add Sku
          </Button>
        </Stack>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Typography variant="h6" color="initial">
                Add New SKU(Scope : {AreaName})
              </Typography>
              <Stack flexDirection="row" gap={2} sx={{maxHeight:"500px", overflowY:"scroll"}}>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "1px",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label>Sku Name</label>
                    <TextField
                      variant="outlined"
                      name="sku_name"
                      placeholder="Enter sku name"
                      error={error.sku_name}
                      helperText={error.sku_name}
                      onChange={handleChange}
                      size="small"
                      style={{
                        // width: "8rem",
                        height: "28px",
                        fontSize: "0.9rem",
                      }}
                    />
                    {/* {error.sku_name && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          marginTop: "-0.1rem",
                        }}
                      >
                        {error.sku_name}
                      </p>
                    )} */}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label>Sku Name(Hindi)</label>
                    <TextField
                      variant="outlined"
                      name="hindi_name"
                      placeholder="Enter sku name"
                      onChange={handleChange}
                      size="small"
                      style={{
                        height: "1.8rem",

                        fontSize: "0.9rem",
                      }}
                    />
                    {error.hindi_name && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          margin: "0px",
                        }}
                      >
                        {error.hindi_name}
                      </p>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label>Sku Name(Gujarati)</label>
                    <TextField
                      variant="outlined"
                      name="gujarati_name"
                      placeholder="Enter SKU name"
                      onChange={handleChange}
                      size="small"
                      style={{
                        height: "1.6rem",

                        fontSize: "0.9rem",
                      }}
                    />
                    {error.gujarati_name && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          margin: "0px",
                        }}
                      >
                        {error.gujarati_name}
                      </p>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label>Select Cateogory</label>
                    <select
                      style={{
                        height: "2.5rem",
                        fontSize: "0.9rem",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      name="category"
                      onChange={handleCategory}
                    >
                      <option value="" disabled selected>
                        Choose Category
                      </option>
                      {categoryList?.map(
                        (item) =>
                          // Only render the option if the status is true
                          item.status && (
                            <option key={item.id} value={item.category_name}>
                              {item.category_name}
                            </option>
                          )
                      )}
                    </select>
                    {error.category && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          marginTop: "-0.1rem",
                        }}
                      >
                        {error.category}
                      </p>
                    )}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <label>Enter MRP</label>
                      <TextField
                        variant="outlined"
                        name="mrp"
                        type="number"
                        placeholder="Enter mrp"
                        onChange={handleChange}
                        size="small"
                        style={{
                          fontSize: "0.9rem",
                        }}
                      />
                      {error.mrp && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "0.8rem",
                            marginTop: "-0.1rem",
                          }}
                        >
                          {error.mrp}
                        </p>
                      )}
                    </Stack>
                    {/* <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <input
                        variant="outlined"
                        name="inventory"
                        type="number"
                        placeholder="Qty"
                        onChange={handleChange}
                        style={{
                          height: "1.8rem",
                          paddingLeft: "0.5rem",
                          fontSize: "0.9rem",
                        }}
                      />
                      {error.inventory && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "0.8rem",
                            marginTop: "-0.1rem",
                          }}
                        >
                          {error.inventory}
                        </p>
                      )}
                    </Stack> */}
                  </Stack>

                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="mrp"
                    type="number"
                    placeholder="Enter mrp"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.mrp && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.mrp}
                    </p>
                  )}
                </Stack> */}
                    {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="selling_price"
                    type="number"
                    placeholder="Enter Sale price"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.selling_price && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.selling_price}
                    </p>
                  )}
                </Stack> */}
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label>Enter Scheme</label>
                    <TextField
                      variant="outlined"
                      name="scheme"
                      type="text"
                      placeholder="Enter Scheme"
                      onChange={handleChange}
                      size="small"
                      style={{
                        fontSize: "0.9rem",
                      }}
                    />
                    {error.scheme && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          marginTop: "-0.1rem",
                        }}
                      >
                        {error.scheme}
                      </p>
                    )}
                  </Stack>
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.4rem",
                    }}
                  >
                    <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                      <input
                        id="sku_image"
                        type="file"
                        size="small"
                        accept="image/*"
                        name="sku_image"
                        style={{
                          display: "none",
                        }}
                        onChange={handleImageChange}
                      />
                      <div
                        style={{
                          height: "1.8rem",
                          paddingLeft: "0.5rem",
                          fontSize: "0.9rem",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        {form.selectedImage
                          ? form.selectedImage
                          : "Choose Image"}
                      </div>
                    </label>
                    {error.selectedImage && (
                      <p
                        style={{
                          color: "red",
                          justifyContent: "center",
                          display: "flex",
                          fontSize: "0.8rem",
                          marginTop: "-0.1rem",
                        }}
                      >
                        {error.selectedImage}
                      </p>
                    )}
                  </Stack>
                </Stack>
                <Stack>
                  <Stack gap={1}>
                    <Typography>Set PTR</Typography>
                    <Stack gap={2}>
                      <Stack flexDirection="row">
                        <div>
                          <input
                            type="radio"
                            id="flat"
                            name="priceType"
                            value="flat"
                            checked={form.priceType === "flat"}
                            onChange={handleChange}
                          />
                          <label htmlFor="flat">Flat Price</label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="slab"
                            name="priceType"
                            value="slab"
                            checked={form.priceType === "slab"}
                            onChange={handleChange}
                          />
                          <label htmlFor="slab">Slab Price</label>
                        </div>
                      </Stack>
                      {form?.enable_slab ? (
                        <Stack gap={1}>
                          <Stack flexDirection="row" gap={1}>
                            <Typography sx={{ width: "fitContent" }}>
                              Slab 1
                            </Typography>
                            <Stack gap={3}>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                                gap={1}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="fromOne"
                                    type="number"
                                    placeholder="Qty 1"
                                    onChange={handleChange}
                                    size="small"
                                    style={{
                                      maxWidth: "102px",
                                      height: "1.8rem",
                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.fromOne && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        marginTop: "-0.1rem",
                                      }}
                                    >
                                      {error.fromOne}
                                    </p>
                                  )}
                                </Stack>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="toOne"
                                    type="number"
                                    placeholder="Qty 2"
                                    onChange={handleChange}
                                    size="small"
                                    style={{
                                      maxWidth: "102px",
                                      height: "1.8rem",
                                      paddingLeft: "0.5rem",
                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.toOne && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                      }}
                                    >
                                      {error.toOne}
                                    </p>
                                  )}
                                </Stack>
                              </Stack>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.4rem",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  name="priceOne"
                                  type="number"
                                  placeholder=""
                                  size="small"
                                  onChange={handleChange}
                                  style={{
                                    fontSize: "0.9rem",
                                  }}
                                />
                                {error.priceOne && (
                                  <p
                                    style={{
                                      color: "red",
                                      justifyContent: "center",
                                      display: "flex",
                                      fontSize: "0.8rem",
                                      margin: "0px",
                                    }}
                                  >
                                    {error.priceOne}
                                  </p>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                          <Stack flexDirection="row" gap={1}>
                            <label>Slab 2</label>
                            <Stack gap={2}>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                                gap={1}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="fromTwo"
                                    type="number"
                                    placeholder="Qty 1"
                                    onChange={handleChange}
                                    size="small"
                                    style={{
                                      maxWidth: "102px",

                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.fromTwo && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                      }}
                                    >
                                      {error.fromTwo}
                                    </p>
                                  )}
                                </Stack>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="toTwo"
                                    type="number"
                                    placeholder="Qty 2"
                                    size="small"
                                    onChange={handleChange}
                                    style={{
                                      maxWidth: "102px",

                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.toTwo && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                      }}
                                    >
                                      {error.toTwo}
                                    </p>
                                  )}
                                </Stack>
                              </Stack>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.4rem",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  name="priceTwo"
                                  type="number"
                                  placeholder=""
                                  size="small"
                                  onChange={handleChange}
                                  style={{
                                    fontSize: "0.9rem",
                                  }}
                                />
                                {error.priceTwo && (
                                  <p
                                    style={{
                                      color: "red",
                                      justifyContent: "center",
                                      display: "flex",
                                      fontSize: "0.8rem",
                                      margin: "0px",
                                    }}
                                  >
                                    {error.priceTwo}
                                  </p>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                          <Stack flexDirection="row" gap={1}>
                            <label>Slab 3</label>
                            <Stack gap={2}>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                                gap={1}
                              >
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="fromThree"
                                    type="number"
                                    placeholder="Qty 1"
                                    onChange={handleChange}
                                    size="small"
                                    style={{
                                      maxWidth: "102px",

                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.fromThree && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                      }}
                                    >
                                      {error.fromThree}
                                    </p>
                                  )}
                                </Stack>
                                <Stack
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "0.4rem",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    name="toThree"
                                    type="number"
                                    placeholder="Qty 2"
                                    onChange={handleChange}
                                    size="small"
                                    style={{
                                      maxWidth: "102px",

                                      fontSize: "0.9rem",
                                    }}
                                  />
                                  {error.toThree && (
                                    <p
                                      style={{
                                        color: "red",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: "0.8rem",
                                        margin: "0px",
                                      }}
                                    >
                                      {error.toThree}
                                    </p>
                                  )}
                                </Stack>
                              </Stack>
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0.4rem",
                                }}
                              >
                                <TextField
                                  variant="outlined"
                                  name="priceThree"
                                  type="number"
                                  placeholder=""
                                  onChange={handleChange}
                                  size="small"
                                  style={{
                                    fontSize: "0.9rem",
                                  }}
                                />
                                {error.priceThree && (
                                  <p
                                    style={{
                                      color: "red",
                                      justifyContent: "center",
                                      display: "flex",
                                      fontSize: "0.8rem",
                                      margin: "0px",
                                    }}
                                  >
                                    {error.priceThree}
                                  </p>
                                )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.4rem",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            name="ptr"
                            type="number"
                            placeholder="Enter ptr"
                            size="small"
                            onChange={handleChange}
                            style={{
                              fontSize: "0.9rem",
                            }}
                          />
                          {error.ptr && (
                            <p
                              style={{
                                color: "red",
                                justifyContent: "center",
                                display: "flex",
                                fontSize: "0.8rem",
                                marginTop: "-0.1rem",
                              }}
                            >
                              {error.ptr}
                            </p>
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#DC1D13",
                    border: "2px solid #DC1D13",
                    height: "1.8rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#DC1D13",
                    color: "white",
                    height: "1.8rem",
                    "&:hover": {
                      background: "#DC1D13",
                    },
                  }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
