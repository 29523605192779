import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AxiosPrivate, ImageAxiosPrivate } from "../utility/axios";
import useRefreshToken from "./useRefreshToken";

const useImageAxiosPrivate = () => {
  const navigate = useNavigate();
  const store = useSelector((state) => state.login.loginData);
  const refresh = useRefreshToken();
  useEffect(() => {
    const requestInterceptors = ImageAxiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer {store?.access_token}`;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseInterceptors = ImageAxiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const preRequest = error?.config;

        if (error?.response?.status === 403 || !preRequest) {
          preRequest.sent = true;
          const newAccessToken = await refresh();
          preRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return ImageAxiosPrivate(preRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      ImageAxiosPrivate.interceptors.request.eject(requestInterceptors);
      ImageAxiosPrivate.interceptors.response.eject(responseInterceptors);
    };
  }, [store, refresh]);

  return ImageAxiosPrivate;
};

export default useImageAxiosPrivate;
