import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userList: null,
    userDetails: null,
    userUpdatedDetails: null,
    userStatusList: null,
    areaNameList: null,
  },
  reducers: {
    getUserList: (state, action) => {
      state.userList = action.payload;
    },
    getUserStatusList: (state, action) => {
      state.userStatusList = action.payload;
    },
    getAreaNameList: (state, action) => {
      state.areaNameList = action.payload;
    },
    getUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateUserDetails: (state, action) => {
      state.userUpdatedDetails = action.payload;
    },
  },
});

export default userSlice.reducer;
export const {
  getUserList,
  getUserDetails,
  updateUserDetails,
  getUserStatusList,
  getAreaNameList,
} = userSlice.actions;
