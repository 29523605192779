import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { GetUserMgtWholesalerList } from '../../../../actions/UserMgt/UserMgt';
import UserMgtWholesalerTable from "./UserMgtWholesalerTable";
import UserMgtWholesalerUpdateForm from "./UserMgtWholesalerUpdateForm";
import styles from "../styles/UserMgtWholesaler.module.css";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import { style } from "@mui/system";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Modal from "../../../utility/Modal";
import useDistributorList from "../../../hooks/useDistributorList";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
  padding: "0px",
}));

const UserMgtWholesaler = ({ zone }) => {
  const [q, setQ] = useState("");
  const userlist = useSelector((state) => state.UserMgt);
  const dispatch = useDispatch();
  const Firsttoken = useSelector((state) => state.Token);
  const [partnerCount, setPartnerCount] = useState(0);
  const [selectedAreaName, setSelectedAreaName] = useState("ALL");
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedStateName, setSelectedStateName] = useState("ALL");
  const [selectedStateId, setSelectedStateId] = useState("ALL");
  const [selectedZoneName, setSelectedZoneName] = useState("ALL");
  const [selectedZoneId, setSelectedZoneId] = useState("ALL");
  const areaNameList = useSelector((store) => store.distributor.areaNameList);
  const userStatusList = useSelector(
    (store) => store.distributor.userStatusList
  );
  const stateNameList = useSelector((store) => store.distributor.stateList);
  const zoneNameList = useSelector((store) => store.distributor.zoneList);
  const [RoleList, setRoleList] = useState([
    "All",
    "Wholesaler",
    "Rider",
    "Wd",
  ]);
  const [Role, setRole] = React.useState("All");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const distributorAllList = useSelector(
    (store) => store.distributor.distributorList
  );
  const userData = useSelector(
    (state) => state?.login?.refreshToken?.user_data
  );
  // const getAllDistributorList = useDistributorList();

  const getAllDistributorList = useDistributorList(
    selectedStatus,
    selectedStateId,
    selectedZoneId,
    selectedAreaName
  );
  // useEffect(() => {
  //   getAllDistributorList();
  // }, []);

  useEffect(() => {
    if (distributorAllList && Array.isArray(distributorAllList)) {
      setPartnerCount(distributorAllList.length);
    } else {
      setPartnerCount(0);
    }
  }, [distributorAllList]);

  const [filterlist, setFilterlist] = useState([]);
  const [Userdetail, setUserDetail] = useState({});

  React.useEffect(() => {
    if (
      userlist &&
      userlist.wholesalerList &&
      userlist.wholesalerList.length !== 0 &&
      userlist.wholesalerList !== "undefined" &&
      userlist.wholesalerList !== null
    ) {
      setFilterlist(userlist.wholesalerList);
      setUserDetail(userlist.wholesalerList[0]);
    }
  }, [userlist]);

  // React.useEffect(() => {
  //     if (Firsttoken && Firsttoken.token && Firsttoken.token !== null && Firsttoken.token.length !== 0 && Firsttoken !== "undefined") {
  //         setToken(Firsttoken.token);
  //         let param = {
  //             "admin_id": 1,
  //             "zone_name": `${zone}`
  //         }
  //         dispatch(GetUserMgtWholesalerList(Firsttoken.token, param))
  //     }

  // }, [Firsttoken, zone])

  const Searchfilter = (datas) => {
    return datas.filter((row) =>
      searchColumn.some((column) =>
        row[column] !== null &&
        row[column] !== "undefined" &&
        row[column].length !== 0
          ? row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : ""
      )
    );
  };

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = () => {
    let neo = [];
    let filterRole = Role.toLowerCase();

    if (filterRole.toLocaleLowerCase() !== "all") {
      if (
        userlist.wholesalerList &&
        userlist.wholesalerList.length !== 0 &&
        userlist.wholesalerList !== "undefined" &&
        userlist.wholesalerList !== null
      ) {
        for (let i = 0; i < userlist.wholesalerList.length; i++) {
          if (
            userlist.wholesalerList[i].role_name.toLocaleLowerCase() ===
            filterRole.toLocaleLowerCase()
          ) {
            neo.push(userlist.wholesalerList[i]);
          }
        }
      }

      // Ashish
    } else {
      neo = userlist.wholesalerList;
    }
    //Ashish
    setFilterlist(neo);
    setUserDetail(neo[0]);
  };

  const [isAdd, setIsAdd] = useState(false);

  const handleAdd = () => {
    setIsAdd(true);
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterDistributorList, setFilterDistributorList] = useState(null);

  useEffect(() => {
    if (distributorAllList) {
      setFilterDistributorList(distributorAllList);
    }
  }, [distributorAllList]);
  useEffect(() => {
    const filteredData = distributorAllList?.filter((distributor) => {
      const areaNamesTitle = distributor?.shape_details
        ?.map((shape) => shape?.area_name)
        .join(", ");
      return (
        distributor?.name?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        distributor?.partner_code
          ?.toLowerCase()
          .includes(query?.toLowerCase()) ||
        distributor?.area_name?.toLowerCase().includes(query?.toLowerCase()) ||
        areaNamesTitle?.toLowerCase().includes(query?.toLowerCase())
        // distributor?.zone_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterDistributorList(filteredData);
  }, [query]);

  const handleStatusChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedAreaName("ALL");
      setSelectedStateId("ALL");
      setSelectedZoneId("ALL");
      setSelectedStateName("ALL");
      setSelectedZoneName("ALL");
    }
    setSelectedStatus(e.target.value);
  };

  const handleAreaNameChange = (e) => {
    setSelectedAreaName(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedStateName(e.target.value);
    const selectedState = stateNameList?.find(
      (state) => state.state_name === e.target.value
    );
    setSelectedStateId(selectedState?.state_id);
  };

  const handleZoneChange = (e) => {
    setSelectedZoneName(e.target.value);
    const selectedZone = zoneNameList?.find(
      (zone) => zone?.zone_name === e.target.value
    );
    setSelectedZoneId(selectedZone?.zone_id);
  };

  return (
    
    <>
    <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className={styles.UserMgtWholesalerTable}>
        <div className={styles.MidSubContainerOne}>
          {/* <div className={styles.MidSubContainerOneList}>
            <label className={styles.MidSubContainerOneLabel}>Role</label>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <Select
                  value={Role}
                  onChange={handleRoleChange}
                  size="small"
                  sx={{ height: "1.8rem" }}
                >
                  {RoleList &&
                    RoleList.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </div> */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                style={{ height: "1.8rem", marginTop: "-0.2rem" }}
                // size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
              />
            </div>
            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <div className={styles.MidSubContainerOneList}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="shape-id-status-label"
                      id="shape-id-status-select"
                      value={selectedStatus}
                      size="small"
                      sx={{ height: "1.8rem" }}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      {userStatusList?.map((status) => (
                        <MenuItem
                          key={status.user_status}
                          value={status.user_status}
                        >
                          {status.user_status?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <label className={styles.MidSubContainerOneLabel}>State</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedStateName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleStateChange(e)}
                  >
                    {stateNameList?.map((stateName) => (
                      <MenuItem
                        key={stateName?.state_name}
                        value={stateName?.state_name}
                      >
                        {stateName?.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <label className={styles.MidSubContainerOneLabel}>Zone</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    disabled={selectedStateName === "ALL"}
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedZoneName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleZoneChange(e)}
                  >
                    {zoneNameList?.map((zoneName) => (
                      <MenuItem
                        key={zoneName?.zone_name}
                        value={zoneName?.zone_name}
                      >
                        {zoneName?.zone_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <label className={styles.MidSubContainerOneLabel}>
                Area Name
              </label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    disabled={
                      selectedStateName === "ALL" || selectedZoneName === "ALL"
                    }
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedAreaName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleAreaNameChange(e)}
                  >
                    {areaNameList?.map((areaName) => (
                      <MenuItem
                        key={areaName?.area_name}
                        value={areaName?.area_name}
                      >
                        {areaName?.area_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </div>
          <div
            className={styles.MidSubContainerTwo}
            style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "16px", fontWeight: "400" }}
              color="initial"
            >
              Total Distribution Partner:{" "}
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: "6px 12px 6px 12px",
                  margin:"4px",
                  backgroundColor: "#DC1D13",
                  borderRadius: "40%",
                  color: "#fff",
                }}
              >
                {" "}
                {partnerCount}
              </span>
            </Typography>
            {userData?.role === "admin" ? (
              <RedButton onClick={handleAdd} variant="contained">
                <Modal getAllDistributorList={getAllDistributorList}/>
              </RedButton>
            ) : (
              <></>
            )}
          </div>
        </div>
        <UserMgtWholesalerTable
          filterlist={filterDistributorList}
          Searchfilter={Searchfilter}
          query={query}
          setUserDetail={setUserDetail}
        />
      </div>

      <div className={styles.UserMgtWholesalerUpdateFormScrollViewContainer}>
        <div id="UserMgtWholesalerUpdateFormScrollView">
          {distributorAllList &&
          distributorAllList?.length !== 0 &&
          distributorAllList !== "undefined" ? (
            <UserMgtWholesalerUpdateForm
              detail={
                distributorAllList &&
                distributorAllList?.length !== 0 &&
                distributorAllList !== "undefined"
                  ? Userdetail
                  : {}
              }
              getAllDistributorList={getAllDistributorList}
            />
          ) : (
            <h5>No Data</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default UserMgtWholesaler;
