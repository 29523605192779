import React, { useEffect } from "react";
import useImageAxiosPrivate from "./useImageAxiosPrivate";
import Swal from "sweetalert2";
import { getCategoryFormData } from "../slice/categorySlice";
import { useDispatch } from "react-redux";
import { getGiftSkuFormData, getSkuFormData } from "../slice/skuSlice";

const useGiftSkuImageUpload = () => {
  const ImageAxiosPrivate = useImageAxiosPrivate();
  const dispatch = useDispatch();
  const getGiftSkuImageUpload = async (formData) => {
    console.log(formData);
    const response = await ImageAxiosPrivate.post(
      "/gift_sku_image_upload",
      formData
    );
    const data = await response.data;
    dispatch(getGiftSkuFormData(data));
  };

  return getGiftSkuImageUpload;
};

export default useGiftSkuImageUpload;
