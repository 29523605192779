
import { getDeliveryMapData } from "../slice/adminMapSlice";
import { getShapeIdList } from "../slice/appSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";


const useGetShapeIdList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  
  const getShapeIdAllList = async (param) => {
    try {

      const response = await Axios.post("/get_shapefile_list", param);
      const data = response.data;
   
      dispatch(getShapeIdList(data));
      return data;  
    } catch (error) {
      dispatch(getShapeIdList(null));
      console.log("Error fetching admin map data:", error);
    }
  };
  return getShapeIdAllList;
};

export default useGetShapeIdList;

