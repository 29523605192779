import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getUserList } from "../slice/userSlice";


const useRefreshUserList = () => {
  const currentzone = useSelector((state) => state.SFstatus.zone_id);
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllUserList = async () => {
    if (currentzone) {
      const param = {
        zone_id: currentzone
      };
    const response = await Axios.post("/delivery_boy_list", param);
    const data = await response.data;
    dispatch(getUserList(data.body));
    // console.log(data);
    }
  };
  
   return getAllUserList;
  
};

export default useRefreshUserList;