import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";
import { getUserList } from "../slice/userSlice";
import { getStateList, getZoneList } from "../slice/appSlice";


const useStateList = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const getAllStateList = async () => {
    const response = await Axios.post("/state_list");
    const data = await response.data;
    dispatch(getStateList(data.body));
   
  };
  
  return getAllStateList;  
};

export default useStateList;