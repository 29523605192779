import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import useSkuImageUpload from "../../hooks/useSkuImageUpload";
import useAddSku from "../../hooks/useAddSku";
import { ToastContainer, toast } from "react-toastify";
import useSkuList from "../../hooks/useSkuList";
import { getSkuList, resetSkuFormData } from "../../slice/skuSlice";

// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddSku(shapeId) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const [enable, setEnable] = React.useState(false);
  const categoryList = useSelector((store) => store.category.categoryList);
  const getSkuImageUpload = useSkuImageUpload();
  const addSku = useAddSku(form);
  const getAllSkuList = useSkuList();

  const [error, setError] = React.useState({});
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);
  const currentImageSelected = useSelector((state) => state.sku.skuFormData);

  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    dispatch(resetSkuFormData());
  };

  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.sku_name?.trim() === "" ||
      form.sku_name?.trim() === undefined ||
      form.sku_name?.trim() === null
    ) {
      err.sku_name = "**SKU Name Cannot be Empty**";
      success = false;
    }
    if (
      form.hindi_name?.trim() === "" ||
      form.hindi_name?.trim() === undefined ||
      form.hindi_name?.trim() === null
    ) {
      err.hindi_name = "**Hindi Name Cannot be Empty**";
      success = false;
    }
    if (
      form.gujarati_name?.trim() === "" ||
      form.gujarati_name?.trim() === undefined ||
      form.gujarati_name?.trim() === null
    ) {
      err.gujarati_name = "**Gujarati Name Cannot be Empty**";
      success = false;
    }
    if (
      form.scheme?.trim() === "" ||
      form.scheme?.trim() === undefined ||
      form.scheme?.trim() === null
    ) {
      err.scheme = "**Scheme cannot be Empty**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
      // } else if (
      //   imageDimensions.width !== 200 ||
      //   imageDimensions.height !== 200
      // ) {
      //   err.selectedImage = "**Image should be 200x200 pixels**";
      //   success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }

    // if (
    //   form.ptr?.trim() === "" ||
    //   form.ptr?.trim() === undefined ||
    //   form.ptr?.trim() === null ||
    //   parseFloat(form.ptr) <= 0
    // ) {
    //   err.ptr = "**Ptr must be a non-zero positive number**";
    //   success = false;
    // }

    // if (
    //   form.ptw?.trim() === "" ||
    //   form.ptw?.trim() === undefined ||
    //   form.ptw?.trim() === null ||
    //   parseFloat(form.ptw) <= 0
    // ) {
    //   err.ptw = "**Ptw must be a non-zero positive number**";
    //   success = false;
    // }

    // if (
    //   form.mrp?.trim() === "" ||
    //   form.mrp?.trim() === undefined ||
    //   form.mrp?.trim() === null ||
    //   parseFloat(form.mrp) <= 0
    // ) {
    //   err.mrp = "**MRP must be a non-zero positive number**";
    //   success = false;
    // }

    // if (
    //   form.selling_price?.trim() === "" ||
    //   form.selling_price?.trim() === undefined ||
    //   form.selling_price?.trim() === null ||
    //   parseFloat(form.selling_price) <= 0
    // ) {
    //   err.selling_price = "**Price must be a non-zero positive number**";
    //   success = false;
    // }

    if (
      form.category_id === 0 ||
      form.category_id === undefined ||
      form.category_id === null
    ) {
      err.category = "**Category Cannot be Empty**";
      success = false;
    }
    setError(err);
    return success;
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));
    setEnable(true);
    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };

    getSkuImageUpload(formData);
    setError({});
    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // useEffect(() => {
  // console.log(form , "form");
  // }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid()) {
      await addSku();
      await getAllSkuList(shapeId);
      setOpen(false);
      setForm({});
    }
  };

  const handleCategory = (e) => {
    let categoryId = 0;
    for (let i = 0; i < categoryList?.length; i++) {
      if (e.target.value === categoryList[i]?.category_name) {
        categoryId = categoryList[i]?.category_id;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      category_id: categoryId,
    }));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Button
          onClick={handleOpen}
          sx={{
            height: "0.6rem",
            color: "white",
            background: "#DC1D13",
            padding: "1rem",
            "&:hover": {
              background: "#DC1D13",
            },
          }}
        >
          <AddIcon
            sx={{
              fontSize: "14px",
              fontWeight: "700",
            }}
          />
          Add Sku
        </Button>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Sku Name</label>
                <input
                  variant="outlined"
                  name="sku_name"
                  placeholder="Enter sku name"
                  onChange={handleChange}
                  style={{
                    // width: "8rem",
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.sku_name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.sku_name}
                  </p>
                )}
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Sku Name(Hindi)</label>
                <input
                  variant="outlined"
                  name="hindi_name"
                  placeholder="Enter Sku name"
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.hindi_name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.hindi_name}
                  </p>
                )}
              </Stack>
              
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Sku Name(Gujarati)</label>
                <input
                  variant="outlined"
                  name="gujarati_name"
                  placeholder="Enter Sku name"
                  onChange={handleChange}
                  style={{
                    height: "1.6rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.gujarati_name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.gujarati_name}
                  </p>
                )}
              </Stack>
    
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="category"
                  onChange={handleCategory}
                >
                  <option value="" disabled selected>
                    Choose Category
                  </option>
                  {categoryList?.map(
                    (item) =>
                      // Only render the option if the status is true
                      item.status && (
                        <option key={item.id} value={item.category_name}>
                          {item.category_name}
                        </option>
                      )
                  )}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="ptr"
                    type="number"
                    placeholder="Enter ptr"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.ptr && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.ptr}
                    </p>
                  )}
                </Stack>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="mrp"
                    type="number"
                    placeholder="Enter mrp"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.mrp && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.mrp}
                    </p>
                  )}
                </Stack>
                {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="ptw"
                    type="number"
                    placeholder="Enter ptw"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.ptw && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.ptw}
                    </p>
                  )}
                </Stack> */}
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="mrp"
                    type="number"
                    placeholder="Enter mrp"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.mrp && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.mrp}
                    </p>
                  )}
                </Stack> */}
                {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="selling_price"
                    type="number"
                    placeholder="Enter Sale price"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.selling_price && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.selling_price}
                    </p>
                  )}
                </Stack> */}
              </Stack>
              {/* <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="inventory"
                    type="number"
                    placeholder="Enter inventory"
                    onChange={handleChange}
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.inventory && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                      }}
                    >
                      {error.inventory}
                    </p>
                  )}
                </Stack> */}
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.4rem",
                }}
              >
                <input
                  variant="outlined"
                  name="scheme"
                  type="text"
                  placeholder="Enter Scheme"
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.scheme && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.scheme}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <div
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    {form.selectedImage ? form.selectedImage : "Choose Image"}
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#DC1D13",
                    border: "2px solid #DC1D13",
                    height: "1.8rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#DC1D13",
                    color: "white",
                    height: "1.8rem",
                    "&:hover": {
                      background: "#DC1D13",
                    },
                  }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
