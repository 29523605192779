import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateZone = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateZoneDetails = async (form) => {
    
    let param = {
      zone_name:form?.zone_name,
      zone_id:form?.zone_id,
      status: form?.status,
      lat: form?.lat,
      lng: form?.lng,
    };
    
    const response = await Axios.post("update_zone", param);
    const data = await response.data;   
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }
  };
  return updateZoneDetails;
};

export default useUpdateZone;
