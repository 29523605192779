import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Stack,
} from "@mui/material";
import { useEffect } from "react";
import { BaseImgUrl } from "../../../utility/BaseURL";
import { ToastContainer } from "react-toastify";
import useUpdateGiftCategory from "../../../hooks/useUpdateGiftCategory";
import useGiftCategoryList from "../../../hooks/useGiftCategoryList";
import useGiftCategoryImageUpload from "../../../hooks/useGiftCategoryImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { resetGiftCategoryFormData } from "../../../slice/categorySlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditGiftCategory({
  setOpen,
  open,
  categoryEditId,
  categoryRowData,
}) {
  const handleClose = () => {
    setOpen(false);
    setError({});
    dispatch(resetGiftCategoryFormData());
  };
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const statusList = ["Active", "Inactive"];
  const [enable, setEnable] = React.useState(false);
  const [error, setError] = React.useState({});
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);
  //Api Calls
  const updateGiftCategoryDetails = useUpdateGiftCategory(form);
  const getAllGiftCategoryList = useGiftCategoryList();
  const getGiftCategoryImageUpload = useGiftCategoryImageUpload();
  
  const currentImageSelected = useSelector((state) => state.category.giftCategoryFormData);
  //useEffect
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      category_id: categoryEditId,
    }));
  }, [categoryEditId]);

  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      categoryRowData: categoryRowData,
      name:categoryRowData?.category_name,
      selectedImage:categoryRowData?.category_image
    }));
  }, [categoryRowData]);

  useEffect(() => {
    const formData = new FormData();
    formData.append("file", form?.category_image);
  }, [form?.category_image]);

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.name?.trim() === "" ||
      form.name?.trim() === undefined ||
      form.name?.trim() === null
    ) {
      err.name = "**Category Name Cannot be Empty**";
      success = false;
    }

    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    // } else if (
    //   imageDimensions.width !== 200 ||
    //   imageDimensions.height !== 200
    // ) {
    //   err.selectedImage = "**Image should be 200x200 pixels**";
    //   success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }
    setError(err);
    return success;
  };

  const handleImageChange = async(e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));
    setEnable(true);
    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };

     await getGiftCategoryImageUpload(formData);
     setError({});
    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
    await updateGiftCategoryDetails();
    await getAllGiftCategoryList();
    setOpen(false);
    setForm({});
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            

            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >
             
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <input
                  variant="outlined"
                  name="name"
                  value={form.name}
                  // placeholder={form?.categoryRowData?.category_name}
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {form?.categoryRowData?.status === true
                      ? "Active"
                      : "Inactive"}
                  </option>
                  {statusList.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}
              >
                <img
                  style={{ width: "2rem" }}
                  src={currentImageSelected?.full_path ?? categoryRowData?.category_image}
                />
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <div
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    Choose Image
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#DC1D13",
                    border: "2px solid #DC1D13",
                    height: "1.8rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#DC1D13",
                    color: "white",
                    height: "1.8rem",
                    ":hover": { backgroundColor: "#DC1D13" },
                  }}
                  onClick={handleSubmit}
                  disabled={enable}   
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
