import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
const tableData = ({ tableData }) => {
  const columns = [
    // { id: "pincode", label: "Pincode", minWidth: 130 },
    { id: "shape_id", label: "Area Name | Pincode", minWidth: 100 },
    { id: "sr_name", label: "SR Name", minWidth: 100 },
    { id: "retailer_no", label: "No. of Ret.", minWidth: 100 },
    { id: "calls", label: "Calls", minWidth: 100 },
    { id: "eco", label: "ECO", minWidth: 100 },
    { id: "time_stamp", label: "TimeStamp", minWidth: 100 },
    { id: "sales", label: "Sales", minWidth: 100 },
    { id: "Bubblegum", label: "Bubblegum", minWidth: 100 },
    { id: "Punch", label: "Punch", minWidth: 100 },
    { id: "Rockstar", label: "Rockstar", minWidth: 100 },
    { id: "Watermelon", label: "Watermelon", minWidth: 100 },
  ];
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "black",
      fontSize: "14px",
      fontWeight: "600",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <div
      style={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <TableContainer
        sx={{ maxHeight: 700, minWidth: "100%", borderRadius: "8px" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#fff" }}>
            <TableRow
              // className={styles.head}
              sx={{ backgroundColor: "#fff" }}
            >
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align="center"
                  // style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData && tableData.length !== 0 ? (
              <>
                {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                {tableData?.map((row, index) => {
                  const areaNames = row?.shape_details
                    ?.slice(0, 2)
                    ?.map((shape) => (shape?.area_name ? shape?.area_name : ""))
                    .join(", ");
                  const areaNamesTitle = row?.shape_details
                    ?.map((shape) => (shape?.area_name ? shape?.area_name : ""))
                    .join(", ");
                  const displayAreaNames =
                    areaNames?.trim()?.length === 0 || !/[^\s,]/.test(areaNames)
                      ? "N/A"
                      : areaNames;
                  return (
                    <TableRow hover role="checkbox" key={index}>
                      <>
                        {/* <TableCell align="center">{row?.pincode}</TableCell> */}
                        <TableCell align="left" title={areaNamesTitle}>
                          {displayAreaNames ?? "N/A"}
                        </TableCell>
                        <TableCell align="center">
                          {row?.first_name} {row?.last_name}
                        </TableCell>
                        <TableCell align="center">
                          {row?.retailer_count}
                        </TableCell>
                        <TableCell align="center">
                          {row?.today_call_count}
                        </TableCell>
                        <TableCell align="center">
                          {row?.today_sale_count}
                        </TableCell>
                        <TableCell align="center">{row?.hour_diff}</TableCell>
                        <TableCell align="center">
                          ₹{row?.today_sale_amount}
                        </TableCell>
                        <TableCell align="center">
                          {row?.quantity?.bubblegum ?? 0}
                        </TableCell>
                        <TableCell align="center">{row?.quantity?.punch ?? 0}</TableCell>
                        <TableCell align="center">{row?.quantity?.rockstar ?? 0}</TableCell>
                        <TableCell align="center">NA</TableCell>
                      </>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={tableData?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    /> */}
    </div>
  );
};

export default tableData;
