import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@mui/icons-material";
import {
  getCategoryFormData,
  getCategoryList,
  resetCategoryDromData,
} from "../../slice/categorySlice";
import useCategoryImageUpload from "../../hooks/useCategoryImageUpload";
import useAddCategory from "../../hooks/useAddCategory";
import { BaseImgUrl } from "../../utility/BaseURL";
import { ToastContainer, toast } from "react-toastify";
import useCategoryList from "../../hooks/useCategoryList";
import useUpdateCategory from "../../hooks/useUpdateCategory";
// import { getZoneList } from "../slice/appSlice";
// import useZoneList from "../hooks/useZoneList";
// import useStateList from "../hooks/useStateList";
// import useDistributorList from "../hooks/useDistributorList";
// import useAddDistributor from "../hooks/useAddDistributor";

const viewImgPath = `${BaseImgUrl}/category/`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditCategory({setOpen,open,categoryEditId,categoryRowData}) {
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(resetCategoryDromData());
    setError({});
  };
  
  useEffect(()=>{
    setForm((prev)=>({
      ...prev,
      category_id:categoryEditId,
    }))
  },[categoryEditId])

  useEffect(()=>{
    setForm((prev)=>({
      ...prev,
      hindi_name:categoryRowData?.hindi_name,
      gujarati_name: categoryRowData?.gujarati_name,
      categoryRowData:categoryRowData,
      name:categoryRowData?.category_name,
      selectedImage:categoryRowData?.category_image
    }))
  },[categoryRowData])
  const currentImageSelected = useSelector((state) => state.category.categoryFormData);
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({});
  const getCategoryImageUpload = useCategoryImageUpload();
  const addCategory = useAddCategory(form);
  const [enable, setEnable] = React.useState(false);
  const getAllCategoryList = useCategoryList();
  const statusList = ["Active", "Inactive"];
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);
  //   const zoneList = useSelector((store) => store.app.zoneList);
  //   const stateList = useSelector((store) => store.app.stateList);
  //   useZoneList();
  //   useStateList();
  //   const addDistributor = useAddDistributor(form);
  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);
   
  
  // useEffect(()=>{
  //   console.log(form, "form");
  // },[form])

  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const [error, setError] = React.useState({});
  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.name?.trim() === "" ||
      form.name?.trim() === undefined ||
      form.name?.trim() === null
    ) {
      err.name = "**Category Name Cannot be Empty**";
      success = false;
    }
    if (
      form.hindi_name?.trim() === "" ||
      form.hindi_name?.trim() === undefined ||
      form.hindi_name?.trim() === null
    ) {
      err.hindi_name = "**Hindi Name Cannot be Empty**";
      success = false;
    }
    if (
      form.gujarati_name?.trim() === "" ||
      form.gujarati_name?.trim() === undefined ||
      form.gujarati_name?.trim() === null
    ) {
      err.gujarati_name = "**Gujarati Name Cannot be Empty**";
      success = false;
    }
    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }
    setError(err);
    return success;
  };


  useEffect(() => {
    if(form?.category_image){
    const formData = new FormData();
    formData.append("file", form?.category_image);
    getCategoryImageUpload(formData);
    }
  }, [form?.category_image]);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));
    setEnable(true);
    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }
    const formData = new FormData();
    formData.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };

    getCategoryImageUpload(formData);
    // Clear any previous error
    setError({});

    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };

  const updateCategoryDetails=useUpdateCategory(form)

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (isValid()) {
      await updateCategoryDetails()
      await getAllCategoryList();
      setOpen(false);
      setForm({});
      dispatch(resetCategoryDromData());
    }
  };



  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}

            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}
            >

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Category Name</label>
                <input
                  variant="outlined"
                  name="name"
                  value={form?.name}
                  // placeholder={form?.categoryRowData?.category_name}
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.name}
                  </p>
                )}
              </Stack>

              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Category Name(Hindi)</label>
                <input
                  variant="outlined"
                  name="hindi_name"
                  placeholder="Enter category name"
                  value={form?.hindi_name ? form?.hindi_name : " " }
                  onChange={handleChange}
                  style={{
                    height: "1.8rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.hindi_name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.hindi_name}
                  </p>
                )}
              </Stack>
              
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Category Name(Gujarati)</label>
                <input
                  variant="outlined"
                  name="gujarati_name"
                  placeholder="Enter category name"
                  value={form?.gujarati_name ? form?.gujarati_name : " " }
                  onChange={handleChange}
                  style={{
                    height: "1.6rem",
                    paddingLeft: "0.5rem",
                    fontSize: "0.9rem",
                  }}
                />
                {error.gujarati_name && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.gujarati_name}
                  </p>
                )}
              </Stack>
              
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
              >
                <label>Status</label>
                <select
                  style={{
                    height: "1.8rem",
                    fontSize: "0.9rem",
                    cursor: "pointer",
                  }}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" disabled selected>
                    {form?.categoryRowData?.status===true?"Active":"Inactive"}
                  </option>
                  {statusList.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
                {error.category && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.category}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}
              >
                <img
                  style={{ width: "2rem" }}
                  src={currentImageSelected?.full_path ?? categoryRowData?.category_image}
                  currentImageSelected
                />
                <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                  <input
                    id="sku_image"
                    type="file"
                    size="small"
                    accept="image/*"
                    name="sku_image"
                    style={{
                      display: "none",
                    }}
                    onChange={handleImageChange}
                  />
                  <div
                    style={{
                      height: "1.8rem",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      width:"100%"
                    }}
                  >
                    Choose Image
                  </div>
                </label>
                {error.selectedImage && (
                  <p
                    style={{
                      color: "red",
                      justifyContent: "center",
                      display: "flex",
                      fontSize: "0.8rem",
                      marginTop: "-0.1rem",
                    }}
                  >
                    {error.selectedImage}
                  </p>
                )}
              </Stack>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.4rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#DC1D13",
                    border: "2px solid #DC1D13",
                    height: "1.8rem"
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{ background: "#DC1D13", color: "white",height: "1.8rem", ":hover":{backgroundColor:"#DC1D13"} }}
                  onClick={handleSubmit} disabled={enable}
                >
                  Submit
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
