import * as React from "react";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import categoryData from "./data.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import useCategoryList from "../../hooks/useCategoryList";

export default function TableContainerPage() {
  const categoryList = useSelector((store) => store.category.categoryList);
  useCategoryList();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Zone
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Total Outlets
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Total Sales
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Sales
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                No Sale
              </TableCell>
              <TableCell
                sx={{
                  background: "#F8F8F8",
                  fontSize: "16px",
                  color: "#5F6868",
                  fontWeight: "500",
                }}
                align="start"
                colSpan={1}
              >
                Active SE
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                return (
                  <>
                    <TableRow hover role="checkbox" tabIndex={-1} key={data.id}>
                      <TableCell align="start" colSpan={1}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.category_name}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.totalSku ?? "N/A"}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.status === true ? (
                          <Typography
                            sx={{
                              background: "#6EB443",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography
                            sx={{
                              background: "#58585A",
                              width: "max-content",
                              padding: "2px 10px 2px 12px",
                              borderRadius: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#FFFFFF",
                              fontSize: "9px",
                              fontWeight: "400",
                            }}
                          >
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        <Stack
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            width: "0.5rem",
                            gap: "0.5rem",
                          }}
                        >
                          <EditIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                          />
                          <DeleteIcon
                            sx={{
                              width: "1.2rem",
                              color: "#DC1D13",
                              cursor: "pointer",
                            }}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell align="start" colSpan={1}>
                        {data.totalSku ?? "N/A"}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={categoryList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
