import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import styles from "./styles/usermgttable.module.css";
import { styled } from "@mui/material/styles";
import { red, purple, white } from "@mui/material/colors";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { USERMGTRETAILERDETAIL } from "../constants/UserMgt/UserMgt";
import {
  getRetailerList,
  getRetailerUserDetails,
} from "../../slice/retailerSlice";
import useRetailerList from "../../hooks/useRetailerList";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

//   const StyledbodyTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//     },
//   }));

const RedButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#DC1D13",
    color: "white",
    border: "2px solid #DC1D13",
  },
  height: "30px",
  fontSize: "12px",
  border: "2px solid #DC1D13",
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});

const UserMgtRetailerTable = ({ filterList, Searchfilter, query, selectedStatus, selectedAreaName }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const retailerCount = useSelector((state) => state.retailer.retailerListCount);
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  // useEffect(() => {
  //   setPage(0);
  // }, [query]);
  const getRetailerList = useRetailerList(selectedStatus, selectedAreaName);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); 
  
    return () => {
      clearTimeout(handler);
    };
  }, [query]);
  
  useEffect(() => {
    const fetchData = async () => {
      const limit = rowsPerPage;
      const offset = page * rowsPerPage;
      await getRetailerList(limit, offset, debouncedQuery);
    };
    fetchData();
  }, [page, rowsPerPage, debouncedQuery, selectedStatus, selectedAreaName,zoneData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rowsPerPage
  };

  const columns = [
    { id: "zone", label: "Area Name | Pincode", minWidth: 130 },
    { id: "userid", label: "RetailerId", minWidth: 100 },
    { id: "name", label: "OwnerName", minWidth: 100 },
    { id: "surname", label: "ShopName", minWidth: 100 },
    { id: "mobile", label: "Ph No", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleClick = (row) => {
    dispatch(getRetailerUserDetails(row));
    document
      .getElementById("UserMgtRetailerDetail")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <TableContainer sx={{ maxHeight: 700, minWidth: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#c4c4c4" }}>
            <TableRow sx={{ backgroundColor: "#c4c4c4" }}>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align="center">
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterList && filterList.length !== 0 ? (
              filterList.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={index}
                    sx={
                      parseInt(row.visit_c) === 0
                        ? { backgroundColor: "white" }
                        : parseInt(row.visit_c) > 0 && parseInt(row.sale_c) === 0
                        ? { backgroundColor: "#FFDFDB" }
                        : parseInt(row.visit_c) > 0 && parseInt(row.sale_c) === 1
                        ? { backgroundColor: "#FEFFB4" }
                        : parseInt(row.visit_c) > 0 && parseInt(row.sale_c) > 1
                        ? { backgroundColor: "#E4FFC5" }
                        : null
                    }
                  >
                    <>
                      <TableCell align="center">{row.area_name ?? "N/A"}</TableCell>
                      <TableCell align="center">{row.retailer_id}</TableCell>
                      <TableCell align="center">
                        {row.first_name + " " + row.last_name}
                      </TableCell>
                      <TableCell align="center">{row.shop_name}</TableCell>
                      <TableCell align="center">{row.mobile_no}</TableCell>
                      <TableCell align="center">
                        {row.user_status === "inactive_reported"
                          ? "rejected"
                          : row.user_status}
                      </TableCell>
                      <TableCell align="center">
                        <RedButton
                          variant="outlined"
                          onClick={() => handleClick(row)}
                        >
                          Details
                        </RedButton>
                      </TableCell>
                    </>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell>No Data</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={retailerCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserMgtRetailerTable;
