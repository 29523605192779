import React, { useState, useEffect } from 'react';
import Styles from './styles/ApprovalCoinTransaction.module.css';
import TextField from '@mui/material/TextField';
import ApprovalCoinTransactionTable from './ApproveCoinTransactionTable';
import { useSelector } from 'react-redux';
import useReportedRetailersList from '../../hooks/useReportedRetailersList';
import ApporvalBottomForm from './ApporvalBottomForm';

const ApproveCoinTransaction = ({ token }) => {

    const Searchcolumn = [ "first_name", "last_name", "mobile_no", "shop_name"];
    const [q, setQ] = useState('');
    const currentzone = useSelector((state) => state.SFstatus.zone_id);
    const reportedRetailers = useSelector((state) => state.retailer.reportedRetailers);
    const getReportedRetailerList = useReportedRetailersList();

    useEffect(() => {
        getReportedRetailerList();
    }, [currentzone]);

    const Searchfilter = (datas) => {
        return datas?.filter((row) =>
            Searchcolumn.some(column =>
                row[column] !== null &&
                row[column] !== "undefined" &&
                row[column].length !== 0 ? (
                    column === "first_name" || column === "last_name" || column === "mobile_no" || column === "shop_name" ?
                        (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1) :
                        (row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1)
                ) : ("")
            )
        );
    }

    return (
        <div className={Styles.container}>
            <div className={Styles.searchContainer}>
                <div className={Styles.TitleContainer}>
                    <label className={Styles.Title}>Reported Retailers</label>
                </div>
                <div className={Styles.TextContainer}>
                    <TextField variant="outlined" size="small" placeholder='Search Retailer' sx={{ width: "100%" }} onChange={e => setQ(e.target.value)} />
                </div>
            </div>
            <div className={Styles.TableContainer}>
                <ApprovalCoinTransactionTable tableData={Searchfilter(reportedRetailers)} token={token} />
                <ApporvalBottomForm />
            </div>
        </div>
    );
};

export default ApproveCoinTransaction;