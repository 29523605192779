import React, { useEffect, useState } from "react";
import { MenuItem, Stack, Typography, Button } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";

import useShapeFileList from "../../../hooks/useShapeFileList";
import useInventoryList from "../../../hooks/useInventoryList";

const InventoryDropdown = ({ setdropDown, query, setQuery }) => {
  const getShapeFileList = useShapeFileList();

  const categoryList = useSelector((state) => state.category.categoryList);
  const areaNameList = useSelector((state) => state.app.shapeFileByZone);
  const zoneData = useSelector((state) => state.app.curretDropdownData);
  const [form, setForm] = React.useState({
    status: true,
    office_name: null,
    shape_id: null,
  });

  useEffect(() => {
    setdropDown(form)
  },[form]);
  const [selectedCategory, setSelectedCategory] = useState("");


  React.useEffect(() => {
    let category_id = null;
    let shape_id = null;
    if (categoryList && categoryList.length > 0) {
      setSelectedCategory(categoryList[0].category_name);
      category_id = categoryList[0].category_id;
      setForm((prev) => ({
        ...prev,
        category_id: categoryList[0].category_id,
        category_name: categoryList[0].category_name
      }));
    }
    if (areaNameList && areaNameList.length > 0) {
      shape_id = areaNameList[0].shape_id;
      setForm((prev) => ({
        ...prev,
        office_name: areaNameList[0].office_name,
        shape_id: areaNameList[0].shape_id,
        pincode: areaNameList[0].pincode,
      }));
    }
    if(category_id && shape_id){
      const param ={
        category_id: category_id,
        shape_id: shape_id
      }
       getInventoruList(param);
    }
  }, [categoryList, areaNameList]);

  useEffect(() => {
    const param = {
      type: 0,
    };
    getShapeFileList(param);
  }, [zoneData]);
 const getInventoruList = useInventoryList();
 const handleAreaChange = async (e) => {
   const selectedValue = e.target.value;
   let shape_id = null;
   let pincode = null;
   for (let i = 0; i < areaNameList.length; i++) {
     if (selectedValue === areaNameList[i].office_name) {
       shape_id = areaNameList[i].shape_id;
       pincode = areaNameList[i].pincode;
       break;
     }
   }
   setForm((prev) => ({
     ...prev,
     office_name: selectedValue,
     shape_id: shape_id,
     pincode: pincode,
   }));
   const param = {
     zone_id: zoneData?.zone_id,
     shape_id: shape_id,
     category_id: form.category_id,
   };
   await getInventoruList(param);
 };
 const handleCategoryChange = async (e) => {
   const selectedValue = e.target.value;
   let CategoryId = 0;
   let CategoryIdArray = [];
   let CategoryName = selectedValue;

   if (selectedValue !== "All") {
     for (let i = 0; i < categoryList.length; i++) {
       if (selectedValue === categoryList[i].category_name) {
         CategoryId = categoryList[i].category_id;
         CategoryIdArray.push(CategoryId);
         break;
       }
     }
   } else {
     CategoryIdArray = categoryList.map((category) => category.category_id);
   }
   
   // Update the state
   setForm((prev) => ({
     ...prev,
     category_name: CategoryName,
     category_id: CategoryId,
     CategoryIdArray: CategoryIdArray,
   }));
   const param = {
     zone_id: zoneData?.zone_id,
     shape_id: form?.shape_id,
     category_id: CategoryId,
   };
   await getInventoruList(param);
 };
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Box
      sx={{
        minWidth: 140,
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        width: "100%",
        justifyContent: "space-between",
        marginTop: "6px",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div>
          <TextField
            variant="outlined"
            style={{
              borderRadius: "4px",
              width: "185px",
              "& .MuiInputBase-root": {
                height: "35px",
              },
            }}
            size="small"
            // value={query}
            onChange={handleSearch}
            placeholder="Search SKU"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon /> {/* Assume SearchIcon is imported */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          {/* <InputLabel sx={{ top: "-15px", color: "#989797" }}>Area</InputLabel> */}
          <Select
            // labelId="sc-label"
            // id="sc-select"
            value={form?.office_name}
            onChange={(e)=>handleAreaChange(e)}
            size="small"
            sx={{ height: "35px", minWidth: 160 }}
            MenuProps={{
              PaperProps: {
                sx: {
                  "& .MuiList-root": {
                    display: "flex !important",
                    flexDirection: "column !important",
                  },
                },
              },
            }}
          >
            {areaNameList?.map((item, index) => (
              <MenuItem value={item.office_name} key={index}>
                {item.office_name} | {item.pincode}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: 160,
            backgroundColor: "#fff",
            "& .MuiSelect-select": { backgroundColor: "#fff" },
          }}
        >
          <InputLabel sx={{ top: "-15px", color: "#989797" }}>
            Category
          </InputLabel>
          <Select
            labelId="shape-id-label"
            id="shape-id-select"
            value={selectedCategory}
            size="small"
            sx={{ height: "35px", minWidth: 160 }}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              handleCategoryChange(e);
            }}
          >
            {categoryList
              ?.filter((sku) => sku.status === true)
              .map((item) => (
                <MenuItem value={item.category_name} key={item.category_id}>
                  {item.category_name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
};

export default InventoryDropdown;
