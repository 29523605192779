import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FormControl, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import useGiftCategoryList from "../../../hooks/useGiftCategoryList";
import useGiftSkuImageUpload from "../../../hooks/useGiftSkuImageUpload";
import useAddGiftSku from "../../../hooks/useAddGiftSku";
import useGiftSkuList from "../../../hooks/useGiftSkuList";
import { resetSkuFormData, resetSkuGiftFormData } from "../../../slice/skuSlice";
import { useEffect } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AddGiftSku() {
  const [open, setOpen] = React.useState(false);
  const [enable, setEnable] = React.useState(false);
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setForm({});
    setError({});
    dispatch(resetSkuGiftFormData());
  };
  
  const dispatch = useDispatch();
  const statusList = ["active", "inactive"];
  const [form, setForm] = React.useState({});
  const [error, setError] = React.useState({});
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = React.useState(0);

  const currentImageSelected = useSelector(
    (state) => state.sku.skuGiftFormData
  );

  useEffect(() => {
    setEnable(false);
  }, [currentImageSelected]);

  //Api Calls
  const getAllGiftCategoryList = useGiftCategoryList();
  const getGiftSkuImageUpload = useGiftSkuImageUpload();
  const getAllGiftSkuList = useGiftSkuList();
  const addGiftSku = useAddGiftSku(form);

  //Redux
  const giftCategoryList = useSelector(
    (store) => store.category.giftCategoryList
  );

  const isValid = () => {
    let success = true;
    let err = {};

    if (
      form.gift_name?.trim() === "" ||
      form.gift_name?.trim() === undefined ||
      form.gift_name?.trim() === null
    ) {
      err.gift_name = "**Gift Name Cannot be Empty**";
      success = false;
    }

    if (
      form.status?.trim() === "" ||
      form.status?.trim() === undefined ||
      form.status?.trim() === null
    ) {
      err.status = "**Status can't be unselected**";
      success = false;
    }
    if (
      form.selectedImage?.trim() === "" ||
      form.selectedImage?.trim() === undefined ||
      form.selectedImage?.trim() === null
    ) {
      err.selectedImage = "**Image should be selected**";
      success = false;
    // } else if (
    //   imageDimensions.width !== 200 ||
    //   imageDimensions.height !== 200
    // ) {
    //   err.selectedImage = "**Image should be 200x200 pixels**";
    //   success = false;
    } else if (imageSize > 50 * 1024) {
      err.selectedImage = "**Image size should be less than 50KB**";
      success = false;
    }

    if (
      !form.price ||
      form.price.trim() === "" ||
      form.price.trim() === undefined ||
      form.price.trim() === null ||
      parseFloat(form.price) <= 0
    ) {
      err.price = "**Price must be a non-zero positive value**";
      success = false;
    }
    
    if (
      !form.coins ||
      form.coins.trim() === "" ||
      form.coins.trim() === undefined ||
      form.coins.trim() === null ||
      parseInt(form.coins) <= 0
    ) {
      err.coins = "**Coins must be a non-zero positive value**";
      success = false;
    }
    
    if (
      !form.quantity ||
      form.quantity.trim() === "" ||
      form.quantity.trim() === undefined ||
      form.quantity.trim() === null ||
      parseInt(form.quantity) <= 0
    ) {
      err.quantity = "**Quantity must be a non-zero positive value**";
      success = false;
    }

    if (
      form?.category_id === 0 ||
      form?.category_id === undefined ||
      form?.category_id === null
    ) {
      err.category = "**Category Cannot be Empty**";
      success = false;
    }
    setError(err);
    return success;
  };
  // await getGiftSkuImageUpload(formData);
  const handleImageChange = async(e) => {
    const selectedImage = e.target.files[0];
    setForm((prev) => ({
      ...prev,
      [e.target.name]: selectedImage,
    }));
    setEnable(true);

    if (selectedImage.size > 50 * 1024) {
      setError({ selectedImage: "**Image size should be less than 50KB**" });
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);

    // Update image dimensions and size
    const img = new Image();
    img.src = URL.createObjectURL(selectedImage);

    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
      setImageSize(selectedImage.size);
    };
    await getGiftSkuImageUpload(formData);
    setError({});

    if (selectedImage) {
      setForm((prev) => ({
        ...prev,
        selectedImage: selectedImage.name,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        selectedImage: null,
      }));
    }
  };
  const handleChange = (e) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid()) {
      await addGiftSku();
      await getAllGiftSkuList();
      setOpen(false);
      setForm({});
    }
  };

  const handleCategory = (e) => {
    let categoryId = 0;
    for (let i = 0; i < giftCategoryList?.length; i++) {
      if (e.target.value === giftCategoryList[i]?.category_name) {
        categoryId = giftCategoryList[i]?.category_id;
        break;
      }
    }

    setForm((prev) => ({
      ...prev,
      category_id: categoryId,
    }));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        {userData?.role === "admin" ? (
          <Button
            onClick={handleOpen}
            sx={{
              height: "0.6rem",
              color: "white",
              background: "#DC1D13",
              padding: "1rem",
              "&:hover": {
                background: "#DC1D13",
              },
              marginTop: "6px",
            }}
          >
            <AddIcon
              sx={{
                fontSize: "14px",
                fontWeight: "700",
              }}
            />
            Add Gift
          </Button>
        ) : null}
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <FormControl
              fullWidth
              size="small"
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Stack
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                {/* Gift Name */}
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.4rem",
                  }}
                >
                  <input
                    variant="outlined"
                    name="gift_name"
                    placeholder="Enter Gift Name"
                    onChange={handleChange}
                    style={{
                      // width: "8rem",
                      height: "28px",
                      paddingLeft: "0.5rem",
                      fontSize: "0.9rem",
                    }}
                  />
                  {error.gift_name && (
                    <p
                      style={{
                        color: "red",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "0.8rem",
                        marginTop: "-0.1rem",
                      }}
                    >
                      {error.gift_name}
                    </p>
                  )}
                </Stack>

                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Select Category && Image Upload */}
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                    }}
                  >
                    {/* Category */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <select
                        style={{
                          height: "32px",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          width: "168px",
                        }}
                        name="category"
                        onChange={handleCategory}
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>
                        {giftCategoryList?.map(
                          (item) =>
                            // Only render the option if the status is true
                            item.status && (
                              <option key={item.id} value={item.category_name}>
                                {item.category_name}
                              </option>
                            )
                        )}
                      </select>
                      {error.category && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "0.6rem",
                            marginTop: "-0.1rem",
                          }}
                        >
                          {error.category}
                        </p>
                      )}
                    </Stack>
                    {/* Image Upload */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.4rem",
                      }}
                    >
                      <label htmlFor="sku_image" style={{ cursor: "pointer" }}>
                        <input
                          id="sku_image"
                          type="file"
                          size="small"
                          accept="image/*"
                          name="sku_image"
                          style={{
                            display: "none",
                          }}
                          onChange={handleImageChange}
                        />
                        <div
                          style={{
                            height: "9rem",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            background: "#F0F0F0",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#767676",
                              fontSize: "14px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "8rem",
                            }}
                          >
                            {form.selectedImage ? (
                              form.selectedImage
                            ) : (
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <FileUploadIcon />
                                <Typography
                                  sx={{ color: "#767676", fontSize: "14px" }}
                                >
                                  Upload Item Image
                                </Typography>
                              </Stack>
                            )}
                          </Typography>
                        </div>
                      </label>
                      {error.selectedImage && (
                        <p
                          style={{
                            color: "red",
                            justifyContent: "center",
                            display: "flex",
                            fontSize: "0.8rem",
                            marginTop: "-0.1rem",
                          }}
                        >
                          {error.selectedImage}
                        </p>
                      )}
                    </Stack>
                  </Stack>

                  {/* Price, Coins, Quantity, Status */}
                  <Stack
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.4rem",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* Price */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ width: "4rem" }}>Price</Typography>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.4rem",
                        }}
                      >
                        <input
                          variant="outlined"
                          name="price"
                          type="number"
                          // placeholder="$"
                          onChange={handleChange}
                          style={{
                            height: "28px",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            width: "99px",
                          }}
                        />
                        {error.price && (
                          <p
                            style={{
                              color: "red",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "0.6rem",
                              marginTop: "-0.1rem",
                            }}
                          >
                            {error.price}
                          </p>
                        )}
                      </Stack>
                    </Stack>

                    {/* Coins */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ width: "4rem" }}>Coins</Typography>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.4rem",
                        }}
                      >
                        <input
                          variant="outlined"
                          name="coins"
                          type="number"
                          placeholder="0"
                          onChange={handleChange}
                          style={{
                            height: "1.8rem",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            width: "99px",
                          }}
                        />
                        {error.coins && (
                          <p
                            style={{
                              color: "red",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "0.6rem",
                              marginTop: "-0.1rem",
                            }}
                          >
                            {error.coins}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                    {/* Quantity */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ width: "4rem" }}>Quantity</Typography>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.4rem",
                        }}
                      >
                        <input
                          variant="outlined"
                          name="quantity"
                          type="number"
                          placeholder="0"
                          onChange={handleChange}
                          style={{
                            height: "28px",
                            paddingLeft: "0.5rem",
                            fontSize: "0.9rem",
                            width: "99px",
                          }}
                        />
                        {error.quantity && (
                          <p
                            style={{
                              color: "red",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "0.55rem",
                              marginTop: "-0.1rem",
                            }}
                          >
                            {error.quantity}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                    {/* Status */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ width: "4rem" }}>Status</Typography>
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.4rem",
                        }}
                      >
                        <select
                          style={{
                            height: "32px",
                            fontSize: "0.9rem",
                            cursor: "pointer",
                            width: "110px",
                          }}
                          name="status"
                          onChange={handleChange}
                        >
                          <option value="" disabled selected>
                            Select
                          </option>
                          {statusList?.map((item) => (
                            // Only render the option if the status is true
                            <option>{item}</option>
                          ))}
                        </select>
                        {error.status && (
                          <p
                            style={{
                              color: "red",
                              justifyContent: "center",
                              display: "flex",
                              fontSize: "0.55rem",
                              marginTop: "-0.1rem",
                            }}
                          >
                            {error.status}
                          </p>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "0.8rem",
                }}
              >
                <Button
                  sx={{
                    background: "white",
                    color: "#FF5A5A",
                    border: "2px solid #FF5A5A",
                    height: "1.8rem",
                    padding: "1.1rem",
                  }}
                  onClick={handleClose}
                >
                  Discard
                </Button>
                <Button
                  sx={{
                    background: "#FF5A5A",
                    color: "white",
                    height: "1.8rem",
                    "&:hover": {
                      background: "#FF5A5A",
                    },
                    padding: "1.2rem",
                  }}
                  onClick={handleSubmit}
                  disabled={enable}
                >
                  <AddIcon
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  />
                  Add New Item
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </>
  );
}
