import { useEffect } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreaNameList,
  getRetailerList,
  getRetailerListCount,
  getUserStatusList,
} from "../slice/retailerSlice";

const useRetailerList = (selectedStatus, selectedAreaName) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const currentzone = useSelector((state) => state.SFstatus.zone_id);

  const getAllRetailerList = async (limit, offset, query) => {
    const param = {
      zone_id: currentzone,
      user_status: selectedStatus,
      area_name: selectedAreaName,
      limit: limit,
      offset: offset,
      search_keyword: query
    };
    try {
      const response = await Axios.post("/retailer_list", param);
      const data = await response.data;
      dispatch(getRetailerList(data.body));
      dispatch(getAreaNameList(data.areaNameList));
      dispatch(getUserStatusList(data.userStatusList));
      dispatch(getRetailerListCount(data.retailers_count));
    } catch (error) {
      console.error("Error fetching retailer list:", error);
    }
  };

  // useEffect(() => {
  //   getAllRetailerList();
  // }, [currentzone, selectedStatus, selectedAreaName]);

  return getAllRetailerList;
};

export default useRetailerList;
