import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
// import { DatePicker } from '@mui/lab';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useAddNewApk from "../../hooks/useAddNewApk";
import { ToastContainer, toast } from "react-toastify";

const AddApkForm = () => {
  const [form, setForm] = useState({
    apkUrl: "",
    apkVersion: "",
    isCompulsoryUpdate: false,
    releaseDate: "", // Today's date as default
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = type === "checkbox" ? checked : value;

    // Convert APK Version to integer if it's a valid number
    // if (
    //   name === "apkVersion" &&
    //   !isNaN(processedValue) &&
    //   parseFloat(processedValue) === parseInt(processedValue)
    // ) {
    //   processedValue = parseInt(processedValue);
    // }

    setForm((prevForm) => ({
      ...prevForm,
      [name]: processedValue,
    }));
  };

  useEffect(() => {
    console.log(form, "form");
  }, [form]);
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate APK URL
    if (!form.apkUrl) {
      isValid = false;
      newErrors.apkUrl = "APK URL is required";
    }

    // Validate APK Version
    if (!form.apkVersion) {
      isValid = false;
      newErrors.apkVersion = "APK Version is required";
    }
    //  else if (!Number.isInteger(Number(form.apkVersion))) {
    //   isValid = false;
    //   newErrors.apkVersion = "APK Version must be an integer Number";
    // }

    setErrors(newErrors);
    return isValid;
  };

  const addnewApk = useAddNewApk();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await addnewApk(form);
      setForm({
        apkUrl: "",
        apkVersion: "",
        isCompulsoryUpdate: false,
        releaseDate: new Date().toISOString().split("T")[0], // Reset release date to today
      });
    }
  };

  return (
    <Stack sx={{ alignItems: "center", width: "100%", marginTop: "20px" }}>
      <Typography variant="h3">Add New Apk</Typography>
      <Stack maxWidth="sm" sx={{ width: "100%", marginTop: "60px" }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="APK URL"
                variant="outlined"
                name="apkUrl"
                value={form.apkUrl}
                onChange={handleInputChange}
                error={!!errors.apkUrl}
                helperText={errors.apkUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="APK Version"
                variant="outlined"
                name="apkVersion"
                value={form.apkVersion}
                onChange={handleInputChange}
                error={!!errors.apkVersion}
                helperText={errors.apkVersion}
              />
            </Grid>
            <Grid item xs={12}>
              {/* <DatePicker
                label="Release Date"
                value={form.releaseDate}
                onChange={(date) => handleInputChange({ target: { name: 'releaseDate', value: date } })}
                renderInput={(params) => <TextField {...params} />}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    // value={form.releaseDate}
                    label="Release Date"
                    onChange={(date) => {
                      // Assuming date is in GMT
                      const dateValue = new Date(date); // Convert to Date object

                      // Get IST offset in milliseconds (5 hours and 30 minutes ahead of GMT)
                      const ISTOffset = 5.5 * 60 * 60 * 1000;

                      // Adjust the date with IST offset
                      const ISTDate = new Date(dateValue.getTime() + ISTOffset);

                      // If the adjusted time crosses the boundary of a day,
                      // adjust the date to the next day in IST timezone
                      if (ISTDate.getDate() !== dateValue.getDate()) {
                        ISTDate.setDate(dateValue.getDate() + 1);
                      }

                      // ISTDate now holds the date in IST timezone
                      console.log(ISTDate.toISOString(), "date in IST");

                      // If you need the date in string format without time
                      const ISTDateWithoutTime =
                        ISTDate.toISOString().split("T")[0];
                      handleInputChange({
                        target: {
                          name: "releaseDate",
                          value: ISTDateWithoutTime,
                        },
                      });
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isCompulsoryUpdate"
                    checked={form.isCompulsoryUpdate}
                    onChange={handleInputChange}
                  />
                }
                label="Compulsory Update"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  backgroundColor: "#DC1D13",
                  ":hover": { backgroundColor: "#DC1D13" },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Stack>
      <ToastContainer position="bottom-right" />
    </Stack>
  );
};

export default AddApkForm;
