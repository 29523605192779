import React from "react";
import { Stack, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ContactsIcon from "@mui/icons-material/Contacts";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import MapIcon from "@mui/icons-material/Map";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PublicIcon from "@mui/icons-material/Public";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import SellIcon from '@mui/icons-material/Sell';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
const Sidebar = () => {
  const userData = useSelector((state) => state?.login?.refreshToken?.user_data);
  return (
    <Box
      sx={{
        position: "relative",
        
      }}
    >
      <Box
        sx={{
          top: "0",
          left: "0",
          margin: "2px",
          marginTop: "5px",
          marginRight: "6px",
          backgroundColor: "#58585A", // Adjust the background color as needed
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          height: "calc(100vh - 99px)",
          padding: "20px",
          borderRadius: "16px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          position: "sticky", 
          top: 0,
        }}
      >
        {/* Sidebar content goes here */}
        <Tooltip title="Dashboard">
          <Typography>
          <Link to="/home/dashboard">
            <DashboardIcon
              fontSize="medium"
              sx={{ color: "#fff", cursor: "pointer" }}
            />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="User Management">
          <Typography>
            {/* <Link to="/usermgt"> */}
            <Link to="/home">
              <ContactsIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="Category Management">
          <Typography>
            <Link to="/home/category">
              <CategoryOutlinedIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        <Tooltip title="Basket Management">
          <Typography>
            <Link to="/home/giftCategory">
              <ShoppingCartIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        {userData?.role === "admin" || userData?.is_asm === true ||  userData?.role === "partner"?(
        <Tooltip title="Route Management">
          <Typography>
            <Link to="/home/routemgt">
              <AltRouteIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        ):(<>
        </>)}
        <Tooltip title="Approval Management">
          <Typography>
            <Link to="/home/approval">
              <SafetyDividerIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        { userData?.role === "admin"?(
        <Tooltip title="Coin Management">
          <Typography>
            <Link to="/home/coinmgt">
              <CurrencyRupeeRoundedIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>):(<>
        </>)} 
        
        <Tooltip title="Delivery Management">
          <Typography>
            <Link to="/home/deliverymgt">
              <LocalShippingIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        { userData?.role === "admin"?(
        <Tooltip title="Zone Management">
          <Typography>
            <Link to="/home/zonemgt">
              <PublicIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>):(<>
        </>)} 
        
        <Tooltip title="Sale Management">
          <Typography>
            <Link to="/home/salesmgt">
              <SellIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>
        
        { userData?.role === "admin"?(
        <Tooltip title="App URL Management">
          <Typography>
            <Link to="/home/addApk">
              <MobileFriendlyIcon
                fontSize="medium"
                sx={{ color: "#fff", cursor: "pointer" }}
              />
            </Link>
          </Typography>
        </Tooltip>):(<>
        </>)}

        <Typography
          sx={{
            fontSize: "12px",
            color: "#fff",
            position: "absolute",
            bottom: "10px",
            left:"15px"
          }}
        >
          v1.0.71
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
