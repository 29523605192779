import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateRetailer = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateRetailerDetails = async () => {
    
    let param = {
      user_status:form?.user_status,
      retailer_id: form?.retailer_id,
      first_name: form?.first_name,
      last_name: form?.last_name,
    };
    
    const response = await Axios.post("update_retailer", param);
    const data = await response.data;

    console.log(data, "parameter");
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateRetailerDetails;
};

export default useUpdateRetailer;
