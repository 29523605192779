import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "../slice/loginSlice";
import retailerSlice from "../slice/retailerSlice";
import categorySlice from "../slice/categorySlice";
import userSlice from "../slice/userSlice";
import distributorSlice from "../slice/distributorSlice";
import saleSlice from "../slice/saleSlice";
import appSlice from "../slice/appSlice";
import routeSlice from "../slice/routeSlice";
import adminMapSlice from "../slice/adminMapSlice";
import skuSlice from "../slice/skuSlice";
import assignSEslice from "../slice/assignSEslice";
import superCategorySlice from "../slice/superCategorySlice";
import seSlice from "../slice/seSlice";
import SFstatusSlice from "../slice/SFstatusSlice";
import coinSlice from "../slice/coinSlice";
import salesmanSlice from "../slice/salesmanSlice";
import dashboardSlice from "../slice/dashboardSlice";
import asmSlice from "../slice/asmSlice";
const store = configureStore({
  reducer: {
    login: loginSlice,
    retailer: retailerSlice,
    category: categorySlice,
    user: userSlice,
    distributor: distributorSlice,
    sale: saleSlice,
    app: appSlice,
    route: routeSlice,
    assignSE: assignSEslice,
    adminMap: adminMapSlice,
    sku: skuSlice,
    superCategory: superCategorySlice,
    se: seSlice,
    SFstatus: SFstatusSlice,
    coin: coinSlice,
    salesman: salesmanSlice,
    dashboard: dashboardSlice,
    asm: asmSlice
  },
});
export default store;
