
import { getCoinTable } from "../slice/coinSlice";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch } from "react-redux";


const useCoinRules = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();

  const getCoinRules = async (param) => {

    try {
      const response = await Axios.post("coin_rules");
      const data = response.data;      
      dispatch(getCoinTable(data.body));
      return data;  
    } catch (error) {
      console.error("Error fetching Coin Details", error);
      throw error;  
    }
  };
  return getCoinRules;
};

export default useCoinRules;

