import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const useUpdateFakeRetailer = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const updateFakeRetailer = async () => {
    
    let param = {
      user_status:form?.user_status,
      retailer_id: form?.retailer_id,
    };
    
    const response = await Axios.post("update_fake_retailer", param);
    const data = await response.data;

    console.log(data, "parameter");
    if (data.code === 200) {
      toast.success("Updated SuccessFully");
    } else {
      toast.error("Update Failed");
    }

    console.log(data);
  };
  // useEffect(() => {
  //   getAllUserList();
  // }, []);
  return updateFakeRetailer;
};

export default useUpdateFakeRetailer;
