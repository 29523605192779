import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { getASMDashboardData } from "../slice/appSlice";
const useASMDashboard = () => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.app.curretDropdownData);
  const getASMDashboard = async () => {
    try {
      const param = {
        zone_id: Data?.zone_id,
        sku_id: 89
      };
      const response = await Axios.post("asm_dashboard", param);
      const data = response.data;
      dispatch(getASMDashboardData(data.body));
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };
  return getASMDashboard;
};

export default useASMDashboard;
