import React, { useState, useEffect } from "react";
import styles from "./styles/midContainerUser.module.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";

import { useSelector, useDispatch } from "react-redux";

import exportFromJSON from "export-from-json";

import SalesmanMgtTable from "./ASMTable";
import AddSalesmanForm from "./AddSalesmanForm";
import useAddSalesman from "../../hooks/useAddSalesman";
import useSalesmanList from "../../hooks/useSalesmanList";
import SalesmanUpdateForm from "./SalesmanUpdateForm";
import AddASMForm from "./AddASMForm";
import useAddASM from "../../hooks/useAddASM";
import useASMList from "../../hooks/useASMList";
import ASMUpdateForm from "./ASMUpdateForm";

const RedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#DC1D13",
  "&:hover": {
    backgroundColor: "#DC1D13",
  },
}));

const ASMContainer = ({ asmZone }) => {
  const dispatch = useDispatch();
  // const [zonelist, setZonelist] = React.useState([]);
  const [RoleList, setRoleList] = useState(["All", "ASM", "TSI", "SE"]);
  const [StatusList, setStatusList] = useState([
    "All",
    "ACTIVE",
    "INACTIVE",
    "PENDING",
    "REJECT",
  ]);
  //   const Firsttoken = useSelector((state) => state.Token);
  const [Userdetail, setUserDetail] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedStateName, setSelectedStateName] = useState("ALL");
  const [selectedStateId, setSelectedStateId] = useState("ALL");
  const [selectedZoneName, setSelectedZoneName] = useState("ALL");
  const [selectedZoneId, setSelectedZoneId] = useState("ALL");
  const [q, setQ] = useState("");
  const [Role, setRole] = React.useState("All");
  const [Status, setStatus] = React.useState("All");
  const [searchColumn, setSearchColumn] = useState([
    "user_id",
    "user_code",
    "name",
    "surname",
    "mobile_no",
  ]);
  const [filterlist, setFilterlist] = useState([]);
  const userAllList = useSelector((store) => store.asm.asmList);
  //   const list = useSelector((state) => state.Zone.list);
  //   const userlist = useSelector((state) => state.UserMgt);
  const [token, setToken] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dropDownData = useSelector((state) => state.app.curretDropdownData);

  const userStatusList = useSelector((store) => store.asm.userStatusList);
  const stateNameList = useSelector((store) => store.asm.stateList);
  const zoneNameList = useSelector((store) => store.asm.zoneList);
  const addASM = useAddASM();

  const getAllASMList = useASMList(
    selectedZoneId,
    selectedStatus,
    selectedStateId
  );

  const handleAddASM = async (formData) => {
    await addASM(formData);
    await getAllASMList();
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const fileName = "ASM/TSI/SE";
  const exportType = "csv";
  const handleDownload = () => {
    let data = filterlist;

    exportFromJSON({ data, fileName, exportType });
  };

  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };
  const [filterUserList, setFilterUserList] = useState(null);

  useEffect(() => {
    if (userAllList) {
      setFilterUserList(userAllList);
    }
  }, [userAllList]);
  useEffect(() => {
    const filteredData = userAllList?.filter((user) => {
      const full_name = user?.name?.toLowerCase();
      return (
        full_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.mobile_no?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.user_role_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.zone_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.bank_name?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.email?.toLowerCase().includes(query?.toLowerCase()) ||
        user?.area_name?.toLowerCase().includes(query?.toLowerCase())
      );
    });

    setFilterUserList(filteredData);
  }, [query]);

  const handleStatusChange = (e) => {
    if (e.target.value === "ALL") {
      setSelectedStateId("ALL");
      setSelectedZoneId("ALL");
      setSelectedStateName("ALL");
      setSelectedZoneName("ALL");
    }
    setSelectedStatus(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedStateName(e.target.value);
    const selectedState = stateNameList?.find(
      (state) => state.state_name === e.target.value
    );
    setSelectedStateId(selectedState?.state_id);
  };

  const handleZoneChange = (e) => {
    setSelectedZoneName(e.target.value);
    const selectedZone = zoneNameList?.find(
      (zone) => zone?.zone_name === e.target.value
    );
    setSelectedZoneId(selectedZone?.zone_id);
  };

  return (
    <>
      <div
        className={styles.MidContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={styles.MidContainerOne}>
          <div className={styles.MidSubContainerOne}>
            <div
              className={styles.MidSubContainerOneList}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label className={styles.MidSubContainerOneLabel}>
                Search User
              </label>
              <input
                variant="outlined"
                // sx={{ minWidth: 200}}
                // size="small"
                value={query}
                onChange={handleSearch}
                placeholder="search...."
                style={{ height: "1.8rem" }}
              />
            </div>

            <div className={styles.MidSubContainerOneList}>
              <label className={styles.MidSubContainerOneLabel}>Status</label>
              <div className={styles.MidSubContainerOneList}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <Select
                      labelId="shape-id-status-label"
                      id="shape-id-status-select"
                      value={selectedStatus}
                      size="small"
                      sx={{ height: "1.8rem" }}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      {userStatusList?.map((status) => (
                        <MenuItem
                          key={status.user_status}
                          value={status.user_status}
                        >
                          {status.user_status?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <label className={styles.MidSubContainerOneLabel}>State</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedStateName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleStateChange(e)}
                  >
                    {stateNameList?.map((stateName) => (
                      <MenuItem
                        key={stateName?.state_name}
                        value={stateName?.state_name}
                      >
                        {stateName?.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <label className={styles.MidSubContainerOneLabel}>Zone</label>
              <Box sx={{ minWidth: 170 }}>
                <FormControl fullWidth>
                  <Select
                    disabled={selectedStateName === "ALL"}
                    labelId="area-name-label"
                    id="area-name-id-select"
                    value={selectedZoneName}
                    size="small"
                    sx={{ height: "1.8rem" }}
                    onChange={(e) => handleZoneChange(e)}
                  >
                    {zoneNameList?.map((zoneName) => (
                      <MenuItem
                        key={zoneName?.zone_name}
                        value={zoneName?.zone_name}
                      >
                        {zoneName?.zone_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div className={styles.MidSubContainerOneList}>
              {/* <Button style={{ backgroundColor: "#00b300", color: "white",marginTop:"-4px" }} onClick={handleDownload}>Export</Button> */}
            </div>
          </div>

          <div className={styles.MidSubContainerTwo}>
            <RedButton
              variant="contained"
              onClick={() => setIsDialogOpen(true)}
              sx={{ textTransform: "none" }}
            >
              Add
            </RedButton>

            <AddASMForm
              open={isDialogOpen}
              onClose={() => setIsDialogOpen(false)}
              onSubmit={handleAddASM}
            />
          </div>
        </div>
        <div className={styles.MidContainerTwo}>
          <SalesmanMgtTable
            filterlist={filterUserList}
            detail={
              filterlist &&
              filterlist.length !== 0 &&
              filterlist !== "undefined"
                ? Userdetail
                : {}
            }
            query={query}
            setUserDetail={setUserDetail}
          />
        </div>
      </div>

      <div id="AsmUserUpdate">
        <div>
          <div>
            <div>
              <div className={styles.UserMgtBottomContainer}>
                {/* {filterlist &&
                filterlist.length !== 0 &&
                filterlist !== "undefined" ? ( */}
                <ASMUpdateForm
                  asmZone={asmZone}
                  detail={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? Userdetail
                      : {}
                  }
                  list={
                    filterlist &&
                    filterlist.length !== 0 &&
                    filterlist !== "undefined"
                      ? filterlist
                      : []
                  }
                  refreshUserList={getAllASMList}
                />
                {/* ) : (
                  <h5>No Data</h5>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ASMContainer;
