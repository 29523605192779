import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import styles from "../styles/UserMgtWholesalerTable.module.css";
import { USERMGTGETWHOLESALERDETAILS } from "../../constants/UserMgt/UserMgt";
import useDistributorList from "../../../hooks/useDistributorList";
import { getDistributorDetails } from "../../../slice/distributorSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E5E5E5",
    color: "black",
    fontSize: "14px",
    fontWeight: "600",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const RedButton = styled(Button)(({ theme }) => ({
  color: "#DC1D13",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "#DC1D13",
    color: "white",
    border: "2px solid #DC1D13",
  },
  border: "2px solid #DC1D13",
  height: "30px",
  fontSize: "12px",
}));

const useStyles = makeStyles({
  head: {
    borderBottom: "2px solid black",
  },
});

const UserMgtWholesalerTable = ({
  filterlist,
  Searchfilter,
  query,
  setUserDetail,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // useDistributorList();
  const distributorAllList = useSelector(
    (store) => store.distributor.distributorList
  );

  useEffect(() => {
    setRowsPerPage(10);
    setPage(0);
  }, [query]);

  // console.log(distributorAllList)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "Shape Id", label: "Area Name | Pincode", minWidth: 130 },
    { id: "laabhcode", label: "Laabh Partner Code", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    // { id: "surname", label: "Surname", minWidth: 100 },
    { id: "mobile", label: "Mobile No", minWidth: 100 },
    { id: "status", label: "Status", minWidth: 100 },
    { id: "email", label: "Email", minWidth: 100 },
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleDetails = (row) => {
    dispatch(getDistributorDetails(row));
    document
      .getElementById("UserMgtWholesalerUpdateFormScrollView")
      .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setUserDetail(row);
  };

  return (
    <div style={{ width: "100%", marginTop: "10px" }}>
      <Paper>
        <TableContainer sx={{ maxHeight: 700, minWidth: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              className={styles.head}
              sx={{ backgroundColor: "#c4c4c4" }}
            >
              <TableRow
                className={styles.head}
                sx={{ backgroundColor: "#c4c4c4" }}
              >
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align="center"
                    // style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterlist &&
              filterlist !== "undefined" &&
              filterlist?.length !== 0 ? (
                <>
                  {filterlist
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const areaNames = row?.shape_details
                        ?.map((shape) => shape?.area_name)
                        .slice(0, 2) // Take only the first 2 elements
                        .join(", ");
                        const areaNamesTitle = row?.shape_details
                        ?.map((shape) => shape?.area_name)
                        .join(", ");
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={index}
                          // sx={
                          //   detail && detail.user_id === row.user_id
                          //     ? { backgroundColor: "#faebf0" }
                          //     : { backgroundColor: "white" }
                          // }
                        >
                          <>
                            <TableCell align="center" title={areaNamesTitle}>
                              {areaNames ?? "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {row?.partner_code?.toUpperCase() ?? "N/A"}
                            </TableCell>
                            <TableCell align="center">{row?.name}</TableCell>
                            {/* <TableCell align="center">{row?.last_name}</TableCell> */}
                            <TableCell align="center">
                              {row.mobile_no}
                            </TableCell>
                            <TableCell align="center">
                              {row?.user_status}
                            </TableCell>
                            <TableCell align="center">{row?.email}</TableCell>
                            <TableCell align="center">
                              <RedButton
                                variant="outlined"
                                onClick={() => handleDetails(row)}
                              >
                                Details
                              </RedButton>
                            </TableCell>
                          </>
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={12}>
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterlist && filterlist.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default UserMgtWholesalerTable;
