import React from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const useAddInventoryV2 = (form) => {
  const Axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const skuFormData = useSelector(
    (store) => store.sku.skuFormData
  );
  const SFId = useSelector((store) => store.sku.selectedSF);
  const addInventory = async () => {
    let param = {
      sku_name: form?.sku_name,
      hindi_name:form?.hindi_name,
      gujarati_name: form?.gujarati_name,
      sku_image: skuFormData?.full_path,
      category_id:form?.category_id,
      ptr:form?.ptr,
      inventory:form?.inventory,
      ptw:form?.ptw,
      mrp:form?.mrp,
      scheme:form?.scheme,
      selling_price:Number(form?.selling_price), 
      enable_slab:form?.enable_slab,
      sku_scope:SFId,
      shape_id:SFId,
      fromOne: form?.fromOne,
      toOne: form?.toOne,
      priceOne: form?.priceOne,
      priceTwo: form?.priceTwo,
      toTwo: form?.toTwo,
      fromTwo: form?.fromTwo,
      fromThree: form?.fromThree,
      toThree: form?.toThree,
      priceThree: form?.priceThree
    };
    // console.log(param, "distributorList");
    const response = await Axios.post("v2/add_sku", param);
    const data = await response.data;
    console.log(data, "distributorList");
    if (data.code === 200) {
      toast.success("Added SuccessFully");
    } else {
      toast.error(data.message);
    }
  };
  return addInventory;
};

export default useAddInventoryV2 ;
